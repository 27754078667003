import {
  BannersDto,
  FeedPersonalizationBannerDto,
  FullNameConfirmationBannerDto,
  NpsDto,
  PortalMergeAnnouncementBannerDto,
  PortalMergeDataMigrationBannerDto,
  PortalMergeFeedBannerDto,
  TermsAndConditionsBannerDto,
  WelcomeScreenDto,
  PortalMergeGoToPortalBannerDto,
  ListerActivationBannerDto,
  OnboardingModalDto,
  MultiVariantsOnboardingModalDto,
  BusinessAccountBannerDto,
  CatalogRulesBannerDto,
  PortalMergeItemViewBannerDto,
  TaxpayerBannerDto,
  EprBannerDto,
} from 'types/dtos'

import {
  BannersModel,
  FeedPersonalizationBannerModel,
  FullNameConfirmationBannerModel,
  NpsModel,
  PortalMergeBannerModel,
  PortalMergeDataMigrationBannerModel,
  PortalMergeFeedBannerModel,
  TermsAndConditionsBannerModel,
  ListerActivationBannerModel,
  OnboardingModalModel,
  MultiVariantsOnboardingModalModel,
  BusinessAccountBannerModel,
  CatalogRulesBannerModel,
  PortalMergeItemViewBannerModel,
  TaxpayerBannerModel,
  EprBannerModel,
} from 'types/models'

export const transformFeedPersonalization = ({
  title,
  subtitle,
  action_title,
  bottom_title,
  bottom_subtitle,
  bottom_action_title,
}: FeedPersonalizationBannerDto): FeedPersonalizationBannerModel => ({
  title,
  subtitle,
  actionTitle: action_title,
  bottomTitle: bottom_title,
  bottomSubtitle: bottom_subtitle,
  bottomActionTitle: bottom_action_title,
})

export const transformNps = ({
  question_title,
  open_question_title,
  score_explanation,
}: NpsDto): NpsModel => ({
  questionTitle: question_title,
  openQuestionTitle: open_question_title,
  scoreExplanation: score_explanation,
})

export const transformFullNameConfirmation = ({
  button_title,
  hint,
  placeholder,
  real_name,
  subtitle,
  title,
}: FullNameConfirmationBannerDto): FullNameConfirmationBannerModel => ({
  buttonTitle: button_title,
  hint,
  placeholder,
  realName: real_name,
  subtitle,
  title,
})

export const transformTermsAndConditions = ({
  title,
  subtitle,
  button_title,
}: TermsAndConditionsBannerDto): TermsAndConditionsBannerModel => ({
  title,
  subtitle,
  buttonTitle: button_title,
})

export const transformPortalMergeFeed = ({
  heading,
  text,
  cta_title,
  cta_url,
}: PortalMergeFeedBannerDto): PortalMergeFeedBannerModel => ({
  heading,
  text,
  buttonTitle: cta_title,
  buttonUrl: cta_url,
})

export const transformPortalMergeAnnouncement = ({
  animation_url,
  image_url,
  heading,
  text,
  text_link_title,
  text_link_url,
  cta_title,
  cta_url,
  close_title,
  close_url,
}: PortalMergeAnnouncementBannerDto): PortalMergeBannerModel => ({
  animationUrl: animation_url,
  imageUrl: image_url,
  heading,
  text,
  textLinkTitle: text_link_title,
  textLinkUrl: text_link_url,
  primaryActionTitle: cta_title,
  primaryActionUrl: cta_url,
  closeActionTitle: close_title,
  closeActionUrl: close_url ?? undefined,
})

export const transformMergeWelcomeScreen = ({
  image_url,
  heading,
  text,
  text_link_title,
  text_link_url,
  main_cta_title,
  main_cta_url,
  secondary_web_cta_title,
}: WelcomeScreenDto): PortalMergeBannerModel => ({
  animationUrl: undefined,
  imageUrl: image_url,
  heading,
  text,
  textLinkTitle: text_link_title,
  textLinkUrl: text_link_url,
  primaryActionTitle: main_cta_title,
  primaryActionUrl: main_cta_url,
  closeActionTitle: secondary_web_cta_title,
})

export const transformPortalMergeDataMigration = ({
  cta_title,
  heading,
  text,
}: PortalMergeDataMigrationBannerDto): PortalMergeDataMigrationBannerModel => ({
  heading,
  text,
  ctaTitle: cta_title,
})

export const transformGoToOldPortalBanner = ({
  image_url,
  heading,
  text,
  main_cta_title,
  main_cta_url,
  secondary_cta_title,
  secondary_cta_url,
}: PortalMergeGoToPortalBannerDto): PortalMergeBannerModel => ({
  animationUrl: undefined,
  heading,
  text,
  imageUrl: image_url,
  primaryActionTitle: main_cta_title,
  primaryActionUrl: main_cta_url || undefined,
  closeActionTitle: secondary_cta_title,
  closeActionUrl: secondary_cta_url || undefined,
})

export const transformListerActivationBanner = ({
  image_urls,
  catalog_id,
  title,
  subtitle,
  button_link_text,
  button_link_url,
}: ListerActivationBannerDto): ListerActivationBannerModel => ({
  imageUrls: image_urls,
  catalogId: catalog_id,
  title,
  subtitle,
  buttonLinkText: button_link_text,
  buttonLinkUrl: button_link_url,
})

export const transformListerActivationBanners = (
  banners?: Array<ListerActivationBannerDto>,
): Array<ListerActivationBannerModel> =>
  banners ? banners.map(transformListerActivationBanner) : []

export const transformOnboardingModal = ({
  name,
  steps,
}: OnboardingModalDto): OnboardingModalModel => ({
  name,
  steps: steps.map(step => ({
    name: step.name,
    title: step.title,
    body: step.body,
    imageUrl: step.image_url,
    video: step.video && {
      vertical: {
        formats: step.video.vertical.formats,
        imageUrl: step.video.vertical.image_url,
      },
    },
  })),
  isClosed: false,
})

export const transformMultiVariantsOnboardingModal = ({
  name,
  actions,
  steps,
}: MultiVariantsOnboardingModalDto): MultiVariantsOnboardingModalModel => ({
  name,
  actions,
  steps: steps.map(step => ({
    name: step.name,
    title: step.title,
    body: step.body,
    imageUrl: step.image_url,
    video: step.video && {
      vertical: {
        formats: step.video.vertical.formats,
        imageUrl: step.video.vertical.image_url,
      },
    },
  })),
  isClosed: false,
})

export const transformBusinessAccountBanner = ({
  title,
  body,
  cancel_button_label,
  continue_button_label,
  continue_button_url,
}: BusinessAccountBannerDto): BusinessAccountBannerModel => ({
  title,
  body,
  cancelButtonLabel: cancel_button_label,
  continueButtonLabel: continue_button_label,
  continueButtonUrl: continue_button_url,
})

export const transformEprBanner = ({
  name,
  title,
  body,
  add_uin_button_label,
  add_uin_button_url,
  learn_more_button_label,
  learn_more_button_url,
}: EprBannerDto): EprBannerModel => ({
  name,
  title,
  body,
  addUinButtonLabel: add_uin_button_label,
  addUinButtonUrl: add_uin_button_url,
  learnMoreButtonLabel: learn_more_button_label,
  learnMoreButtonUrl: learn_more_button_url,
})

export const transformCatalogRulesBanner = ({
  name,
  title,
  subtitle,
  action_title,
  action_url,
}: CatalogRulesBannerDto): CatalogRulesBannerModel => ({
  name,
  title,
  subtitle,
  actionTitle: action_title,
  actionUrl: action_url,
})

export const transformPortalMergeItemViewBanner = ({
  cta_title,
  cta_url,
  bottom_text,
  bottom_text_link_title,
  bottom_text_link_url,
}: PortalMergeItemViewBannerDto): PortalMergeItemViewBannerModel => ({
  ctaTitle: cta_title,
  ctaUrl: cta_url,
  bottomText: bottom_text,
  bottomTextLinkTitle: bottom_text_link_title,
  bottomTextLinkUrl: bottom_text_link_url,
})

export const transformTaxpayerBanner = ({
  news_feed,
  type,
  title,
  body,
  is_special_verification,
  version,
  actions,
  id,
  show_in_screens,
}: TaxpayerBannerDto): TaxpayerBannerModel => ({
  newsFeed: {
    dismissed: news_feed.dismissed,
    isDismissible: news_feed.is_dismissible,
    showModal: news_feed.show_modal,
    forceRedirect: news_feed.force_redirect,
  },
  type,
  title,
  body,
  isSpecialVerification: is_special_verification,
  version,
  id,
  actions: actions?.map(action => ({
    title: action.title,
    link: action.link,
    type: action.type,
  })),
  showInScreens: show_in_screens,
})

export const transformBanners = ({
  feed_personalization_banner,
  nps,
  email_confirmation,
  full_name_confirmation,
  terms_and_conditions,
  portal_merge_feed,
  portal_merge_source_announcement,
  portal_merge_welcome_screen,
  portal_merge_data_migration,
  lister_activation,
  onboarding_modal,
  multi_variants_onboarding_modal,
  business_account,
  business_account_epr,
  catalog_rules,
  portal_merge_item_view_banner,
  taxpayer_banner,
  first_time_listing_guideline_banner,
}: BannersDto): BannersModel => ({
  feedPersonalizationBanner:
    feed_personalization_banner && transformFeedPersonalization(feed_personalization_banner),
  nps: nps && transformNps(nps),
  emailConfirmation: email_confirmation,
  fullNameConfirmation:
    full_name_confirmation && transformFullNameConfirmation(full_name_confirmation),
  termsAndConditions: terms_and_conditions && transformTermsAndConditions(terms_and_conditions),
  portalMergeFeed: portal_merge_feed && transformPortalMergeFeed(portal_merge_feed),
  portalMergeSourceAnnouncement:
    portal_merge_source_announcement &&
    transformPortalMergeAnnouncement(portal_merge_source_announcement),
  portalMergeWelcomeScreen:
    portal_merge_welcome_screen && transformMergeWelcomeScreen(portal_merge_welcome_screen),
  portalMergeDataMigration:
    portal_merge_data_migration && transformPortalMergeDataMigration(portal_merge_data_migration),
  listerActivation: transformListerActivationBanners(lister_activation),
  onboardingModal: onboarding_modal && transformOnboardingModal(onboarding_modal),
  multiVariantsOnboardingModal:
    multi_variants_onboarding_modal &&
    transformMultiVariantsOnboardingModal(multi_variants_onboarding_modal),
  businessAccount: business_account && transformBusinessAccountBanner(business_account),
  businessAccountEpr: business_account_epr && transformEprBanner(business_account_epr),
  catalogRules: catalog_rules && transformCatalogRulesBanner(catalog_rules),
  portalMergeItemViewBanner:
    portal_merge_item_view_banner &&
    transformPortalMergeItemViewBanner(portal_merge_item_view_banner),
  taxpayerBanner: taxpayer_banner && transformTaxpayerBanner(taxpayer_banner),
  firstTimeListingGuidelineBanner: first_time_listing_guideline_banner,
})
