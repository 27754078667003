import { takeEvery, put, apply } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import { transformReturnShippingOptionDto } from 'data/transformers/return-shipping-option'
import { transformPickUpDatesDto } from 'data/transformers/pick-up-dates'
import { transformShippingInstructionsDto } from 'data/transformers/shipping-instructions'
import { transformCollectionDates } from 'data/transformers/collection-dates'
import { transformLabelOptions } from 'data/transformers/label-options'
import { transformTransaction } from 'data/transformers/transaction'
import { transformShippingOrderPackageSizesDto } from 'data/transformers/shipping-orders-package-sizes'

import * as api from 'data/api'
import { HttpStatus, ResponseCode } from 'data/api/response-codes'

import { navigateToPage } from 'libs/utils/window'
import { tracker } from 'libs/common/tracker'
import {
  conversationReplyEvent,
  startConversationEvent,
  conversationHarassmentEvent,
  abTestExposeEvent,
  makeOfferEvent,
} from 'libs/common/event-tracker/events'

import { getAbTestByName } from 'state/ab-tests/selectors'
import { getUserId, getExposee } from 'state/session/selectors'

import { CONVERSATION_URL } from 'constants/routes/inbox'
import { AbTestVariant } from 'constants/abtest'

import * as statelessActions from './actions'
import { actions } from './slice'
import {
  transformTrackingJourneySummary,
  transformOfferRequestOptions,
  transformShipmentInstructions,
  transformDigitalLabel,
  transformConversationResponse,
} from './transformers'
import {
  getConversation as selectConversation,
  getWasHarassmentWarningOpen,
  getScoredHarassmentMessage,
  getScoredHarassmentMessageCorrelationId,
  getOfferRequestSessionId,
} from './selectors'

function* translateConversation({
  payload: { conversationId, translate },
}: ReturnType<typeof statelessActions.translateConversationRequest>) {
  const response = yield* call(api.translateConversation, {
    conversationId,
    translate,
  })

  if ('errors' in response) return

  yield put(
    actions.getConversationSuccess({
      conversation: transformConversationResponse(response.conversation),
      status: response.status,
    }),
  )
}

export function* getConversation({
  payload: { conversationId, fromPostReply = false },
}: ReturnType<typeof statelessActions.getConversationRequest>) {
  yield put(actions.getConversationUiPending({ fromPostReply }))

  if (Number.isNaN(Number(conversationId))) {
    // TODO: backend returns 200 when passed a string. Should be fixed in backend
    yield put(actions.getConversationUiFailure({ error: null, status: HttpStatus.NotFound }))

    return
  }

  const response = yield* call(api.getConversation, conversationId)
  const conversation = yield* select(selectConversation)
  const exposee = yield* select(getExposee)
  const firstTimeListerEducationAbTest = yield* select(
    getAbTestByName('first_time_lister_education_v2'),
  )

  // Braze conversations are updated synchronously, hence we need to avoid overriding Braze conversation in store
  if (conversation && !fromPostReply) return

  if ('errors' in response) {
    yield put(
      actions.getConversationUiFailure({
        error: response.errors[0]?.value || null,
        status: response.status,
      }),
    )

    return
  }

  const transformedConversation = transformConversationResponse(response.conversation)

  if (transformedConversation.transactionId) {
    yield put(
      actions.fetchTransactionRequest({ transactionId: transformedConversation.transactionId }),
    )
  }

  yield put(
    actions.getConversationSuccess({
      conversation: transformedConversation,
      status: response.status,
    }),
  )

  if (transformedConversation.isFirstTimeListerEducationRequired) {
    if (!firstTimeListerEducationAbTest) return

    const exposeEvent = abTestExposeEvent({ ...exposee, ...firstTimeListerEducationAbTest })

    yield apply(tracker, tracker.track, [exposeEvent])

    if (firstTimeListerEducationAbTest.variant !== AbTestVariant.Off) {
      yield put(actions.setFirstTimeListerEducationModalVisible({ isVisible: true }))
    }
  }
}

export function* getTrackingJourneySummary({
  payload: { transactionId },
}: ReturnType<typeof actions.getTrackingJourneySummaryRequest>) {
  const response = yield* call(api.getTrackingJourneySummary, {
    transactionId,
  })

  if ('errors' in response) {
    yield put(
      actions.getTrackingJourneySummaryFailure({ error: response?.errors?.[0]?.value || null }),
    )

    return
  }

  yield put(
    actions.getTrackingJourneySummarySuccess(
      transformTrackingJourneySummary(response.journey_summary),
    ),
  )
}

export function* getShipmentInstructions({
  payload: { transactionId },
}: ReturnType<typeof actions.getShipmentInstructionsRequest>) {
  const response = yield* call(api.getShipmentInstructions, {
    transactionId,
  })

  if ('errors' in response) {
    yield put(actions.getShipmentInstructionsFailure({ error: response.errors[0]?.value || null }))

    return
  }

  if (response.shipment_instructions) {
    yield put(
      actions.getShipmentInstructionsSuccess(
        transformShipmentInstructions(response.shipment_instructions),
      ),
    )
  }

  if (response.shipment_instructions.return_shipping_option) {
    yield put(
      actions.getReturnShipmentOptionSuccess(
        transformReturnShippingOptionDto(response.shipment_instructions.return_shipping_option),
      ),
    )
  }
}

export function* postReply({
  payload: { value, photoTempUuids },
}: ReturnType<typeof actions.postReplyRequest>) {
  const userId = yield* select(getUserId)
  const conversation = yield* select(selectConversation)
  const wasHarassmentWarningOpen = yield* select(getWasHarassmentWarningOpen)
  const scoredHarassmentMessage = yield* select(getScoredHarassmentMessage)
  const correlationId = yield* select(getScoredHarassmentMessageCorrelationId)

  if (!conversation?.id || !userId) return

  const response = yield* call(api.postReply, {
    conversationId: conversation.id,
    body: value,
    photoTempUuids,
  })

  if (response?.code === ResponseCode.HarassmentInMessageDetected && 'errors' in response) {
    yield put(
      actions.setHarassmentMessage({
        messageText: value || '',
        correlationId: response.payload?.correlation_id ?? null,
      }),
    )

    return
  }

  if (response?.code === ResponseCode.EmailSharingDetected && 'errors' in response) {
    yield put(actions.setEmailSharingModalVisible({ isVisible: true }))
  }

  if (wasHarassmentWarningOpen) {
    const harassmentEvent = conversationHarassmentEvent({
      wasEdited: scoredHarassmentMessage !== value,
      conversationId: conversation.id,
      correlationId,
    })

    yield put(actions.clearHarassmentMessage())

    yield apply(tracker, tracker.track, [harassmentEvent])
  }

  if ('errors' in response) {
    yield put(actions.postReplyFailure({ error: response?.errors?.[0]?.value || null }))

    return
  }

  if (conversation.isSuspicious) yield* call(api.deleteConversationSuspicion, conversation.id)

  yield put(actions.postReplySuccess())
  yield put(
    statelessActions.getConversationRequest({
      conversationId: conversation.id,
      fromPostReply: true,
    }),
  )

  const replyEvent = conversationReplyEvent({ userId, itemId: conversation.itemId ?? null })

  yield apply(tracker, tracker.track, [replyEvent])
}

export function* createConversationThread({
  payload: { recipientId, value, photoTempUuids },
}: ReturnType<typeof actions.createConversationThreadRequest>) {
  const userId = yield* select(getUserId)
  const wasHarassmentWarningOpen = yield* select(getWasHarassmentWarningOpen)
  const scoredHarassmentMessage = yield* select(getScoredHarassmentMessage)
  const correlationId = yield* select(getScoredHarassmentMessageCorrelationId)

  if (!userId) return

  const response = yield* call(api.createConversationThread, {
    recipientId,
    photoTempUuids,
  })

  if (response?.code === ResponseCode.EmailSharingDetected && 'errors' in response) {
    yield put(actions.setEmailSharingModalVisible({ isVisible: true }))
  }

  if (response?.code === ResponseCode.HarassmentInMessageDetected && 'errors' in response) {
    yield put(
      actions.setHarassmentMessage({
        messageText: value || '',
        correlationId: response.payload?.correlation_id ?? null,
      }),
    )

    return
  }

  if (wasHarassmentWarningOpen) {
    const conversation = yield* select(selectConversation)

    const harassmentEvent = conversationHarassmentEvent({
      wasEdited: scoredHarassmentMessage !== value,
      conversationId: conversation?.id || null,
      correlationId,
    })

    yield put(actions.clearHarassmentMessage())

    yield apply(tracker, tracker.track, [harassmentEvent])
  }

  if ('errors' in response) {
    yield put(actions.createConversationThreadFailure({ error: response.errors[0]?.value || null }))

    return
  }

  if ('conversation' in response) {
    const conversationStartEvent = startConversationEvent({ userId, itemId: null })

    yield* call(api.postReply, {
      conversationId: response.conversation.id,
      body: value,
      photoTempUuids,
    })

    yield apply(tracker, tracker.track, [conversationStartEvent])
    navigateToPage(CONVERSATION_URL(response.conversation.id))
  }
}

export function* createItemConversationThread({
  payload: { itemId, sellerId, initiator },
}: ReturnType<typeof actions.createItemConversationThreadRequest>) {
  const response = yield* call(api.createItemConversationThread, {
    itemId,
    initiator,
    receiverId: sellerId,
  })

  if ('errors' in response) {
    yield put(
      actions.createItemConversationThreadFailure({ error: response.errors[0]?.value || null }),
    )

    return
  }

  if (!('conversation' in response)) return

  const conversation = transformConversationResponse(response.conversation)

  yield put(actions.createItemConversationThreadSuccess({ conversation }))
}

export function* enterTrackingCode({
  payload: { transactionId, trackingCode },
}: ReturnType<typeof actions.enterTrackingCodeRequest>) {
  const response = yield* call(api.enterTrackingCode, {
    transactionId,
    trackingCode,
  })

  if ('errors' in response) {
    yield put(actions.enterTrackingCodeUiFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    yield put(actions.enterTrackingCodeSuccess())
  }
}

export function* markAsShipped({
  payload: { transactionId, proofPhotoUuid },
}: ReturnType<typeof actions.markAsShippedRequest>) {
  const response = yield* call(api.markAsShipped, {
    transactionId,
    proofPhotoUuid,
  })

  if ('errors' in response) {
    yield put(actions.markAsShippedUiFailure({ error: response.errors[0]?.value || null }))

    return
  }

  yield put(actions.markAsShippedSuccess())
}

export function* setSelectedDropOffTypeMethod({
  payload: { transactionId, dropOffTypeId },
}: ReturnType<typeof actions.setSelectedDropOffTypeMethodRequest>) {
  const response = yield* call(api.setSelectedDropOffTypeMethod, {
    transactionId,
    dropOffTypeId,
  })

  if ('errors' in response) {
    yield put(
      actions.setSelectedDropOffTypeMethodFailure({ error: response.errors[0]?.value || null }),
    )

    return
  }

  yield put(actions.setSelectedDropOffTypeMethodSuccess())
}

export function* getDigitalLabel({
  payload: { transactionId },
}: ReturnType<typeof statelessActions.getDigitalLabelRequest>) {
  const response = yield* call(api.getDigitalLabel, {
    transactionId,
  })

  if ('errors' in response) return

  yield put(actions.getDigitalLabelSuccess(transformDigitalLabel(response.digital_label)))
}

export function* createOffer({
  payload: {
    offerPrice,
    currentPrice,
    isBuyer,
    currency,
    transactionId,
    offerSuggestionOptionIndex,
  },
}: ReturnType<typeof actions.createOfferRequest>) {
  const apiCall = isBuyer ? api.createOfferRequest : api.createOffer

  const response = yield* call(apiCall, {
    transactionId,
    price: offerPrice,
    currency,
  })

  if ('errors' in response) {
    yield put(actions.createOfferFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    const offerRequestSessionId = yield* select(getOfferRequestSessionId)

    const offerRequestId = isBuyer
      ? 'offer_request' in response && response.offer_request.id
      : 'offer' in response && response.offer.id

    const offerSuggestionOptionNumber = offerSuggestionOptionIndex
      ? offerSuggestionOptionIndex + 1
      : undefined

    const offerEvent = makeOfferEvent({
      isBuyer,
      transactionId,
      offerRequestSessionId,
      offerSuggestionOptionNumber,
      offerRequestId: offerRequestId || undefined,
      offeredPrice: Number(offerPrice),
      currentPrice: Number(currentPrice),
    })

    yield apply(tracker, tracker.track, [offerEvent])

    yield put(actions.createOfferSuccess())
  }
}

export function* acceptOffer({
  payload: { transactionId, offerRequestId },
}: ReturnType<typeof actions.acceptOfferRequest>) {
  const response = yield* call(api.acceptOffer, {
    transactionId,
    offerRequestId,
  })

  if ('errors' in response) {
    yield put(actions.acceptOfferFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    yield put(actions.acceptOfferSuccess())
  }
}

export function* rejectOffer({
  payload: { transactionId, offerRequestId },
}: ReturnType<typeof actions.rejectOfferRequest>) {
  const response = yield* call(api.rejectOffer, {
    transactionId,
    offerRequestId,
  })

  if ('errors' in response) {
    yield put(actions.rejectOfferFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    yield put(actions.rejectOfferSuccess())
  }
}

export function* getCourierPickUpDates({
  payload: { transactionId },
}: ReturnType<typeof actions.getCourierPickUpDatesRequest>) {
  const response = yield* call(api.getCourierPickUpDates, transactionId)

  if ('errors' in response) {
    yield put(actions.getCourierPickUpDatesFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    const courierPickUpDates = transformPickUpDatesDto(response.pick_up_dates)

    yield put(actions.getCourierPickUpDatesSuccess(courierPickUpDates))
  }
}

export function* getOfferRequestOptions({
  payload: { itemPrice },
}: ReturnType<typeof actions.getOfferRequestOptionsRequest>) {
  const response = yield* call(api.getOfferRequestOptions, {
    itemPrice,
  })

  if ('errors' in response) {
    yield put(actions.getOfferRequestOptionsFailure({ error: response?.errors?.[0]?.value }))

    return
  }

  yield put(
    actions.getOfferRequestOptionsSuccess({
      offerRequestOptions: transformOfferRequestOptions(response.request_options),
    }),
  )
}

export function* fetchTransaction({ payload }: ReturnType<typeof actions.fetchTransactionRequest>) {
  const response = yield* call(api.getTransaction, { id: payload.transactionId })

  if ('errors' in response) {
    yield put(actions.fetchTransactionRequestFailure({ error: response?.errors?.[0]?.value }))

    return
  }

  const transaction = transformTransaction(response.transaction)

  yield put(actions.fetchTransactionRequestSuccess({ transaction }))
}

export function* fetchShippingInstructions({
  payload: { transactionId },
}: ReturnType<typeof actions.fetchShippingInstructionsRequest>) {
  const response = yield* call(api.getShippingInstructions, transactionId)

  if ('errors' in response) {
    yield put(
      actions.fetchShippingInstructionsRequestFailure({
        error: response?.errors?.[0]?.value || null,
      }),
    )

    return
  }

  const shippingInstructions = transformShippingInstructionsDto(response.shipping_instructions)

  yield put(actions.fetchShippingInstructionsRequestSuccess({ shippingInstructions }))
}

export function* getCollectionDates({
  payload: { shipmentId },
}: ReturnType<typeof actions.getCollectionDatesRequest>) {
  const response = yield* call(api.getCollectionDates, shipmentId)

  if ('errors' in response) {
    yield put(actions.getCollectionDatesFailure({ error: response?.errors?.[0]?.value || null }))
  } else {
    const collectionDates = transformCollectionDates(response.collection_dates)

    yield put(actions.getCollectionDatesSuccess({ collectionDates }))
  }
}

export function* getLabelOptions({
  payload: { shipmentId },
}: ReturnType<typeof actions.getLabelOptionsRequest>) {
  const response = yield* call(api.getLabelOptions, shipmentId)

  if ('errors' in response) {
    yield put(actions.getLabelOptionsFailure({ error: response.errors[0]?.value || null }))

    return
  }

  const labelOptions = transformLabelOptions(response.label_options)

  yield put(actions.getLabelOptionsSuccess({ labelOptions }))
}

export function* getShippingOrderPackageSizes({
  payload: { shipmentId, shippingOrderId },
}: ReturnType<typeof actions.getShippingOrderPackageSizesRequest>) {
  const response = yield* call(api.getShippingOrderPackageSizes, shippingOrderId, shipmentId)

  if ('errors' in response) {
    yield put(
      actions.getShippingOrderPackageSizesFailure({ error: response?.errors?.[0]?.value || null }),
    )
  } else {
    const { packageSizeSelections, packageSizes } = transformShippingOrderPackageSizesDto(response)

    yield put(actions.getShippingOrderPackageSizesSuccess({ packageSizeSelections, packageSizes }))
  }
}

export default function* saga() {
  yield takeEvery(statelessActions.translateConversationRequest, translateConversation)
  yield takeEvery(statelessActions.getConversationRequest, getConversation)
  yield takeEvery(actions.getTrackingJourneySummaryRequest, getTrackingJourneySummary)
  yield takeEvery(actions.getShipmentInstructionsRequest, getShipmentInstructions)
  yield takeEvery(actions.postReplyRequest, postReply)
  yield takeEvery(actions.createConversationThreadRequest, createConversationThread)
  yield takeEvery(actions.createItemConversationThreadRequest, createItemConversationThread)
  yield takeEvery(actions.enterTrackingCodeRequest, enterTrackingCode)
  yield takeEvery(actions.markAsShippedRequest, markAsShipped)
  yield takeEvery(actions.setSelectedDropOffTypeMethodRequest, setSelectedDropOffTypeMethod)
  yield takeEvery(statelessActions.getDigitalLabelRequest, getDigitalLabel)
  yield takeEvery(actions.createOfferRequest, createOffer)
  yield takeEvery(actions.acceptOfferRequest, acceptOffer)
  yield takeEvery(actions.rejectOfferRequest, rejectOffer)
  yield takeEvery(actions.getCourierPickUpDatesRequest, getCourierPickUpDates)
  yield takeEvery(actions.getOfferRequestOptionsRequest, getOfferRequestOptions)
  yield takeEvery(actions.fetchTransactionRequest, fetchTransaction)
  yield takeEvery(actions.fetchShippingInstructionsRequest, fetchShippingInstructions)
  yield takeEvery(actions.getCollectionDatesRequest, getCollectionDates)
  yield takeEvery(actions.getLabelOptionsRequest, getLabelOptions)
  yield takeEvery(actions.getShippingOrderPackageSizesRequest, getShippingOrderPackageSizes)
}
