import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  isContentExpanded: false,
}

const setPrivacyPolicyAccordionsExpanded: CaseReducer<
  State,
  PayloadAction<{ isExpanded: boolean }>
> = (draft, action) => {
  const { isExpanded } = action.payload

  draft.isContentExpanded = isExpanded
}

const PrivacyPolicyAccordionSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setPrivacyPolicyAccordionsExpanded,
  },
})

export const { actions } = PrivacyPolicyAccordionSlice
export const plug = { [stateName]: PrivacyPolicyAccordionSlice.reducer }
export default PrivacyPolicyAccordionSlice.reducer
