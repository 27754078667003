import { CharityDto, FundraiserDto, UserFundraiserDto } from 'types/dtos'
import { CharityModel, FundraiserModel } from 'types/models'

export const transformCharity = (charity: CharityDto): CharityModel => {
  const { heading, description, code, logo } = charity

  return {
    heading,
    description,
    code,
    logo: {
      darkUrl: logo.dark_url,
      lightUrl: logo.light_url,
    },
  }
}

export const transformFundraiserConfigurationForUser = (
  fundraiser: FundraiserDto,
): UserFundraiserDto => {
  const { active, percentage } = fundraiser

  return {
    active: active || false,
    percentage,
  }
}

export const transformFundraiserConfiguration = (fundraiser: FundraiserDto): FundraiserModel => {
  const {
    active,
    charity_code,
    charity,
    percentage,
    donations_total: { currency_code, amount },
  } = fundraiser

  return {
    active,
    charityCode: charity_code,
    charity: charity ? transformCharity(charity) : null,
    percentage,
    currency: currency_code,
    amountDonated: amount,
  }
}

export const transformCharities = (charities: Array<CharityDto>): Array<CharityModel> =>
  charities.map(transformCharity)
