import { serverSide } from 'libs/utils/environment'
import { logError, logMessage, logWarning } from 'libs/utils/window'
import { Breakpoints } from 'types/components'

type LogContext = {
  isBot: boolean
  isWebview: boolean
  dd_view_id: string | undefined
  ssrBreakpoints: Breakpoints
  clientBreakpoints: Breakpoints
}

export function getBody() {
  if (serverSide) return ''

  return document.getElementById('__next')?.innerHTML || ''
}

function normalizeBody(text: string) {
  return text.replace(/( style="[^"]+?)"/g, '')
}

function normalizeBeforeBody(text: string) {
  return normalizeBody(text).replace(/<!--.*?-->/g, '')
}

function normalizeAfterBody(text: string) {
  return normalizeBody(text)
}

function popElementChildren(element: Element) {
  const children = [...element.children]

  children.forEach(element.removeChild.bind(element))

  return children
}

function getElementSelector(element: Element) {
  if (element.id) {
    return { primary: true, value: `#${element.id}` }
  }
  if (element instanceof HTMLElement && element.dataset.testid) {
    return { primary: true, value: `[data-testid=${element.dataset.testid}]` }
  }
  if (element.className) {
    return {
      primary: false,
      value: element.className?.replace
        ? `.${element.className.replace(/\s+/g, '.')}`
        : element.className,
    }
  }

  return { primary: false, value: element.tagName.toLowerCase() }
}

function logDiff(extra: Record<string, unknown>) {
  logWarning('TrackRerender: difference between SSR and client side', {
    feature: 'minified-react-error',
    extra: JSON.stringify(extra),
  })
}

function logElementDiffs(
  beforeElement: Element | null | undefined,
  afterElement: Element | null | undefined,
  logContext: LogContext,
  path: Array<string> = [],
) {
  if (!beforeElement || !afterElement) return
  if (beforeElement.outerHTML === afterElement.outerHTML) return

  const beforeOuterHtml = beforeElement.outerHTML
  const afterOuterHtml = afterElement.outerHTML
  const beforeChildren = popElementChildren(beforeElement)
  const afterChildren = popElementChildren(afterElement)
  const selector = getElementSelector(beforeElement)
  const newPath = selector.primary ? [selector.value] : [...path, selector.value]

  if (beforeElement.isEqualNode(afterElement) && beforeChildren.length === afterChildren.length) {
    beforeChildren.map((beforeChild, index) =>
      logElementDiffs(beforeChild, afterChildren[index], logContext, newPath),
    )

    return
  }

  logDiff({
    selector: newPath.join(' > '),
    before: beforeOuterHtml.substring(0, 600),
    after: afterOuterHtml.substring(0, 600),
    ...logContext,
  })
}

export function logBodyDiffs({
  before,
  after,
  logContext,
}: {
  before: string
  after: string
  logContext: LogContext
}) {
  logMessage('Comparing html', {
    feature: 'minified-react-error',
    extra: JSON.stringify(logContext),
  })

  try {
    const parser = new DOMParser()
    const parsedBefore = parser.parseFromString(normalizeBeforeBody(before), 'text/html')
    const parsedAfter = parser.parseFromString(normalizeAfterBody(after), 'text/html')

    logElementDiffs(
      parsedBefore.activeElement?.firstElementChild,
      parsedAfter.activeElement?.firstElementChild,
      logContext,
    )
  } catch (error) {
    logError(error, { feature: 'minified-react-error' })
  }
}
