import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const getBundleSummaryRequest = createAction<{ itemIds: Array<number>; userId: number }>(
  `${stateName}/getBundleSummaryRequest`,
)

export const getInstantBundleOrderReviewRequest = createAction<{
  itemIds: Array<number>
  userId: number
}>(`${stateName}/getInstantBundleSummaryOrderReviewRequest`)

export const getBundleSummaryFailure = createAction(`${stateName}/getBundleSummaryFailure`)
