export const UPLOADED_ITEM_ID = 'uploaded_item_id'
export const UPLOADED_ITEM_PROMOTIONS = 'uploaded_item_promotions'

export enum PromotionStorageKeys {
  ShowPushedUp = 'pushed_up_promotion',
  ShowFeedback = 'feedback_promotion',
  ShowUploadAnotherItemTip = 'upload_another_item_tip_promotion',
}

export const PUSHED_ITEM_STORAGE_KEY = 'new_pushed_item_id'
export const SAVED_ITEM_STORAGE_KEY = 'new_saved_item_id'
export const FEEDBACK_ITEM_ID = 'feedback_item_id'
export const SHOW_UPLOAD_ANOTHER_ITEM_TIP = 'show_upload_another_item_tip'

export enum ItemAfterUploadActions {
  ShowOfflineVerificationModal = 'show_offline_verification_modal',
  ShowUploadAnotherItemTip = 'show_upload_another_item_tip',
}

export enum ItemPriceSuggestionDisplayType {
  Always = 'always',
  Banner = 'banner',
  Range = 'range',
  Off = 'off',
}
