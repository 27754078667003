import { takeEvery } from 'redux-saga/effects'
import { call, put, select } from 'typed-redux-saga'

import { UiState } from 'constants/ui'
import { getFaqEntryById, getFaqEntryByType } from 'data/api'
import { transformFaqEntryDto } from 'data/transformers/faq-entry'

import * as statelessActions from './actions'
import * as selectors from './selectors'
import { actions } from './slice'

export function* fetchFaqEntryById({
  payload: { id },
}: ReturnType<typeof statelessActions.fetchFaqEntryByIdRequest>) {
  const state = yield* select(selectors.getFaqEntryStateById, id)

  if (state !== UiState.Idle) return

  yield* put(actions.setStateById({ id, state: UiState.Pending }))
  const response = yield* call(getFaqEntryById, id.toString())

  if ('errors' in response || !response.faq_entry) {
    yield* put(actions.setStateById({ id, state: UiState.Failure }))

    return
  }

  const faqEntry = yield* call(transformFaqEntryDto, response.faq_entry)

  yield put(actions.fetchFaqEntrySuccess({ faqEntry }))
}

export function* fetchFaqEntryByType({
  payload: { type },
}: ReturnType<typeof statelessActions.fetchFaqEntryByTypeRequest>) {
  const state = yield* select(selectors.getFaqEntryStateByType, type)

  if (state !== UiState.Idle) return

  yield* put(actions.setStateByType({ type, state: UiState.Pending }))
  const response = yield* call(getFaqEntryByType, type)

  if ('errors' in response || !response.faq_entry) {
    yield* put(actions.setStateByType({ type, state: UiState.Failure }))

    return
  }

  const faqEntry = yield* call(transformFaqEntryDto, response.faq_entry)

  yield put(actions.fetchFaqEntrySuccess({ faqEntry, type }))
}

export default function* saga() {
  yield takeEvery(statelessActions.fetchFaqEntryByIdRequest, fetchFaqEntryById)
  yield takeEvery(statelessActions.fetchFaqEntryByTypeRequest, fetchFaqEntryByType)
}
