import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export function getAreProblemOptionsLoading(state: AllState) {
  return localState(state).areProblemOptionsLoading
}

export function getAreRatingsOptionsLoading(state: AllState) {
  return localState(state).areRatingsOptionsLoading
}

export function getIsProblemsModalOpen(state: AllState) {
  return localState(state).isProblemsModalOpen
}

export function getIsFeedbackModalOpen(state: AllState) {
  return localState(state).isFeedbackModalOpen
}

export function getProblemOptions(state: AllState) {
  return localState(state).problemOptions
}

export function getRatings(state: AllState) {
  return localState(state).ratings
}
