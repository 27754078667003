import { CollectionsPlanPricingDto } from 'types/dtos'
import { CollectionsPlanPricingModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformCollectionsPlanPricing = (
  dto: CollectionsPlanPricingDto,
): CollectionsPlanPricingModel => ({
  id: dto.id,
  effectiveDays: dto.effective_days,
  maxActiveCount: dto.max_active_count,
  default: dto.default,
  totalPrice: transformCurrencyAmountDto(dto.total_price),
})

export const transformCollectionsPlansPricing = (
  dtos: Array<CollectionsPlanPricingDto>,
): Array<CollectionsPlanPricingModel> => dtos.map(transformCollectionsPlanPricing)
