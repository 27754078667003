import { find } from 'lodash'

import { AdPage, AdShape } from 'constants/ads'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUiState = (state: AllState) => localState(state).uiState

export const getPlacements = (state: AllState) => localState(state).placements

export const getSegments = (state: AllState) => localState(state).segments

export const getShouldMockAds = (state: AllState) => localState(state).shouldMockAds

export const getPlacementByShape =
  (shape: AdShape, mediation: string | null = null) =>
  (state: AllState) =>
    find(getPlacements(state), { shape, mediation })

export const getIsPlacementsPresent = (state: AllState) => !!getPlacements(state).length

// When the first of existing placements has the same page name as the one provided in `page`
// argument of the function - this should return true. This can be used to determine if current
// placements are for the page that triggered `loadAdsPlacements`. If there are no placements
// or if the page name is different - it will return false.

export const getIsPlacementPageMatching = (page: AdPage) => (state: AllState) =>
  getPlacements(state)[0]?.page === page

export const getIsPlacementPresent =
  (shape: AdShape, mediation: string | null = null) =>
  (state: AllState) =>
    !!getPlacementByShape(shape, mediation)(state)

export const getIsAdBlockerUsed = (state: AllState) => localState(state).isAdBlockerUsed

export const getHasAdBlockerBeenTracked = (state: AllState) =>
  localState(state).hasAdBlockerBeenTracked

export const getAdBlockerVisitorId = (state: AllState) => localState(state).adBlockerVisitorId
