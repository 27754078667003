import { createAction } from '@reduxjs/toolkit'

import { PageId } from 'types/page-id'

import { stateName } from './constants'

export const loadAdsPlacements = createAction<{ pageId?: PageId | null }>(
  `${stateName}/loadAdsPlacements`,
)
export const loadAdsSegments = createAction(`${stateName}/loadAdsSegments`)
