import { takeEvery, put } from 'redux-saga/effects'
import { select, call } from 'typed-redux-saga'

import { updateCurrentUser } from 'data/api'
import { UiState } from 'constants/ui'
import { ColorTheme, COLOR_THEME_COOKIE_NAME } from 'constants/dark-mode'
import * as sessionSelectors from 'state/session/selectors'
import { scrollToTop, reloadPage } from 'libs/utils/window'
import { saveCookie, getCookie, deleteCookie } from 'libs/utils/cookies'

import { tracker } from 'libs/common/tracker'
import { userSelectTheme } from 'libs/common/event-tracker/events'
import { getFingerprint } from 'libs/utils/fingerprint'

import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'

export function* loadFormAttributes() {
  const currentUser = yield* select(sessionSelectors.getUser)

  if (!currentUser) return

  const { real_name, gender, birthday, is_on_holiday, login, international_trading_enabled } =
    currentUser

  const settings = {
    realName: real_name || undefined,
    isOnHoliday: is_on_holiday,
    username: login,
    gender,
    birthday: birthday || undefined,
    isInternationalTradingEnabled: international_trading_enabled,
    isDarkMode: getCookie(COLOR_THEME_COOKIE_NAME) === ColorTheme.Dark || undefined,
  }

  yield put(actions.setInitialSettings({ settings }))
}

export function* saveSettings() {
  const uiState = yield* select(selectors.getSettingsUiState)

  if (uiState === UiState.Pending) return

  const { isDarkMode, ...settings } = yield* select(selectors.getAccountSettings)

  yield put(actions.changeUiState({ uiState: UiState.Pending }))

  const fingerprint = yield* call(getFingerprint)
  const response = yield* call(updateCurrentUser, { ...settings, fingerprint })

  if ('errors' in response) {
    yield put(actions.setError({ error: response }))
    yield put(actions.changeUiState({ uiState: UiState.Failure }))

    yield* call(scrollToTop)

    return
  }

  if (isDarkMode !== undefined) {
    tracker.track(userSelectTheme({ theme: isDarkMode ? ColorTheme.Dark : ColorTheme.Light }))

    if (isDarkMode) {
      yield* call(saveCookie, COLOR_THEME_COOKIE_NAME, ColorTheme.Dark)
    } else {
      yield* call(deleteCookie, COLOR_THEME_COOKIE_NAME)
    }
  }

  yield* call(reloadPage)
}

export default function* saga() {
  yield takeEvery(statelessActions.loadFormAttributes, loadFormAttributes)
  yield takeEvery(statelessActions.saveSettings, saveSettings)
}
