import { PaymentsIdentityFormConfigurationResp, SubmitPaymentsIdentityArgs } from 'types/api'
import {
  BusinessAccountAddressDto,
  BusinessRegistrationAddressDto,
  BusinessRegistrationAddressesDto,
  BusinessRegistrationDetailsDto,
  BusinessRegistrationDto,
  BusinessRegistrationProfileDto,
  BusinessRegistrationRepresentativeDto,
  DocumentFieldConfigurationDto,
  LegalEntityDto,
  PaymentsIdentityDocumentConfigurationDto,
  PaymentsIdentityDocumentFileConfigurationDto,
  PaymentstIdentityDocumentInfoDto,
  PaymentsIdentityDynamicConfigurationDto,
} from 'types/dtos'
import {
  BusinessRegistrationAddressesModel,
  BusinessRegistrationAddressModel,
  BusinessRegistrationDetailsModel,
  BusinessRegistrationModel,
  BusinessRegistrationProfileModel,
  BusinessRegistrationRepresentativeModel,
  DocumentFieldConfigurationModel,
  LegalEntityModel,
  PaymentsIdentityConfigurationModel,
  PaymentsIdentityDocumentConfigurationModel,
  PaymentsIdentityDocumentFileConfigModel,
  PaymentsIdentityDocumentInfoModel,
  PaymentsIdentityDynamicConfigurationModel,
} from 'types/models'

import { transformAddress } from 'data/transformers/address'

export const transformBusinessDetailsToDto = (
  model: BusinessRegistrationDetailsModel,
): BusinessRegistrationDetailsDto => ({
  legal_code: model.legalCode,
  vat: model.isVatDisabled || model.vat === '' ? undefined : model.vat,
  legal_name: model.legalName,
  entity_type: model.entityType,
  registrar_name: model.registrarName,
})

export const transformBusinessAddressToDto = (
  model: BusinessRegistrationAddressModel,
): BusinessRegistrationAddressDto => ({
  postal_code: model.fullPostalCode || model.postalCode,
  city: model.city,
  country_id: model.countryId ? Number(model.countryId) : null,
  line1: model.line1,
  line2: model.line2,
})

export const transformBusinessRepresentativeToDto = (
  model: BusinessRegistrationRepresentativeModel,
): BusinessRegistrationRepresentativeDto => ({
  full_name: model.fullName,
  birthday: model.birthday,
  nationality: model.nationality.code,
  country: model.country.code,
  first_names: model.firstNames,
  last_name: model.lastName,
  email: model.email,
  legal_representative_address:
    model.isSameLegalRepresentativeAddress || !model.legalRepresentativeAddress
      ? undefined
      : transformBusinessAddressToDto(model.legalRepresentativeAddress),
})

export const transformBusinessAddressesToDto = (
  model: BusinessRegistrationAddressesModel,
): BusinessRegistrationAddressesDto => ({
  business_address: model.businessAddress && transformBusinessAddressToDto(model.businessAddress),
  business_equal_to_personal_address: model.isBusinessEqualToPersonalAddress,
  legal_representative_address:
    model.isSameLegalRepresentativeAddress || !model.legalRepresentativeAddress
      ? undefined
      : transformBusinessAddressToDto(model.legalRepresentativeAddress),
})

export const transformBusinessProfileToDto = (
  model: BusinessRegistrationProfileModel,
): BusinessRegistrationProfileDto => ({
  photo_temp_uuid: model.photoTempUuid,
  business_account_name: model.businessAccountName,
  phone_number: model.phoneNumber,
  personal_phone_number: model.isPhoneNumberPersonal,
  email: model.email,
  personal_email: model.isEmailPersonal,
})

export const transformBusinessRegistrationToDto = (
  model: Partial<BusinessRegistrationModel>,
): BusinessRegistrationDto => ({
  business_details: model.businessDetails && transformBusinessDetailsToDto(model.businessDetails),
  business_representative:
    model.businessRepresentative &&
    transformBusinessRepresentativeToDto(model.businessRepresentative),
  addresses: model.addresses && transformBusinessAddressesToDto(model.addresses),
  profile: model.profile && transformBusinessProfileToDto(model.profile),
})

export const transformLegalEntity = (dto: LegalEntityDto): LegalEntityModel => ({
  typeLabel: dto.type_label,
  typeValue: dto.type_value,
  name: dto.name,
  streetAddressLine1: dto.street_address_line_1,
  streetAddressLine2: dto.street_address_line_2,
  postalCode: dto.postal_code,
})

export const transformPaymentsIdentityDocumentFile = (
  file: PaymentsIdentityDocumentFileConfigurationDto,
): PaymentsIdentityDocumentFileConfigModel => ({
  identifier: file.identifier,
  uploadNote: file.upload_note,
})

export const transformPaymentsIdentityDocumentConfiguration = (
  document: PaymentsIdentityDocumentConfigurationDto,
): PaymentsIdentityDocumentConfigurationModel => ({
  files: document.files.map(file => transformPaymentsIdentityDocumentFile(file)),
  title: document.title,
  type: document.type,
  maximumFileSize: document.maximum_file_size,
  mediaTypes: document.media_types,
})

export const transformPaymentstIdentityDocumentInfo = ({
  title,
  description,
}: PaymentstIdentityDocumentInfoDto): PaymentsIdentityDocumentInfoModel => ({
  title,
  description,
})

export const transformDocumentFieldConfiguration = (
  documentFieldConfigurations: DocumentFieldConfigurationDto,
): DocumentFieldConfigurationModel => {
  return {
    field: documentFieldConfigurations.field,
    group: documentFieldConfigurations.group,
    hint: documentFieldConfigurations.hint,
    options: documentFieldConfigurations.options.map(
      transformPaymentsIdentityDocumentConfiguration,
    ),
    info: documentFieldConfigurations.info
      ? transformPaymentstIdentityDocumentInfo(documentFieldConfigurations.info)
      : null,
  }
}

export const transformDocumentFieldConfigurations = (
  documentFieldConfigurations: Array<DocumentFieldConfigurationDto>,
): Array<DocumentFieldConfigurationModel> =>
  documentFieldConfigurations.map(transformDocumentFieldConfiguration)

export const transformPaymentsIdentityDynamicConfiguration = (
  dynamicConfiguration: PaymentsIdentityDynamicConfigurationDto,
): PaymentsIdentityDynamicConfigurationModel => ({
  personalIdNumberRequiredNationalities:
    dynamicConfiguration.personal_id_number_required_nationalities,
  addressType: dynamicConfiguration.address_type,
})

export const transformPaymentsIdentityConfiguration = ({
  payments_identity,
}: PaymentsIdentityFormConfigurationResp): PaymentsIdentityConfigurationModel => {
  return {
    firstName: payments_identity.first_name,
    lastName: payments_identity.last_name,
    birthdate: payments_identity.birthdate,
    ssnSerial: payments_identity.ssn_serial,
    personalIdNumber: payments_identity.personal_id_number,
    status: payments_identity.status,
    requiredFields: payments_identity.required_fields,
    nationality: payments_identity.nationality,
    address: (payments_identity.address && transformAddress(payments_identity.address)) || null,
    agreementHint: payments_identity.agreement_hint,
    businessAddress:
      payments_identity.business_address && transformAddress(payments_identity.business_address),
    registrationNumber: payments_identity.registration_number,
    accountType: payments_identity.account_type,
    documentFieldConfigurations: transformDocumentFieldConfigurations(
      payments_identity.document_field_configurations,
    ),
    verificationFailures: payments_identity.verification_failures && [
      ...payments_identity.verification_failures,
    ],
    statusMessage: payments_identity.status_message,
    statusNote: payments_identity.status_note,
    dynamicConfiguration:
      payments_identity.dynamic_configuration &&
      transformPaymentsIdentityDynamicConfiguration(payments_identity.dynamic_configuration),
  }
}

export const transformPaymentsIdentityUbo = (
  kybData: SubmitPaymentsIdentityArgs,
  business_representative: BusinessRegistrationRepresentativeDto,
) => {
  if (!kybData.ubos) {
    return []
  }

  return [
    {
      ...kybData.ubos[0],
      firstName: business_representative.first_names ?? '',
      lastName: business_representative.last_name ?? '',
      birthday: business_representative.birthday,
      address: {
        ...business_representative.legal_representative_address,
        postalCode: business_representative.legal_representative_address?.postal_code,
        countryId: business_representative.legal_representative_address?.country_id,
        name: business_representative.full_name,
      },
    },
  ]
}

export const transformKybData = (
  kybData: SubmitPaymentsIdentityArgs,
  business_representative: BusinessRegistrationRepresentativeDto,
  business_address: BusinessAccountAddressDto,
) => {
  return {
    ...kybData,
    firstName: business_representative.first_names ?? '',
    lastName: business_representative.last_name ?? '',
    birthdate: business_representative.birthday,
    address: {
      city: business_address.city,
      countryId: business_address.country_id,
      line1: business_address.line1,
      line2: business_address.line2,
      name: business_address.name,
      postalCode: business_address.postal_code,
    },
  }
}
