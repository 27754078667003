import { createAction } from '@reduxjs/toolkit'

import { ItemDto, CatalogItemDto } from 'types/dtos'

import { stateName } from './constants'
import { ItemPayload } from './types'

export const retryCatalogItemsRequest = createAction(`${stateName}/retryCatalogItemsRequest`)

export const getCatalogItemsSuccess = createAction(
  `${stateName}/getCatalogItemsSuccess`,
  (payload: ItemPayload<ItemDto | CatalogItemDto>, meta: { isPaginationEvent: boolean }) => ({
    payload,
    meta,
  }),
)
