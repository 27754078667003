import { FeaturedCollectionDto } from 'types/dtos'
import { FeaturedCollectionModel } from 'types/models'

import { transformItemDtosToProductItems } from './product-item'

export const transformFeaturedCollection = (
  dto: FeaturedCollectionDto,
): FeaturedCollectionModel => ({
  id: dto.id,
  title: dto.title,
  discount: dto.discount,
  items: transformItemDtosToProductItems(dto.items),
  isActive: dto.is_active,
  humanizedTimeLeft: dto.humanized_time_left || undefined,
})
