import { createElement, ReactHTML } from 'react'

const WHITELISTED_HTML_TAGS: Array<keyof ReactHTML> = [
  'b',
  'div',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'ul',
  'u',
]

export const defaultInterpolations = WHITELISTED_HTML_TAGS.reduce(
  (accumulator, tag) => {
    accumulator[tag] = (chunks: JSX.Element) => createElement(tag, null, chunks)

    return accumulator
  },
  {} as Record<keyof ReactHTML, (chunks: JSX.Element) => ReturnType<typeof createElement>>,
)

// Pluralize functions are based on config/locales/plurals.rb

type Pluralize = (count: number) => string

const ltPluralize: Pluralize = count => {
  if (count % 10 === 1 && count % 100 !== 11) return 'one'

  return [2, 3, 4, 5, 6, 7, 8, 9].includes(count % 10) &&
    ![11, 12, 13, 14, 15, 16, 17, 18, 19].includes(count % 100)
    ? 'few'
    : 'other'
}

const czPluralize: Pluralize = count => {
  if (count === 1) return 'one'

  return [2, 3, 4].includes(count) ? 'few' : 'other'
}

const plPluralize: Pluralize = count => {
  if (count === 1) return 'one'

  return [2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100) ? 'few' : 'other'
}

const roPluralize: Pluralize = count => {
  if (count === 1) return 'one'

  return count >= 20 ? 'other' : 'few'
}

const huPluralize: Pluralize = () => 'one'

const frPluralize: Pluralize = count => (count <= 1 ? 'one' : 'other')

const skPluralize: Pluralize = count => {
  if (count === 1) return 'one'

  return [2, 3, 4].includes(count) ? 'other' : 'few'
}

// Determines the plural form for numbers in Croatian (hr) based on specific rules.
// Returns 'one' for numbers ending in 1 (e.g., 1, 21, 31) except those ending in 11 (e.g., 11, 111, 211).
// This is because numbers ending in 1 use a different form than other numbers, but 11 is an exception to this rule.
const hrPluralize: Pluralize = count => {
  if (count % 10 === 1 && count % 100 !== 11) return 'one'

  if ([2, 3, 4].includes(count % 10) && ![12, 13, 14].includes(count % 100)) return 'few'

  return 'other'
}

const defaultPluralize: Pluralize = count => (count === 1 ? 'one' : 'other')

const pluralFunctionMap: Record<string, Pluralize> = {
  lt: ltPluralize,
  cs: czPluralize,
  pl: plPluralize,
  ro: roPluralize,
  hu: huPluralize,
  fr: frPluralize,
  sk: skPluralize,
  hr: hrPluralize,
}

export const pluralization = (locale: string) => {
  const pluralizationFunction = pluralFunctionMap[locale]

  return pluralizationFunction ?? defaultPluralize
}
