import { includes, isEmpty, pickBy } from 'lodash'

export const filterEmptyValues = <T extends object>(object: T): Partial<T> =>
  pickBy(
    object,
    val => !((Array.isArray(val) && isEmpty(val)) || val === null || typeof val === 'undefined'),
  )

export const nullifyEmptyValue = <T>(value: T): T | null => (isEmpty(value) ? null : value)

export const isValueInObject = <T extends string | number>(
  value: unknown,
  object: Record<string, T>,
): value is T => includes(Object.values(object), value)

export const isObjectKey = <K extends string | number>(
  key: string | number,
  object: Record<K, unknown>,
): key is K => {
  return key in object
}

export const filterEmptyStringAttributes = <T extends object>(object: T): Partial<T> =>
  pickBy(object, val => String(val))

export const isObject = (candidate: unknown): candidate is Record<string, unknown> =>
  !!candidate && typeof candidate === 'object'

export const deleteUndefinedValues = (object: Record<string, unknown> | undefined): void => {
  if (!object) return

  // eslint-disable-next-line no-restricted-syntax
  for (const key in object) {
    if (!Object.hasOwn(object, key)) continue // eslint-disable-line no-continue

    const value = object[key]

    if (isObject(value)) deleteUndefinedValues(value)
    else if (value === undefined) delete object[key] // eslint-disable-line no-param-reassign
  }
}
