import {
  ComplaintDto,
  ComplaintEscalationDto,
  ComplaintResolutionDto,
  ComplaintUserDto,
  ComplaintPhotoDto,
  ComplaintOrderItemDto,
  ComplaintEstimatedRefundDto,
  ComplaintEstimatedReleaseDto,
  ComplaintEstimatedReleaseDetailsDto,
  ComplaintEstimatedRefundDetailsDto,
  ComplaintEstimateRefundBeforeConversionDto,
} from 'types/dtos'
import {
  ComplaintModel,
  ComplaintEscalationModel,
  ComplaintResolutionModel,
  ComplaintUserModel,
  ComplaintPhotoModel,
  ComplaintOrderItemModel,
  ComplaintEstimatedRefundModel,
  ComplaintEstimatedReleaseModel,
  ComplaintEstimateReleaseDetailsModel,
  ComplaintEstimatedRefundDetailsModel,
  ComplaintEstimatedRefundBeforeConversionModel,
} from 'types/models'

import { transformFaqEntryDto } from './faq-entry'
import { transformCurrencyAmountDto } from './currency-amount'

export const transformComplaintPhoto = (photo: ComplaintPhotoDto): ComplaintPhotoModel => ({
  url: photo.url,
  highResolution: photo.high_resolution,
})

export const transformComplaintPhotos = (
  photoDtos: Array<ComplaintPhotoDto>,
): Array<ComplaintPhotoModel> => photoDtos.map(transformComplaintPhoto)

export const transformComplaintUser = (user: ComplaintUserDto): ComplaintUserModel => ({
  id: user.id,
  login: user.login,
})

export const transformComplaintResolution = (
  resolution: ComplaintResolutionDto,
): ComplaintResolutionModel => ({
  action: resolution.action,
  isPrimary: resolution.is_primary,
  tip: resolution.tip,
  button: resolution.button,
  confirmTitle: resolution.confirm_title,
  confirmButton: resolution.confirm_button,
  confirmation: resolution.confirmation,
})

export const transformComplaintEscalation = (
  escalation: ComplaintEscalationDto,
): ComplaintEscalationModel => ({
  tip: escalation.tip,
  button: escalation.button,
  confirmTitle: escalation.confirm_title,
  confirmButton: escalation.confirm_button,
  confirmation: escalation.confirmation,
  englishAllowedVisible: escalation.english_allowed_visible,
  close: escalation.close,
})

export const transformComplaintOrderItem = (
  complaintOrderItem: ComplaintOrderItemDto,
): ComplaintOrderItemModel => ({
  orderItemId: complaintOrderItem.order_item_id,
  returnable: complaintOrderItem.returnable,
  reasonText: complaintOrderItem.reason_text,
  item: {
    id: complaintOrderItem.item.id,
    title: complaintOrderItem.item.title,
    thumbnail: complaintOrderItem.item.thumbnail_150x210,
  },
})

export const transformEstimatedRefundDetailsDto = (
  estimatedRefundDetails: ComplaintEstimatedRefundDetailsDto,
): ComplaintEstimatedRefundDetailsModel => ({
  items: estimatedRefundDetails.items.map(({ title, original }) => ({
    title,
    original: transformCurrencyAmountDto(original),
  })),
  itemsEstimatedRefundTotal: transformCurrencyAmountDto(
    estimatedRefundDetails.items_estimated_refund_total,
  ),
  totalItemsDiscount: estimatedRefundDetails.total_items_discount
    ? transformCurrencyAmountDto(estimatedRefundDetails.total_items_discount)
    : null,
  buyerProtectionFee: transformCurrencyAmountDto(estimatedRefundDetails.buyer_protection_fee),
  shipment: transformCurrencyAmountDto(estimatedRefundDetails.shipment),
  returnShipment: transformCurrencyAmountDto(estimatedRefundDetails.return_shipment),
  authenticityCheckFee: estimatedRefundDetails.authenticity_check_fee
    ? transformCurrencyAmountDto(estimatedRefundDetails.authenticity_check_fee)
    : null,
})

export const transformBeforeConversionDto = (
  beforeConversion: ComplaintEstimateRefundBeforeConversionDto,
): ComplaintEstimatedRefundBeforeConversionModel => ({
  details: {
    totalItemPrice: transformCurrencyAmountDto(beforeConversion.details.total_item_price),
    shipment: transformCurrencyAmountDto(beforeConversion.details.shipment),
    factualShippingMethod: beforeConversion.details.factual_shipping_method,
  },
  exchange: {
    baseRate: transformCurrencyAmountDto(beforeConversion.exchange.base_rate),
    exchangeRate: transformCurrencyAmountDto(beforeConversion.exchange.exchange_rate),
  },
  markup: beforeConversion.markup,
})

export const transformEstimatedRefund = (
  esimatedRefund: ComplaintEstimatedRefundDto,
): ComplaintEstimatedRefundModel => ({
  estimatedRefundWithoutShipment: transformCurrencyAmountDto(
    esimatedRefund.estimated_refund_without_shipment,
  ),
  totalEstimatedRefund: transformCurrencyAmountDto(esimatedRefund.total_estimated_refund),
  details: transformEstimatedRefundDetailsDto(esimatedRefund.details),
  beforeConversion: esimatedRefund?.before_conversion
    ? transformBeforeConversionDto(esimatedRefund.before_conversion)
    : null,
})

export const transformEstimatedReleaseDetailsDto = (
  detailsDto: ComplaintEstimatedReleaseDetailsDto,
): ComplaintEstimateReleaseDetailsModel => ({
  totalItemsDiscount: detailsDto.total_items_discount
    ? transformCurrencyAmountDto(detailsDto.total_items_discount)
    : null,
  refundableShippingVat: detailsDto.refundable_shipping_vat
    ? transformCurrencyAmountDto(detailsDto.refundable_shipping_vat)
    : null,
  items: detailsDto.items.map(item => ({
    title: item.title,
    original: transformCurrencyAmountDto(item.original),
  })),
})

export const transformEstimatedRelease = (
  estimatedRelease: ComplaintEstimatedReleaseDto,
): ComplaintEstimatedReleaseModel => ({
  totalPrice: transformCurrencyAmountDto(estimatedRelease.total_price),
  totalEstimatedRefund: transformCurrencyAmountDto(estimatedRelease.total_estimated_refund),
  totalEstimatedRelease: transformCurrencyAmountDto(estimatedRelease.total_estimated_release),
  details: transformEstimatedReleaseDetailsDto(estimatedRelease.details),
})

export const transformComplaint = (complaint: ComplaintDto): ComplaintModel => ({
  buyerId: complaint.buyer_id,
  eligibleForReturnLabel: complaint.eligible_for_return_label,
  escalated: complaint.escalated,
  escalation: complaint.escalation ? transformComplaintEscalation(complaint.escalation) : null,
  id: complaint.id,
  message: complaint.message,
  transactionId: complaint.transaction_id,
  sellerId: complaint.seller_id,
  photos: transformComplaintPhotos(complaint.photos),
  resolutions: complaint.resolutions
    ? complaint.resolutions.map(transformComplaintResolution)
    : null,
  faqEntry: transformFaqEntryDto(complaint.faq_entry),
  user: transformComplaintUser(complaint.user),
  returnable: complaint.returnable ? complaint.returnable : null,
  complaintOrderItems: complaint.complaint_order_items
    ? complaint.complaint_order_items.map(transformComplaintOrderItem)
    : null,
  estimatedRefund: complaint.estimated_refund
    ? transformEstimatedRefund(complaint.estimated_refund)
    : null,
  estimatedRelease: complaint.estimated_release
    ? transformEstimatedRelease(complaint.estimated_release)
    : null,
  userMsgThreadId: complaint.user_msg_thread_id,
  isBundleOrder: complaint.is_bundle_order,
  orderType: complaint.order_type,
})

export const transformSnadComplaintDto = ({
  complaint,
}: {
  complaint: ComplaintDto | null
}): ComplaintModel | null => (complaint ? transformComplaint(complaint) : null)
