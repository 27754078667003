import { createSelector } from 'reselect'

import { AllState } from './types'
import { PromoAfterItemUpload } from './constants'

const select = (state: AllState) => state.profile
const selectUi = (state: AllState) => select(state).ui

export const getCurrentPromotion = createSelector(
  selectUi,
  ({ currentPromotion }) => currentPromotion,
)

export const getUploadedItemId = (state: AllState) => select(state).uploadedItemId

export const getShowPushedUp = (state: AllState) => select(state).ui.promotions.pushedUp
export const getShowFeedback = (state: AllState) => select(state).ui.promotions.feedback
export const getShowUploadAnotherItemTip = (state: AllState) =>
  select(state).ui.promotions.uploadAnotherItemTip

export const getIsPromoShown = createSelector(
  select,
  // TODO: `state` can be `undefined` in `/pro` page, causing the page to crash if `select` is used and destructured.
  // E. g. https://github.com/vinted/core/pull/77652. Investigate this.
  state =>
    Boolean(state?.ui?.currentPromotion) &&
    state?.ui?.currentPromotion !== PromoAfterItemUpload.Empty,
)
