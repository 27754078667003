import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import type { InAppMessageProvider } from 'libs/common/braze/providers'
import useLocation from 'hooks/useLocation'
import { getModalDisplayStatus } from 'state/banners/selectors'
import { getIsPromoShown } from 'state/profile/selectors'
import { WHITELISTING_RULES } from 'libs/common/braze/constants'

const isPathWhitelisted = (path: string) =>
  WHITELISTING_RULES.some(rule => new RegExp(rule).test(path))

export default function useManageInAppMessage(
  inAppMessageProvider: InAppMessageProvider | undefined,
) {
  const couldOnboardingModalBeShown = useSelector(getModalDisplayStatus) !== 'not shown'
  const isProfilePromoShown = useSelector(getIsPromoShown)
  const { relativeUrl } = useLocation()

  const isAnyModalShown = couldOnboardingModalBeShown || isProfilePromoShown
  const shouldEnable = isPathWhitelisted(relativeUrl) && !isAnyModalShown

  useEffect(() => {
    if (!inAppMessageProvider) return

    if (shouldEnable) {
      inAppMessageProvider.enable()
      inAppMessageProvider.publishStoredMessages()
    } else {
      inAppMessageProvider.disable()
    }
  }, [inAppMessageProvider, shouldEnable])
}
