import { SellerDiscoveryNoteDto } from 'types/dtos'
import { SellerDiscoveryNoteModel } from 'types/models'

export const transformSellerDiscoveryNote = (
  dto: SellerDiscoveryNoteDto,
): SellerDiscoveryNoteModel => ({
  message: dto.message,
  learnMoreMessage: dto.learn_more_message,
  learnMoreDetails: dto.learn_more_details,
  type: dto.type,
})
