import { CookieConsentVersion } from 'constants/consent'

import { stateName } from './constants'
import { State } from './types'

const initialState: State = {
  cookieConsentVersion: CookieConsentVersion.None,
}

const consentReducer = (state: State = initialState): State => state

export const plug = { [stateName]: consentReducer }
export default consentReducer
