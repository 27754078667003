import { UiState } from 'constants/ui'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getBanners = (state: AllState) => localState(state).banners
export const getBannersUiState = (state: AllState) => localState(state).uiState
export const getModalDisplayStatus = (state: AllState): 'shown' | 'not shown' | 'pending' => {
  const { banners, uiState } = localState(state) || {}

  if (uiState === UiState.Pending) return 'pending'
  if (banners?.onboardingModal && !banners?.onboardingModal?.isClosed) return 'shown'

  return 'not shown'
}
