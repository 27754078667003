import { stateName } from './constants'
import { State } from './types'

// @ts-ignore This will always get set via react-on-rails
const initialState: State = {}

const railsContextReducer = (state: State = initialState): State => state

export const plug = { [stateName]: railsContextReducer }
export default railsContextReducer
