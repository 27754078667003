export const stateName = 'profile' as const

export enum PromoAfterItemUpload {
  Empty = 'empty',
  ListAnotherItem = 'list_another_item',
  PushUpValueProposition = 'push_up_value_proposition',
  PushUpCheckout = 'push_up_checkout',
  ItemUploadFeedback = 'item_upload_feedback',
  SellerOfflineVerification = 'seller_offline_verification',
}
