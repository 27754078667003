import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const fetchCountriesRequest = createAction(`${stateName}/fetchCountriesRequest`)
export const fetchCountriesFailure = createAction(`${stateName}/fetchCountriesFailure`)

export const searchCitiesRequest = createAction<{ countryId: number; searchText?: string }>(
  `${stateName}/searchCitiesRequest`,
)
export const searchCitiesFailure = createAction(`${stateName}/searchCitiesFailure`)
