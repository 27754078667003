import { plug as conversation } from './conversation/slice'
import { plug as catalogFilters } from './catalog-filters/slice'
import { plug as savedSearches } from './saved-searches/slice'
import { plug as userFeedback } from './user-feedback/slice'
import { plug as userStats } from './user-stats/slice'
import { plug as mediaUpload } from './media-upload/slice'
import { plug as notifications } from './notifications/slice'
import { plug as faqEntry } from './faq-entry/slice'
import { plug as railsContext } from './rails-context/reducers'
import { plug as session } from './session/slice'
import { plug as screen } from './screen/slice'
import { plug as intl } from './intl/slice'
import { plug as items } from './items/slice'
import { plug as banners } from './banners/slice'
import { plug as bump } from './bump/slice'
import { plug as bundle } from './bundle/slice'
import { plug as directDonation } from './direct-donation/slice'
import { plug as fundraiser } from './fundraiser/slice'
import { plug as favouriteItems } from './favourite-items/slice'
import { plug as ads } from './ads/slice'
import { plug as consent } from './consent/reducers'
import { plug as auth } from './auth/slice'
import { plug as location } from './location/slice'
import { plug as accountSettings } from './account-settings/slice'
import { plug as itemUpload } from './item-upload/slice'
import { plug as featureSwitches } from './feature-switches/slice'
import { plug as profile } from './profile/slice'
import { plug as checkout } from './checkout'
import { plug as packageSizes } from './package-sizes/slice'
import { plug as kyc } from './kyc/slice'
import { plug as wallet } from './wallet/slice'
import { plug as activateBalance } from './activate-balance/slice'
import { plug as payout } from './payout/slice'
import { plug as bankAccount } from './bank-account/slice'
import { plug as profileSettings } from './profile-settings/slice'
import { plug as businessAddresses } from './business-addresses/slice'
import { plug as helpCenterFeedback } from './help-center-feedback/slice'
import { plug as shippingConfig } from './shipping-config/slice'
import { plug as abTests } from './ab-tests/slice'
import { plug as privacyPolicyAccordion } from './privacy-policy/slice'
import { plug as shippingContact } from './shipping-contact/slice'
import { plug as systemConfiguration } from './system-configuration/slice'
import { plug as returnShipment } from './return-shipment/slice'
import { plug as extraService } from './extra-service/slice'
import { plug as intellectualPropertyInfringement } from './intellectual-property-infringement/slice'
import { plug as verifications } from './verifications/slice'
import { plug as nextEnv } from './next-env/slice'
import { plug as complaint } from './complaint/slice'
import { plug as dropOffPointMap } from './drop-off-point-map/slice'
import { plug as userAddress } from './user-address/slice'

export const staticReducers = {
  ...railsContext,
  ...accountSettings,
  ...session,
  ...intl,
  ...screen,
  ...abTests,
  ...featureSwitches,
  ...nextEnv,
}

export const sharedReducers = {
  ...consent,
  ...auth,
  ...notifications,
  ...systemConfiguration,
  ...catalogFilters,
}

// Reducers for core app that are not split by route
export const coreReducers = {
  ...sharedReducers,
  ...location,
  ...bundle,
  ...savedSearches,
  ...mediaUpload,
  ...userFeedback,
  ...userStats,
  ...faqEntry,
  ...conversation,
  ...items,
  ...banners,
  ...bump,
  ...directDonation,
  ...fundraiser,
  ...ads,
  ...itemUpload,
  ...checkout,
  ...favouriteItems,
  ...packageSizes,
  ...kyc,
  ...wallet,
  ...activateBalance,
  ...payout,
  ...bankAccount,
  ...profileSettings,
  ...businessAddresses,
  ...helpCenterFeedback,
  ...shippingConfig,
  ...privacyPolicyAccordion,
  ...shippingContact,
  ...returnShipment,
  ...extraService,
  ...intellectualPropertyInfringement,
  ...profile,
  ...verifications,
  ...complaint,
  ...dropOffPointMap,
  ...userAddress,
}

export { plug as businessAccountsReducer } from './business-accounts/slice'
