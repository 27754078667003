import { SellerOfferOptionsDto } from 'types/dtos'
import { SellerOfferOptionsModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformSellerOfferOptionsDto = (
  sellerOfferOptions: SellerOfferOptionsDto,
): SellerOfferOptionsModel => ({
  minPrice: transformCurrencyAmountDto(sellerOfferOptions.min_price),
  maxPrice: transformCurrencyAmountDto(sellerOfferOptions.max_price),
  feesReductionThreshold: sellerOfferOptions.fees_reduction_threshold
    ? transformCurrencyAmountDto(sellerOfferOptions.fees_reduction_threshold)
    : null,
})
