import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { SavedFileModel } from 'types/models'
import { ErrorItem } from 'types/api'
import { FormModel } from 'pages/HelpCenter/IntellectualPropertyInfringement/types'
import { Field } from 'pages/HelpCenter/IntellectualPropertyInfringement/constants'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  filesToRetry: [],
  uploadUiState: UiState.Idle,
  failedFilenames: [],
  savedFiles: [],
  formData: null,
  formUiState: UiState.Idle,
  fieldErrors: {},
}

const uploadFilesRequest: CaseReducer<State, PayloadAction<{ files: Array<File> }>> = draft => {
  draft.uploadUiState = UiState.Pending
  draft.failedFilenames = []
  draft.filesToRetry = []
}

const uploadFilesSuccess: CaseReducer<
  State,
  PayloadAction<{ savedFiles: Array<SavedFileModel> }>
> = (draft, { payload }) => {
  draft.uploadUiState = UiState.Success
  draft.savedFiles = draft.savedFiles.concat(payload.savedFiles)
}

const deleteSavedFile: CaseReducer<State, PayloadAction<{ id: string }>> = (draft, { payload }) => {
  draft.savedFiles = draft.savedFiles.filter(file => file.id !== payload.id)
}

const addFileToRetry: CaseReducer<State, PayloadAction<{ file: File }>> = (draft, { payload }) => {
  draft.filesToRetry.push(payload.file)
}

const addFailedFilename: CaseReducer<State, PayloadAction<{ filename: string }>> = (
  draft,
  { payload },
) => {
  draft.failedFilenames.push(payload.filename)
}

const submitFormRequest: CaseReducer<State, PayloadAction<{ formData: FormModel }>> = draft => {
  draft.formUiState = UiState.Pending
  draft.formData = null
  draft.fieldErrors = {}
}

const submitFormSuccess: CaseReducer<State> = draft => {
  draft.formUiState = UiState.Success
  draft.formData = null
  draft.savedFiles = []
}

const submitFormFailure: CaseReducer<State, PayloadAction<{ errors: Array<ErrorItem> }>> = (
  draft,
  { payload },
) => {
  payload.errors.forEach(error => {
    if (error.field !== 'property_rights.illegal_contents.urls') return

    const urls = error.value.split(',')

    if (!urls.length) return

    draft.fieldErrors[Field.InfringingUrlsTextArea] = urls
  })

  draft.formUiState = Object.keys(draft.fieldErrors).length ? UiState.Idle : UiState.Failure
}

const setFormData: CaseReducer<State, PayloadAction<{ formData: FormModel }>> = (
  draft,
  { payload },
) => {
  draft.formData = payload.formData
}

const setFormUiState: CaseReducer<State, PayloadAction<{ formUiState: UiState }>> = (
  draft,
  { payload },
) => {
  draft.formUiState = payload.formUiState
}

const intellectualPropertyInfringementSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    uploadFilesRequest,
    uploadFilesSuccess,
    addFileToRetry,
    addFailedFilename,
    deleteSavedFile,
    submitFormRequest,
    submitFormSuccess,
    submitFormFailure,
    setFormData,
    setFormUiState,
  },
})

export const { actions } = intellectualPropertyInfringementSlice
export const plug = { [stateName]: intellectualPropertyInfringementSlice.reducer }
export default intellectualPropertyInfringementSlice.reducer
