export enum RegistrationStep {
  BusinessDetails = 'business-details',
  BusinessRepresentative = 'business-representative',
  BusinessAddress = 'business-address',
  BusinessProfile = 'business-profile',
}

export enum NewRegistrationStep {
  BusinessDetails = 'business-details',
  BusinessRepresentative = 'business-representative',
  BusinessProfile = 'business-profile',
}
export enum NewBusinessDetailsField {
  LegalCode = 'businessDetails.legalCode',
  LegalName = 'businessDetails.legalName',
  RegistrarName = 'businessDetails.registrarName',
  EntityType = 'businessDetails.entityType',
  Vat = 'businessDetails.vat',
  IsVatDisabled = 'businessDetails.isVatDisabled',
}

export enum NewBusinessAddressField {
  Country = 'addresses.businessAddress.countryId',
  AddressLine1 = 'addresses.businessAddress.line1',
  AddressLine2 = 'addresses.businessAddress.line2',
  PostalCode = 'addresses.businessAddress.postalCode',
  City = 'addresses.businessAddress.city',
  FullPostalCode = 'addresses.businessAddress.fullPostalCode',
  IsBusinessEqualToPersonalAddress = 'addresses.isBusinessEqualToPersonalAddress',
}

export enum NewRepresentativeField {
  FirstNames = 'businessRepresentative.firstNames',
  LastName = 'businessRepresentative.lastName',
  Birthday = 'businessRepresentative.birthday',
  Nationality = 'businessRepresentative.nationality',
  Country = 'businessRepresentative.country',
  Email = 'businessRepresentative.email',
  Ubos = 'businessRepresentative.ubos',
  IsSameLegalAddress = 'businessRepresentative.isSameLegalRepresentativeAddress',
  RepresentativeCountry = 'businessRepresentative.legalRepresentativeAddress.countryId',
  RepresentativeAddressLine1 = 'businessRepresentative.legalRepresentativeAddress.line1',
  RepresentativeAddressLine2 = 'businessRepresentative.legalRepresentativeAddress.line2',
  RepresentativePostalCode = 'businessRepresentative.legalRepresentativeAddress.postalCode',
  RepresentativeFullPostalCode = 'businessRepresentative.legalRepresentativeAddress.fullPostalCode',
  RepresentativeCity = 'businessRepresentative.legalRepresentativeAddress.city',
}

export const ORDERED_REGISTRATION_STEPS = [
  RegistrationStep.BusinessDetails,
  RegistrationStep.BusinessRepresentative,
  RegistrationStep.BusinessAddress,
  RegistrationStep.BusinessProfile,
]

export const NEW_ORDERED_REGISTRATION_STEPS = [
  NewRegistrationStep.BusinessDetails,
  NewRegistrationStep.BusinessRepresentative,
  NewRegistrationStep.BusinessProfile,
]

export enum BusinessAccountStatus {
  Registered = 'registered',
  WalletConversionInProgress = 'conversion_in_progress',
  Completed = 'completed',
}

export enum BusinessAccountFiles {
  Front = 'front',
  Back = 'back',
}
export enum BusinessAccountMediaTypes {
  Pdf = 'application/pdf',
  PngImage = 'image/png',
  JpegImage = 'image/jpeg',
}

export enum WalletConversionStatus {
  Idle = 'idle',
  Pending = 'pending',
  Failed = 'failed',
  Completed = 'completed',
  Unverified = 'unverified',
}

export enum BusinessAccountType {
  SoleTrader = 'sole_trader',
  LegalBusiness = 'legal_business',
  Organization = 'organization',
}

export const BusinessAccountEntityOptions = [
  BusinessAccountType.SoleTrader,
  BusinessAccountType.LegalBusiness,
  BusinessAccountType.Organization,
]

export const LEGAL_CODE_FIELD = 'legal_code'

export enum DocumentGroup {
  IdentityDocuments = 'identity_documents',
  SupportingDocuments = 'supporting_documents',
}

export enum KybFormField {
  IdentityDocument = 'identityDocument',
  IdentityDocumentExpiryDate = 'identityDocumentExpiryDate',
  SupportingDocuments = 'supportingDocuments',
}
