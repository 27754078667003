'use client'

import useAbTest from 'hooks/useAbTest'

const InpostAbTestGlobalTracking = () => {
  useAbTest({
    abTestName: 'inpost_economy_integration_2d',
    shouldTrackOnce: true,
    shouldTrackExpose: true,
  })

  useAbTest({
    abTestName: 'inpost_economy_integration_7d',
    shouldTrackOnce: true,
    shouldTrackExpose: true,
  })

  return null
}

export default InpostAbTestGlobalTracking
