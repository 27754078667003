import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import ConsentContext from 'containers/ConsentProvider/ConsentContext'
import { ConsentGroup, CookieConsentVersion } from 'constants/consent'
import { checkConsentGroup } from 'libs/utils/consentGroups'
import * as consentSelectors from 'state/consent/selectors'

const useIsConsentGroupEnabled = (group: ConsentGroup) => {
  const { serverCookieOptanonConsent } = useContext(ConsentContext)
  const cookieConsentVersion = useSelector(consentSelectors.getCookieConsentVersion)
  const [isConsentGroupEnabled, setIsConsentGroupEnabled] = useState(
    checkConsentGroup(group, serverCookieOptanonConsent),
  )

  const handleBannerLoaded = useCallback(() => {
    setIsConsentGroupEnabled(checkConsentGroup(group, serverCookieOptanonConsent))
  }, [group, serverCookieOptanonConsent])

  useEffect(() => {
    window.addEventListener('bannerLoaded', handleBannerLoaded)

    return () => {
      window.removeEventListener('bannerLoaded', handleBannerLoaded)
    }
  }, [handleBannerLoaded])

  return useMemo(() => {
    if (cookieConsentVersion === CookieConsentVersion.None) return true

    return isConsentGroupEnabled
  }, [isConsentGroupEnabled, cookieConsentVersion])
}

export default useIsConsentGroupEnabled
