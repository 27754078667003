import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { DEFAULT_ISO_LANGUAGE_CODE } from 'constants/language'
import { AddressDto, UserBundleDiscountDto, UserDto, UserFundraiserDto } from 'types/dtos'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  countryCode: '',
  user: null,
  anon_id: '',
  tracking_platform: 'web',
  tracker_debug_pin: null,
  languageCode: DEFAULT_ISO_LANGUAGE_CODE,
  portalMergeSourceEnabled: false,
  facebookAppId: '',
  googleClientId: '',
  appleClientId: '',
  googleMapsApiKey: '',
  braze: {
    sdkKey: '',
    apiKey: '',
    safariWebsitePushId: '',
    sdkEndpoint: '',
  },
  isSmartAdServerAvailable: false,
  isWebView: false,
  isContentOnlyView: false,
}

const setUser: CaseReducer<State, PayloadAction<{ user: UserDto }>> = (draft, action) => {
  const { user } = action.payload

  draft.user = user
}

const setUserDefaultAddress: CaseReducer<State, PayloadAction<{ address: AddressDto }>> = (
  draft,
  action,
) => {
  const { address } = action.payload

  if (!draft.user) return

  draft.user.default_address = address
}

const setFundraiserConfiguration: CaseReducer<
  State,
  PayloadAction<{ fundraiser: UserFundraiserDto }>
> = (draft, action) => {
  const { fundraiser } = action.payload

  if (!draft.user) return

  draft.user.fundraiser = fundraiser
}

const setHasConfirmedPaymentsAccount: CaseReducer<
  State,
  PayloadAction<{ isConfirmed: boolean }>
> = (draft, action) => {
  const { isConfirmed } = action.payload

  if (!draft.user) return

  draft.user.has_confirmed_payments_account = isConfirmed
}

const setAnonId: CaseReducer<State, PayloadAction<{ anonId: string }>> = (draft, action) => {
  const { anonId } = action.payload

  draft.anon_id = anonId
}

const setDebugPin: CaseReducer<State, PayloadAction<{ debugPin: number }>> = (draft, action) => {
  const { debugPin } = action.payload

  draft.tracker_debug_pin = debugPin
}

const setIsWebView: CaseReducer<State, PayloadAction<{ isWebView: boolean }>> = (draft, action) => {
  draft.isWebView = action.payload.isWebView
}

const setIsContentOnlyView: CaseReducer<State, PayloadAction<{ isContentOnlyView: boolean }>> = (
  draft,
  action,
) => {
  draft.isContentOnlyView = action.payload.isContentOnlyView
}

const setCountryCode: CaseReducer<State, PayloadAction<{ countryCode: string }>> = (
  draft,
  action,
) => {
  const { countryCode } = action.payload

  draft.countryCode = countryCode
}

const setTrackingPlatform: CaseReducer<State, PayloadAction<{ trackingPlatform: string }>> = (
  draft,
  action,
) => {
  draft.tracking_platform = action.payload.trackingPlatform
}

const setLanguageCode: CaseReducer<State, PayloadAction<{ languageCode: string }>> = (
  draft,
  action,
) => {
  draft.languageCode = action.payload.languageCode
}

const setUserBundleDiscounts: CaseReducer<
  State,
  PayloadAction<{ userBundleDiscounts: UserBundleDiscountDto | null }>
> = (draft, action) => {
  if (!draft.user) return

  draft.user.bundle_discount = action.payload.userBundleDiscounts
}

const userSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setUser,
    setUserDefaultAddress,
    setFundraiserConfiguration,
    setHasConfirmedPaymentsAccount,
    setAnonId,
    setDebugPin,
    setIsWebView,
    setIsContentOnlyView,
    setCountryCode,
    setTrackingPlatform,
    setLanguageCode,
    setUserBundleDiscounts,
  },
})

export const { actions } = userSlice
export const plug = { [stateName]: userSlice.reducer }
export default userSlice.reducer
