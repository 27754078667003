import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'
import uuid from 'uuid'

import * as api from 'data/api'

import { actions } from './slice'
import * as statelessActions from './actions'
import { transformBundleSummary, transformInstantBundleSummary } from './transformers'

export function* setSelectedItems({
  payload: { itemIds, userId },
}: ReturnType<typeof actions.setSelectedItems>) {
  yield put(statelessActions.getInstantBundleOrderReviewRequest({ itemIds, userId }))

  yield put(statelessActions.getBundleSummaryRequest({ itemIds, userId }))
}

export function* createBundleSummary({
  payload: { itemIds, userId },
}: ReturnType<typeof statelessActions.getBundleSummaryRequest>) {
  const response = yield* call(api.createBundleSummary, {
    itemIds,
    sellerId: userId,
  })

  if ('errors' in response) {
    yield put(statelessActions.getBundleSummaryFailure())

    return
  }

  if (response) {
    yield put(
      actions.getBundleSummarySuccess({
        bundleSummary: transformBundleSummary(response.bundle_summary),
      }),
    )
  }
}

export function* getInstantBundleOrderReview({
  payload: { itemIds, userId },
}: ReturnType<typeof statelessActions.getInstantBundleOrderReviewRequest>) {
  const response = yield* call(api.getBundleOrderReview, {
    itemIds,
    sellerId: userId,
  })

  if ('errors' in response) {
    yield put(statelessActions.getBundleSummaryFailure())

    return
  }

  if (response) {
    yield put(
      actions.getInstantBundleSummarySuccess({
        instantBundleSummary: transformInstantBundleSummary(response.bundle_order_review),
      }),
    )
  }
}

export function* startBundling() {
  const bundleTrackingId = uuid.v4()

  yield put(actions.setBundleTrackingId({ bundleTrackingId }))
}

export default function* saga() {
  yield takeLatest(actions.startBundling, startBundling)
  yield takeLatest(actions.setSelectedItems, setSelectedItems)
  yield takeLatest(statelessActions.getBundleSummaryRequest, createBundleSummary)
  yield takeLatest(statelessActions.getInstantBundleOrderReviewRequest, getInstantBundleOrderReview)
}
