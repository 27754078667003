import { ProfileCollectionDto, CollectionsDto } from 'types/dtos'
import { ProfileCollectionModel, CollectionsModel } from 'types/models'

export const transformCollection = (collection: ProfileCollectionDto): ProfileCollectionModel => ({
  id: collection.id,
  isActive: collection.is_active,
  humanizedDiscountAmount: collection.humanized_discount_amount,
  title: collection.title,
  itemsPhotos: collection.items_photos,
  itemsCount: collection.items_count,
})

export const transformCollections = (dto: CollectionsDto): CollectionsModel => ({
  humanizedTimeLeft: dto.humanized_time_left,
  maxActiveCount: dto.max_active_count,
  maxTotalCount: dto.max_total_count,
  collections: dto.collections.map(transformCollection),
})
