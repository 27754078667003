import {
  DynamicConfigurationDto,
  NationalityCountryDto,
  PaymentsAccountDto,
  RequirementsDto,
} from 'types/dtos'
import {
  DynamicConfigurationModel,
  NationalityCountryModel,
  PaymentsAccountModel,
  RequirementsModel,
} from 'types/models'

import { transformAddress } from './address'
import { transformPepDetails } from './pep-details'

export const transformNationality = (
  nationality: NationalityCountryDto,
): NationalityCountryModel => ({
  code: nationality.code,
  title: nationality.title,
})

export const transformPaymentsAccount = (
  paymentsAccount: PaymentsAccountDto,
): PaymentsAccountModel => ({
  firstName: paymentsAccount.first_name,
  lastName: paymentsAccount.last_name,
  birthdate: paymentsAccount.birthdate,
  nationality: paymentsAccount.nationality
    ? transformNationality(paymentsAccount.nationality)
    : null,
  address: paymentsAccount.user_address ? transformAddress(paymentsAccount.user_address) : null,
  pepDetails: paymentsAccount.pep_details ? transformPepDetails(paymentsAccount.pep_details) : null,
})

export const transformDynamicConfiguration = (
  dynamicConfiguration: DynamicConfigurationDto,
): DynamicConfigurationModel => ({
  showInformationHint: dynamicConfiguration.show_information_hint,
  agreementHint: dynamicConfiguration.agreement_hint,
  personalIdNumberRequiredNationalities:
    dynamicConfiguration.personal_id_number_required_nationalities,
  addressType: dynamicConfiguration.address_type,
  lockedFields: dynamicConfiguration.locked_fields,
  maskedFields: dynamicConfiguration.masked_fields,
})

export const transformRequirements = (requirements: RequirementsDto): RequirementsModel => ({
  ssnSerial: requirements.ssn_serial,
  nationality: requirements.nationality,
  pepDeclaration: requirements.pep_declaration,
})
