import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State } from './types'
import { EmailCodeView, stateName } from './constants'

export const initialState: State = {
  emailCodeView: EmailCodeView.EnterCode,
}

const setEmailCodeView: CaseReducer<State, PayloadAction<EmailCodeView>> = (draft, action) => {
  draft.emailCodeView = action.payload
}

const verificationsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setEmailCodeView,
  },
})

export const { actions } = verificationsSlice
export const plug = { [stateName]: verificationsSlice.reducer }
export default verificationsSlice.reducer
