import { useCallback, useContext, useMemo } from 'react'

import RequestContext from 'containers/RequestProvider/RequestContext'
import { toParams } from 'libs/utils/url'
import { navigateToPage } from 'libs/utils/window'

const useLocation = () => {
  const { url, urlParams, back, push, replace, refreshUrl } = useContext(RequestContext).location

  const safeBack = useCallback(
    (fallbackUrl: string) => {
      if (document.referrer.indexOf(new URL(url).origin) === -1) {
        navigateToPage(fallbackUrl)
      } else {
        back()
      }
    },
    [back, url],
  )

  return useMemo(() => {
    const currentUrl = new URL(url)

    return {
      baseUrl: currentUrl.origin,
      host: currentUrl.host,
      urlQuery: currentUrl.search,
      relativeUrl: currentUrl.pathname,
      urlHash: currentUrl.hash,
      searchParams: toParams(currentUrl.search),
      urlParams,
      safeBack,
      goToPreviousPage: back,
      pushHistoryState: push,
      replaceHistoryState: replace,
      refreshUrl,
    }
  }, [back, push, refreshUrl, replace, safeBack, url, urlParams])
}

export default useLocation
