import { PepDetailsDto } from 'types/dtos'
import { PepDetailsModel } from 'types/models'

export const transformPepDetails = (pepDetails: PepDetailsDto): PepDetailsModel => ({
  type: pepDetails.type,
  associatedPepFullName: pepDetails.associated_pep_full_name,
  holdsPositionFrom: pepDetails.holds_position_from,
  organisation: pepDetails.organisation,
  position: pepDetails.position,
})
