import { InvoiceLineDto, WalletInvoicesDto } from 'types/dtos'
import { InvoiceLineModel } from 'types/models'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'

import { Invoices } from './types'

export const transformInvoiceLineDto = (line: InvoiceLineDto): InvoiceLineModel => {
  const { id, amount, date, title, subtitle, type, entity_id, entity_type } = line

  return {
    id,
    amount: transformCurrencyAmountDto(amount),
    date,
    title,
    subtitle,
    type,
    entityId: entity_id,
    entityType: entity_type,
  }
}

export const transformInvoiceLinesDto = (lines: Array<InvoiceLineDto>): Array<InvoiceLineModel> =>
  lines.map(transformInvoiceLineDto)

export const transformWalletInvoices = (walletInvoices: WalletInvoicesDto): Invoices => ({
  invoiceLines: transformInvoiceLinesDto(walletInvoices.invoice_lines),
  startBalance: transformCurrencyAmountDto(walletInvoices.previous_balance),
  endBalance: transformCurrencyAmountDto(walletInvoices.balance),
  startingDate: walletInvoices.starting_date,
  history: walletInvoices.history?.map(history => ({
    title: history.title,
    year: history.year,
    month: history.month,
    balance: transformCurrencyAmountDto(history.balance),
  })),
  taxpayerRestricted: walletInvoices.taxpayer_restricted,
})
