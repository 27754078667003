import {
  put,
  all,
  call,
  race,
  take,
  delay,
  select,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects'

import { getUserId } from 'state/session/selectors'

import { getBrowserAttributes } from 'libs/utils/threeDS2'
import { getExtraServiceOrderPaymentAuthAction } from 'libs/utils/extraService'

import { ExtraServicePaymentResp, ResponseError } from 'types/api'

import {
  PAYMENT_POLL_DELAY,
  ExtraServiceOrderStatus,
  ExtraServicePaymentStatus,
} from 'constants/extra-service'

import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import { transformWalletBalanceDto } from 'data/transformers/wallet'
import { transformPayInMethodDtos } from 'data/transformers/pay-in-method'
import { transformExtraServicePaymentDto } from 'data/transformers/extra-service-payment'
import { transformCheckoutConfigurationDto } from 'data/transformers/checkout-configuration'
import { transformCreditCardDto, transformCreditCardDtos } from 'data/transformers/credit-card'

import { actions } from './slice'
import * as selectors from './selectors'
import * as statelessActions from './actions'
import { filterOutEmptyWallet } from './utils'

// Single use cards are not returned via getCreditCards api call.
// To make sure that, for example, after 3D secure redirect right card is selected as payment method, this API call is needed to restore state from before redirect with a single use card.
export function* getCardIfNotInState({
  payload: { creditCardId },
}: ReturnType<typeof actions.setSelectedPayInMethod>) {
  if (!creditCardId) return

  const cards = yield select(selectors.getCreditCards)

  const isCardAlreadyInState = cards.some(({ id }) => id === creditCardId)

  if (isCardAlreadyInState) return

  const response = yield call(api.getCreditCard, { cardId: creditCardId })

  if ('errors' in response) {
    yield put(actions.getPayInMethodsFailure())

    return
  }

  const card = transformCreditCardDto(response.card)

  yield put(
    actions.getCreditCardSuccess({
      creditCard: card,
    }),
  )
}

export function* getPayInMethods() {
  const userId = yield select(getUserId)

  if (!userId) return

  const { creditCardsResponse, payInMethodsResponse, walletBalanceResponse } = yield all({
    creditCardsResponse: call(api.getCreditCards),
    payInMethodsResponse: call(api.getExtraServicePayInMethods),
    walletBalanceResponse: call(api.getWalletBalance, userId),
  })

  if (
    'errors' in creditCardsResponse ||
    'errors' in payInMethodsResponse ||
    'errors' in walletBalanceResponse
  ) {
    yield put(actions.getPayInMethodsFailure())

    return
  }

  const creditCards = transformCreditCardDtos(creditCardsResponse.cards)
  const payInMethods = transformPayInMethodDtos(payInMethodsResponse.pay_in_methods)
  const walletBalance = transformWalletBalanceDto(walletBalanceResponse.user_balance)

  yield put(
    actions.getPayInMethodsSuccess({
      creditCards,
      walletBalance,
      payInMethods: filterOutEmptyWallet(payInMethods, walletBalance.availableAmount),
    }),
  )
}

function* handleOrderGeneralError(responseError: ResponseError) {
  if (!responseError?.code) return

  switch (responseError.code) {
    case ResponseCode.KYCError:
      yield put(actions.setIsKycConfirmationNeeded({ isNeeded: true }))
      break
    default:
      yield put(
        actions.setOrderPaymentErrorMessage({ errorMessage: responseError.errors[0]?.value }),
      )
      break
  }
}

function* handleOrderPaymentError({ extra_service_payment }: ExtraServicePaymentResp) {
  const extraServicePayment = transformExtraServicePaymentDto(extra_service_payment)

  if (!extraServicePayment.failureReason) return

  yield put(
    actions.setOrderPaymentErrorMessage({ errorMessage: extraServicePayment.failureReason }),
  )
}

function* handleOrderPendingState({ extra_service_payment }: ExtraServicePaymentResp) {
  yield delay(PAYMENT_POLL_DELAY)

  const extraServicePayment = transformExtraServicePaymentDto(extra_service_payment)

  const response = yield call(api.getExtraServicePayment, extraServicePayment.id)

  yield put(statelessActions.handleOrderConfirmationResult({ response }))
}

export function* handleOrderConfirmationResult({
  payload: { response },
}: ReturnType<typeof statelessActions.handleOrderConfirmationResult>) {
  if ('errors' in response) {
    yield call(handleOrderGeneralError, response)

    return
  }

  if (response.extra_service_payment.status === ExtraServicePaymentStatus.Failed) {
    yield call(handleOrderPaymentError, response)

    return
  }

  const authAction = getExtraServiceOrderPaymentAuthAction(response)

  if (authAction) {
    yield put(statelessActions.fetchConfigurationRequest())

    const configurationFetchResult = yield race({
      success: take(actions.fetchConfigurationSuccess),
      failure: take(actions.fetchConfigurationFailure),
    })

    if (configurationFetchResult.failure) return

    yield put(authAction)

    return
  }

  if (response.extra_service_payment.status === ExtraServicePaymentStatus.Pending) {
    yield call(handleOrderPendingState, response)

    return
  }

  yield put(actions.confirmOrderPaymentSuccess())
}

export function* confirmOrder({ payload }: ReturnType<typeof actions.confirmOrderPaymentRequest>) {
  const {
    orderId,
    blikCode,
    orderType,
    entityType,
    selectedCreditCardId,
    selectedPaymentMethodId,
  } = payload

  const orderArgs = {
    orderId,
    blikCode,
    orderType,
    entityType,
    creditCardId: selectedCreditCardId,
    paymentMethodId: selectedPaymentMethodId,
    browserAttributes: getBrowserAttributes(),
  }

  const response = yield call(api.confirmExtraServiceOrder, orderArgs)

  yield put(statelessActions.handleOrderConfirmationResult({ response }))
}

export function* fetchConfiguration() {
  const response = yield call(api.getCheckoutConfiguration)

  if ('errors' in response) {
    yield put(actions.fetchConfigurationFailure({ errorMessage: response.errors[0]?.value }))

    return
  }

  yield put(
    actions.fetchConfigurationSuccess({
      configuration: transformCheckoutConfigurationDto(response.checkout_configuration),
    }),
  )
}

export function* updatePaymentData({
  payload,
}: ReturnType<typeof actions.updateOrderPaymentDataRequest>) {
  const orderStatus = yield select(selectors.getPaymentStatus)

  if (orderStatus === ExtraServiceOrderStatus.Success) return

  const response = yield call(api.updateExtraServicePaymentData, payload.paymentId, {
    paymentData: {
      details: payload.state.data.details,
      data: payload.state.data.paymentData,
    },
  })

  yield put(statelessActions.handleOrderConfirmationResult({ response }))
}

export default function* saga() {
  yield takeLeading(actions.getPayInMethodsRequest.type, getPayInMethods)
  yield takeLatest(actions.confirmOrderPaymentRequest.type, confirmOrder)
  yield takeLatest(actions.setSelectedPayInMethod.type, getCardIfNotInState)
  yield takeLatest(actions.updateOrderPaymentDataRequest.type, updatePaymentData)
  yield takeLatest(statelessActions.fetchConfigurationRequest.type, fetchConfiguration)
  yield takeLatest(
    statelessActions.handleOrderConfirmationResult.type,
    handleOrderConfirmationResult,
  )
}
