import { takeEvery, put } from 'redux-saga/effects'
import { select, call } from 'typed-redux-saga'
import { unescape } from 'lodash'

import {
  updateCurrentUser,
  updateBusinessAccountProfileDetails,
  updateUserLanguage,
} from 'data/api'
import { UiState } from 'constants/ui'
import * as sessionSelectors from 'state/session/selectors'
import * as locationStatelessActions from 'state/location/actions'
import * as locationSelectors from 'state/location/selectors'
import { scrollToTop, redirectToPage } from 'libs/utils/window'

import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'

export function* selectCountry({ payload }: ReturnType<typeof statelessActions.selectCountry>) {
  yield put(actions.setCountryId({ id: payload.id }))
  yield put(actions.setCity({ id: null, title: null }))

  yield put(locationStatelessActions.searchCitiesRequest({ countryId: payload.id }))
}

export function* selectCity({ payload }: ReturnType<typeof statelessActions.selectCity>) {
  const city = yield* select(locationSelectors.getCityById, payload.id)

  if (!city) return

  yield put(actions.setCity({ id: city.id, title: city.title }))
}

export function* loadFormAttributes() {
  const currentUser = yield* select(sessionSelectors.getUser)
  const languageCode = yield* select(sessionSelectors.getLanguageCode)

  if (!currentUser) return

  const { country_id, city_id, city, is_location_public, about, business_account } = currentUser

  const settings = {
    countryId: country_id,
    cityId: city_id,
    cityTitle: city,
    isCityVisible: !!is_location_public,
    about: unescape(about),
    languageCode,
    photoTempUuid: undefined,
  }

  if (business_account) {
    const { name, email, phone_number, vat } = business_account

    Object.assign(settings, {
      username: name,
      contactEmail: email,
      contactNumber: phone_number,
      vat,
    })
  }

  yield put(actions.setInitialSettings({ settings }))

  if (country_id) yield put(locationStatelessActions.searchCitiesRequest({ countryId: country_id }))
}

export function* saveSettings({
  payload: { redirect },
}: ReturnType<typeof statelessActions.saveSettings>) {
  const uiState = yield* select(selectors.getSettingsUiState)

  if (uiState === UiState.Pending) return

  const settings = yield* select(selectors.getProfileSettings)

  const initialLanguageCode = yield* select(sessionSelectors.getLanguageCode)
  const businessAccount = yield* select(sessionSelectors.getBusinessAccount)
  const isBusinessAccount = yield* select(sessionSelectors.getIsBusinessAccount)

  yield put(actions.changeUiState({ uiState: UiState.Pending }))

  const { languageCode, ...userSettings } = settings
  let response

  if (businessAccount && isBusinessAccount) {
    const { username, contactEmail, contactNumber, vat, about, photoTempUuid } = userSettings

    response = yield* call(updateBusinessAccountProfileDetails, {
      businessAccountId: businessAccount.id,
      username,
      contactEmail,
      contactNumber,
      about,
      photoTempUuid,
      vat,
    })
  } else {
    response = yield* call(updateCurrentUser, userSettings)
  }

  if ('errors' in response) {
    yield put(actions.setError({ error: response }))
    yield put(actions.changeUiState({ uiState: UiState.Failure }))

    yield* call(scrollToTop)

    return
  }

  const currentUser = yield* select(sessionSelectors.getUser)

  if (currentUser && initialLanguageCode !== languageCode) {
    yield* call(updateUserLanguage, { userId: currentUser.id, locale: languageCode })
  }

  yield* call(redirectToPage, redirect)
}

export default function* saga() {
  yield takeEvery(statelessActions.selectCountry, selectCountry)
  yield takeEvery(statelessActions.selectCity, selectCity)
  yield takeEvery(statelessActions.loadFormAttributes, loadFormAttributes)
  yield takeEvery(statelessActions.saveSettings, saveSettings)
}
