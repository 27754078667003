import { takeLatest, put } from 'redux-saga/effects'
import { call, select, all } from 'typed-redux-saga'

import { getUserId } from 'state/session/selectors'
import { transformAddress } from 'data/transformers/address'
import {
  transformDynamicConfiguration,
  transformPaymentsAccount,
  transformRequirements,
} from 'data/transformers/payments-account'
import * as api from 'data/api'
import {
  AddressModel,
  DynamicConfigurationModel,
  PaymentsAccountModel,
  RequirementsModel,
} from 'types/models'
import { UiState } from 'constants/ui'
import { PepType } from 'constants/pep'

import { actions } from './slice'
import { ActivateBalanceFormValues } from './types'

export function* getDefaultFormValues() {
  const currentUserId = yield* select(getUserId)

  if (!currentUserId) return

  const [billingResponse, paymentsResponse] = yield* all([
    call(api.getDefaultBillingAddress),
    call(api.getPaymentsAccount, currentUserId),
  ])

  let address: AddressModel | null = null
  let paymentsAccount: PaymentsAccountModel | null = null
  let isPersonalIdNumberRequired = false
  let isAccountDetailsLocked = false
  let isBalanceActivated = false
  let agreementHint = ''
  let dynamicConfiguration: DynamicConfigurationModel | null = null
  let requirements: RequirementsModel | null = null

  if ('user_address' in billingResponse) {
    address = transformAddress(billingResponse.user_address)
  }

  if ('payments_account' in paymentsResponse && paymentsResponse.payments_account) {
    paymentsAccount = transformPaymentsAccount(paymentsResponse.payments_account)
  }

  if ('locked_account_details' in paymentsResponse) {
    isAccountDetailsLocked = paymentsResponse.locked_account_details
  }

  if ('balance_activated' in paymentsResponse) {
    isBalanceActivated = paymentsResponse.balance_activated
  }

  if ('personal_id_number_required' in paymentsResponse) {
    isPersonalIdNumberRequired = paymentsResponse.personal_id_number_required
  }

  if ('agreement_hint' in paymentsResponse) {
    agreementHint = paymentsResponse.agreement_hint
  }

  if ('dynamic_configuration' in paymentsResponse && paymentsResponse.dynamic_configuration) {
    dynamicConfiguration = transformDynamicConfiguration(paymentsResponse.dynamic_configuration)
  }

  if ('requirements' in paymentsResponse && paymentsResponse.requirements) {
    requirements = transformRequirements(paymentsResponse.requirements)
  }

  const pepDetails = paymentsAccount?.pepDetails

  const form: ActivateBalanceFormValues = {
    firstName: paymentsAccount?.firstName || '',
    lastName: paymentsAccount?.lastName || '',
    birthdate: paymentsAccount?.birthdate || '',
    ssn: '',
    personalIdNumber: '',
    nationality: paymentsAccount?.nationality || null,
    pepType: pepDetails ? pepDetails.type || PepType.NotPep : undefined,
    pepFullName: pepDetails?.associatedPepFullName || '',
    pepOrganisation: pepDetails?.organisation || '',
    pepPosition: pepDetails?.position || '',
    pepPositionDate: pepDetails?.holdsPositionFrom || '',
  }

  yield put(
    actions.getDefaultFormValuesSuccess({
      form,
    }),
  )

  yield* all([
    put(
      actions.setCanChange({
        canChange: !isAccountDetailsLocked,
      }),
    ),
    put(
      actions.setIsPersonalIdNumberRequired({
        status: isPersonalIdNumberRequired,
      }),
    ),
    put(
      actions.setIsBalanceActivated({
        status: isBalanceActivated,
      }),
    ),
    put(
      actions.setAgreementHint({
        status: agreementHint,
      }),
    ),
    put(
      actions.setDynamicConfiguration({
        dynamicConfiguration,
      }),
    ),
    put(
      actions.setRequirements({
        requirements,
      }),
    ),
  ])
  yield put(actions.setAddress({ address }))
  yield put(actions.setStatus({ status: UiState.Success }))
}

export default function* saga() {
  yield takeLatest(actions.getDefaultFormValuesRequest.type, getDefaultFormValues)
}
