import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { CustomShipmentConfigModel } from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  custom: {
    ui: UiState.Idle,
    config: null,
  },
}

const fetchCustomShipmentConfigRequest: CaseReducer<State> = draft => {
  draft.custom.ui = UiState.Pending
}

const fetchCustomShipmentConfigSuccess: CaseReducer<
  State,
  PayloadAction<{ config: CustomShipmentConfigModel }>
> = (draft, action) => {
  draft.custom.ui = UiState.Success
  draft.custom.config = action.payload.config
}

const fetchCustomShipmentConfigFailure: CaseReducer<State> = draft => {
  draft.custom.ui = UiState.Failure
}

const shipmentConfigSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchCustomShipmentConfigRequest,
    fetchCustomShipmentConfigSuccess,
    fetchCustomShipmentConfigFailure,
  },
})

export const { actions } = shipmentConfigSlice
export const plug = { [stateName]: shipmentConfigSlice.reducer }
export default shipmentConfigSlice.reducer
