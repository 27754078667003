export enum ViewableElement {
  FacebookLikeButton = 'facebook_like_button',
  MyItemsGrid = 'my_items_grid',
  MyItemsList = 'my_items_list',
  MyFavoritesGrid = 'my_favorites_grid',
  MyFavoritesList = 'my_favorites_list',
  MeasurementsInfo = 'measurements_info',
  CatalogGrid = 'catalog_grid',
  CatalogList = 'catalog_list',
  NpsSurvey = 'nps_survey',
  SellTabSurvey = 'sell_tab_survey',
  SellerAfterUploadInstructions = 'seller_after_upload_instructions',
  SellerAfterSaleInstructions = 'seller_after_sale_instructions',
  SellerAfterTransactionCompleteInstructions = 'seller_after_transaction_complete_instructions',
  EmailConfirmation = 'email_confirmation',
  EmailConfirmed = 'email_confirmed',
  Video = 'video',
  SellerBanner = 'seller_banner',
  SellTabBanner = 'sell_tab_banner',
  SellerFunnelWelcomeSlide = 'seller_funnel_welcome_slide',
  WalletSetupSuccess = 'wallet_setup_success',
  WalletPayInSuccess = 'wallet_pay_in_success',
  NewsletterSubscriptionBanner = 'newsletter_subscription_banner',
  EditorialCampaignBanner = 'editorial_campaign_banner',
  CtaPrompty = 'cta_prompt',
  FeedPersonalizationBanner = 'feed_personalization_banner',
  PersonalizationBannerAfterFeed = 'personalization_banner_after_feed',
  AdBox = 'ad_box',
  BumpPromotion = 'bump_promotion',
  OnboardingModalCard = 'onboarding_modal_card',
  AppRatingDialog = 'app_rating_dialog',
  UploadOnboardingCarouselCard = 'upload_onboarding_carousel_card',
  Notification = 'notification',
  SeeWholeClosetCta = 'see_whole_closet_cta',
  CookieAndAdPersonalizationBanner = 'cookie_and_ad_personalization_banner',
  AdPersonalizationToggle = 'ad_personalization_toggle',
  ShippingPrice = 'shipping_price',
  TranslationHint = 'translation_hint',
  LocalSearchSuggestion = 'local_search_suggestion',
  BrandClusterSection = 'brand_cluster_section',
  PhotoPickerInfoBanner = 'photo_picker_info_banner',
  CrmMessageVideo = 'crm_message_video',
  FreeReturnLabelModal = 'free_return_label_modal',
  LabelTypeSelection = 'label_type_selection',
  PushUpOrderConfirmation = 'push_up_order_confirm',
  ReturnOrder = 'return_order',
  ShipFastBadgeDescription = 'ship_fast_badge_description',
  PhysicalAuthenticityBuyerModal = 'physical_auth_buyer',
  OfflineVerificationSellerModal = 'physical_auth_seller',
  OfflineVerificationStatus = 'physical_auth_eligible',
  ValueSelectModal = 'value_select_modal',
  CtaPrompt = 'cta_prompt',
  DropOffPoint = 'drop_off_point',
  TaxRules = 'taxpayers_tax_rules',
  TaxpayersDac7Banner = 'taxpayers_dac7_banner',
  TaxpayersBalanceBlockModal = 'taxpayers_balance_block_modal',
  TaxpayersSalesBlockModal = 'taxpayers_sales_block_modal',
  TaxpayerReport = 'taxpayers_report',
  TaxpayersDac7SpecialVerificationBanner = 'taxpayers_dac7_special_verification_banner',
  TaxpayersTaxRulesExamples = 'taxpayers_tax_rules_testimonial',
  TaxpayersTaxRulesStories = 'taxpayers_tax_rules_stories',
  Checkout = 'checkout',
  SeeDropOffPoints = 'see_drop_off_points',
  ListingIncreaseBanner = 'listing_increase_banner',
  TaxpayersSpecialVerificationBalanceBlockModal = 'taxpayers_special_verification_balance_block_modal',
  PricingDetails = 'pricing_details',
  SalesTax = 'sales_tax',
  BundleDiscountsSettings = 'bundle_discounts_settings',
  ExtendShippingDeadline = 'extend_shipping_deadline',
  VerificationCompleted = 'verification_completed',
  CpReminder = 'cp_reminder',
  UserLastSeen = 'user_last_seen',
  SelectParcelSize = 'select_parcel_size',
}
