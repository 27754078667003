import { ReturnShipmentOrderDto } from 'types/dtos'
import { ReturnShipmentOrderModel } from 'types/models'

export const transformReturnShipmentOrderDto = (
  returnShipmentOrder: ReturnShipmentOrderDto,
): ReturnShipmentOrderModel => ({
  id: returnShipmentOrder.id,
  totalAmount: returnShipmentOrder.total_amount,
  payableAmount: returnShipmentOrder.payable_amount,
  currency: returnShipmentOrder.currency,
})
