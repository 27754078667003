import { AbTestDto } from 'types/dtos'

import { stateName } from './constants'
import { AllState, State } from './types'

const localState = (state: AllState): State => state[stateName]

export const getAbTestByName =
  (name: string) =>
  (state: AllState): AbTestDto | undefined =>
    localState(state)[name]

export const getIsAbTestVariant =
  (name: string, variant: string) =>
  (state: AllState): boolean =>
    getAbTestByName(name)(state)?.variant === variant

export const getIsAbTestOn =
  (name: string) =>
  (state: AllState): boolean =>
    getIsAbTestVariant(name, 'on')(state)

export const getIsShippingFeesApplyBannerAbTestEnabled = (state: AllState): boolean =>
  getIsAbTestOn('info_banner_shipping_fees_apply_feed')(state)

export const getIsFeedRemovalAbTestEnabled = (state: AllState): boolean =>
  getIsAbTestOn('feed_removal')(state)
