import { getLocalStorageItem, setLocalStorageItem } from 'libs/utils/localStorage'

const SUBSCRIBED_VALUE = 'true'
const SAVED_SEARCHES_SUBSCRIBED_KEY = 'saved_searches_subscribed'

export const saveFirstTimeSubscribe = () => {
  setLocalStorageItem(SAVED_SEARCHES_SUBSCRIBED_KEY, SUBSCRIBED_VALUE)
}

export const isSavedSearchUser = (): boolean =>
  getLocalStorageItem(SAVED_SEARCHES_SUBSCRIBED_KEY) === SUBSCRIBED_VALUE
