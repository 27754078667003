import { transformShippingBusinessHoursDto } from 'data/transformers/shipping-point'
import {
  DropOffPointCarrierDto,
  DropOffPointOpeningStatusDto,
  DropOffPointDto,
  NearbyDropOffPointDto,
} from 'types/dtos'
import {
  DropOffPointCarrierModel,
  DropOffPointOpeningStatusModel,
  DropOffPointModel,
  NearbyDropOffPointModel,
} from 'types/models'

export const transformDropOffPointCarrierDto = ({
  name,
  icon_url,
  location_map_note,
}: DropOffPointCarrierDto): DropOffPointCarrierModel => ({
  name,
  iconUrl: icon_url,
  locationMapNote: location_map_note,
})

export const transformDropOffPointOpeningStatusDto = ({
  status,
  text,
}: DropOffPointOpeningStatusDto): DropOffPointOpeningStatusModel => ({
  status,
  text,
})

export const transformDropOffPointDto = ({
  code,
  name,
  distance,
  distance_unit,
  description,
  drop_off_point_address,
  latitude,
  longitude,
  photo_url,
  point_type_icon_url,
  description_icon_url,
  opening_status,
  business_hours,
}: DropOffPointDto): DropOffPointModel => ({
  code,
  name,
  distance,
  distanceUnit: distance_unit,
  description,
  dropOffPointAddress: drop_off_point_address,
  latitude,
  longitude,
  photoUrl: photo_url,
  pointTypeIconUrl: point_type_icon_url,
  descriptionIconUrl: description_icon_url,
  openingStatus: transformDropOffPointOpeningStatusDto(opening_status),
  businessHours: business_hours?.map(transformShippingBusinessHoursDto),
})

export const transformNearbyDropOffPointDto = ({
  suggested_drop_off_point_code,
  carrier,
  drop_off_points,
}: NearbyDropOffPointDto): NearbyDropOffPointModel => ({
  suggestedDropOffPointCode: suggested_drop_off_point_code,
  carrier: transformDropOffPointCarrierDto(carrier),
  dropOffPoints: drop_off_points.map(transformDropOffPointDto),
})
