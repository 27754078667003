import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const fetchDataFailure = createAction(`${stateName}/fetchDataFailure`)

export const fetchDynamicFilterSearchRequest = createAction(
  `${stateName}/fetchDynamicFilterSearchRequest`,
  (code: string, text: string | null | undefined = null) => ({
    payload: {
      text,
      code,
    },
  }),
)

export const fetchDynamicFilterSearchFailure = createAction(
  `${stateName}/fetchDynamicFilterSearchFailure`,
)
