export const stateName = 'mediaUpload' as const

export enum DisplayType {
  Full,
  Minimal,
}

export enum MediaSelectType {
  Item = 'item',
  Complaint = 'complaint',
  UserMsg = 'user_msg',
  SupportTicket = 'support_ticket',
}
