import {
  GooglePlacesAutocompleteRequestType,
  GoogleTagManagerEvent,
  GTM_EC_EVENT_FIELD,
} from 'constants/google'

export const buildComponentRestrictions = (country: string | undefined) => {
  if (!country) return undefined

  return { country }
}

export type BuildGooglePlacesAutocompleteRequestArgs = {
  value: string | null
  sessionToken: google.maps.places.AutocompleteSessionToken | null
  type?: GooglePlacesAutocompleteRequestType
  country?: string
}

export const buildGooglePlacesAutocompleteRequest = ({
  value,
  country,
  sessionToken,
  type = GooglePlacesAutocompleteRequestType.Geocode,
}: BuildGooglePlacesAutocompleteRequestArgs): google.maps.places.AutocompletionRequest => ({
  input: value || '',
  sessionToken: sessionToken || {},
  types: [type],
  componentRestrictions: buildComponentRestrictions(country),
})

export const buildGooglePlaceDetailsRequest = (
  placeId: string,
  sessionToken?: google.maps.places.AutocompleteSessionToken,
  fields = ['address_component', 'formatted_address', 'geometry'],
) => ({
  placeId,
  sessionToken,
  fields,
})

export const googleTagManagerTrack = (
  event: GoogleTagManagerEvent,
  data: NonNullable<typeof window.dataLayer>[number],
  isGtmEcFieldEnabled = false,
) => {
  const eventData = {
    ...data,
    event,
  }

  if (!isGtmEcFieldEnabled) {
    delete eventData[GTM_EC_EVENT_FIELD]
  }

  window.dataLayer = window.dataLayer || []

  window.dataLayer.push(eventData)
}

export const getAddressComponent = (
  componentType: string,
  placeDetails: google.maps.places.PlaceResult,
  // FE: short_name: 'FR', long_name: 'France'
  returnShortName = false,
) => {
  const addressComponent = placeDetails.address_components?.find(component => {
    return component.types.find(type => type === componentType) && component
  })

  if (returnShortName) return addressComponent?.short_name

  return addressComponent?.long_name
}

export const geocodeAddress = (address: string) => {
  if (!window.google?.maps) return Promise.resolve(undefined)

  return new Promise<google.maps.GeocoderResult | undefined>(resolve => {
    new window.google.maps.Geocoder().geocode({ address }, results => {
      resolve(results?.[0])
    })
  })
}

export const getGooglePlacePredictions = (
  options: BuildGooglePlacesAutocompleteRequestArgs,
): Promise<Array<google.maps.places.AutocompletePrediction> | null> =>
  new Promise(resolve => {
    if (typeof window?.google?.maps === 'undefined') resolve(null)
    if (!options.value) resolve(null)

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        buildGooglePlacesAutocompleteRequest(options),
        (predictions, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) return

          resolve(predictions)
        },
      )
    } catch {
      resolve(null)
    }
  })

export type getGooglePlaceDetailsArgs = {
  placeId: string
  inputName: string
  sessionToken: google.maps.places.AutocompleteSessionToken | null
}

export const getGooglePlaceDetails = ({
  placeId,
  inputName,
  sessionToken,
}: getGooglePlaceDetailsArgs): Promise<google.maps.places.PlaceResult | null> =>
  new Promise(resolve => {
    const input = document.getElementById(inputName)

    if (typeof window?.google?.maps === 'undefined' || !placeId || !input || !sessionToken) {
      resolve(null)

      return
    }

    try {
      new window.google.maps.places.PlacesService(new window.google.maps.Map(input)).getDetails(
        buildGooglePlaceDetailsRequest(placeId, sessionToken),
        (placeDetails, status) => {
          if (!placeDetails) return
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) return

          resolve(placeDetails)
        },
      )
    } catch {
      resolve(null)
    }
  })
