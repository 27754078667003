import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const fetchCountryBoundsRequest = createAction(`${stateName}/fetchCountryBoundsRequest`)

export const fetchNearbyDropOffOptionsRequest = createAction<{
  countryCode?: string
  longitude?: number
  latitude?: number
  dropOffPointSource?: string | null
  limit?: number
}>(`${stateName}/fetchNearbyDropOffOptionsRequest`)
