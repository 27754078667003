import { FaqEntryType } from 'constants/faq-entry'
import { UiState } from 'constants/ui'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getFaqEntryRecordById = (state: AllState, id: number | string | undefined | null) =>
  localState(state).byId[Number(id)]

export const getFaqEntryRecordByType = (state: AllState, type: FaqEntryType) =>
  localState(state).byType[type]

export const getFaqEntryById = (state: AllState, id: number | string | undefined | null) =>
  getFaqEntryRecordById(state, id)?.entry

export const getFaqEntryByType = (state: AllState, type: FaqEntryType) =>
  getFaqEntryRecordByType(state, type)?.entry

export const getFaqEntryStateById = (state: AllState, id: number | string | undefined | null) =>
  getFaqEntryRecordById(state, id)?.state || UiState.Idle

export const getFaqEntryStateByType = (state: AllState, type: FaqEntryType) =>
  getFaqEntryRecordByType(state, type)?.state || UiState.Idle

export const getFaqEntry = (id: string | undefined) => (state: AllState) =>
  getFaqEntryById(state, id)
