export enum PhoneRequirement {
  Disabled = 'disabled',
  Optional = 'optional',
  Mandatory = 'mandatory',
}

export enum ShippingContactType {
  ShippingLabel = 'shipping_label',
  DigitalLabel = 'digital_label',
  ReturnLabel = 'return_label',
  Buyer = 'buyer',
  Seller = 'seller',
}
