import { CurrencyAmountModel, PayInMethodModel } from 'types/models'
import { PayInMethodCode } from 'constants/pay-in-method'

export function filterOutEmptyWallet(
  payInMethods: Array<PayInMethodModel>,
  walletBalance: CurrencyAmountModel,
) {
  return payInMethods.filter(
    ({ code }) => code !== PayInMethodCode.Wallet || parseFloat(walletBalance.amount) > 0,
  )
}
