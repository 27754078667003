import { takeLatest, put } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import * as api from 'data/api'
import { getIsPersonalIdNumberFieldRequired } from 'components/BalanceActivationForm/utils'

import * as statelessActions from './actions'
import { transformPaymentsIdentityConfiguration } from './transformers'
import { actions } from './slice'
import * as selectors from './selectors'
import { FieldName } from './constants'

export function* fetchConfiguraton() {
  const response = yield* call(api.getPaymentsIdentityFormConfiguration)

  if ('errors' in response) {
    yield put(statelessActions.fetchFormConfigurationFailure())

    return
  }

  const configuration = transformPaymentsIdentityConfiguration(response)

  yield put(actions.setConfiguration({ configuration }))
}

export function* submitForm() {
  yield put(actions.setIsFormDisabled({ isFormDisabled: true }))
  yield put(actions.setHasUnknownApiErrorOccured({ status: false }))

  const formData = yield* select(selectors.getFormData)
  const requiredFields = yield* select(selectors.getRequiredFields)

  const personalIdRequiredNationalities = yield* select(
    selectors.getPersonalIdNumberRequiredNationalities,
  )

  const isNationalityRequired = requiredFields?.includes(FieldName.Nationality)
  const isPersonalIdNumberRequired = getIsPersonalIdNumberFieldRequired(
    personalIdRequiredNationalities,
    formData.nationality,
  )

  const { nationality, birthdate, personalIdNumber, ...restFormData } = formData

  const response = yield* call(api.submitPaymentsIdentity, {
    ...restFormData,
    birthdate: isPersonalIdNumberRequired ? null : birthdate,
    ...(isPersonalIdNumberRequired && { personalIdNumber }),
    ...(isNationalityRequired && { nationality }),
  })

  if ('errors' in response) {
    yield put(actions.setGeneralErrors({ errors: response.errors }))
    yield put(actions.setIsFormDisabled({ isFormDisabled: false }))

    if (!response.errors.length) yield put(actions.setHasUnknownApiErrorOccured({ status: true }))

    return
  }

  const configuration = transformPaymentsIdentityConfiguration(response)

  yield put(actions.setConfiguration({ configuration }))
  yield put(actions.submitFormSuccess())

  if (!configuration.externalVerificationId) return

  yield put(actions.setIsOndatoIdvInitiated({ isOndatoIdvInitiated: true }))
}

export default function* saga() {
  yield takeLatest(statelessActions.fetchFormConfigurationRequest, fetchConfiguraton)
  yield takeLatest(actions.submitFormRequest, submitForm)
}
