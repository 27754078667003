import { takeEvery, put } from 'redux-saga/effects'
import { call, select, all } from 'typed-redux-saga'

import { GoogleTagManagerEvent } from 'constants/google'
import { createPhoto, getPhotos } from 'data/api'
import { Response, CreatePhotoResp } from 'types/api/response'
import { transformMediaUploadPhotoDtos } from 'data/transformers/media-upload-photo'
import { googleTagManagerTrack } from 'data/utils/google'
import * as sessionSelectors from 'state/session/selectors'

import { actions } from './slice'
import { createPhotoFormData } from './utils'
import { getPhotosCount } from './selectors'

export function* uploadPhotoFiles({ payload }: ReturnType<typeof actions.uploadPhotoFiles>) {
  const { tempUuid, type, files } = payload

  try {
    const convertedPhotos = files.map(file => createPhotoFormData(file, type, tempUuid))

    const responses = yield* all(convertedPhotos.map(photo => call(createPhoto, photo)))

    const photoResponses = responses.filter(
      (response): response is Response<CreatePhotoResp> => !('errors' in response),
    )

    const hasError = responses.reduce(
      (previous, response) => previous || 'errors' in response,
      false,
    )

    if (hasError) {
      yield put(actions.uploadPhotoFailure())

      return
    }

    const transformedPhotos = yield* call(transformMediaUploadPhotoDtos, photoResponses)

    yield put(actions.uploadPhotoSuccess({ photos: transformedPhotos }))

    const count = yield* select(getPhotosCount)
    const itemCount = yield* select(sessionSelectors.getUserItemCount)

    googleTagManagerTrack(GoogleTagManagerEvent.PhotoUpload, {
      photo_count: count,
      occurrence_number: itemCount + 1,
    })
  } catch (error) {
    yield put(actions.uploadPhotoFailure())
  }
}

export function* fetchPhotosRequest({ payload }: ReturnType<typeof actions.fetchPhotosRequest>) {
  const { type, photoEntryId, tempUuid } = payload

  const response = yield* call(getPhotos, { type, photoEntryId, tempUuid })

  if ('errors' in response) {
    yield put(actions.fetchPhotosFailure())

    return
  }

  const transformedPhotos = yield* call(transformMediaUploadPhotoDtos, response.photos_dto)

  yield put(actions.fetchPhotosSuccess({ photos: transformedPhotos }))
}

export default function* saga() {
  yield takeEvery(actions.uploadPhotoFiles, uploadPhotoFiles)
  yield takeEvery(actions.fetchPhotosRequest, fetchPhotosRequest)
}
