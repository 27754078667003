import { PepType } from 'constants/pep'
import { NationalityCountryModel } from 'types/models'
import { CreatePaymentsAccountArgs } from 'types/api'

import { FormModel } from './types'

export const getIsPersonalIdNumberFieldRequired = (
  personalIdNumberRequiredNationalities?: Array<string> | null,
  selectedNationality?: NationalityCountryModel | null,
) =>
  personalIdNumberRequiredNationalities?.some(
    nationality => nationality.toLowerCase() === selectedNationality?.code.toLowerCase(),
  ) || false

export const mapPepDetails = ({
  pepType,
  pepFullName,
  pepPosition,
  pepOrganisation,
  pepPositionDate,
}: Partial<FormModel>): CreatePaymentsAccountArgs['pepDetails'] | undefined => {
  if (!pepType) return undefined

  switch (pepType) {
    case PepType.NotPep:
      return {
        type: pepType,
      }
    case PepType.Pep:
      return {
        type: pepType,
        organisation: pepOrganisation,
        position: pepPosition,
        holdsPositionFrom: pepPositionDate,
      }
    case PepType.PepAssociate:
      return {
        type: pepType,
        associatedPepFullName: pepFullName,
        organisation: pepOrganisation,
        position: pepPosition,
        holdsPositionFrom: pepPositionDate,
      }
    default:
      return undefined
  }
}
