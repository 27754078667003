'use client'

import { useEffect, useState } from 'react'

import useIsMounted from 'hooks/useIsMounted'
import { isInternalHostname, normalizeHost } from 'libs/utils/url'

import useClientSideOnly from '../../hooks/useClientSideOnly'

const CanaryToken = () => {
  const [rerender, setRerender] = useState(false)
  const isClientSide = useClientSideOnly()
  const isMounted = useIsMounted()

  useEffect(() => {
    function update() {
      if (!isMounted()) return

      setRerender(prevState => !prevState)
    }

    setTimeout(update, 30_000)
  }, [isMounted, rerender])

  if (!isClientSide) return null

  const { hostname, protocol, href } = window.location

  if (hostname === 'localhost') return null
  if (isInternalHostname(normalizeHost(hostname))) return null

  const lParam = encodeURI(href)
  const rParam = encodeURI(document.referrer)

  const src = `${protocol}//2c47338122bb.o3n.io/cdn/x8rbdk96oz2842hcd5jk2hbi1/logo.gif?l=${lParam}&r=${rParam}`

  // eslint-disable-next-line @next/next/no-img-element
  return <img src={src} alt="" className="u-block" style={{ height: 0 }} />
}

export default CanaryToken
