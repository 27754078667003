import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { CountryModel, CityModel } from 'types/models'

import { setNormalizedData, initialEntityStructure } from 'libs/utils/redux'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  cities: initialEntityStructure,
  countries: initialEntityStructure,
}

const searchCitiesSuccess: CaseReducer<State, PayloadAction<{ cities: Array<CityModel> }>> = (
  draft,
  action,
) => {
  const { cities } = action.payload

  setNormalizedData(draft.cities, cities)
}

const fetchCountriesSuccess: CaseReducer<
  State,
  PayloadAction<{ countries: Array<CountryModel> }>
> = (draft, action) => {
  const { countries } = action.payload

  setNormalizedData(draft.countries, countries)
}

const locationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: { searchCitiesSuccess, fetchCountriesSuccess },
})

export const { actions } = locationSlice
export const plug = { [stateName]: locationSlice.reducer }
export default locationSlice.reducer
