export enum CountryCode {
  De = 'de',
  Fr = 'fr',
  Lt = 'lt',
  Es = 'es',
  Nl = 'nl',
  Pl = 'pl',
  Cz = 'cz',
  Be = 'be',
  Lu = 'lu',
  Uk = 'uk',
  Us = 'us',
  At = 'at',
  It = 'it',
  Pt = 'pt',
  Sk = 'sk',
  Hu = 'hu',
  Se = 'se',
  Ro = 'ro',
  Dk = 'dk',
  Fi = 'fi',
  Hr = 'hr',
  Gr = 'gr',
}

export const UNKNOWN_COUNTRY_CODE = '--'
