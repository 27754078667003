export const stateName = 'kyc' as const

export enum FieldName {
  FirstName = 'first_name',
  LastName = 'last_name',
  Birthdate = 'birthdate',
  Nationality = 'nationality',
  SsnSerial = 'ssn_serial',
  PersonalIdNumber = 'personal_id_number',
  Address = 'address',
  IdentityDocument = 'identity_document',
  RegistrationNumber = 'registration_number',
  BusinessAddress = 'business_address',
  Ubo = 'ubos',
}
