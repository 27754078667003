export const stateName = 'dropOffPointMap' as const

export const DropOffPointSource = {
  UserAddress: 'user_address',
  CurrentLocation: 'current_location',
  SearchAreaButton: 'search_area_button',
  SearchBarAddress: 'search_bar_address',
}

export const DropOffPointView = {
  Map: 'map',
  Preview: 'preview',
}
