import { createSelector, Selector } from 'reselect'

import { ItemDto } from 'types/dtos'

import { stateName, ItemUiView } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUserItemState = (state: AllState) => localState(state)[ItemUiView.UserItems]

export const getUserItemIds = createSelector(getUserItemState, state => state.ids)

export const getUserItemsById = createSelector(getUserItemState, state => state.byId)

export const getUserItemUiState = createSelector(getUserItemState, state => state.uiState)

export const getUserItemCurrentPage = createSelector(getUserItemState, state => state.currentPage)

export const getUserItemTotalPages = createSelector(getUserItemState, state => state.totalPages)

export const getUserItemEndReached = createSelector(getUserItemState, state => state.endReached)

export const getUserBundleItemState = (state: AllState) =>
  localState(state)[ItemUiView.UserBundleItems]

export const getUserBundleItemIds = createSelector(getUserBundleItemState, state => state.ids)

export const getUserBundleItemsById = createSelector(getUserBundleItemState, state => state.byId)

export const getUserBundleItemUiState = createSelector(
  getUserBundleItemState,
  state => state.uiState,
)

export const getUserBundleItemCurrentPage = createSelector(
  getUserBundleItemState,
  state => state.currentPage,
)

export const getUserBundleItemTotalPages = createSelector(
  getUserBundleItemState,
  state => state.totalPages,
)

export const getUserBundleItemEndReached = createSelector(
  getUserBundleItemState,
  state => state.endReached,
)

export const getUserBundleItemsContainsOfflineVerificationItem = createSelector(
  getUserBundleItemState,
  state => state.containsOfflineVerificationItem,
)

export const getUserBundleItemsOfflineVerificationFee = createSelector(
  getUserBundleItemState,
  state => state.offlineVerificationFee,
)

export const getFavouriteItemState = (state: AllState) =>
  localState(state)[ItemUiView.FavouriteItems]

export const getFavouriteItemIds = createSelector(getFavouriteItemState, state => state.ids)

export const getFavouriteItemsById = createSelector(getFavouriteItemState, state => state.byId)

export const getFavouriteItemUiState = createSelector(getFavouriteItemState, state => state.uiState)

export const getFavouriteItemCurrentPage = createSelector(
  getFavouriteItemState,
  state => state.currentPage,
)

export const getFavouriteItemTotalPages = createSelector(
  getFavouriteItemState,
  state => state.totalPages,
)

export const getFavouriteItemEndReached = createSelector(
  getFavouriteItemState,
  state => state.endReached,
)

export const getSimilarItemsState = (state: AllState) => localState(state)[ItemUiView.SimilarItems]

export const getSimilarItemsIds = createSelector(getSimilarItemsState, state => state.ids)

export const getSimilarItemsById = createSelector(getSimilarItemsState, state => state.byId)

export const getSimilarItemsUiState = createSelector(getSimilarItemsState, state => state.uiState)

export const getCatalogItemState = (state: AllState) => localState(state)[ItemUiView.CatalogItems]

export const getCatalogItemIds = createSelector(getCatalogItemState, state => state.ids)

export const getCatalogItemsById = createSelector(getCatalogItemState, state => state.byId)

export const getCatalogItemUiState = createSelector(getCatalogItemState, state => state.uiState)

export const getCatalogItemCurrentPage = createSelector(
  getCatalogItemState,
  state => state.currentPage,
)

export const getCatalogItemTotalPages = createSelector(
  getCatalogItemState,
  state => state.totalPages,
)

export const getCatalogItemEndReached = createSelector(
  getCatalogItemState,
  state => state.endReached,
)

export const getCatalogItemPerPage = createSelector(getCatalogItemState, state => state.perPage)

export const getCatalogItemTime = createSelector(getCatalogItemState, state => state.time)

export const getCatalogItemOffset = createSelector(
  getCatalogItemCurrentPage,
  getCatalogItemPerPage,
  (page = 1, perPage = 0) => (page - 1) * perPage,
)

export const getCatalogItemTotalEntries = createSelector(
  getCatalogItemState,
  state => state.totalEntries,
)

export const getCatalogSearchParams = createSelector(
  getCatalogItemState,
  state => state.searchParams || {},
)

export const getCatalogItemErrors = createSelector(getCatalogItemState, state => state.errors)

export const getItemsFactory = <T = AllState, P = ItemDto>(
  idSelector: Selector<T, Array<number>>,
  byIdSelector: Selector<AllState, Record<number, P>>,
) =>
  createSelector(byIdSelector, idSelector, (items, ids) =>
    Object.keys(items).length ? ids.map(id => items[id]).filter(Boolean) : [],
  )

export const getUserItems = getItemsFactory(getUserItemIds, getUserItemsById)
export const getFavouriteItems = getItemsFactory(getFavouriteItemIds, getFavouriteItemsById)
export const getUserBundleItems = getItemsFactory(getUserBundleItemIds, getUserBundleItemsById)
export const getSimilarItems = getItemsFactory(getSimilarItemsIds, getSimilarItemsById)
export const getCatalogItems = getItemsFactory(getCatalogItemIds, getCatalogItemsById)
