import { createSelector } from 'reselect'
import { size } from 'lodash'

import { UiState } from 'constants/ui'
import { ServiceType } from 'constants/shipping-option'

import { AllState, CheckoutState } from '../types'
import { DeliveryType, PickupModalElement, stateName } from '../constants'

const checkoutState = (state: AllState): CheckoutState => state[stateName]
const localState = (state: AllState) => checkoutState(state).shipping

const getCheckoutState = createSelector(checkoutState, ({ checkout }) => checkout)

const getCheckoutUiState = createSelector(getCheckoutState, ({ ui }) => ui)
const getCheckoutData = createSelector(getCheckoutState, ({ checkoutData }) => checkoutData)
const getCheckoutShippingService = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.shipping,
)
export const getShipment = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.shipment || null,
)

export const getCountryBounds = createSelector(localState, ({ countryBounds }) => countryBounds)

export const getNearbyPoints = createSelector(localState, ({ nearbyPoints }) => nearbyPoints)

export const getUiState = createSelector(localState, ({ ui }) => ui)

export const getDeliveryOptionsUiState = createSelector(
  getCheckoutUiState,
  ({ checkoutData }) => checkoutData,
)

export const getPickupModalUiState = createSelector(getUiState, ({ pickupModal }) => pickupModal)

export const getPickupModalUiStateByElement = (state: AllState, element: PickupModalElement) =>
  localState(state).ui.pickupModal[element]

export const getIsPickupModalElementLoaded = (state: AllState, element: PickupModalElement) =>
  [UiState.Success, UiState.Failure].includes(localState(state).ui.pickupModal[element])

export const getPickupPoint = createSelector(getShipment, shipment => shipment?.pickupPoint)

export const getIsShipmentActionable = createSelector(
  getShipment,
  shipment => !shipment?.carrier?.custom && !shipment?.carrier?.noShipping,
)

export const getSelectedDeliveryType = createSelector(
  getCheckoutShippingService,
  checkoutShippingService => checkoutShippingService?.selectedDeliveryType || null,
)

export const getDeliveryPickupPointDetails = createSelector(getShipment, shipment => ({
  title: shipment?.packageType?.title || shipment?.carrier?.buyerTitle,
  packageType: shipment?.packageType,
  carrier: shipment?.carrier,
}))

export const getPackageType = createSelector(getShipment, shipment => shipment?.packageType)

export const getBuyerPhoneRequirement = createSelector(
  getPackageType,
  packageType => packageType?.buyerPhoneRequirement,
)

export const getSuggestedShippingPointCode = createSelector(
  localState,
  ({ suggestedShippingPointCode }) => suggestedShippingPointCode,
)

export const getSuggestedRateUuid = createSelector(
  localState,
  ({ suggestedRateUuid }) => suggestedRateUuid,
)

export const getSelectedRateUuid = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.shipping?.deliveryTypes?.pickup?.selectedRateUuid,
)

export const getSelectedServiceType = createSelector(
  localState,
  ({ selectedServiceType }) => selectedServiceType || ServiceType.Economy,
)

export const getIsIntergratedShipping = createSelector(getShipment, shipment => {
  if (!shipment?.carrier) return false

  switch (shipment.carrier.deliveryType) {
    case DeliveryType.Home:
    case DeliveryType.PickupPoint:
      return true
    default:
      return false
  }
})

export const getSelectedShippingPoint = createSelector(
  localState,
  ({ selectedShippingPoint }) => selectedShippingPoint,
)

export const getPickupPointsUiState = createSelector(getUiState, ({ pickupPoints }) => pickupPoints)

export const getShippingOptions = createSelector(
  localState,
  ({ shippingOptions }) => shippingOptions,
)

export const getDeliveryTypesUiState = createSelector(
  getCheckoutUiState,
  ({ checkoutData }) => checkoutData,
)

export const getDeliveryTypes = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.services.shipping?.deliveryTypes ?? null,
)

export const getDeliveryTypesSize = createSelector(getDeliveryTypes, deliveryTypes =>
  size(deliveryTypes),
)

export const getRateUuid = createSelector(getShipment, shipment => shipment?.rateUuid)

export const getIsItemOfflineVerificationEnabled = createSelector(
  getCheckoutData,
  checkoutData => checkoutData?.shipment.packageType.itemOfflineVerificationEnabled,
)
