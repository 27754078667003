import { takeLatest, put, takeEvery } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'
import { actions as sessionActions } from 'state/session/slice'

import { actions } from './slice'
import {
  transformCharities,
  transformFundraiserConfiguration,
  transformFundraiserConfigurationForUser,
} from './transformers'

export function* fetchFundraiserConfiguration() {
  const response = yield* call(api.getFundraiserConfiguration)

  if ('errors' in response) {
    yield put(actions.fetchFundraiserConfigurationFailure())

    return
  }

  yield put(
    actions.fetchFundraiserConfigurationSuccess({
      fundraiser: transformFundraiserConfiguration(response.fundraiser),
    }),
  )
}

export function* manageFundraiser({
  payload: { charityCode, percentage },
}: ReturnType<typeof actions.manageFundraiserConfiguration>) {
  const response = yield* call(api.updateFundraiser, {
    charityCode,
    percentage,
  })

  if ('errors' in response) {
    yield put(actions.manageFundraiserConfigurationFailure())

    return
  }

  yield put(
    sessionActions.setFundraiserConfiguration({
      fundraiser: transformFundraiserConfigurationForUser(response.fundraiser),
    }),
  )

  yield put(
    actions.manageFundraiserConfigurationSuccess({
      fundraiser: transformFundraiserConfiguration(response.fundraiser),
    }),
  )
}

export function* stopActiveFundraiser() {
  const response = yield* call(api.deleteFundraiser)

  if ('errors' in response) {
    yield put(actions.stopActiveFundraiserFailure())

    return
  }

  yield put(actions.fetchFundraiserConfiguration())
}

export function* fetchCharities() {
  const response = yield* call(api.getCharities)

  if ('errors' in response) {
    yield put(actions.fetchCharitiesFailure())

    return
  }

  yield put(
    actions.fetchCharitiesSuccess({
      charities: transformCharities(response.charities),
    }),
  )
}

export default function* saga() {
  yield takeLatest(actions.fetchFundraiserConfiguration.type, fetchFundraiserConfiguration)
  yield takeLatest(actions.fetchFundraiserConfiguration.type, fetchCharities)
  yield takeEvery(actions.manageFundraiserConfiguration.type, manageFundraiser)
  yield takeEvery(actions.stopActiveFundraiser.type, stopActiveFundraiser)
}
