import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { actions as itemActions } from 'state/items/slice'
import { FavouriteItemPayload } from 'state/items/types'

import { State } from './types'
import { stateName } from './constants'

const initialState: State = {
  isSoldNotificationShown: false,
}

const setIsSoldNotificationShown: CaseReducer<State, PayloadAction<FavouriteItemPayload>> = (
  draft,
  action,
) => {
  const { sold } = action.payload

  draft.isSoldNotificationShown = !!sold
}

const hideSoldNotification: CaseReducer<State> = draft => {
  draft.isSoldNotificationShown = false
}

const favouriteSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    hideSoldNotification,
  },
  extraReducers: {
    [itemActions.getFavouriteItemsSuccess.type]: setIsSoldNotificationShown,
  },
})

export const { actions } = favouriteSlice
export const plug = { [stateName]: favouriteSlice.reducer }
export default favouriteSlice.reducer
