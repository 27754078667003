import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'
import { transformBankAccountRegistration } from 'data/transformers/bank-account-registration'
import { transformBankAccounts } from 'data/transformers/bank-account'

import { actions } from './slice'

export function* fetchBankAccountRegistration() {
  const response = yield* call(api.getBankAccountRegistration)

  if ('errors' in response) {
    yield put(actions.fetchBankAccountRegistrationFailure())

    return
  }

  yield put(
    actions.fetchBankAccountRegistrationSuccess({
      bankAccountRegistration: transformBankAccountRegistration(response),
    }),
  )
}

export function* fetchBankAccounts() {
  const response = yield* call(api.getBankAccounts)

  if ('errors' in response) {
    yield put(actions.fetchBankAccountsFailure())

    return
  }

  yield put(
    actions.fetchBankAccountsSuccess({
      bankAccounts: transformBankAccounts(response.bank_accounts),
    }),
  )
}

export default function* saga() {
  yield takeLatest(actions.fetchBankAccountRegistrationRequest.type, fetchBankAccountRegistration)
  yield takeLatest(actions.fetchBankAccountsRequest.type, fetchBankAccounts)
}
