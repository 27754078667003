import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'

import {
  PaymentsIdentityConfigurationModel,
  AddressModel,
  PaymentIdentityUboModel,
  PaymentsIdentityFileModel,
  NationalityCountryModel,
} from 'types/models'

import { UiState } from 'constants/ui'

import { ErrorItem } from 'types/api'

import { stateName, FieldName } from './constants'

import { State, FormState, FormData } from './types'

const initialFormState: FormState = {
  isFormDisabled: false,
  uiState: UiState.Idle,
  isFormDirty: false,
  hasUnknownApiErrorOccured: false,
}

const initialFormData: FormData = {
  firstName: null,
  lastName: null,
  birthdate: null,
  nationality: null,
  ssnSerial: null,
  personalIdNumber: null,
  address: null,
  ubos: null,
  isLegalRepresentativeUbo: true,
  documents: [],
}

const initialState: State = {
  formData: initialFormData,
  generalErrors: {
    byName: {},
    names: [],
  },
  apiErrors: [],
  paymentsIdentityConfiguration: null,
  formState: initialFormState,
  isOndatoIdvInitiated: false,
}

const setFormIsDirty: CaseReducer<State> = draft => {
  draft.formState.isFormDirty = true
}

function deleteGeneralError(draft: State, fieldName: FieldName) {
  if (fieldName in draft.generalErrors.byName) {
    delete draft.generalErrors.byName[fieldName]
  }

  remove(draft.generalErrors.names, name => name === fieldName)
}

const setIsFormDisabled: CaseReducer<State, PayloadAction<{ isFormDisabled: boolean }>> = (
  draft,
  action,
) => {
  const { isFormDisabled } = action.payload

  draft.formState.isFormDisabled = isFormDisabled
}

const setHasUnknownApiErrorOccured: CaseReducer<State, PayloadAction<{ status: boolean }>> = (
  draft,
  action,
) => {
  const { status } = action.payload

  draft.formState.hasUnknownApiErrorOccured = status
}

const setConfiguration: CaseReducer<
  State,
  PayloadAction<{ configuration: PaymentsIdentityConfigurationModel }>
> = (draft, action) => {
  const { configuration } = action.payload

  draft.paymentsIdentityConfiguration = configuration

  draft.formData = {
    firstName: configuration.firstName,
    lastName: configuration.lastName,
    birthdate: configuration.birthdate,
    nationality: configuration.nationality,
    address: configuration.address,
    ubos: null,
    isLegalRepresentativeUbo: draft.formData.isLegalRepresentativeUbo,
    documents: [],
  }

  draft.formState.isFormDirty = false
  draft.formState.uiState = UiState.Success
}

const submitFormRequest: CaseReducer<State> = draft => {
  draft.formState.uiState = UiState.Pending
}

const submitFormSuccess: CaseReducer<State> = draft => {
  draft.formState.uiState = UiState.Success
  draft.formState.isFormDisabled = false
}

const setFormData: CaseReducer<
  State,
  PayloadAction<{
    firstName: string
    lastName: string
    birthdate: string
    nationality?: NationalityCountryModel | null
    ssnSerial?: string
    personalIdNumber?: string
    ubos: Array<PaymentIdentityUboModel> | null
    documents: Array<PaymentsIdentityFileModel> | null
  }>
> = (draft, action) => {
  const {
    firstName,
    lastName,
    birthdate,
    nationality,
    ssnSerial,
    personalIdNumber,
    ubos,
    documents,
  } = action.payload

  draft.formData.firstName = firstName
  draft.formData.lastName = lastName
  draft.formData.birthdate = birthdate
  draft.formData.nationality = nationality
  draft.formData.ssnSerial = ssnSerial
  draft.formData.personalIdNumber = personalIdNumber

  draft.formData.ubos = ubos
  draft.formData.documents = documents

  draft.formState.isFormDirty = true
}

const setGeneralErrors: CaseReducer<State, PayloadAction<{ errors: Array<ErrorItem> }>> = (
  draft,
  action,
) => {
  const { errors } = action.payload

  errors.forEach(error => {
    draft.generalErrors.byName[error.field] = error.value
  })

  draft.generalErrors.names = errors.map(error => error.field)
  draft.apiErrors = errors
}

const setAddress: CaseReducer<State, PayloadAction<{ address: AddressModel }>> = (
  draft,
  action,
) => {
  if (!draft.paymentsIdentityConfiguration) return

  const { address } = action.payload

  draft.formData.address = address
  draft.formState.isFormDirty = true

  deleteGeneralError(draft, FieldName.Address)
}

const setIsLegalRepresentativeUbo: CaseReducer<
  State,
  PayloadAction<{ isLegalRepresentativeUbo: boolean }>
> = (draft, action) => {
  const { isLegalRepresentativeUbo } = action.payload

  draft.formData.isLegalRepresentativeUbo = isLegalRepresentativeUbo
}

const setIsOndatoIdvInitiated: CaseReducer<
  State,
  PayloadAction<{ isOndatoIdvInitiated: boolean }>
> = (draft, action) => {
  const { isOndatoIdvInitiated } = action.payload

  draft.isOndatoIdvInitiated = isOndatoIdvInitiated
}

const paymentsIdentitySlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setIsFormDisabled,
    setHasUnknownApiErrorOccured,
    setConfiguration,
    submitFormRequest,
    submitFormSuccess,
    setFormData,
    setGeneralErrors,
    setAddress,
    setIsLegalRepresentativeUbo,
    setFormIsDirty,
    setIsOndatoIdvInitiated,
  },
})

export const { actions } = paymentsIdentitySlice
export const plug = { [stateName]: paymentsIdentitySlice.reducer }
export default paymentsIdentitySlice.reducer
