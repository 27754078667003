import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { camelCase, isEmpty } from 'lodash'

import { UiState } from 'constants/ui'
import { ErrorItem } from 'types/api'
import {
  CheckoutModel,
  RequestReturnDetailsModel,
  ShippingContactConfigurationModel,
  TransactionModel,
} from 'types/models'
import { actions as checkoutActions } from 'state/checkout/slice'
import { actions as conversationActions } from 'state/conversation/slice'
import { actions as returnShipmentActions } from 'state/return-shipment/slice'

import { State, ShippingContactUiState } from './types'
import { FieldName, stateName } from './constants'

export const initialUiState: ShippingContactUiState = {
  configuration: UiState.Idle,
  shippingContact: UiState.Idle,
  saveShippingContact: UiState.Idle,
  deleteShippingContact: UiState.Idle,
}

export const initialState: State = {
  configuration: null,
  buyerPhoneNumber: null,
  sellerPhoneNumber: null,
  errors: [],
  ui: initialUiState,
}

const setShippingContactUiState = (uiState: UiState) => (draft: State) => {
  draft.ui.shippingContact = uiState
}

const fetchShippingContactConfigurationRequest: CaseReducer<State> = draft => {
  draft.ui.configuration = UiState.Pending
}

const fetchShippingContactConfigurationSuccess: CaseReducer<
  State,
  PayloadAction<{ configuration: ShippingContactConfigurationModel }>
> = (draft, action) => {
  const { configuration } = action.payload

  draft.configuration = configuration
  draft.ui.configuration = UiState.Success
}

const fetchShippingContactConfigurationFailure: CaseReducer<State> = draft => {
  draft.ui.configuration = UiState.Failure
}

const saveTransactionShippingContactRequest: CaseReducer<
  State,
  PayloadAction<{
    saveForLater: boolean
    transactionId: number | null
    buyerPhoneNumber: string | null
    sellerPhoneNumber: string | null
  }>
> = draft => {
  draft.ui.saveShippingContact = UiState.Pending
}

const saveTransactionShippingContactSuccess: CaseReducer<
  State,
  PayloadAction<{
    transactionId: number
    buyerPhoneNumber: string | null
    sellerPhoneNumber: string | null
  }>
> = (draft, action) => {
  const { buyerPhoneNumber, sellerPhoneNumber } = action.payload

  draft.buyerPhoneNumber = buyerPhoneNumber
  draft.sellerPhoneNumber = sellerPhoneNumber
  draft.ui.saveShippingContact = UiState.Success
}

const saveTransactionShippingContactFailure: CaseReducer<
  State,
  PayloadAction<{ error: ErrorItem }>
> = (draft, action) => {
  const { error } = action.payload

  if (!isEmpty(error)) draft.errors.push(error)

  draft.ui.saveShippingContact = UiState.Failure
}

const deleteTransactionShippingContactRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number | null }>
> = draft => {
  draft.ui.deleteShippingContact = UiState.Pending
}

const deleteTransactionShippingContactSuccess: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.buyerPhoneNumber = null
  draft.sellerPhoneNumber = null
  draft.ui.deleteShippingContact = UiState.Success
}

const deleteTransactionShippingContactFailure: CaseReducer<State> = draft => {
  draft.ui.deleteShippingContact = UiState.Failure
}

const setShippingContactField: CaseReducer<
  State,
  PayloadAction<{ name: FieldName; value?: string }>
> = (draft, action) => {
  const { name, value } = action.payload

  const field = camelCase(name)

  draft[field] = value || draft[field] || ''
}

const setShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.errors.push(error)
}

const removeShippingContactError: CaseReducer<State, PayloadAction<{ error: ErrorItem }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.errors = draft.errors.filter(
    ({ field, value }) => field !== error.field && value !== error.value,
  )
}

const removeShippingContactErrors: CaseReducer<State> = draft => {
  draft.errors = []
}

const setShippingContactFields: CaseReducer<
  State,
  PayloadAction<{
    checkout: CheckoutModel
  }>
> = (draft, action) => {
  draft.ui.shippingContact = UiState.Success
  draft.buyerPhoneNumber = action.payload.checkout.buyer.phoneNumber
}

const setShippingContactFieldsOnTransactionRequestSuccess: CaseReducer<
  State,
  PayloadAction<{ transaction: TransactionModel }>
> = (draft, action) => {
  if (!action.payload.transaction) return

  const { buyerPhoneNumber, sellerPhoneNumber } = action.payload.transaction

  draft.buyerPhoneNumber = buyerPhoneNumber
  draft.sellerPhoneNumber = sellerPhoneNumber
  draft.ui.shippingContact = UiState.Success
}

const setShippingContactFieldsOnReturnShipmentSuccess: CaseReducer<
  State,
  PayloadAction<RequestReturnDetailsModel>
> = (draft, action) => {
  const { phoneNumber } = action.payload

  draft.sellerPhoneNumber = phoneNumber
  draft.ui.shippingContact = UiState.Success
}

const setShippingContactFieldUiState: CaseReducer<
  State,
  PayloadAction<{ field: FieldName; uiState: UiState }>
> = (draft, action) => {
  const { field, uiState } = action.payload

  draft.ui[camelCase[field]] = uiState
}

const shippingContactSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchShippingContactConfigurationRequest,
    fetchShippingContactConfigurationSuccess,
    fetchShippingContactConfigurationFailure,
    saveTransactionShippingContactRequest,
    saveTransactionShippingContactSuccess,
    saveTransactionShippingContactFailure,
    deleteTransactionShippingContactRequest,
    deleteTransactionShippingContactSuccess,
    deleteTransactionShippingContactFailure,
    setShippingContactField,
    setShippingContactError,
    removeShippingContactError,
    removeShippingContactErrors,
    setShippingContactFieldUiState,
  },
  extraReducers: {
    [checkoutActions.updateCheckoutDataRequest.type]: setShippingContactUiState(UiState.Pending),
    [checkoutActions.updateCheckoutDataSuccess.type]: setShippingContactFields,
    [conversationActions.fetchTransactionRequestSuccess.type]:
      setShippingContactFieldsOnTransactionRequestSuccess,
    [returnShipmentActions.getReturnShipmentSuccess.type]:
      setShippingContactFieldsOnReturnShipmentSuccess,
  },
})

export const { actions } = shippingContactSlice
export const plug = { [stateName]: shippingContactSlice.reducer }

export default shippingContactSlice.reducer
