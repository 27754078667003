import { takeLatest, put } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import * as conversationSelectors from 'state/conversation/selectors'
import * as sessionSelectors from 'state/session/selectors'
import * as api from 'data/api'
import { transformFeedbackRatingsResponse } from 'data/api/transformers/response'
import { reloadPage } from 'libs/utils/window'

import * as selectors from './selectors'
import * as statelessActions from './actions'
import { actions } from './slice'
import { transformFeedbackProblemOptions } from './transformers'

export function* fetchFeedbackProblemOptions() {
  const problemOptions = yield* select(selectors.getProblemOptions)

  if (problemOptions.length) {
    yield put(actions.fetchFeedbackDenyOptionsSuccess(problemOptions))

    return
  }

  const userId = yield* select(sessionSelectors.getUserId)
  const conversationId = yield* select(conversationSelectors.getConversationId)

  if (!userId || !conversationId) {
    yield put(actions.fetchFeedbackDenyOptionsFailure())

    return
  }

  const response = yield* call(api.getFeedbackProblemOptions, userId, conversationId)

  if ('errors' in response) {
    yield put(actions.fetchFeedbackDenyOptionsFailure())

    return
  }

  const transformedResponse = transformFeedbackProblemOptions(response.problem_options)

  yield put(actions.fetchFeedbackDenyOptionsSuccess(transformedResponse))
}

export function* updateFeedbackProblemOptions({
  payload,
}: ReturnType<typeof statelessActions.updateFeedbackDenyOptionsRequest>) {
  const userId = yield* select(sessionSelectors.getUserId)
  const conversationId = yield* select(conversationSelectors.getConversationId)

  const { selectedOption, message } = payload

  if (userId && conversationId && selectedOption) {
    yield* call(api.sendFeedbackProblemFeedback, {
      userId,
      conversationId,
      message: message || undefined,
      problemOptionId: selectedOption,
    })
  }

  yield put(actions.closeProblemsModal())
}

export function* confirmComplaint() {
  const conversationId = yield* select(conversationSelectors.getConversationId)

  if (!conversationId) {
    yield put(statelessActions.confirmComplaintFailure())

    return
  }

  yield* call(api.confirmComplaintResolved, conversationId)
}

export function* fetchFeedbackRatings() {
  const ratings = yield* select(selectors.getRatings)

  if (ratings) {
    yield put(actions.fetchFeedbackRatingsSuccess(ratings))

    return
  }

  const userId = yield* select(sessionSelectors.getUserId)
  const conversationId = yield* select(conversationSelectors.getConversationId)

  if (!userId || !conversationId) {
    yield put(actions.fetchFeedbackRatingsFailure())

    return
  }

  const response = yield* call(api.getConversationFeedbackRatingOptions, userId, conversationId)

  if ('errors' in response) {
    yield put(actions.fetchFeedbackRatingsFailure())

    return
  }

  const transformedResponse = transformFeedbackRatingsResponse(response)

  if (!transformedResponse) {
    yield put(actions.fetchFeedbackRatingsFailure())

    return
  }

  yield put(actions.fetchFeedbackRatingsSuccess(transformedResponse))
}

export function* submitFeedbackRating({
  payload,
}: ReturnType<typeof statelessActions.submitFeedbackRatingRequest>) {
  const userId = yield* select(sessionSelectors.getUserId)
  const conversationId = yield* select(conversationSelectors.getConversationId)

  if (!userId || !conversationId) {
    yield put(statelessActions.submitFeedbackRatingFailure())

    return
  }

  const { rating, feedbackStatementIds, message, finalized } = payload

  yield* call(api.updateFeedbackRatingOptions, userId, conversationId, {
    rating,
    message,
    feedbackStatementIds,
    finalized,
  })

  if (finalized) {
    yield* call(reloadPage)
  }
}

export default function* saga() {
  yield takeLatest(actions.fetchFeedbackDenyOptionsRequest, fetchFeedbackProblemOptions)
  yield takeLatest(statelessActions.updateFeedbackDenyOptionsRequest, updateFeedbackProblemOptions)
  yield takeLatest(statelessActions.confirmComplaintRequest, confirmComplaint)
  yield takeLatest(actions.fetchFeedbackRatingsRequest, fetchFeedbackRatings)
  yield takeLatest(statelessActions.submitFeedbackRatingRequest, submitFeedbackRating)
}
