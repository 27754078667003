import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getFailedFilenames = (state: AllState) => localState(state).failedFilenames

export const getSavedFiles = (state: AllState) => localState(state).savedFiles

export const getUploadUiState = (state: AllState) => localState(state).uploadUiState

export const getFilesToRetry = (state: AllState) => localState(state).filesToRetry

export const getFormData = (state: AllState) => localState(state).formData

export const getFormUiState = (state: AllState) => localState(state).formUiState

export const getFieldErrors = (state: AllState) => localState(state).fieldErrors
