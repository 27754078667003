export enum SavedSearchType {
  RecentSearch = 'recent_search',
  SubscribedSearch = 'subscribed_search',
}

export enum SearchStartType {
  SearchSuggestions = 'search_suggestions',
  SearchManual = 'search_manual',
  RecentSearch = 'recent_searches',
  SubscribedSearch = 'subscribed_searches',
  HomepageBrandsListBlock = 'homepage_brands_list_block',
  HomepagePopularSearchesBlock = 'homepage_popular_searches_block',
}
