import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'

import { transformWalletBalanceDto } from 'data/transformers/wallet'

import { actions } from './slice'
import { transformWalletInvoices } from './transformers'

export function* getBalance({ payload: { userId } }: ReturnType<typeof actions.getBalanceRequest>) {
  const response = yield* call(api.getWalletBalance, userId)

  if ('errors' in response) {
    yield put(actions.getBalanceFailure())

    return
  }

  yield put(
    actions.getBalanceSuccess({
      balance: transformWalletBalanceDto(response.user_balance),
    }),
  )
}

export function* getCurrentInvoices() {
  const response = yield* call(api.getCurrentWalletInvoices)

  if ('errors' in response) {
    yield put(actions.getCurrentInvoicesFailure())

    return
  }

  yield put(
    actions.getCurrentInvoicesSuccess({
      invoices: transformWalletInvoices(response),
    }),
  )
}

export function* getHistoricalInvoices({
  payload: { year, month },
}: ReturnType<typeof actions.getHistoricalInvoicesRequest>) {
  const response = yield* call(api.getHistoricalWalletInvoices, year, month)

  if ('errors' in response) {
    yield put(actions.getHistoricalInvoicesFailure())

    return
  }

  yield put(
    actions.getHistoricalInvoicesSuccess({
      invoices: transformWalletInvoices(response),
    }),
  )
}

export default function* saga() {
  yield takeLatest(actions.getBalanceRequest.type, getBalance)
  yield takeLatest(actions.getCurrentInvoicesRequest.type, getCurrentInvoices)
  yield takeLatest(actions.getHistoricalInvoicesRequest.type, getHistoricalInvoices)
}
