import { DiscountDto } from 'types/dtos'
import { DiscountModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformDiscount = (discount: DiscountDto): DiscountModel => ({
  itemId: discount.item_id,
  discountPrice: transformCurrencyAmountDto(discount.discount_price),
})

export const transformDiscounts = (discounts: Array<DiscountDto>): Array<DiscountModel> =>
  discounts.map(transformDiscount)
