import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { stateName } from './constants'
import { UniversalEnvs, State } from './types'

// These valus are set only in next.js context
export const initialState: State = {
  FACEBOOK_CLIENT_ID: undefined,
  APPLE_CLIENT_ID: undefined,
  GOOGLE_CLIENT_ID: undefined,
  GOOGLE_MAPS_API_KEY: undefined,
  RELEASE_VERSION: undefined,
  ONDATO_CERTIFICATE: undefined,
  ONDATO_RSA_PUBLIC_KEY: undefined,
  NEXT_JS: undefined,
  CSRF_TOKEN: undefined,
  NODE_ENV: undefined,
  IMG_TAG: undefined,
  DATADOME_CLIENT_SIDE_KEY: undefined,
}

const setEnvs: CaseReducer<State, PayloadAction<{ envs: UniversalEnvs }>> = (draft, action) => {
  return action.payload.envs
}

const nextEnvSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setEnvs,
  },
})

export const { actions } = nextEnvSlice
export const plug = { [stateName]: nextEnvSlice.reducer }
export default nextEnvSlice.reducer
