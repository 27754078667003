import { calculateRating, getUserThumbnail } from 'data/utils/user'
import { FollowingUserDto } from 'types/dtos'
import { FollowingUserModel } from 'types/models'
import { UserThumbnailSize } from 'constants/images'

import { transformFollowingUserItemDtosToProductItems } from './product-item'

export const transformFollowingUserDto = (user: FollowingUserDto): FollowingUserModel => ({
  id: user.id,
  name: user.login,
  image: getUserThumbnail(user.photo, UserThumbnailSize.X2Large),
  url: user.profile_url,
  rating: calculateRating(user.feedback_reputation),
  ratingCount: user.feedback_count,
  isFollowed: user.is_favourite,
  itemCount: user.item_count,
  newItemsCount: user.new_items_count,
  isBusiness: user.business,
  items: user.items ? transformFollowingUserItemDtosToProductItems(user.items, user.business) : [],
})

export const transformFollowingUsersDtos = (
  data: Array<FollowingUserDto>,
): Array<FollowingUserModel> => data.map(transformFollowingUserDto)
