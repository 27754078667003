import { createContext } from 'react'

export type ConsentContextType = {
  serverCookieOptanonConsent: string
}

const ConsentContext = createContext<ConsentContextType>({
  serverCookieOptanonConsent: '',
})

export default ConsentContext
