import { round } from 'lodash'

import { EMPTY_USER_IMAGE_PATH, CUSTOM_SYSTEM_PHOTOS, UserThumbnailSize } from 'constants/images'
import { PhotoDto } from 'types/dtos'
import { PhotoModel } from 'types/models'

const getEmptyUserImage = (): string => EMPTY_USER_IMAGE_PATH

const getSystemUserImage = (portal: string, flavour: string) => {
  const portalCode = portal.replace('_sandbox', '')
  const name = CUSTOM_SYSTEM_PHOTOS.includes(portalCode) ? portalCode : 'default'

  return `https://static.vinted.com/assets/no-photo/user-${flavour}/system-photo/${name}.png`
}

const getUserThumbnail = (
  photo: PhotoDto<UserThumbnailSize> | null | undefined,
  size: UserThumbnailSize = UserThumbnailSize.Default,
): string => {
  if (!photo) return getEmptyUserImage()
  if (!photo.thumbnails) return getEmptyUserImage()

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === size)

  if (!thumbnail) return getEmptyUserImage()

  return thumbnail.url
}

const getPhotoThumbnail = (
  photo: PhotoModel<string> | null | undefined,
  size: UserThumbnailSize = UserThumbnailSize.Default,
): string => {
  if (!photo) return getEmptyUserImage()
  if (!photo.thumbnails) return getEmptyUserImage()

  const thumbnail = photo.thumbnails.find(thumb => thumb.type === size)

  if (!thumbnail) return getEmptyUserImage()

  return thumbnail.url
}

const calculateRating = (reputation: number): number => round(reputation * 5, 2)

export {
  getEmptyUserImage,
  getSystemUserImage,
  getPhotoThumbnail,
  getUserThumbnail,
  calculateRating,
}
