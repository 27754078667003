import { BankAccountRegistrationDto, BankAccountField } from 'types/dtos'
import { BankAccountRegistrationModel, BankAccountFieldModel } from 'types/models'
import { BankAccountTokenizer, BankAccountFieldType } from 'constants/payments'

const transformBankAccountField = ({
  required,
  keyboard_input_type,
  format,
  min_length,
  max_length,
  adjust_pattern,
}: BankAccountField): BankAccountFieldModel => ({
  required,
  keyboardInputType: keyboard_input_type,
  format,
  minLength: min_length,
  maxLength: max_length,
  adjustPattern: adjust_pattern,
})

const transformBankAccountFields = (
  fields: Partial<Record<BankAccountFieldType, BankAccountField | null>>,
): Partial<Record<BankAccountFieldType, BankAccountFieldModel | null>> =>
  Object.keys(fields).reduce((acc, fieldType) => {
    const dtoField = fields[fieldType]

    return {
      ...acc,
      [fieldType]: dtoField ? transformBankAccountField(dtoField) : null,
    }
  }, {})

export const transformBankAccountRegistration = ({
  provider_name,
  account_type,
  info,
  tokenizer,
  fields,
}: BankAccountRegistrationDto): BankAccountRegistrationModel => ({
  provider: provider_name,
  accountType: account_type,
  tokenizer: tokenizer as BankAccountTokenizer,
  info: {
    country: info.country,
    currency: info.currency,
  },
  fields: transformBankAccountFields(fields),
})
