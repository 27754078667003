import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { getSessionStorageItem } from 'libs/utils/sessionStorage'

import { AD_BLOCKER_VISITOR_KEY, IS_AD_BLOCKER_USED_SESSION_KEY } from 'constants/ads'
import { UiState } from 'constants/ui'

import { AdsPlacementModel, VanPlacementConfigModel } from 'types/models'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  placements: [],
  segments: {},
  uiState: UiState.Idle,
  isAdBlockerUsed: getSessionStorageItem(IS_AD_BLOCKER_USED_SESSION_KEY) === 'true' || null,
  hasAdBlockerBeenTracked: !!getSessionStorageItem(IS_AD_BLOCKER_USED_SESSION_KEY) || false,
  adBlockerVisitorId: getSessionStorageItem(AD_BLOCKER_VISITOR_KEY) || null,
  shouldMockAds: false,
}

const setAdsPlacements: CaseReducer<
  State,
  PayloadAction<{ placements: Array<AdsPlacementModel> }>
> = (draft, action) => {
  const { placements } = action.payload

  draft.placements = placements
  draft.shouldMockAds = process.env.MOCK_ADS_ENABLED === '1'
}

const setVanAdsPlacement: CaseReducer<
  State,
  PayloadAction<{ placement: VanPlacementConfigModel }>
> = (draft, action) => {
  const { placement } = action.payload

  draft.placements = [...draft.placements, placement]
}

const setAdsSegments: CaseReducer<State, PayloadAction<{ segments: Record<string, string> }>> = (
  draft,
  action,
) => {
  const { segments } = action.payload

  draft.segments = segments
}

const setUiState: CaseReducer<State, PayloadAction<{ uiState: UiState }>> = (draft, action) => {
  const { uiState } = action.payload

  draft.uiState = uiState
}

const setIsAdBlockerUsed: CaseReducer<State, PayloadAction<{ isAdBlockerUsed: boolean }>> = (
  draft,
  action,
) => {
  const { isAdBlockerUsed } = action.payload

  if (draft.isAdBlockerUsed === isAdBlockerUsed) return

  draft.isAdBlockerUsed = isAdBlockerUsed
}

const setHasAdBlockerBeenTracked: CaseReducer<
  State,
  PayloadAction<{ hasAdBlockerBeenTracked: boolean }>
> = (draft, action) => {
  const { hasAdBlockerBeenTracked } = action.payload

  draft.hasAdBlockerBeenTracked = hasAdBlockerBeenTracked
}

const setAdBlockerVisitorId: CaseReducer<State, PayloadAction<{ id: string }>> = (
  draft,
  action,
) => {
  const { id } = action.payload

  draft.adBlockerVisitorId = id
}

const adsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setAdsPlacements,
    setAdsSegments,
    setUiState,
    setIsAdBlockerUsed,
    setHasAdBlockerBeenTracked,
    setAdBlockerVisitorId,
    setVanAdsPlacement,
  },
})

export const { actions } = adsSlice
export const plug = { [stateName]: adsSlice.reducer }
export default adsSlice.reducer
