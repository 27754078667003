import { createSelector } from 'reselect'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getPushUpCheckout = (state: AllState) => localState(state).pushUpCheckout
export const getSelectedIds = (state: AllState) => localState(state).selectedIds
export const getItemThumbnailsById = (state: AllState) => localState(state).itemThumbnailsById
export const getActiveCheckoutModal = (state: AllState) => getPushUpCheckout(state).activeModal

export const getSelectedItemThumbnails = createSelector(
  getSelectedIds,
  getItemThumbnailsById,
  (ids, itemThumbnailsById) =>
    Object.keys(itemThumbnailsById).length
      ? ids.map(id => itemThumbnailsById[id]).filter(Boolean)
      : [],
)
