import { createSelector } from 'reselect'

import { PayInMethodCode } from 'constants/pay-in-method'

import { createDeepEqualSelector } from 'state/selectors'

import { stateName } from './constants'
import { State, AllState } from './types'

const localState = (state: AllState): State => state[stateName]

const getExtraServiceOrderPayment = (state: AllState) => localState(state).orderPayment
export const getExtraServiceErrorMessage = (state: AllState) => localState(state).errorMessage
export const getExtraServicePayInMethods = (state: AllState) => localState(state).payInMethods

export const getCreditCards = (state: AllState) => getExtraServicePayInMethods(state).creditCards
export const getPayInMethods = (state: AllState) => getExtraServicePayInMethods(state).payInMethods
export const getWalletBalance = (state: AllState) =>
  getExtraServicePayInMethods(state).walletBalance
export const getPayInMethodsUiState = (state: AllState) =>
  getExtraServicePayInMethods(state).uiState
export const getSelectedPayInMethodId = (state: AllState) =>
  getExtraServicePayInMethods(state).selectedPayInMethod?.id
export const getSelectedCreditCardId = (state: AllState) =>
  getExtraServicePayInMethods(state).selectedPayInMethod?.creditCardId

export const getPaymentAuth = createSelector(getExtraServiceOrderPayment, ({ auth }) => auth)
export const getPaymentStatus = createSelector(getExtraServiceOrderPayment, ({ status }) => status)
export const getPaymentErrorMessage = createSelector(
  getExtraServiceOrderPayment,
  ({ error }) => error.message,
)

export const getPermanentCreditCards = createSelector(getCreditCards, creditCards =>
  creditCards.filter(({ isSingleUse }) => !isSingleUse),
)

export const getIsWalletBalanceInsufficient = createDeepEqualSelector(
  [getWalletBalance, (state: AllState, payableAmount: string | undefined) => payableAmount],
  (walletBalance, payableAmount) => {
    if (!walletBalance || !payableAmount) return false

    const orderPayableAmount = parseFloat(payableAmount)
    const walletBalanceAmount = parseFloat(walletBalance.availableAmount.amount)

    return orderPayableAmount > walletBalanceAmount
  },
)

export const getPreferredPayInMethod = createSelector(getPayInMethods, payInMethods =>
  payInMethods.find(({ preferred }) => !!preferred),
)

export const getSelectedPayInMethod = createSelector(
  getPayInMethods,
  getSelectedPayInMethodId,
  (payInMethods, selectedMethodId) => payInMethods.find(({ id }) => id === selectedMethodId),
)

export const getSelectedCreditCard = createSelector(
  getCreditCards,
  getSelectedCreditCardId,
  getSelectedPayInMethod,
  getPreferredPayInMethod,
  (creditCards, selectedCreditCardId, selectedPayInMethod, preferredPayInMethod) => {
    const activePayInMethod = selectedPayInMethod || preferredPayInMethod

    if (activePayInMethod?.code !== PayInMethodCode.CreditCard) return null

    if (selectedCreditCardId) {
      return creditCards.find(({ id }) => id === selectedCreditCardId)
    }

    return creditCards.find(({ isDefault }) => isDefault)
  },
)

export const getActivePayInMethod = createSelector(
  getSelectedCreditCard,
  getSelectedPayInMethod,
  getPreferredPayInMethod,
  getIsWalletBalanceInsufficient,
  (selectedCreditCard, selectedPayInMethod, preferredPayInMethod, isWalletBalanceInsufficient) => {
    const activeMethod = selectedPayInMethod || preferredPayInMethod

    if (activeMethod?.code === PayInMethodCode.CreditCard && !selectedCreditCard) return null
    if (activeMethod?.code === PayInMethodCode.Wallet && isWalletBalanceInsufficient) return null
    if (activeMethod?.code === PayInMethodCode.ApplePay) return null
    if (activeMethod?.code === PayInMethodCode.GooglePay) return null

    return activeMethod ?? null
  },
)

export const getCreditCardPayInMethod = createSelector(getPayInMethods, payInMethods =>
  payInMethods.find(({ code }) => code === PayInMethodCode.CreditCard),
)

export const getIsBlikAuthRequired = createDeepEqualSelector(
  [getActivePayInMethod, (state: AllState, payableAmount: string | undefined) => payableAmount],
  (activePayInMethod, payableAmount) => {
    if (activePayInMethod?.code !== PayInMethodCode.BlikDirect) return false
    if (!payableAmount) return true

    const orderPayableAmount = parseFloat(payableAmount)

    return orderPayableAmount > 0
  },
)

export const getConfiguration = createSelector(
  getExtraServiceOrderPayment,
  ({ configuration }) => configuration,
)
export const getThreeDS2Configuration = createSelector(
  getConfiguration,
  configuration => configuration?.threeDS2 || null,
)
