import { takeEvery } from 'redux-saga/effects'
import { call, select, put } from 'typed-redux-saga'

import * as api from 'data/api'

import { MapPageIdToAdPage } from 'constants/ads'
import { UiState } from 'constants/ui'

import { transformAdsPlacements } from 'data/transformers/ads'

import { actions } from './slice'
import { getUiState, getIsPlacementPageMatching } from './selectors'
import * as statelessActions from './actions'

export function* loadAdsPlacements({ payload }) {
  const { pageId } = payload
  const uiState = yield* select(getUiState)

  const adsPageName = MapPageIdToAdPage[pageId]

  if (!adsPageName) return

  const isPlacementPageMatching = yield* select(getIsPlacementPageMatching(adsPageName))

  if (uiState !== UiState.Idle || isPlacementPageMatching) return

  yield* put(
    actions.setUiState({
      uiState: UiState.Pending,
    }),
  )

  const response = yield* call(api.getAdsPlacements, {
    pageName: adsPageName,
  })

  if (typeof response !== 'object' || response === null || 'errors' in response) {
    yield* put(
      actions.setUiState({
        uiState: UiState.Failure,
      }),
    )

    return
  }

  yield* put(
    actions.setAdsPlacements({
      placements: transformAdsPlacements(response.placements),
    }),
  )
  yield* put(
    actions.setUiState({
      uiState: UiState.Idle,
    }),
  )
}

export function* loadAdsSegments({ payload }) {
  const { catId } = payload
  const uiState = yield* select(getUiState)

  if (uiState !== UiState.Idle) return

  yield* put(
    actions.setUiState({
      uiState: UiState.Pending,
    }),
  )

  const response = yield* call(api.getAdsSegments, { catId })

  if (typeof response !== 'object' || response === null || 'errors' in response) {
    yield* put(
      actions.setUiState({
        uiState: UiState.Failure,
      }),
    )

    return
  }

  yield* put(
    actions.setAdsSegments({
      segments: response.segments,
    }),
  )

  yield* put(
    actions.setUiState({
      uiState: UiState.Idle,
    }),
  )
}

export default function* saga() {
  yield takeEvery(statelessActions.loadAdsPlacements, loadAdsPlacements)
  yield takeEvery(statelessActions.loadAdsSegments, loadAdsSegments)
}
