import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import {
  transformCurrencyConversionDto,
  transformCurrencyConversionWithMoneyObjectDto,
} from 'data/transformers/currency-conversion'
import { BundleSummaryDto, InstantBundleSummaryDto } from 'types/dtos'
import { BundleSummaryModel, InstantBundleSummaryModel } from 'types/models'

export const transformBundleSummary = ({
  discount_amount,
  discount_applied,
  discount_explanation,
  discount_fraction,
  discount_text,
  full_price,
  price,
  shipping_price,
  show,
  total_price,
  currency,
  offline_verification_fee,
  buyer_protection_fee,
  price_with_buyer_protection_fee,
  conversion,
}: BundleSummaryDto): BundleSummaryModel => ({
  discountAmount: discount_amount,
  discountApplied: discount_applied,
  discountExplanation: discount_explanation,
  discountFraction: discount_fraction,
  discountText: discount_text,
  fullPrice: full_price,
  price,
  shippingPrice: shipping_price,
  show,
  totalPrice: total_price,
  currency,
  offlineVerificationFee:
    offline_verification_fee && transformCurrencyAmountDto(offline_verification_fee),
  buyerProtectionFee: transformCurrencyAmountDto(buyer_protection_fee),
  priceWithBuyerProtectionFee: transformCurrencyAmountDto(price_with_buyer_protection_fee),
  conversion: conversion && transformCurrencyConversionDto(conversion),
})

export const transformInstantBundleSummary = ({
  discount_amount,
  discount_applied,
  discount_explanation,
  discount_fraction,
  discount_text,
  total_price,
  shipping_price,
  offline_verification_fee,
  buyer_protection_fee,
  price_with_buyer_protection_fee,
  conversion,
  items_price,
  bundle_shipping_type,
  contains_offline_verification_item,
}: InstantBundleSummaryDto): InstantBundleSummaryModel => ({
  discountAmount: discount_amount ? transformCurrencyAmountDto(discount_amount) : null,
  discountApplied: discount_applied,
  discountExplanation: discount_explanation,
  discountFraction: discount_fraction,
  discountText: discount_text,
  offlineVerificationFee:
    offline_verification_fee && transformCurrencyAmountDto(offline_verification_fee),
  buyerProtectionFee: transformCurrencyAmountDto(buyer_protection_fee),
  priceWithBuyerProtectionFee: transformCurrencyAmountDto(price_with_buyer_protection_fee),
  conversion: conversion && transformCurrencyConversionWithMoneyObjectDto(conversion),
  itemsPrice: transformCurrencyAmountDto(items_price),
  totalPrice: transformCurrencyAmountDto(total_price),
  shippingPrice: shipping_price ? transformCurrencyAmountDto(shipping_price) : null,
  bundleShippingType: bundle_shipping_type,
  containsOfflineVerificationItem: contains_offline_verification_item,
})
