export const stateName = 'feedback' as const

export const COMMENT_CHAR_LIMIT = 400

export enum FeedbackScreen {
  View = 'VIEW',
  DeleteFeedback = 'DELETE_FEEDBACK',
  CreateComment = 'CREATE_COMMENT',
  EditComment = 'EDIT_COMMENT',
  DeleteComment = 'DELETE_COMMENT',
}
