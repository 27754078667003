export enum ItemAlertStatus {
  MissingSubcategory = 'missing_subcategory',
  ChangeDescription = 'change_description',
  Black = 'black',
  DarkGray = 'dark_gray',
  PackageSize = 'package_size',
  ReplicaProof = 'replica_proof',
  UnderReview = 'under_review',
  EligibleForAuthenticity = 'eligible_for_authenticity',
  VerifiedOnlineAuthenticity = 'verified_online_authenticity',
  DelayedPublication = 'delayed_publication',
}

export enum ItemClosingAction {
  Sold = 'sold',
}

export enum ItemDisposalConditions {
  None = 0,
  ForSell = 4,
}
