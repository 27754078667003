import { getItemThumbnail } from 'data/utils/item'

import { ItemDto, ItemPhotoDto, CheckoutItemDto, ItemConfigurationDto } from 'types/dtos'
import { ItemModel, ItemPhotoModel, CheckoutItemModel, ItemConfigurationModel } from 'types/models'

import { transformPhotoThumbnailDtos } from './photo'
import { transformCurrencyAmountDto, transformCurrencyAmountDtoOrString } from './currency-amount'

export const transformItemPhotoDto = (photo: ItemPhotoDto): ItemPhotoModel => ({
  id: photo.id,
  width: photo.width,
  height: photo.height,
  tempUuid: photo.temp_uuid,
  url: photo.url,
  thumbnails: transformPhotoThumbnailDtos(photo.thumbnails),
  dominantColor: photo.dominant_color,
  dominantColorOpaque: photo.dominant_color_opaque,
  isMain: photo.is_main,
  isSuspicious: photo.is_suspicious,
  fullSizeUrl: photo.full_size_url,
  imageNo: photo.image_no,
})

export const transformItemPhotoDtos = (photos: Array<ItemPhotoDto>): Array<ItemPhotoModel> =>
  photos.map(transformItemPhotoDto)

export const transformItemDto = (item: ItemDto): ItemModel => ({
  id: item.id,
  title: item.title,
  description: item.description,
  catalogId: item.catalog_id,
  size: item.size_title || '',
  status: item.status,
  brand: item.brand_dto?.title || '',
  brandId: item.brand_dto?.id,
  statusId: item.status_id,
  disposalConditions: item.disposal_conditions,
  price: transformCurrencyAmountDto(item.price),
  serviceFee: item.service_fee
    ? transformCurrencyAmountDtoOrString(item.service_fee, item.currency)
    : null,
  thumbnailUrl: getItemThumbnail(item.photos),
  url: item.url,
  photos: transformItemPhotoDtos(item.photos),
})

export const transformItemDtos = (items: Array<ItemDto>): Array<ItemModel> =>
  items.map(transformItemDto)

export const transformCheckoutItemDto = (item: CheckoutItemDto): CheckoutItemModel => ({
  id: item.id,
  size: item.size,
  title: item.title,
  status: item.status,
  brandId: item.brand_id,
  brand: item.brand_title,
  statusId: item.status_id,
  catalogId: item.catalog_id,
  disposalConditions: item.disposal_conditions,
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo]) : null,
})

export const transformCheckoutItemDtos = (
  items: Array<CheckoutItemDto>,
): Array<CheckoutItemModel> => items.map(transformCheckoutItemDto)

export const transformItemConfigurationDto = (
  configuration: ItemConfigurationDto,
): ItemConfigurationModel => ({
  prices: {
    minimum: transformCurrencyAmountDto(configuration.prices.minimum),
    maximum: transformCurrencyAmountDto(configuration.prices.maximum),
  },
})
