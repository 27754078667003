import {
  GLOBAL_SEARCH_SESSION_ID_KEY,
  SEARCH_SESSION_ID_KEY,
  SEARCH_START_ID_KEY,
  SEARCH_START_TYPE_KEY,
} from 'constants/search'
import { SearchStartType } from 'constants/tracking/search'
import { SearchStartData } from 'types/search'

import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from './localStorage'
import { toParams } from './url'

export const isValueSearchStartType = (value: string): value is SearchStartType =>
  Object.values<string>(SearchStartType).includes(value)

export const getSearchStartDataFromUrl = (): {
  searchStartId: string | null
  searchStartType: string | null
} => {
  const currentUrlParams = toParams(window.location.search)
  const searchStartId = (currentUrlParams.search_start_id as string) || null
  const searchStartType = (currentUrlParams.search_start_type as string) || null

  return {
    searchStartId,
    searchStartType,
  }
}

export const getSearchStartData = (): SearchStartData => {
  const searchStartTypeData = getLocalStorageItem(SEARCH_START_TYPE_KEY)
  let searchStartType: SearchStartType | null = null

  if (searchStartTypeData && isValueSearchStartType(searchStartTypeData)) {
    searchStartType = searchStartTypeData
  }

  return {
    searchStartId: getLocalStorageItem(SEARCH_START_ID_KEY),
    searchStartType,
  }
}

export const setSearchStartData = ({ searchStartId, searchStartType }: SearchStartData) => {
  if (!searchStartId || !searchStartType) return

  setLocalStorageItem(SEARCH_START_ID_KEY, searchStartId)
  setLocalStorageItem(SEARCH_START_TYPE_KEY, searchStartType)
}

export const removeSearchStartData = () => {
  removeLocalStorageItem(SEARCH_START_TYPE_KEY)
  removeLocalStorageItem(SEARCH_START_ID_KEY)
}

export const getSearchSessionData = () => ({
  searchSessionId: getLocalStorageItem(SEARCH_SESSION_ID_KEY),
  globalSearchSessionId: getLocalStorageItem(GLOBAL_SEARCH_SESSION_ID_KEY),
})

export const setSearchSessionData = ({
  searchSessionId,
  globalSearchSessionId,
}: {
  searchSessionId?: string
  globalSearchSessionId?: string | null
}) => {
  if (searchSessionId) {
    setLocalStorageItem(SEARCH_SESSION_ID_KEY, searchSessionId)
  }

  if (globalSearchSessionId) {
    setLocalStorageItem(GLOBAL_SEARCH_SESSION_ID_KEY, globalSearchSessionId)
  }
}

export const removeSearchSessionData = () => {
  removeLocalStorageItem(SEARCH_SESSION_ID_KEY)
}
