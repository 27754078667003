export enum ReturnShippingOption {
  Integrated = 'integrated',
  Custom = 'custom',
}

export enum ReturnShippingOptionPricing {
  RefundSubtotal = 'refund_subtotal',
  Postage = 'postage',
  RefundTotal = 'refund_total',
}

export enum ReturnShippingMethod {
  Custom = 'custom',
  Integrated = 'integrated',
}

export enum ReturnShippingMethodPayingSide {
  Seller = 'seller_pays',
  Buyer = 'buyer_pays',
}

export enum ReturnShippingModal {
  ReturnCurrencyConversionModal = 'return_currency_conversion_modal',
  RequestReturnModal = 'request_return_modal',
  GenericErrorModal = 'generic_error_modal',
  None = 'none',
}
