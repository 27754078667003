import { UserBadgeDto } from 'types/dtos'
import { UserBadgeModel } from 'types/models'

export const transformUserBadgeDto = ({
  type,
  progress: { current, goal },
}: UserBadgeDto): UserBadgeModel => ({
  type,
  progress: { current, goal },
})
