'use client'

import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import Script from 'next/script'

import useAsset from 'hooks/useAsset'
import useLocation from 'hooks/useLocation'

import { serverSide } from 'libs/utils/environment'
import { isStringTruthy } from 'libs/utils/string'
import { logError } from 'libs/utils/window'
import { encryptEmail } from 'libs/utils/email-encrypt'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getIsWebView, getUser, getAnonId } from 'state/session/selectors'

import { getConsentKey, initOneTrust } from './utils'

// TODO: Add unit tests for this component
const ConsentBanner = () => {
  const { host, searchParams } = useLocation()
  const user = useSelector(getUser)
  const anonId = useSelector(getAnonId)
  const isWebview = useSelector(getIsWebView)
  const asset = useAsset()
  const hasAdsAmazonPublisherAudience = useSelector(
    getIsFeatureSwitchEnabled('web_ads_amazon_publisher_audience'),
  )
  const consentKey = getConsentKey(host)

  async function initializeConsentBanner() {
    const hashedEmail = user?.email ? await encryptEmail(user.email) : null

    try {
      const hideConsent = isStringTruthy(searchParams.hide_consent)

      // TODO: After websplit, move it all to `./utils`
      window.initConsentBanner?.({
        userId: user?.id.toString(),
        isWebview,
        hideConsent,
        hasAdsAmazonPublisherAudience,
        hashedEmail,
      })
    } catch (error) {
      logError(error)
    }
  }

  function initCookieListModifications() {
    // Define target node that will be filled
    // by OneTrust eventually with the cookie list
    const targetNode = document.getElementById('ot-sdk-cookie-policy')

    // Don't do anything if the targetNode is not found
    if (!targetNode) return

    const observer = new MutationObserver(() => {
      // Disconnect the observer after the first mutation
      observer.disconnect()

      // Find the first table in the targetNode
      // Which will always be Strictly Necessary
      // Cookies table
      const table = targetNode.querySelector('table')

      // If there's no table, do nothing
      if (!table) return

      // Replace all links in with their innerHTML
      table.querySelectorAll('a').forEach(link => link.replaceWith(link.innerHTML))
    })

    observer.observe(targetNode, { childList: true })
  }

  useEffect(() => {
    initOneTrust(!!user, anonId)
    initCookieListModifications()
  }, [user, anonId])

  // `getHost` is malfunctioning in the server-side
  // which results in wrong version of consent script
  // being loaded.
  //
  // TODO: Remove `serverSide` check when `getHost`
  // is fixed in SSR

  if (serverSide) return null

  // profile page with admin functionality renders main content in an iframe
  // which сauses the consent banner to be rendered twice
  if (window.frameElement) return null

  return (
    <>
      {/* TODO: Separate into separate components */}
      <Script data-testid="tcfapi-stub" src="https://cdn.cookielaw.org/consent/tcf.stub.js" />
      <Script
        data-testid="ot-sdk-stub"
        data-document-language="true"
        data-domain-script={consentKey}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      />
      <Script
        data-testid="consent-banner-script"
        src={asset('consent-banner.js')}
        onLoad={initializeConsentBanner}
      />
    </>
  )
}

export default ConsentBanner
