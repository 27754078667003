'use client'

import { useSelector } from 'react-redux'
import Head from 'next/head'
import { useRouter } from 'next/router'

import useAsset from 'hooks/useAsset'
import { portalLogoBrand } from 'libs/utils/portal'
import { getLocale } from 'state/intl/selectors'
import { getPortal } from 'state/system-configuration/selectors'

import HrefLangTags from './HrefLangTags'

type Props = {
  url?: string
}

const AppHead = ({ url }: Props) => {
  const locale = useSelector(getLocale)
  const brand = portalLogoBrand(useSelector(getPortal))
  const router = useRouter()
  const asset = useAsset(`/assets/favicon/${brand}/`)

  /* Query parameters are stripped from canonical URL */
  let canonical = router.asPath.split('#')[0].split('?')[0]
  /* Removes trailing slash for the root page */
  if (url) canonical = new URL(url).origin + (router.asPath === '/' ? '' : canonical)

  const page = Number(router.query.page)
  if (page > 2) canonical += `?page=${page}`

  return (
    <Head>
      <meta name="accept-language" content={locale} />
      <meta charSet="UTF-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />

      <link rel="canonical" href={canonical} />

      <HrefLangTags url={url} />

      <link rel="apple-touch-icon" sizes="180x180" href={asset('apple-touch-icon.png')} />
      <link rel="icon" type="image/png" sizes="32x32" href={asset('favicon-32x32.png')} />
      <link rel="icon" type="image/png" sizes="16x16" href={asset('favicon-16x16.png')} />
      <link rel="shortcut icon" href={asset('/favicon.ico')} />
    </Head>
  )
}

export default AppHead
