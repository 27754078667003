import { PickUpDatesDto, PickUpTimesDto } from 'types/dtos'
import { PickUpDatesModel, PickUpTimesModel } from 'types/models'

export const transformPickUpTimeDto = ({
  from,
  to,
  is_available,
}: PickUpTimesDto): PickUpTimesModel => {
  return {
    from,
    to,
    isAvailable: is_available,
  }
}

export const transformPickUpDateDto = ({
  date,
  is_available,
  times,
}: PickUpDatesDto): PickUpDatesModel => {
  return {
    date,
    isAvailable: is_available,
    times: times ? times.map(transformPickUpTimeDto) : null,
  }
}

export const transformPickUpDatesDto = (
  pickUpDates: Array<PickUpDatesDto>,
): Array<PickUpDatesModel> => pickUpDates.map(transformPickUpDateDto)
