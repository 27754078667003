export enum DisplayType {
  List = 'list',
  ListSearch = 'list_search',
  Grid = 'grid',
  HybridPrice = 'hybrid_price',
  // temporary display type
  ExposedFilter = 'exposed_filter',
  PrefixImage = 'prefix_image',
}

export enum SelectionType {
  Single = 'single',
  Multi = 'multi',
}

export enum OptionType {
  Root = 'root',
  Default = 'default',
  Navigational = 'navigational',
  Group = 'group',
}

export enum OptionExtentionType {
  Color = 'color',
  Image = 'image',
}

export enum PrefixImageSize {
  Regular = 'regular',
  Medium = 'medium',
}

export enum FilterModalNames {
  Default = '',
  Root = 'root',
  Filter = 'filter',
}

export const SUPPORTED_DISPLAY_TYPES = [
  DisplayType.List,
  DisplayType.ListSearch,
  DisplayType.Grid,
  DisplayType.HybridPrice,
  DisplayType.ExposedFilter,
  DisplayType.PrefixImage,
]
