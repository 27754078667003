import { put, takeEvery } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { transformAddress } from 'data/transformers/address'
import * as api from 'data/api'

import { actions } from './slice'

export function* fetchDefaultShippingAddress() {
  const response = yield* call(api.getDefaultShippingAddress)

  if ('errors' in response) {
    yield put(actions.fetchDefaultShippingAddressFailure())

    return
  }

  if (response.user_address) {
    const address = transformAddress(response.user_address)

    yield put(actions.fetchDefaultShippingAddressSuccess({ address }))
  }

  if (!response.user_address) {
    yield put(actions.fetchDefaultShippingAddressSuccess({ address: null }))
  }
}

export default function* saga() {
  yield takeEvery(actions.fetchDefaultShippingAddressRequest, fetchDefaultShippingAddress)
}
