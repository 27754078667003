import ApiClient, { interceptors } from 'libs/common/api-client'
import * as apiTypes from 'types/api'

import { saveCookie } from 'libs/utils/cookies'
import { AUTH_IS_LOGIN_VIEW } from 'constants/cookie'

import { authenticateUserArgsToParams } from '../transformers/args'

const api = new ApiClient({ baseURL: '/web/api/auth' }, [
  interceptors.csrfTokenInterceptor,
  interceptors.errorInterceptor,
  interceptors.localeInterceptor,
])

export const authenticateUser = async (args: apiTypes.LoginUserArgs) =>
  api.post<unknown, apiTypes.AuthenticateUserError>('/oauth', {
    client_id: 'web',
    scope: 'user',
    ...authenticateUserArgsToParams(args),
  })

export function registerUser(args: apiTypes.RegisterUserArgs) {
  const {
    realName,
    login,
    email,
    password,
    agreeRules,
    subscribeToNewsletter = false,
    fingerprint,
  } = args

  const user = {
    real_name: realName,
    login,
    email,
    password,
    agree_rules: agreeRules,
    user_settings: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterUserResponse>('/email-register', {
    user,
    fingerprint,
  })
}

export function registerFacebookUser(args: apiTypes.RegisterFacebookUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterFacebookUserResponse>('/facebook-register', {
    fb_access_token: token,
    user,
    fingerprint,
  })
}

export function registerGoogleUser(args: apiTypes.RegisterFacebookUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterGoogleUserResponse>('/google-register', {
    id_token: token,
    user,
    fingerprint,
  })
}

export function registerAppleUser(args: apiTypes.RegisterAppleUserArgs) {
  const { token, login, email, subscribeToNewsletter = false, realName, fingerprint } = args

  const user = {
    login,
    real_name: realName,
    email,
    user_setting: {
      is_newsletter_subscriber: subscribeToNewsletter,
    },
  }

  return api.post<apiTypes.RegisterAppleUserResponse>('/apple-register', {
    id_token: token,
    user,
    fingerprint,
  })
}

export const refreshSessionTokens = async () => api.post('/refresh')

export const logoutUser = async () => {
  const response = await api.post('/logout')

  saveCookie(AUTH_IS_LOGIN_VIEW.name, '1', AUTH_IS_LOGIN_VIEW.maxAge)

  return !('errors' in response)
}

export function generateRandomUser(args: apiTypes.GenerateRandomUserArgs) {
  const { isGod } = args

  return api.post('/generate-random-user', {
    is_god: isGod,
  })
}
