import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { transformCountryDtos } from 'data/transformers/country'
import { transformCityDtos } from 'data/transformers/city'
import * as api from 'data/api'

import * as statelessActions from './actions'
import { actions } from './slice'

export function* fetchCountries() {
  const response = yield* call(api.getCountries)

  if ('errors' in response) {
    yield put(statelessActions.fetchCountriesFailure())

    return
  }

  const countryModels = transformCountryDtos(response.countries)

  yield put(actions.fetchCountriesSuccess({ countries: countryModels }))
}

export function* searchCities({
  payload,
}: ReturnType<typeof statelessActions.searchCitiesRequest>) {
  const response = yield* call(api.searchCities, {
    searchText: payload.searchText,
    countryId: payload.countryId,
  })

  if ('errors' in response) {
    yield put(statelessActions.searchCitiesFailure())

    return
  }

  const citiesModel = transformCityDtos(response.cities)

  yield put(actions.searchCitiesSuccess({ cities: citiesModel }))
}

export default function* saga() {
  yield takeLatest(statelessActions.fetchCountriesRequest, fetchCountries)
  yield takeLatest(statelessActions.searchCitiesRequest, searchCities)
}
