import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { ResponseError } from 'types/api'

import { stateName } from './constants'
import { State, ProfileSettings } from './types'

export const initialState: State = {
  settings: {
    countryId: null,
    cityId: null,
    cityTitle: null,
    isCityVisible: false,
    about: undefined,
    languageCode: '',
    photoTempUuid: undefined,
    vat: null,
  },
  uiState: UiState.Idle,
}

const setInitialSettings: CaseReducer<State, PayloadAction<{ settings: ProfileSettings }>> = (
  draft,
  action,
) => {
  draft.settings = action.payload.settings
}

const setCountryId: CaseReducer<State, PayloadAction<{ id: number | null }>> = (draft, action) => {
  const { id } = action.payload

  draft.settings.countryId = id
}

const setCity: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    title: string | null
  }>
> = (draft, action) => {
  const { id, title } = action.payload

  draft.settings.cityId = id
  draft.settings.cityTitle = title
}

const setIsCityVisible: CaseReducer<State, PayloadAction<{ isVisible: boolean }>> = (
  draft,
  action,
) => {
  draft.settings.isCityVisible = action.payload.isVisible
}

const setAbout: CaseReducer<State, PayloadAction<{ about: string }>> = (draft, action) => {
  draft.settings.about = action.payload.about
}

const setLanguageCode: CaseReducer<State, PayloadAction<{ languageCode: string }>> = (
  draft,
  action,
) => {
  draft.settings.languageCode = action.payload.languageCode
}

const setPhotoTempUuid: CaseReducer<State, PayloadAction<{ photoTempUuid: string }>> = (
  draft,
  action,
) => {
  draft.settings.photoTempUuid = action.payload.photoTempUuid
}

const setUsername: CaseReducer<State, PayloadAction<{ username: string }>> = (draft, action) => {
  draft.settings.username = action.payload.username
}

const setContactEmail: CaseReducer<State, PayloadAction<{ contactEmail: string }>> = (
  draft,
  action,
) => {
  draft.settings.contactEmail = action.payload.contactEmail
}

const setContactNumber: CaseReducer<State, PayloadAction<{ contactNumber: string }>> = (
  draft,
  action,
) => {
  draft.settings.contactNumber = action.payload.contactNumber
}

const setError: CaseReducer<State, PayloadAction<{ error: ResponseError }>> = (draft, action) => {
  draft.error = action.payload.error
}

const setVat: CaseReducer<State, PayloadAction<{ vat: string | null }>> = (draft, action) => {
  draft.settings.vat = action.payload.vat
}

const changeUiState: CaseReducer<State, PayloadAction<{ uiState: UiState }>> = (draft, action) => {
  const { uiState } = action.payload

  draft.uiState = uiState
}

const profileSettingsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setInitialSettings,
    setCountryId,
    setCity,
    setIsCityVisible,
    setAbout,
    setLanguageCode,
    setPhotoTempUuid,
    setUsername,
    setContactEmail,
    setContactNumber,
    setError,
    setVat,
    changeUiState,
  },
})

export const { actions } = profileSettingsSlice
export const plug = { [stateName]: profileSettingsSlice.reducer }
export default profileSettingsSlice.reducer
