import { NormalizedData } from 'types/redux'

export function addNormalizedData<T extends { id: number }>(
  draft: NormalizedData<T>,
  entities: Array<T>,
) {
  const ids = entities.map(it => it.id)

  entities.forEach(entity => {
    draft.byId[entity.id] = entity
  })

  draft.ids = ids
}

export function setNormalizedData<T extends { id: number }>(
  draft: NormalizedData<T>,
  entities: Array<T>,
) {
  draft.byId = {}

  addNormalizedData(draft, entities)
}

export const entitiesFromStructure = <T extends { id: number }>(data: NormalizedData<T>) =>
  data.ids.map(id => data.byId[id])

export const initialEntityStructure = {
  byId: {},
  ids: [],
}
