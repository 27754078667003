import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const fetchFormConfigurationRequest = createAction(
  `${stateName}/fetchFormConfigurationRequest`,
)

export const fetchFormConfigurationFailure = createAction(
  `${stateName}/fetchFormConfigurationFailure`,
)
