import {
  ReturnCurrencyConversionDto,
  ReturnCurrencyConversionPriceDto,
  ReturnCurrencyConversionShippingDto,
  ReturnCurrencyConversionExchangeDto,
  ReturnCurrencyConversionMarkupDto,
} from 'types/dtos/return-currency-conversion'
import {
  ReturnCurrencyConversionModel,
  ReturnCurrencyConversionPriceModel,
  ReturnCurrencyConversionShippingModel,
  ReturnCurrencyConversionExchangeModel,
  ReturnCurrencyConversionMarkupModel,
} from 'types/models/return-currency-conversion'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformReturnCurrencyConversionMarkupDto = (
  returnCurrencyConversionMarkup: ReturnCurrencyConversionMarkupDto,
): ReturnCurrencyConversionMarkupModel => {
  return {
    rate: returnCurrencyConversionMarkup.rate,
    provider: returnCurrencyConversionMarkup.provider,
  }
}

export const transformReturnCurrencyConversionExchangeDto = (
  returnCurrencyConversionExchange: ReturnCurrencyConversionExchangeDto,
): ReturnCurrencyConversionExchangeModel => {
  return {
    baseRate: transformCurrencyAmountDto(returnCurrencyConversionExchange.base_rate),
    roundedRate: transformCurrencyAmountDto(returnCurrencyConversionExchange.rounded_rate),
  }
}

export const transformReturnCurrencyConversionShippingDto = (
  returnCurrencyConversionShipping: ReturnCurrencyConversionShippingDto,
): ReturnCurrencyConversionShippingModel => {
  return {
    method: returnCurrencyConversionShipping.method,
    afterConversionPrice: transformCurrencyAmountDto(
      returnCurrencyConversionShipping.after_conversion_price,
    ),
    beforeConversionPrice: transformCurrencyAmountDto(
      returnCurrencyConversionShipping.before_conversion_price,
    ),
  }
}

export const transformReturnCurrencyConversionPriceDto = (
  returnCurrencyConversionPrice: ReturnCurrencyConversionPriceDto,
): ReturnCurrencyConversionPriceModel => {
  return {
    afterConversionPrice: transformCurrencyAmountDto(
      returnCurrencyConversionPrice.after_conversion_price,
    ),
    beforeConversionPrice: transformCurrencyAmountDto(
      returnCurrencyConversionPrice.before_conversion_price,
    ),
  }
}

export const transformReturnCurrencyConversionDto = (
  returnCurrencyConversion: ReturnCurrencyConversionDto,
): ReturnCurrencyConversionModel => {
  return {
    itemPrice:
      returnCurrencyConversion.item_price &&
      transformReturnCurrencyConversionPriceDto(returnCurrencyConversion?.item_price),
    factualShipping:
      returnCurrencyConversion.factual_shipping &&
      transformReturnCurrencyConversionShippingDto(returnCurrencyConversion?.factual_shipping),
    returnShipping:
      returnCurrencyConversion.return_shipping &&
      transformReturnCurrencyConversionPriceDto(returnCurrencyConversion?.return_shipping),
    buyerProtectionFee:
      returnCurrencyConversion.buyer_protection_fee &&
      transformCurrencyAmountDto(returnCurrencyConversion?.buyer_protection_fee),
    exchange: transformReturnCurrencyConversionExchangeDto(returnCurrencyConversion.exchange),
    markup: transformReturnCurrencyConversionMarkupDto(returnCurrencyConversion.markup),
  }
}
