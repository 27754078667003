import { kebabCase } from 'lodash'

import {
  Response,
  CountriesResp,
  ClosetPromoPerfrmanceResponse,
  BrandsResp,
  FeedbackRatingOptionsResp,
  GetItemUploadFeedbackRatingsResp,
  ClosetPromotionPricingResp,
  GetAdsPlacementsResp,
  PhotoTipsResp,
  GetShippingDetailsResp,
  UserSizesResp,
  GetSizeGroupsResp,
  NotificationSettingsResp,
  FaqEntryResp,
  GetItemConfigurationResp,
  FeaturedCollectionPricingResp,
  FeaturedCollectionOrderResp,
  UsersResp,
  GetIncomeSettingsResp,
  GetInvoicesAvailableDatesResp,
  UserResp,
  SendEmailVerificationCodeResp,
  GetInfoBoardPostsResp,
  GetTermsAndConditionsResp,
  GetInfoBoardPostResp,
  GetNotificationLandingsResp,
  MyOrdersResp,
  PhotoTipResp,
  FollowingUsersResp,
  FaqEntriesSearchResp,
  GetTermsAndConditionsContentResp,
  FaqEntryRecommendedFaqsResp,
  FaqEntryRelatedFaqsResp,
  BusinessAccountsTermsOfUseResp,
  ReferralShareResp,
  ReferralInviteInstructionsResp,
  GetUserItemsResp,
  ProfileUserInfoResp,
  InSiteSeoContentResp,
  CollectionsPlansPricingResp,
  GetAvailableUserItemsForCollectionResp,
  GetCollectionPolicyResp,
  CollectionsResp,
  CollectionResp,
  ItemResp,
  GetOfflineVerificationFeedbackResp,
  CreateReferrerResp,
  GetSellerOfferOptionsResp,
  BusinessAccountAddressesResp,
  FeaturedCollectionsResp,
  CalculateDiscountResp,
  GetItemEscrowFeesResp,
  GetVasEntryPointsResp,
  PromotedClosetOrderResp,
  GetClosetPromotionOrderResp,
  SellerBadgesResp,
  PromotedClosetsListResp,
  PushUpMinimumPriceResp,
  GetPriceEstimateWithFeesResponse,
  GetPushUpOrderResp,
  PreparePushUpOrderResp,
  BumpRecommendedItemsResp,
  PushUpOptionResp,
} from 'types/api/response'
import {
  CountryModel,
  ClosetPromoPerformanceModel,
  FeedbackRatingModel,
  BrandModel,
  ClosetPromotionPricingModel,
  HelpCenterFaqEntryModel,
  WalletIncomeSettingsModel,
  InvoicesAvailableDatesModel,
  FeedEventsModel,
  HelpCenterQuestionModel,
  ReferralShareModel,
  ReferralInviteInstructionsModel,
  ProductItemModel,
  PaginationModel,
} from 'types/models'
import { transformCountryDto } from 'data/transformers/country'
import { transformClosetPromoPerformanceDto } from 'data/transformers/closet-promo-performance'
import { transformBrandDto } from 'data/transformers/brand'
import { transformEscrowFees } from 'data/transformers/escrow-fees'
import {
  transformClosetPromotionOrder,
  transformClosetPromotionPricing,
} from 'data/transformers/closet-promotion'
import {
  transformItemDtosToProductItems,
  transformCollectionItemDtosToProductItems,
} from 'data/transformers/product-item'
import { transformAdsPlacements } from 'data/transformers/ads'
import { transformPhotoTipDto, transformPhotoTipDtos } from 'data/transformers/photo-tip'
import { transformItemConfigurationDto, transformItemDto } from 'data/transformers/item'
import { transformItemShippingDetailsDto } from 'data/transformers/shipping-option'
import { transformFeedbackFormDto } from 'data/transformers/feedback-form'
import { transformNotificationSettingsDto } from 'data/transformers/notification-settings'
import { transformFeaturedCollectionPricing } from 'data/transformers/featured-collection-pricing'
import { transformFeaturedCollectionOrder } from 'data/transformers/featured-collection-order'
import { transformUserDto, transformUserDtos } from 'data/transformers/user'
import { transformPaginationDto } from 'data/transformers/pagination'
import { HomepageBlocksModel } from 'types/models/homepage-blocks'
import { FeedEventsDto, HomepageBlocksDto } from 'types/dtos'
import { EmailVerificationCodeModel } from 'types/models/email-verification-code'
import { transformTermsAndConditionsDto } from 'data/transformers/terms-and-conditions'
import {
  transformInfoBoardPostDto,
  transformInfoBoardPostDtos,
  transformProfileUserInfoDto,
  transformSellerOfferOptionsDto,
} from 'data/transformers'
import { transformMyOrderDto } from 'data/transformers/my-order'
import { transformFollowingUsersDtos } from 'data/transformers/following-user'
import { AccessChannel } from 'constants/index'
import { transformTermsAndConditionsContentDto } from 'data/transformers/terms-and-conditions-content'
import {
  transformBusinessAccountAddressesDto,
  transformBusinessAccountsTermsOfUseDto,
} from 'data/transformers/business-account'
import {
  transformReferralInviteInstructionsDto,
  transformReferralShareDto,
} from 'data/transformers/referral'
import { transformInSiteSeoContentDto } from 'data/transformers/seo'
import { SeoContentModel } from 'types/models/seo'
import { transformCollectionsPlansPricing } from 'data/transformers/collections-plan-pricing'
import { transformCollectionPolicy } from 'data/transformers/collection-policy'
import { transformCollections } from 'data/transformers/collections'
import { transformCollection } from 'data/transformers/collection'
import { transformFeaturedCollection } from 'data/transformers/featured-collection'
import { transformDiscounts } from 'data/transformers/discount'
import { transformVasEntryPoints } from 'data/transformers/vas-entry-point'
import { transformUserBadgeDto } from 'data/transformers/user-badge'
import { transformClosets } from 'data/transformers/closet'
import {
  transformBumpMinimumPrice,
  transformPushUpOrder,
  transformPushUpsOptions,
} from 'data/transformers/bumps'
import { transformPriceEstimateWithFees } from 'data/transformers/price-estimate-with-fees'

export const transformCountryResponse = (response: Response<CountriesResp>): Array<CountryModel> =>
  response.countries.map(transformCountryDto)

export const transformClosetPromoPerformanceResponse = (
  response: Response<ClosetPromoPerfrmanceResponse>,
): ClosetPromoPerformanceModel | null =>
  response.closet_promotion_performance &&
  transformClosetPromoPerformanceDto(response.closet_promotion_performance)

export const transformBrandsResponse = (response: Response<BrandsResp>): Array<BrandModel> =>
  response.brands.map(transformBrandDto)

export const transformFeedbackRatingsResponse = (
  response: Response<
    | FeedbackRatingOptionsResp
    | GetItemUploadFeedbackRatingsResp
    | GetOfflineVerificationFeedbackResp
  >,
): FeedbackRatingModel | undefined => {
  if (!response.feedback_ratings) return undefined

  return transformFeedbackFormDto(response.feedback_ratings)
}

export const transformClosetPromotionPricingResponse = (
  response: Response<ClosetPromotionPricingResp>,
): ClosetPromotionPricingModel => transformClosetPromotionPricing(response.promoted_closet_pricing)

export const transformGetAdsPlacements = (response: Response<GetAdsPlacementsResp>) =>
  transformAdsPlacements(response.placements)

export const transformPhotoTipsResponse = (response: Response<PhotoTipsResp>) =>
  transformPhotoTipDtos(response.photo_tips)

export const transformPhotoTipResponse = (response: Response<PhotoTipResp>) =>
  transformPhotoTipDto(response.photo_tip)

export const transformGetShippingDetails = (response: Response<GetShippingDetailsResp>) =>
  response.shipping_details && transformItemShippingDetailsDto(response.shipping_details)

export const transformUserSizesResponse = (response: Response<UserSizesResp>) => ({
  sizeIds: response.size_ids,
})

export const transformSizeGroupsResponse = (response: Response<GetSizeGroupsResp>) => ({
  sizeGroups: response.size_groups,
})

export const transformNotificationSettingsResponse = (
  response: Response<NotificationSettingsResp>,
) => transformNotificationSettingsDto(response.notification_settings.email)

export const transformFaqEntryResponse = ({
  faq_entry,
}: FaqEntryResp): HelpCenterFaqEntryModel | undefined => {
  if (!faq_entry) return undefined

  return {
    id: faq_entry.id,
    title: faq_entry.title,
    body: faq_entry.body,
  }
}

export const transformFaqEntrySearchResponse = ({
  faq_entries,
}: FaqEntriesSearchResp): Array<HelpCenterQuestionModel> | null => {
  if (!faq_entries.length) return null

  return faq_entries.map(({ id, title }) => ({
    id,
    title,
    path: `/help/${kebabCase(`${id}-${title}`)}?access_channel=${AccessChannel.HcSearch}`,
  }))
}

export const transformGetItemConfigurationResponse = (
  response: Response<GetItemConfigurationResp>,
) => transformItemConfigurationDto(response.item_configuration)

export const transformFeaturedCollectionsResponse = (response: Response<FeaturedCollectionsResp>) =>
  response.featured_collections.map(transformFeaturedCollection)

export const transformFeaturedCollectionPricingResponse = (
  response: Response<FeaturedCollectionPricingResp>,
) => transformFeaturedCollectionPricing(response.featured_collection_pricing)

export const transformFeaturedCollectionOrderResponse = (
  response: Response<FeaturedCollectionOrderResp>,
) => transformFeaturedCollectionOrder(response.featured_collection_order)

export const transformFollowingUsersResponse = (response: Response<FollowingUsersResp>) => ({
  users: transformFollowingUsersDtos(response.users),
  pagination: transformPaginationDto(response.pagination),
})

export const transformUsersResponse = (response: Response<UsersResp>) => ({
  users: transformUserDtos(response.users),
  pagination: transformPaginationDto(response.pagination),
})

export const transformWalletIncomeSettingsResponse = ({
  is_monthly_report_enabled,
}: GetIncomeSettingsResp): WalletIncomeSettingsModel => ({
  isMonthlyReportEnabled: is_monthly_report_enabled,
})

export const transformInvoicesAvailableDatesResponse = ({
  available_dates,
}: GetInvoicesAvailableDatesResp): InvoicesAvailableDatesModel => ({
  availableDates: [...available_dates],
})

export const transformUserResponse = (response: Response<UserResp>) => ({
  user: transformUserDto(response.user),
})

export const transformHomepageBlockResponse = (
  response: HomepageBlocksDto,
): HomepageBlocksModel => ({
  homepageSessionId: response.homepage_session_id,
  promoBox: response.promo_box?.entity,
  blocks: response.blocks,
})

export const transformFeedEventsResponse = (
  response: Response<FeedEventsDto>,
): FeedEventsModel => ({
  events: response.feed_events,
  initialMaxScore: response.max_score,
  showLoadMoreButton: response.show_load_more_button,
  totalItemCount: response.total_item_count,
})

export const transformEmailVerificationCodeResponse = (
  response: Response<SendEmailVerificationCodeResp>,
): EmailVerificationCodeModel => ({
  email: response.email,
  canChangeEmail: response.can_change_email ?? true,
})

export const transformInfoBoardPostsResponse = (response: Response<GetInfoBoardPostsResp>) =>
  transformInfoBoardPostDtos(response.info_posts)

export const transformTermsAndConditionsResponse = (
  response: Response<GetTermsAndConditionsResp>,
) => transformTermsAndConditionsDto(response.terms_and_conditions)

export const transformInfoBoardPostResponse = (response: Response<GetInfoBoardPostResp>) =>
  transformInfoBoardPostDto(response.info_post)

export const transformNotificationLandingsResponse = ({
  notification_landing,
}: Response<GetNotificationLandingsResp>) => ({
  body: notification_landing.body,
  buttonTitle: notification_landing.button_title,
  buttonUrl: notification_landing.button_url,
  code: notification_landing.code,
  id: notification_landing.id,
  imageUrl: notification_landing.image_url,
  title: notification_landing.title,
})

export const transformMyOrdersResponse = (response: Response<MyOrdersResp>) => ({
  orders: response.my_orders.map(transformMyOrderDto),
  pagination: transformPaginationDto(response.pagination),
})

export const transformTermsAndConditionsContentResponse = (
  response: Response<GetTermsAndConditionsContentResp>,
) => transformTermsAndConditionsContentDto(response)

export const transformFaqEntryRecommendedFaqsResponse = ({
  faq_recommendations,
}: Response<FaqEntryRecommendedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!faq_recommendations.length) return null

  return faq_recommendations
}

export const transformFaqEntryRelatedFaqsResponse = ({
  related_faqs,
}: Response<FaqEntryRelatedFaqsResp>): Array<HelpCenterQuestionModel> | null => {
  if (!related_faqs.length) return null

  return related_faqs
}

export const transformBusinessAccountAddressesResponse = (
  response: Response<BusinessAccountAddressesResp>,
) => ({
  addresses: transformBusinessAccountAddressesDto(response.addresses),
})

export const transformBusinessAccountsTermsOfUse = (response: Response<MyOrdersResp>) => ({
  orders: response.my_orders.map(transformMyOrderDto),
  pagination: transformPaginationDto(response.pagination),
})

export const transformBusinessAccountsTermsOfUseResponse = (
  response: Response<BusinessAccountsTermsOfUseResp>,
) => transformBusinessAccountsTermsOfUseDto(response)

export const transformReferralShareResponse = ({
  share,
}: Response<ReferralShareResp>): ReferralShareModel => transformReferralShareDto(share)

export const transformUserItemsResponse = (
  response: Response<GetUserItemsResp>,
): Array<ProductItemModel> => transformItemDtosToProductItems(response.items)

export const transformBumpRecommendedItemsResponse = (
  response: Response<BumpRecommendedItemsResp>,
): Array<ProductItemModel> => transformItemDtosToProductItems(response.items)

export const transformReferralInviteInstructionsResponse = ({
  invite_instructions,
}: Response<ReferralInviteInstructionsResp>): ReferralInviteInstructionsModel =>
  transformReferralInviteInstructionsDto(invite_instructions)

export const transformProfileUserInfoResponse = (response: Response<ProfileUserInfoResp>) =>
  transformProfileUserInfoDto(response)

export const transformInSiteSeoContentResponse = ({
  data,
}: Response<InSiteSeoContentResp>): SeoContentModel => transformInSiteSeoContentDto(data.attributes)

export const transformCollectionsPlansPricingResponse = (
  response: Response<CollectionsPlansPricingResp>,
) => transformCollectionsPlansPricing(response.collection_pricing)

export const transformAvailableUserItemsForCollectionResponse = (
  response: Response<GetAvailableUserItemsForCollectionResp>,
): {
  items: Array<ProductItemModel>
  pagination: PaginationModel
} => ({
  items: transformCollectionItemDtosToProductItems(response.items),
  pagination: transformPaginationDto(response.pagination),
})

export const transformCollectionPolicyResponse = (response: Response<GetCollectionPolicyResp>) =>
  transformCollectionPolicy(response.policy)

export const transformCollectionsResponse = (response: Response<CollectionsResp>) =>
  transformCollections(response)

export const transformCollectionResponse = (response: Response<CollectionResp>) =>
  transformCollection(response.featured_collection)

export const transformItemResponse = (response: Response<ItemResp>) =>
  transformItemDto(response.item)

export const transformCreateReferrerResponse = (response: Response<CreateReferrerResp>) => ({
  referralInviteUrl: response.referral_invite_url,
  referrerId: response.referrer_id,
})

export const transformSellerOfferOptionsResponse = (
  response: Response<GetSellerOfferOptionsResp>,
) => transformSellerOfferOptionsDto(response.seller_offer_options)

export const transformCalculateDiscountResponse = (response: Response<CalculateDiscountResp>) =>
  transformDiscounts(response.discounts)

export const transformItemEscrowFeesResponse = (response: Response<GetItemEscrowFeesResp>) =>
  transformEscrowFees(response.escrow_fees)

export const transformVasEntryPointsResponse = (response: Response<GetVasEntryPointsResp>) =>
  transformVasEntryPoints(response.entry_points)

export const transformPrepareClosetPromotionOrderResponse = (
  response: Response<PromotedClosetOrderResp>,
) => transformClosetPromotionOrder(response.closet_promotion.closet_promotion_order)

export const transformGetClosetPromotionOrderResponse = (
  response: Response<GetClosetPromotionOrderResp>,
) => transformClosetPromotionOrder(response.closet_promotion_order)

export const transformSellerBadgesResponse = (response: Response<SellerBadgesResp>) =>
  response.badges.map(transformUserBadgeDto)

export const transformClosetPromotionsResponse = (response: Response<PromotedClosetsListResp>) =>
  transformClosets(response.promoted_closets)

export const transformBumpMinimumPriceResponse = (response: Response<PushUpMinimumPriceResp>) =>
  transformBumpMinimumPrice(response.minimum_price)

export const transformGetPriceEstimateWithFeesResponse = (
  response: Response<GetPriceEstimateWithFeesResponse>,
) => transformPriceEstimateWithFees(response)

export const transformPrepareBumpOrderResponse = (response: Response<PreparePushUpOrderResp>) =>
  transformPushUpOrder(response.push_up_order)

export const transformGetBumpOrderResponse = (response: Response<GetPushUpOrderResp>) =>
  transformPushUpOrder(response.push_up_order)

export const transformPushUpOptionsResponse = (response: Response<PushUpOptionResp>) =>
  transformPushUpsOptions(response)
