import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState as UiStateEnum } from 'constants/ui'
import { ErrorItem } from 'types/api'

import { CurrencyAmountDto, ItemUploadDto } from 'types/dtos'
import { PackageSizeModel } from 'types/models'

import { State, UiItem, PackagesUiState, UiState } from './types'
import { stateName } from './constants'

export const initialState: State = {
  packageSizes: {},
  ui: {
    transactionPackagesUi: {
      ids: [],
      packageSizes: { uiState: UiStateEnum.Idle, errors: null },
      packageSelection: { uiState: UiStateEnum.Idle, errors: null },
    },
    catalogPackagesUi: {
      ids: [],
      packageSizes: { uiState: UiStateEnum.Idle, errors: null },
    },
  },
  suggestedPackageSizeCode: undefined,
}

function setUiState(draft: UiItem, uiState: UiStateEnum) {
  draft.uiState = uiState
}

function setError(draft: UiItem, errors: Array<ErrorItem>) {
  setUiState(draft, UiStateEnum.Failure)

  draft.errors = errors
}

function setPackageSizes(
  draft: State,
  packageSizes: Array<PackageSizeModel>,
  isOfflineVerificationEligible?: boolean,
) {
  packageSizes.forEach(packageSize => {
    const { id } = packageSize

    draft.packageSizes[id] = packageSize
    draft.isOfflineVerificationEligible = isOfflineVerificationEligible
  })
}

function setPackageSizeIds(draft: PackagesUiState, packageSizes: Array<PackageSizeModel>) {
  draft.ids = packageSizes.map(packageSize => packageSize.id)
}

function setPackageSizesFlow(
  draft: State,
  uiStateTarget: keyof UiState,
  packageSizes: Array<PackageSizeModel>,
  isOfflineVerificationEligible?: boolean,
) {
  const uiState = draft.ui[uiStateTarget]
  setUiState(uiState.packageSizes, UiStateEnum.Success)

  uiState.packageSizes.errors = null

  setPackageSizes(draft, packageSizes, isOfflineVerificationEligible)
  setPackageSizeIds(uiState, packageSizes)
}

function setSuggestedPackageSizeCode(
  draft: State,
  uiStateTarget: keyof UiState,
  suggestedPackageSizeCode?: string,
) {
  const uiState = draft.ui[uiStateTarget]
  setUiState(uiState.packageSizes, UiStateEnum.Success)

  uiState.packageSizes.errors = null

  draft.suggestedPackageSizeCode = suggestedPackageSizeCode
}

const fetchTransactionPackageSizesRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  const {
    transactionPackagesUi: { packageSizes },
  } = draft.ui

  setUiState(packageSizes, UiStateEnum.Pending)
}

const fetchTransactionPackageSizesSuccess: CaseReducer<
  State,
  PayloadAction<{ packageSizes: Array<PackageSizeModel> }>
> = (draft, action) => {
  const { packageSizes } = action.payload

  setPackageSizesFlow(draft, 'transactionPackagesUi', packageSizes)
}

const fetchTransactionPackageSizesFailure: CaseReducer<
  State,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const {
    transactionPackagesUi: { packageSizes },
  } = draft.ui

  setError(packageSizes, action.payload.errors)
}

const fetchCatalogPackageSizesRequest: CaseReducer<
  State,
  PayloadAction<{ catalogId: number; brandId?: number; price?: CurrencyAmountDto }>
> = draft => {
  const {
    catalogPackagesUi: { packageSizes },
  } = draft.ui

  setUiState(packageSizes, UiStateEnum.Pending)
}

const fetchCatalogPackageSizesSuccess: CaseReducer<
  State,
  PayloadAction<{ packageSizes: Array<PackageSizeModel>; isOfflineVerificationEligible?: boolean }>
> = (draft, action) => {
  const { packageSizes, isOfflineVerificationEligible } = action.payload

  setPackageSizesFlow(draft, 'catalogPackagesUi', packageSizes, isOfflineVerificationEligible)
}

const fetchCatalogPackageSizesFailure: CaseReducer<
  State,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const {
    catalogPackagesUi: { packageSizes },
  } = draft.ui

  setError(packageSizes, action.payload.errors)
}

const fetchSuggestedPackageSizeCodeRequest: CaseReducer<
  State,
  PayloadAction<{ item: ItemUploadDto }>
> = draft => {
  const {
    catalogPackagesUi: { packageSizes },
  } = draft.ui

  setUiState(packageSizes, UiStateEnum.Pending)
}

const fetchSuggestedPackageSizeCodeSuccess: CaseReducer<
  State,
  PayloadAction<{
    suggestedPackageSizeCode?: string
  }>
> = (draft, action) => {
  const { suggestedPackageSizeCode } = action.payload

  setSuggestedPackageSizeCode(draft, 'catalogPackagesUi', suggestedPackageSizeCode)
}

const fetchSuggestedPackageSizeCodeFailure: CaseReducer<
  State,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const {
    catalogPackagesUi: { packageSizes },
  } = draft.ui

  setError(packageSizes, action.payload.errors)
}

const selectTransactionPackageSizeRequest: CaseReducer<
  State,
  PayloadAction<{
    transactionId: number
    packageSizeId: number
    customShipmentPrice: number | undefined
  }>
> = draft => {
  const {
    transactionPackagesUi: { packageSelection },
  } = draft.ui

  setUiState(packageSelection, UiStateEnum.Pending)
}

const selectTransactionPackageSizeSuccess: CaseReducer<State> = draft => {
  const {
    transactionPackagesUi: { packageSelection },
  } = draft.ui

  setUiState(packageSelection, UiStateEnum.Success)

  packageSelection.errors = null
}

const selectTransactionPackageSizeFailure: CaseReducer<
  State,
  PayloadAction<{ errors: Array<ErrorItem> }>
> = (draft, action) => {
  const {
    transactionPackagesUi: { packageSelection },
  } = draft.ui

  setError(packageSelection, action.payload.errors)
}

const packageSizesSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchTransactionPackageSizesRequest,
    fetchTransactionPackageSizesSuccess,
    fetchTransactionPackageSizesFailure,
    fetchCatalogPackageSizesRequest,
    fetchCatalogPackageSizesSuccess,
    fetchCatalogPackageSizesFailure,
    fetchSuggestedPackageSizeCodeRequest,
    fetchSuggestedPackageSizeCodeSuccess,
    fetchSuggestedPackageSizeCodeFailure,
    selectTransactionPackageSizeRequest,
    selectTransactionPackageSizeSuccess,
    selectTransactionPackageSizeFailure,
  },
})

export const { actions } = packageSizesSlice
export const plug = { [stateName]: packageSizesSlice.reducer }
export default packageSizesSlice.reducer
