import { PaymentProgressDetailsDto } from 'types/dtos'
import { PaymentProgressDetailsModel } from 'types/models'

export const transformProgressDetailsDto = (
  details: PaymentProgressDetailsDto,
): PaymentProgressDetailsModel => {
  const { status, title, exact_date, date, note } = details

  return {
    status,
    title,
    exactDate: exact_date,
    date,
    note,
  }
}
