import { createSelector } from '@reduxjs/toolkit'
import { omit } from 'lodash'

import { DocumentGroup, KybFormField } from 'constants/business-accounts'

import { SubmitPaymentsIdentityArgs } from 'types/api'

import { KybFieldName, stateName } from './constants'
import { State } from './types'

const localState = (state: { [stateName]: State }) => state[stateName]

export const getRegistration = createSelector(localState, ({ registration }) => registration)

export const getKybFormData = createSelector(localState, ({ kybFormData }) => kybFormData)

export const getBusinessDetails = createSelector(
  localState,
  ({ registration }) => registration.businessDetails,
)

export const getAddresses = createSelector(localState, ({ registration }) => registration.addresses)

export const getBusinessAddress = createSelector(
  localState,
  ({ registration }) => registration.addresses.businessAddress,
)

export const getProfile = createSelector(localState, ({ registration }) => registration.profile)

export const getBusinessRepresentative = createSelector(
  localState,
  ({ registration }) => registration.businessRepresentative,
)

export const getBusinessRepresentativeFirstName = createSelector(
  localState,
  ({ registration }) => registration.businessRepresentative.firstNames,
)

export const getBusinessRepresentativeLastName = createSelector(
  localState,
  ({ registration }) => registration.businessRepresentative.lastName,
)

export const getBusinessRepresentativeBirthdate = createSelector(
  localState,
  ({ registration }) => registration.businessRepresentative.birthday,
)

export const getUiState = createSelector(localState, ({ uiState }) => uiState)

export const getErrors = createSelector(localState, ({ errors }) => errors)

export const getLegalEntityUiState = createSelector(
  localState,
  ({ legalEntityUiState }) => legalEntityUiState,
)

export const getShowGeneralError = createSelector(
  localState,
  ({ showGeneralError }) => showGeneralError,
)

export const getBusinessAddressCity = createSelector(
  localState,
  ({ registration }) => registration.addresses.businessAddress.city,
)

export const getEntityType = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.entityType,
)

export const getIsPhoneNumberPersonal = createSelector(
  localState,
  ({ registration }) => registration.profile.isPhoneNumberPersonal,
)

export const getIsEmailPersonal = createSelector(
  localState,
  ({ registration }) => registration.profile.isEmailPersonal,
)

export const getLegalAddressCity = createSelector(
  localState,
  ({ registration }) => registration.addresses.legalRepresentativeAddress?.city,
)

export const getRegistrarName = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.registrarName,
)

export const getLegalCode = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.legalCode,
)

export const getLegalName = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.legalName,
)

export const getVat = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.vat,
)

export const getIsVatDisabled = createSelector(
  localState,
  ({ registration }) => registration.businessDetails.isVatDisabled,
)

export const getIsLegalRepresentativeUbo = createSelector(
  localState,
  ({ kybFormData }) => kybFormData.isLegalRepresentativeUbo,
)

export const getIdentityDocumentExpiryDate = createSelector(
  localState,
  ({ kybFormData }) => kybFormData.identityDocumentExpiryDate,
)

export const getUbos = createSelector(localState, ({ kybFormData }) => kybFormData.ubos)

export const getPaymentsIdentityConfiguration = createSelector(
  localState,
  ({ paymentsIdentityConfiguration }) => paymentsIdentityConfiguration,
)

export const getKybStatus = createSelector(
  localState,
  ({ paymentsIdentityConfiguration }) => paymentsIdentityConfiguration?.status,
)

export const getDocumentFieldConfigurations = createSelector(
  localState,
  ({ paymentsIdentityConfiguration }) => paymentsIdentityConfiguration?.documentFieldConfigurations,
)

export const getRequiredFields = createSelector(
  localState,
  ({ paymentsIdentityConfiguration }) => paymentsIdentityConfiguration?.requiredFields,
)

export const getIdentityDocumentConfiguration = createSelector(
  getDocumentFieldConfigurations,
  documentFieldConfigurations =>
    documentFieldConfigurations?.filter(
      ({ group }) => group === DocumentGroup.IdentityDocuments,
    )?.[0],
)

export const getSupportingDocumentsConfiguration = createSelector(
  getDocumentFieldConfigurations,
  documentFieldConfigurations =>
    documentFieldConfigurations?.filter(
      ({ group, options }) => group === DocumentGroup.SupportingDocuments && options.length === 1,
    ),
)

export const getIsSupportingDocumentRequired = createSelector(
  getSupportingDocumentsConfiguration,
  getRequiredFields,
  (documentFieldConfigurations, requiredFields) => {
    const supportingDocumentTypes = documentFieldConfigurations?.map(
      ({ options }) => options?.[0].type,
    )

    return !!requiredFields?.filter(field => supportingDocumentTypes?.includes(field))?.length
  },
)

export const getKybData = createSelector(
  getKybFormData,
  getIsLegalRepresentativeUbo,
  getUbos,
  getRequiredFields,
  (kybFormData, isLegalRepresentativeUbo, ubos, requiredFields): SubmitPaymentsIdentityArgs => {
    const isUboRequired = requiredFields?.includes(KybFieldName.Ubos)

    // Expiry date field will not be sent to MangoPay, we save it to our database instead.
    const mangoPayKybData = omit(kybFormData, KybFormField.IdentityDocumentExpiryDate)

    if (isUboRequired && isLegalRepresentativeUbo) {
      const firstUbo = ubos?.[0] || {
        nationality: '',
        birthplaceCountry: '',
        birthplaceCity: '',
      }

      return {
        ...mangoPayKybData,
        ubos: [{ ...firstUbo, firstName: '', lastName: '', birthday: '', address: {} }],
        // The four blank fields will be filled in through the saga call.
      }
    }

    return mangoPayKybData
  },
)
