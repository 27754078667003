import { find, filter, intersection } from 'lodash'
import { createSelector } from 'reselect'

import { ConversationMessageType } from 'constants/conversation'
import { ApplicableTransactionAction, AdditionalTransactionAction } from 'constants/transaction'
import { Translation } from 'constants/translation'
import { PhoneRequirement } from 'constants/shipping-contact'

import * as shippingContactSelectors from 'state/shipping-contact/selectors'

import { stateName } from './constants'
import { AllState, Message } from './types'

const localState = (state: AllState) => state[stateName]

export const getMessageById = (state: AllState, id: number) => {
  const messages = filter(
    localState(state).conversation?.messages,
    message => message.entityType === ConversationMessageType.Message,
  ).map(message => message.message as Message)

  return find(messages, { id })
}

export const getUiState = createSelector(localState, ({ ui }) => ui)

export const getIsTranslated = (state: AllState) => localState(state).conversation?.isTranslated

export const getConversation = (state: AllState) => localState(state).conversation

export const getConversationUiState = (state: AllState) =>
  localState(state).ui.getConversation.uiState

export const getConversationUi = (state: AllState) => localState(state).ui.getConversation

export const getTransaction = (state: AllState) => localState(state).transaction

export const getConversationTransaction = (state: AllState) =>
  localState(state).conversation?.transaction

export const getTransactionUiState = (state: AllState) =>
  localState(state).ui.getTransaction.uiState

export const getModeratedItems = (state: AllState) => localState(state).conversation?.moderatedItems

export const getTransactionId = (state: AllState) => localState(state).conversation?.transactionId

export const getSuggestedMessages = (state: AllState) =>
  localState(state).conversation?.suggestedMessages

export const getConversationId = (state: AllState) => localState(state).conversation?.id

export const getOppositeUser = (state: AllState) => localState(state).conversation?.oppositeUser

export const getOppositeUserId = (state: AllState) =>
  localState(state).conversation?.oppositeUser?.id

export const getMessages = (state: AllState) => localState(state).conversation?.messages

export const getTranslationType = (state: AllState) =>
  localState(state).conversation?.translationType ?? Translation.None

export const getConversationOrderItemIds = (state: AllState) =>
  localState(state).conversation?.transaction?.order.itemIds

export const getIsReadByOppositeUser = (state: AllState) =>
  localState(state).conversation?.isReadByOppositeUser

export const getIsSidebarOpen = (state: AllState) => localState(state).ui.isSidebarOpen

export const getSendMessageUi = (state: AllState) => localState(state).ui.sendMessage

export const getSendMessageUiState = createSelector(getSendMessageUi, ui => ui.uiState)

export const getSendMessageUiError = createSelector(getSendMessageUi, ui => ui.error)

export const getTrackingJourneySummary = (state: AllState) => localState(state).tracking

export const getTrackingJourneySummaryUi = (state: AllState) =>
  localState(state).ui.trackingJourneySummary

export const getTrackingJourneySummaryUiState = createSelector(
  getTrackingJourneySummaryUi,
  ui => ui.uiState,
)

export const getShipmentInstructions = (state: AllState) => localState(state).shipmentInstructions

export const getShipmentInstructionsUi = createSelector(getUiState, ui => ui.shipmentInstructions)

export const getShipmentInstructionsUiState = createSelector(
  getShipmentInstructionsUi,
  ui => ui.uiState,
)

export const getFirstTransactionItem = (state: AllState) => localState(state).conversation?.item

export const getFirstTransactionItemPhoto = createSelector(
  getFirstTransactionItem,
  item => item?.photo,
)

export const getComplaintId = createSelector(
  getTransaction,
  transaction => transaction?.complaintId,
)

export const getShippingLabelUrl = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.labelUrl,
)

export const getSellerAddressId = createSelector(
  getTransaction,
  transaction => transaction?.seller?.defaultAddress?.id,
)

export const getTransactionShipmentPrice = createSelector(
  getTransaction,
  transaction => transaction?.buyerDebit?.shipmentPrice,
)

export const getTransactionShipmentCurrency = createSelector(
  getTransaction,
  transaction => transaction?.buyerDebit?.currency,
)

export const getTransactionPrice = createSelector(
  getConversationTransaction,
  transaction => transaction?.offer.price,
)

// TODO: remove this selector and dependency on it.
export const getTransactionCurrency = createSelector(
  getConversationTransaction,
  transaction => transaction?.offer.price.currencyCode,
)

export const getApplicableTransactionActions = createSelector(
  getConversationTransaction,
  transaction => {
    if (!transaction) return undefined

    return intersection(
      transaction.availableActions,
      Object.values(ApplicableTransactionAction),
    ) as Array<ApplicableTransactionAction>
  },
)

export const getAditionalTransactionActions = createSelector(
  getConversationTransaction,
  transaction => {
    if (!transaction) return undefined

    return intersection(
      transaction.availableActions,
      Object.values(AdditionalTransactionAction),
    ) as Array<AdditionalTransactionAction>
  },
)

export const getTransactionStatus = createSelector(
  getConversationTransaction,
  transaction => transaction?.status,
)

export const getTransactionShipmentDefaultAddress = createSelector(
  getTransaction,
  transaction => transaction?.seller?.defaultAddress,
)

export const getTransactionShipmentBuyerAddress = createSelector(
  getTransaction,
  transaction => transaction?.buyer.defaultAddress,
)

export const getSellerCountryCode = createSelector(
  getTransaction,
  transaction => transaction?.seller?.countryCode,
)

export const getEnterTrackingCodeUi = createSelector(getUiState, ui => ui.enterTrackingCode)

export const getEnterTrackingCodeUiState = createSelector(getEnterTrackingCodeUi, ui => ui.uiState)

export const getEnterTrackingCodeUiError = createSelector(getEnterTrackingCodeUi, ui => ui.error)

export const getMarkAsShippedUi = createSelector(getUiState, ui => ui.markAsShipped)

export const getMarkAsShippedUiState = createSelector(getMarkAsShippedUi, ui => ui.uiState)

export const getMarkAsShippedUiError = createSelector(getMarkAsShippedUi, ui => ui.error)

export const getShipmentInstructionsCarrier = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.carrier,
)

export const getDropOffTypeMethods = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.packageType.dropOffTypes,
)

export const getSelectedDropOffTypeMethod = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.dropOffType,
)

export const getSelectedDropOffTypeUiState = createSelector(getUiState, ui => ui.selectDropOffType)

export const getSelectedDropOffTypeId = createSelector(
  getSelectedDropOffTypeUiState,
  ui => ui.selectedDropOffTypeId,
)

export const getDropOffTypeSubmitUiState = createSelector(
  getSelectedDropOffTypeUiState,
  ui => ui.isSubmitEnabled,
)

export const getRecipientId = (state: AllState) => localState(state).recipientId

export const getSelectedDropOffTypeMethodUi = createSelector(getUiState, ui => ui.selectDropOffType)

export const getSelectedDropOffTypeMethodUiState = createSelector(
  getSelectedDropOffTypeMethodUi,
  ui => ui.uiState,
)

export const getSelectedDropOffTypeMethodUiError = createSelector(
  getSelectedDropOffTypeMethodUi,
  ui => ui.error,
)

export const getLabelFormatDetails = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.labelFormatDetails,
)

export const getDigitalLabel = (state: AllState) => localState(state).digitalLabel

export const getCreateOfferUi = createSelector(getUiState, ui => ui.createOffer)

export const getCreateOfferUiState = createSelector(getCreateOfferUi, ui => ui.uiState)

export const getCreateOfferUiError = createSelector(getCreateOfferUi, ui => ui.error)

export const getAcceptOfferUi = createSelector(getUiState, ui => ui.acceptOffer)

export const getAcceptOfferUiState = createSelector(getAcceptOfferUi, ui => ui.uiState)

export const getAcceptOfferUiError = createSelector(getAcceptOfferUi, ui => ui.error)

export const getRejectOfferUi = createSelector(getUiState, ui => ui.rejectOffer)

export const getRejectOfferUiState = createSelector(getRejectOfferUi, ui => ui.uiState)

export const getRejectOfferUiError = createSelector(getRejectOfferUi, ui => ui.error)

export const getCourierPickUpDatesUi = createSelector(
  getUiState,
  ui => ui.courierPickUpDatesSelectionModal,
)

export const getCourierPickUpDatesUiState = createSelector(
  getCourierPickUpDatesUi,
  ui => ui.uiState,
)

export const getCourierPickUpDatesUiError = createSelector(getCourierPickUpDatesUi, ui => ui.error)

export const getOfferRequestOptions = (state: AllState) => localState(state).offerRequestOptions

export const getOfferPriceSuggestions = createSelector(
  getOfferRequestOptions,
  offerRequestOptions => offerRequestOptions?.offerSuggestions || [],
)

export const getPreselectedOfferPriceSuggestion = createSelector(
  getOfferPriceSuggestions,
  offerPriceSuggestions => offerPriceSuggestions[0],
)

export const getSelectedOfferPriceSuggestionOption = (state: AllState) =>
  localState(state).selectedOfferPriceSuggestionOption

export const getOfferRequestSessionId = (state: AllState) => localState(state).offerRequestSessionId

export const getTransactionUserSide = createSelector(
  getConversationTransaction,
  transaction => transaction?.userSide,
)

export const getSellerPhoneRequirement = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.packageType?.sellerPhoneRequirement,
)

export const getBuyerPhoneRequirement = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.packageType.buyerPhoneRequirement,
)

export const getIsSellerShippingContactDisabled = createSelector(
  getSellerPhoneRequirement,
  requirement => !requirement || requirement === PhoneRequirement.Disabled,
)

export const getIsBuyerShippingContactDisabled = createSelector(
  getBuyerPhoneRequirement,
  requirement => !requirement || requirement === PhoneRequirement.Disabled,
)

export const getIsSellerShippingContactCompleted = createSelector(
  getSellerPhoneRequirement,
  shippingContactSelectors.getSellerPhoneNumber,
  (requirement, sellerPhoneNumber) =>
    !(requirement === PhoneRequirement.Mandatory && !sellerPhoneNumber),
)

export const getIsBuyerShippingContactCompleted = createSelector(
  getBuyerPhoneRequirement,
  shippingContactSelectors.getBuyerPhoneNumber,
  (requirement, buyerPhoneNumber) =>
    !(requirement === PhoneRequirement.Mandatory && !buyerPhoneNumber),
)

export const getIsReplyAllowed = (state: AllState) =>
  !!localState(state).conversation?.isReplyAllowed

export const getShipmentId = createSelector(
  getConversationTransaction,
  transaction => transaction?.shipment?.id,
)

export const getShipmentStatus = createSelector(
  getConversationTransaction,
  transaction => transaction?.shipment?.status,
)

export const getShipmentLabelType = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.shipmentLabelType,
)

export const getActiveGenerateLabelModal = (state: AllState) => localState(state).activeModal

export const getDropOffPrintIconDetails = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.dropOffType?.dropOffTypeMeta.dropOffPrintIcon,
)

export const getIsPickUpDateShown = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.dropOffType?.pickUpDateShown,
)

export const getIsPickUpTimeShown = createSelector(
  getShipmentInstructions,
  shipmentInstructions => shipmentInstructions?.dropOffType?.pickUpTimeShown,
)

export const getReturnShippingOption = (state: AllState) => localState(state).returnShippingOption

export const getSelectedCourierPickUpDate = createSelector(
  getCourierPickUpDatesUi,
  ui => ui.selectedCourierPickUpDate,
)

export const getSelectedCourierPickUpTime = createSelector(
  getCourierPickUpDatesUi,
  ui => ui.selectedCourierPickUpTime,
)

export const getCourierPickUpDates = (state: AllState) => localState(state).courierPickupDates

export const getDigitalLabelTypes = createSelector(
  getTransaction,
  transaction => transaction?.shipment?.digitalLabelTypes,
)

export const getDigitalLabelTypesUi = createSelector(getUiState, ui => ui.selectLabelType)

export const getDigitalLabelTypeId = createSelector(
  getDigitalLabelTypesUi,
  ui => ui.selectedLabelType,
)

export const getDigitalLabelTypesUiState = createSelector(
  getDigitalLabelTypesUi,
  ui => ui.isSubmitEnabled,
)

export const getIsHarassmentWarningModalOpen = (state: AllState) =>
  localState(state).ui.harassmentWarning.isModalOpen

export const getWasHarassmentWarningOpen = (state: AllState) =>
  localState(state).ui.harassmentWarning.wasModalOpen

export const getScoredHarassmentMessage = (state: AllState) =>
  localState(state).ui.harassmentWarning.scoredMessage

export const getScoredHarassmentMessageCorrelationId = (state: AllState) =>
  localState(state).ui.harassmentWarning.scoredHarassmentMessageCorrelationId

export const getIsEmailSharingModalVisible = (state: AllState) =>
  localState(state).ui.isEmailSharingModalVisible

export const getShippingInstructions = (state: AllState) => localState(state).shippingInstructions

export const getIsItemVerified = createSelector(
  getConversationTransaction,
  transaction => transaction?.isItemVerified,
)

export const getTransactionOfflineVerification = createSelector(
  getTransaction,
  transaction => transaction?.offlineVerification,
)

export const getConversationTransactionId = createSelector(
  getTransaction,
  transaction => transaction?.id,
)

export const getIsFirstTimeListerEducationModalVisible = (state: AllState) =>
  localState(state).ui.isFirstTimeListerEducationModalVisible

export const getCourierPickUpDate = (state: AllState) => localState(state).selectedCourierPickUpDate

export const getCourierPickUpTime = (state: AllState) => localState(state).selectedCourierPickUpTime

export const getIsConversationDeletionRestricted = (state: AllState) =>
  localState(state).conversation?.isDeletionRestricted

export const getCollectionDatesUi = createSelector(getUiState, ui => ui.collectionDates)

export const getCollectionDatesUiState = createSelector(getCollectionDatesUi, ui => ui.uiState)

export const getCollectionDatesUiError = createSelector(getCollectionDatesUi, ui => ui.error)

export const getCollectionDates = (state: AllState) => localState(state).collectionDates

export const getSelectedCollectionDate = createSelector(
  getCollectionDatesUi,
  ui => ui.selectedCollectionDate,
)

export const getSelectedCollectionTime = createSelector(
  getCollectionDatesUi,
  ui => ui.selectedCollectionTime,
)

export const getAvailableCollectionTime = createSelector(
  getCollectionDatesUi,
  ui => ui.availableCollectionTimes,
)

export const getAvailableCollectionDates = createSelector(
  getCollectionDatesUi,
  ui => ui.availableCollectionDates,
)

export const getConfirmedCollectionDate = (state: AllState) =>
  localState(state).selectedCollectionDate

export const getConfirmedCollectionTime = (state: AllState) =>
  localState(state).selectedCollectionTime

export const getLabelOptions = (state: AllState) => localState(state).labelOptions

export const getLabelOptionsUi = createSelector(getUiState, ui => ui.labelOptions)

export const getLabelOptionsUiState = createSelector(getLabelOptionsUi, ui => ui.uiState)

export const getLabelOptionsUiError = createSelector(getLabelOptionsUi, ui => ui.error)

export const getShippingOrderId = createSelector(
  getConversationTransaction,
  transaction => transaction?.shippingOrderId,
)

export const getIsTransactionBundle = createSelector(
  getConversationTransaction,
  transaction => transaction?.isBundle,
)

export const getShippingOrderPackageSizes = (state: AllState) =>
  localState(state).shippingOrderPackageSizes

export const getShippingOrderPackageSizesUi = createSelector(
  getUiState,
  ui => ui.shippingOrderPackageSizes,
)

export const getShippingOrderPackageSizesUiState = createSelector(
  getShippingOrderPackageSizesUi,
  ui => ui.uiState,
)

export const getShippingOrderPackageSizesUiError = createSelector(
  getShippingOrderPackageSizesUi,
  ui => ui.error,
)

export const getSelectedPackageSizeId = createSelector(
  getShippingOrderPackageSizesUi,
  ui => ui.selectedPackageSizeId,
)

export const getSubmittedSelectedPackageSizeId = createSelector(
  getShippingOrderPackageSizesUi,
  ui => ui.submittedSelectedPackageSizeId,
)

export const getIsPackageSizeValidationVisible = createSelector(
  getShippingOrderPackageSizesUi,
  ui => ui.isPackageSizeValidationVisible,
)
