import { CollectionDto } from 'types/dtos'
import { CollectionModel } from 'types/models'

import { transformCollectionItemDtosToProductItems } from './product-item'

export const transformCollection = (dto: CollectionDto): CollectionModel => ({
  discount: dto.discount,
  id: dto.id,
  isActive: dto.is_active,
  title: dto.title,
  items: transformCollectionItemDtosToProductItems(dto.items),
})
