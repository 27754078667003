import type { IncomingMessage } from 'http'

import type { NextPage } from 'next'
import { NextPageContext } from 'next/types'
import type { ErrorProps } from 'next/error'
import NextErrorComponent from 'next/error'
import { Span } from 'dd-trace'

import { serverSide } from 'libs/utils/environment'
import { logError } from 'libs/utils/window'
import { NEXT_HEADERS } from 'constants/header'

import { clearCapturedHeaders, getCapturedHeader } from '../libs/server-utils/captured-headers'

const PAGE_ERROR_TRACKING_PATHS = [/next\/pages/, /^\.\/pages/, /^\.\.app/]
const INGORED_PAGE_ERROR_PATHS = [/.*app\.tsx$/]

function isPageStackTrace(stack: string): boolean {
  const [, errorSourceLine] = stack.split('\n')
  const matches = /\((?:.*:\/\/\/?)?(.+):\d+:\d+\)/.exec(errorSourceLine)
  if (!matches) return false

  const [, filePath] = matches

  return (
    PAGE_ERROR_TRACKING_PATHS.some(path => path.test(filePath)) &&
    !INGORED_PAGE_ERROR_PATHS.every(path => path.test(filePath))
  )
}

async function capturePageError(req: IncomingMessage | undefined, error: Error) {
  if (!req) return

  const nextOutput = getCapturedHeader(req.headers, NEXT_HEADERS.X_INVOKE_OUTPUT)
  if (!nextOutput) return

  if (!error.stack || !isPageStackTrace(error.stack)) return

  const { metrics } = await import('../libs/server-utils/metrics')

  metrics.pageErrorTotal.inc({ next_output: nextOutput })
}

async function ddRootSpanErrorFix(error: Error) {
  const ddTracer = (await import('dd-trace')).tracer
  // Workaround for https://github.com/DataDog/dd-trace-js/issues/723
  const spanContext = ddTracer.scope().active()?.context()
  // @ts-expect-error accessing datadog internals
  // eslint-disable-next-line no-underscore-dangle
  const rootSpan: Span | undefined = spanContext?._trace.started[0]

  if (rootSpan) {
    rootSpan.addTags({
      'error.type': error.name,
      'error.msg': error.message,
      'error.stack': error.stack,
    })
  }
}

async function handleErrorServerSide(context: NextPageContext<any>) {
  if (context.err) {
    await Promise.all([capturePageError(context.req, context.err), ddRootSpanErrorFix(context.err)])
  }

  if (context.req) clearCapturedHeaders(context.req.headers)
}

const CustomErrorComponent: NextPage<ErrorProps> = ({ statusCode }) => {
  return <NextErrorComponent statusCode={statusCode} />
}

CustomErrorComponent.getInitialProps = async context => {
  if (serverSide) {
    await handleErrorServerSide(context)
  } else if (context.err) {
    logError(context.err)
  } else {
    logError(new Error('Error page rendered without error'), { extra: context.pathname })
  }

  return NextErrorComponent.getInitialProps(context)
}

export default CustomErrorComponent
