import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'

import { actions } from './slice'
import { transformPayout, transformPayoutConfiguration } from './transformers'

export function* getPayout({
  payload: { userId, payoutId },
}: ReturnType<typeof actions.getPayoutRequest>) {
  const response = yield* call(api.getPayout, userId, payoutId)

  if ('errors' in response) {
    yield put(actions.getPayoutFailure())

    return
  }

  yield put(
    actions.getPayoutSuccess({
      payout: transformPayout(response.payout),
    }),
  )
}

export function* getPayoutConfiguration({
  payload: { userId },
}: ReturnType<typeof actions.getPayoutConfigurationRequest>) {
  const response = yield* call(api.getPayoutConfiguration, userId)

  if ('errors' in response) {
    yield put(actions.getPayoutConfigurationFailure())

    return
  }

  yield put(
    actions.getPayoutConfigurationSuccess({
      payoutConfiguration: transformPayoutConfiguration(response.payout),
    }),
  )
}

export default function* saga() {
  yield takeLatest(actions.getPayoutRequest.type, getPayout)
  yield takeLatest(actions.getPayoutConfigurationRequest.type, getPayoutConfiguration)
}
