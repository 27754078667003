import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'
import { remove } from 'lodash'
import { arrayMove } from '@dnd-kit/sortable'

import { ErrorItem } from 'types/api'
import {
  PhotoTipModel,
  SizeGroupModel,
  VideoGameRatingModel,
  MediaUploadPhotoModel,
  BrandModel,
  SizeModel,
  ItemEditModel,
  ItemPriceSuggestionsModel,
  ItemAuthenticityModalModel,
  BookDetailsModel,
  ItemUploadConfigurationModel,
} from 'types/models'
import { PackageSizeShippingOptionModel } from 'types/models/shipping-option'
import { actions as mediaUploadActions } from 'state/media-upload/slice'
import { UiState as UiStateEnum } from 'constants/ui'
import { initialEntityStructure, setNormalizedData } from 'libs/utils/redux'

import { AdditionalAttributeModel, DynamicAttributeModel } from 'types/models/dynamic-attribute'

import { stateName, FieldName, IsbnValidity, ItemStatus } from './constants'
import {
  UiState,
  State,
  ItemAttributes,
  ItemFormDataState,
  CatalogSelectType,
  ColorSelectType,
  StatusSelectType,
  DynamicAttribute,
} from './types'

const initialItemAttributes: ItemAttributes = {
  id: null,
  assignedPhotos: [],
  catalogId: null,
  colorIds: [],
  title: '',
  description: '',
  statusId: null,
  sizeId: null,
  price: null,
  isUnisex: false,
  isPromoted: false,
  brandId: null,
  brandTitle: null,
  isbn: null,
  packageSize: {
    id: null,
    custom: {
      domestic: null,
      international: null,
    },
  },
  catalog: {
    attributes: {
      byName: {},
      names: [],
    },
  },
  videoGameRatingId: null,
  alertType: null,
  isPushUpChecked: false,
  feedbackId: null,
  measurementLength: null,
  measurementWidth: null,
  manufacturer: null,
  manufacturerLabel: null,
}

const initialFormDataState: ItemFormDataState = {
  title: {
    isEditedByUser: false,
    suggested: [],
  },
  brands: {
    ...initialEntityStructure,
    suggested: initialEntityStructure,
    isLuxury: null,
  },
  colors: {
    ...initialEntityStructure,
    suggested: initialEntityStructure,
  },
  catalogs: {
    ...initialEntityStructure,
    initialIds: [],
    suggested: [],
  },
  sizes: {
    ...initialEntityStructure,
    suggested: initialEntityStructure,
  },
  statuses: initialEntityStructure,
  shippingOptions: [],
  sizeGroups: initialEntityStructure,
  photoTips: initialEntityStructure,
  videoGameRatings: initialEntityStructure,
  priceSuggestions: {
    min: null,
    max: null,
    currency: null,
    similarItemsAbTest: null,
  },
  dynamicAttributes: {
    byName: {},
  },
  additionalAttributes: {
    byName: {},
  },
  bookDetails: null,
}

const initialUiState: UiState = {
  uiState: UiStateEnum.Pending,
  errors: {
    byName: {},
    names: [],
  },
  title: {
    uiState: UiStateEnum.Idle,
  },
  isLuxuryItemModalOpen: false,
  showMissingPostalCode: false,
  shippingOptions: {
    uiState: UiStateEnum.Idle,
    isModalOpen: false,
  },
  isFormDisabled: false,
  price: {
    hasSimilarItems: false,
    isSimilarItemsModalOpen: false,
    displayType: null,
  },
  color: {
    uiState: UiStateEnum.Idle,
  },
  containsWebPhoto: false,
  isOfflineVerificationModalOpen: false,
  isAuthenticityProofModalOpen: false,
  authenticityModal: {
    isOpen: false,
    content: null,
  },
  canBumpItem: false,
  dynamicAttributes: {
    uiState: UiStateEnum.Idle,
  },
  isbn: {
    uiState: UiStateEnum.Idle,
  },
}

export const initialState: State = {
  previousAttributes: initialItemAttributes,
  attributes: initialItemAttributes,
  formData: initialFormDataState,
  ui: initialUiState,
  isOfflineVerificationEligible: false,
  configuration: {
    catalogFieldsOrder: [],
    maxShippingPrice: null,
    currency: '',
    abTests: {},
  },
  isPackageSizeSelectedManually: false,
  isSecondDayLister: false,
  isPreviousLister: false,
}

function deleteError(draft: State, fieldName: FieldName) {
  if (draft.ui.errors.byName[fieldName]) {
    delete draft.ui.errors.byName[fieldName]
  }

  remove(draft.ui.errors.names, name => name === fieldName)
}

const setInitialItemData: CaseReducer<State, PayloadAction<{ item: ItemEditModel }>> = (
  draft,
  action,
) => {
  const { item } = action.payload

  draft.attributes.id = item.id
  draft.attributes.title = item.title || ''
  draft.attributes.description = item.description || ''
  draft.attributes.isUnisex = item.isUnisex
  draft.attributes.catalogId = item.catalogId
  draft.attributes.statusId = item.statusId
  draft.attributes.sizeId = item.sizeId
  draft.attributes.colorIds = item.colorIds
  draft.attributes.price = item.price
  draft.attributes.brandId = item.brandId
  draft.attributes.brandTitle = item.brand?.title || null
  draft.attributes.isPromoted = item.isPromoted
  draft.attributes.isbn = item.isbn
  draft.attributes.packageSize.id = item.packageSizeId
  draft.attributes.packageSize.custom.domestic = item.domesticShipmentPrice
  draft.attributes.packageSize.custom.international = item.internationalShipmentPrice
  draft.attributes.videoGameRatingId = item.videoGameRatingId
  draft.attributes.measurementLength = item.measurementLength
  draft.attributes.measurementWidth = item.measurementWidth
  draft.attributes.manufacturer = item.manufacturer
  draft.attributes.manufacturerLabel = item.manufacturerLabel
  draft.attributes.alertType = item.itemAlert?.itemAlertType || null
  draft.previousAttributes.id = item.id
  draft.previousAttributes.title = item.title || ''
  draft.previousAttributes.description = item.description || ''
  draft.previousAttributes.catalogId = item.catalogId
  draft.previousAttributes.statusId = item.statusId
  draft.previousAttributes.sizeId = item.sizeId
  draft.previousAttributes.colorIds = item.colorIds
  draft.previousAttributes.price = item.price
  draft.previousAttributes.brandId = item.brandId
  draft.previousAttributes.brandTitle = item.brand?.title || null
  draft.previousAttributes.isbn = item.isbn
  draft.previousAttributes.packageSize.id = item.packageSizeId
  draft.previousAttributes.packageSize.custom.domestic = item.domesticShipmentPrice
  draft.previousAttributes.packageSize.custom.international = item.internationalShipmentPrice
  draft.previousAttributes.videoGameRatingId = item.videoGameRatingId
  draft.previousAttributes.measurementLength = item.measurementLength
  draft.previousAttributes.measurementWidth = item.measurementWidth
  draft.previousAttributes.alertType = item.itemAlert?.itemAlertType || null
  draft.formData.brands.isLuxury = item.brand?.isLuxury || null
  draft.formData.bookDetails = item.bookDetails
  draft.ui.canBumpItem = item.canPushUp
}

const setCurrency: CaseReducer<State, PayloadAction<{ currency: string }>> = (draft, action) => {
  draft.configuration.currency = action.payload.currency
}

const setStatusId: CaseReducer<
  State,
  PayloadAction<{ id: number | null; itemStatus: ItemStatus }>
> = (draft, action) => {
  draft.attributes.statusId = action.payload.id
  deleteError(draft, FieldName.Status)
}

const setSizeId: CaseReducer<
  State,
  PayloadAction<{ id: number | null; itemStatus: ItemStatus }>
> = (draft, action) => {
  draft.attributes.sizeId = action.payload.id
  deleteError(draft, FieldName.Size)
}

const setCatalogId: CaseReducer<
  State,
  PayloadAction<{ id: number | null; itemStatus: ItemStatus }>
> = (draft, action) => {
  draft.attributes.catalogId = action.payload.id
  deleteError(draft, FieldName.Catalog)
}

const setColorIds: CaseReducer<
  State,
  PayloadAction<{ ids: Array<number>; itemStatus: ItemStatus }>
> = (draft, action) => {
  const { ids } = action.payload

  draft.attributes.colorIds = ids
  deleteError(draft, FieldName.Color)
}

const toggleIsUnisex: CaseReducer<State> = draft => {
  draft.attributes.isUnisex = !draft.attributes.isUnisex
}

const setBrand: CaseReducer<
  State,
  PayloadAction<{
    id: number | null
    title: string | null
    isLuxury: boolean
    itemStatus: ItemStatus
  }>
> = (draft, action) => {
  draft.attributes.brandId = action.payload.id
  draft.attributes.brandTitle = action.payload.title
  draft.formData.brands.isLuxury = action.payload.isLuxury
  deleteError(draft, FieldName.Brand)
}

const setTitle: CaseReducer<State, PayloadAction<{ title: string; isEditedByUser: boolean }>> = (
  draft,
  action,
) => {
  draft.attributes.title = action.payload.title
  draft.formData.title.isEditedByUser = action.payload.isEditedByUser
  deleteError(draft, FieldName.Title)
}

const setPrice: CaseReducer<State, PayloadAction<{ price: number | null }>> = (draft, action) => {
  draft.attributes.price = action.payload.price
  deleteError(draft, FieldName.Price)
}

const setDescription: CaseReducer<State, PayloadAction<{ description: string }>> = (
  draft,
  { payload },
) => {
  draft.attributes.description = payload.description
  deleteError(draft, FieldName.Description)
}

const setIsAuthenticityProofModalOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isAuthenticityProofModalOpen = action.payload.isOpen
}

const setAuthenticityModalContent: CaseReducer<
  State,
  PayloadAction<{ content: ItemAuthenticityModalModel | null }>
> = (draft, action) => {
  draft.ui.authenticityModal.content = action.payload.content
}

const setIsAuthenticityModalOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.authenticityModal.isOpen = action.payload.isOpen
}

const setConfiguration: CaseReducer<
  State,
  PayloadAction<{ configuration: ItemUploadConfigurationModel }>
> = (draft, { payload: { configuration } }) => {
  draft.configuration.catalogFieldsOrder = configuration.catalogGroupFields
  draft.configuration.maxShippingPrice = configuration.maxCustomShippingPrice
  draft.configuration.abTests = configuration.abTests
}

const setIsOfflineVerificationEligible: CaseReducer<State, PayloadAction<{ eligible: boolean }>> = (
  draft,
  action,
) => {
  draft.isOfflineVerificationEligible = action.payload.eligible
}

const setIsOfflineVerificationModalOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isOfflineVerificationModalOpen = action.payload.isOpen
}

const setFieldErrors: CaseReducer<State, PayloadAction<{ fieldErrors: Array<ErrorItem> }>> = (
  draft,
  action,
) => {
  const { fieldErrors } = action.payload

  fieldErrors.forEach(error => {
    draft.ui.errors.byName[error.field] = error.value
  })

  draft.ui.errors.names = fieldErrors.map(error => error.field)
}

const setFieldError: CaseReducer<State, PayloadAction<{ fieldError: ErrorItem }>> = (
  draft,
  action,
) => {
  const { fieldError } = action.payload

  draft.ui.errors.byName[fieldError.field] = fieldError.value

  if (draft.ui.errors.names.includes(fieldError.field)) return

  draft.ui.errors.names.push(fieldError.field)
}

const removeFieldError: CaseReducer<State, PayloadAction<{ fieldName: FieldName }>> = (
  draft,
  action,
) => {
  deleteError(draft, action.payload.fieldName)
}

const setPhotoTips: CaseReducer<State, PayloadAction<{ photoTips: Array<PhotoTipModel> }>> = (
  draft,
  action,
) => {
  const { photoTips } = action.payload

  setNormalizedData(draft.formData.photoTips, photoTips)
}

const setSizeGroups: CaseReducer<State, PayloadAction<{ sizeGroups: Array<SizeGroupModel> }>> = (
  draft,
  action,
) => {
  const { sizeGroups } = action.payload

  setNormalizedData(draft.formData.sizeGroups, sizeGroups)
}

const toggleIsShippingOptionsModalOpen: CaseReducer<State> = draft => {
  draft.ui.shippingOptions.isModalOpen = !draft.ui.shippingOptions.isModalOpen
}

const setIsLuxuryItemModalOpen: CaseReducer<State, PayloadAction<{ isOpen: boolean }>> = (
  draft,
  action,
) => {
  draft.ui.isLuxuryItemModalOpen = action.payload.isOpen
}

const showMissingPostalCode: CaseReducer<State> = draft => {
  draft.ui.showMissingPostalCode = true
}

const hideMissingPostalCode: CaseReducer<
  State,
  PayloadAction<{ resubmitItem: boolean } | undefined>
> = draft => {
  draft.ui.showMissingPostalCode = false
}

const fetchShippingOptionsRequest: CaseReducer<State> = draft => {
  draft.ui.shippingOptions.uiState = UiStateEnum.Pending
}

const fetchShippingOptionsFailure: CaseReducer<State> = draft => {
  draft.ui.shippingOptions.uiState = UiStateEnum.Failure
}

const fetchShippingOptionsSuccess: CaseReducer<
  State,
  PayloadAction<{ shippingOptions: Array<PackageSizeShippingOptionModel> }>
> = (draft, action) => {
  const { shippingOptions } = action.payload

  draft.formData.shippingOptions = shippingOptions
  draft.ui.shippingOptions.uiState = UiStateEnum.Success
}

const setUiState: CaseReducer<State, PayloadAction<{ uiState: UiStateEnum }>> = (draft, action) => {
  const { uiState } = action.payload

  draft.ui.uiState = uiState
}

const setVideoGameRatings: CaseReducer<State, PayloadAction<Array<VideoGameRatingModel>>> = (
  draft,
  action,
) => {
  setNormalizedData(draft.formData.videoGameRatings, action.payload)
}

const setIsFormDisabled: CaseReducer<State, PayloadAction<{ isFormDisabled: boolean }>> = (
  draft,
  action,
) => {
  const { isFormDisabled } = action.payload

  draft.ui.isFormDisabled = isFormDisabled
}

const disableItemUpload: CaseReducer<State> = draft => {
  draft.ui.isFormDisabled = true
}

const enableItemUpload: CaseReducer<State> = draft => {
  draft.ui.isFormDisabled = false
}

const toggleIsSimilarItemsModalOpen: CaseReducer<State> = draft => {
  draft.ui.price.isSimilarItemsModalOpen = !draft.ui.price.isSimilarItemsModalOpen
}

const setPriceSuggestions: CaseReducer<
  State,
  PayloadAction<{ suggestions: ItemPriceSuggestionsModel }>
> = (draft, action) => {
  const { min, max, hasSimilarItems, currency, displayType, similarItemsAbTest } =
    action.payload.suggestions

  draft.formData.priceSuggestions.min = min
  draft.formData.priceSuggestions.max = max
  draft.formData.priceSuggestions.currency = currency
  draft.ui.price.hasSimilarItems = hasSimilarItems
  draft.ui.price.displayType = displayType
  draft.formData.priceSuggestions.similarItemsAbTest = similarItemsAbTest
}

const clearPriceSuggestions: CaseReducer<State> = draft => {
  draft.formData.priceSuggestions = initialFormDataState.priceSuggestions
  draft.ui.price = initialUiState.price
}

const setBrands: CaseReducer<State, PayloadAction<{ brands: Array<BrandModel> }>> = (
  draft,
  action,
) => {
  const { brands } = action.payload

  setNormalizedData(draft.formData.brands, brands)
}

const setCatalogs: CaseReducer<State, PayloadAction<{ catalogs: Array<CatalogSelectType> }>> = (
  draft,
  action,
) => {
  const { catalogs } = action.payload

  setNormalizedData(draft.formData.catalogs, catalogs)

  // Starting ids for catalogs at root level (with no parent id)
  const initialCatalogIds: Array<number> = []

  catalogs.forEach(catalog => {
    if (!catalog.parentId) {
      initialCatalogIds.push(catalog.id)
    }
  })

  draft.formData.catalogs.initialIds = initialCatalogIds
}

const setColors: CaseReducer<State, PayloadAction<{ colors: Array<ColorSelectType> }>> = (
  draft,
  action,
) => {
  const { colors } = action.payload

  setNormalizedData(draft.formData.colors, colors)

  draft.ui.color.uiState = UiStateEnum.Success
}

const setSizes: CaseReducer<State, PayloadAction<{ sizes: Array<SizeModel> }>> = (
  draft,
  action,
) => {
  const { sizes } = action.payload

  setNormalizedData(draft.formData.sizes, sizes)
}

const setSuggestions: CaseReducer<
  State,
  PayloadAction<{
    colors: Array<ColorSelectType>
    sizes: Array<SizeModel>
    brands: Array<BrandModel>
    catalogs: Array<number>
  }>
> = (draft, action) => {
  const { colors, sizes, brands, catalogs } = action.payload

  draft.formData.catalogs.suggested = catalogs

  setNormalizedData(draft.formData.colors.suggested, colors)
  setNormalizedData(draft.formData.sizes.suggested, sizes)
  setNormalizedData(draft.formData.brands.suggested, brands)
}

const setColorPendingState: CaseReducer<State> = draft => {
  draft.ui.color.uiState = UiStateEnum.Pending
}

const setColorFailureState: CaseReducer<State> = draft => {
  draft.ui.color.uiState = UiStateEnum.Failure
}

const setStatuses: CaseReducer<State, PayloadAction<{ statuses: Array<StatusSelectType> }>> = (
  draft,
  action,
) => {
  const { statuses } = action.payload

  setNormalizedData(draft.formData.statuses, statuses)
}

const setAssignedPhotos: CaseReducer<
  State,
  PayloadAction<{ photos: Array<MediaUploadPhotoModel> }>
> = (draft, action) => {
  const { photos } = action.payload

  photos.forEach(({ id, isWeb }) => {
    draft.previousAttributes.assignedPhotos.push(id)
    draft.attributes.assignedPhotos.push(id)

    if (isWeb) {
      draft.ui.containsWebPhoto = true
    }
  })

  deleteError(draft, FieldName.Photos)
}

const addAssignedPhotos: CaseReducer<
  State,
  PayloadAction<{ photos: Array<MediaUploadPhotoModel> }>
> = (draft, action) => {
  const { photos } = action.payload

  photos.forEach(({ id, isWeb }) => {
    draft.attributes.assignedPhotos.push(id)

    if (isWeb) {
      draft.ui.containsWebPhoto = true
    }
  })

  draft.ui.isFormDisabled = false
  deleteError(draft, FieldName.Photos)
}

const removeAssignedPhoto: CaseReducer<State, PayloadAction<{ id: number }>> = (draft, action) => {
  const { id } = action.payload

  remove(draft.attributes.assignedPhotos, photoId => photoId === id)
}

const changeAssignedPhotoOrder: CaseReducer<
  State,
  PayloadAction<{ oldIndex: number; newIndex: number }>
> = (draft, action) => {
  const { oldIndex, newIndex } = action.payload

  const reorderedAssignedPhotos = arrayMove(draft.attributes.assignedPhotos, oldIndex, newIndex)

  draft.attributes.assignedPhotos = reorderedAssignedPhotos
}

const fetchCategoryAttributesSuccess: CaseReducer<
  State,
  PayloadAction<{
    attributes: Array<DynamicAttributeModel>
    additionalAttributes: Array<AdditionalAttributeModel>
  }>
> = (draft, action) => {
  const { attributes, additionalAttributes } = action.payload

  draft.formData.dynamicAttributes.byName = {}
  draft.formData.additionalAttributes.byName = {}

  attributes.forEach(attr => {
    draft.formData.dynamicAttributes.byName[attr.code] = attr
  })

  additionalAttributes.forEach(attr => {
    draft.formData.additionalAttributes.byName[attr.code] = attr
  })

  draft.ui.dynamicAttributes.uiState = UiStateEnum.Success
}

const fetchCategoryAttributesFailure: CaseReducer<State> = draft => {
  draft.ui.dynamicAttributes.uiState = UiStateEnum.Failure
}

const setDynamicAttributesUiState: CaseReducer<State, PayloadAction<{ uiState: UiStateEnum }>> = (
  draft,
  action,
) => {
  const { uiState } = action.payload

  draft.ui.dynamicAttributes.uiState = uiState
}
const setSelectedDynamicAttributeValues: CaseReducer<
  State,
  PayloadAction<{ selectedValues: Array<DynamicAttribute> }>
> = (draft, action) => {
  const { selectedValues } = action.payload

  selectedValues.forEach(value => {
    draft.attributes.catalog.attributes.byName[value.field] = value.value
    draft.previousAttributes.catalog.attributes.byName[value.field] = value.value
  })

  const attributeNames = selectedValues.map(value => value.field)

  draft.attributes.catalog.attributes.names = attributeNames
  draft.previousAttributes.catalog.attributes.names = attributeNames
}

const setSelectedDynamicAttributeValue: CaseReducer<State, PayloadAction<DynamicAttribute>> = (
  draft,
  action,
) => {
  const { field, value } = action.payload

  draft.attributes.catalog.attributes.byName[field] = value

  if (draft.attributes.catalog.attributes.names.includes(field)) return

  draft.attributes.catalog.attributes.names.push(field)
}

const setIsPackageSizeManuallySelected: CaseReducer<
  State,
  PayloadAction<{ isPackageSizeManuallySelected: boolean }>
> = (draft, action) => {
  const { isPackageSizeManuallySelected } = action.payload

  draft.isPackageSizeSelectedManually = isPackageSizeManuallySelected
}

const setIsbnPendingState: CaseReducer<State> = draft => {
  draft.ui.isbn.uiState = UiStateEnum.Pending
}

const setIsbn: CaseReducer<
  State,
  PayloadAction<{ isbn: string | null; validity: IsbnValidity }>
> = (draft, action) => {
  const { isbn } = action.payload

  draft.attributes.isbn = isbn
  draft.formData.bookDetails = null
  draft.ui.isbn.uiState = UiStateEnum.Idle
}

const setBookDetails: CaseReducer<State, PayloadAction<{ bookDetails: BookDetailsModel }>> = (
  draft,
  action,
) => {
  const { bookDetails } = action.payload

  draft.formData.bookDetails = bookDetails
  draft.ui.isbn.uiState = UiStateEnum.Success
}

const setPackageSizeId: CaseReducer<State, PayloadAction<{ id: number | null | undefined }>> = (
  draft,
  action,
) => {
  draft.attributes.packageSize.id = action.payload.id
  deleteError(draft, FieldName.PackageSize)
}

const setDomesticShippingPrice: CaseReducer<
  State,
  PayloadAction<{ price: string | null | undefined }>
> = (draft, action) => {
  draft.attributes.packageSize.custom.domestic = action.payload.price
  deleteError(draft, FieldName.DomesticShipmentPrice)
}

const setInternationalShippingPrice: CaseReducer<
  State,
  PayloadAction<{ price: string | null | undefined }>
> = (draft, action) => {
  draft.attributes.packageSize.custom.international = action.payload.price
  deleteError(draft, FieldName.InternationalShipmentPrice)
}

const setFeedbackId: CaseReducer<State, PayloadAction<{ id: number | null }>> = (draft, action) => {
  const { id } = action.payload

  draft.attributes.feedbackId = id
  draft.previousAttributes.feedbackId = id
}

const setVideoGameRatingId: CaseReducer<State, PayloadAction<{ id: number | null }>> = (
  draft,
  action,
) => {
  draft.attributes.videoGameRatingId = action.payload.id
  deleteError(draft, FieldName.VideoGameRating)
}

const toggleIsPushUpChecked: CaseReducer<State> = draft => {
  draft.attributes.isPushUpChecked = !draft.attributes.isPushUpChecked
}

const setMeasurementWidth: CaseReducer<State, PayloadAction<{ width: number | null }>> = (
  draft,
  action,
) => {
  draft.attributes.measurementWidth = action.payload.width
  deleteError(draft, FieldName.MeasurementWidth)
}

const setMeasurementLength: CaseReducer<State, PayloadAction<{ length: number | null }>> = (
  draft,
  action,
) => {
  draft.attributes.measurementLength = action.payload.length
  deleteError(draft, FieldName.MeasurementLength)
}

const setIsSecondDayLister: CaseReducer<State, PayloadAction<{ isSecondDayLister: boolean }>> = (
  draft,
  action,
) => {
  draft.isSecondDayLister = action.payload.isSecondDayLister
}

const setIsPreviousLister: CaseReducer<State, PayloadAction<{ isPreviousLister: boolean }>> = (
  draft,
  action,
) => {
  draft.isPreviousLister = action.payload.isPreviousLister
}

const setManufacturer: CaseReducer<State, PayloadAction<{ manufacturer: string | null }>> = (
  draft,
  action,
) => {
  draft.attributes.manufacturer = action.payload.manufacturer
  deleteError(draft, FieldName.Manufacturer)
}

const setManufacturerLabel: CaseReducer<
  State,
  PayloadAction<{ manufacturerLabel: string | null }>
> = (draft, action) => {
  draft.attributes.manufacturerLabel = action.payload.manufacturerLabel
  deleteError(draft, FieldName.ManufacturerLabel)
}

const setTitleIdleState: CaseReducer<State> = draft => {
  draft.ui.title.uiState = UiStateEnum.Idle
}

const setTitlePendingState: CaseReducer<State> = draft => {
  draft.ui.title.uiState = UiStateEnum.Pending
}

const fetchItemTitleSuggestionsSuccess: CaseReducer<
  State,
  PayloadAction<{ title: string; isEditedByUser: boolean }>
> = (draft, action) => {
  setTitle(draft, action)
  draft.ui.title.uiState = UiStateEnum.Success
  draft.formData.title.suggested = [...draft.formData.title.suggested, action.payload.title]
}

const fetchItemTitleSuggestionsFailure: CaseReducer<State> = draft => {
  draft.attributes.title = ''
  draft.ui.title.uiState = UiStateEnum.Failure
}

const itemUploadSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setInitialItemData,
    toggleIsUnisex,
    setCatalogId,
    setColorIds,
    setTitle,
    setDescription,
    setFieldError,
    setFieldErrors,
    removeFieldError,
    setStatusId,
    setSizeId,
    setStatuses,
    setBrands,
    setCatalogs,
    setColors,
    setSizes,
    setPrice,
    setSuggestions,
    setColorPendingState,
    setColorFailureState,
    setBrand,
    setIsAuthenticityProofModalOpen,
    setAuthenticityModalContent,
    setIsAuthenticityModalOpen,
    fetchColorsSuccess: setColors,
    fetchColorsRequest: setColorPendingState,
    fetchColorsFailure: setColorFailureState,
    setIsFormDisabled,
    showMissingPostalCode,
    hideMissingPostalCode,
    toggleIsShippingOptionsModalOpen,
    setIsLuxuryItemModalOpen,
    fetchShippingOptionsRequest,
    fetchShippingOptionsFailure,
    fetchShippingOptionsSuccess,
    setUiState,
    setPhotoTips,
    setSizeGroups,
    setVideoGameRatings,
    setPriceSuggestions,
    clearPriceSuggestions,
    toggleIsSimilarItemsModalOpen,
    setIsOfflineVerificationModalOpen,
    setIsOfflineVerificationEligible,
    fetchCategoryAttributesSuccess,
    fetchCategoryAttributesFailure,
    setDynamicAttributesUiState,
    setSelectedDynamicAttributeValues,
    setSelectedDynamicAttributeValue,
    setConfiguration,
    setIsPackageSizeManuallySelected,
    setIsSecondDayLister,
    setIsPreviousLister,
    setIsbn,
    setBookDetails,
    setIsbnPendingState,
    setPackageSizeId,
    setDomesticShippingPrice,
    setInternationalShippingPrice,
    setVideoGameRatingId,
    setMeasurementLength,
    setMeasurementWidth,
    setFeedbackId,
    toggleIsPushUpChecked,
    setCurrency,
    setManufacturer,
    setManufacturerLabel,
    setTitleIdleState,
    fetchItemTitleSuggestionsRequest: setTitlePendingState,
    fetchItemTitleSuggestionsSuccess,
    fetchItemTitleSuggestionsFailure,
  },
  extraReducers: {
    [mediaUploadActions.fetchPhotosSuccess.type]: setAssignedPhotos,
    [mediaUploadActions.uploadPhotoFiles.type]: disableItemUpload,
    [mediaUploadActions.uploadPhotoSuccess.type]: addAssignedPhotos,
    [mediaUploadActions.uploadPhotoFailure.type]: enableItemUpload,
    [mediaUploadActions.removePhoto.type]: removeAssignedPhoto,
    [mediaUploadActions.changePhotoOrder.type]: changeAssignedPhotoOrder,
  },
})

export const { actions } = itemUploadSlice
export const plug = { [stateName]: itemUploadSlice.reducer }
export default itemUploadSlice.reducer
