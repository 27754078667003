'use client'

import { useSelector } from 'react-redux'
import { Button, Container, EmptyState, Image } from '@vinted/web-ui'

import useAsset from 'hooks/useAsset'
import useTranslate from 'hooks/useTranslate'
import * as sessionSelectors from 'state/session/selectors'

import StackTrace from '../StackTrace'
import type { FallbackComponentProps } from '../ErrorBoundary'

const LayoutError = ({ error, errorInfo, handleRetry }: FallbackComponentProps) => {
  const translate = useTranslate('error_page')
  const isGod = useSelector(sessionSelectors.getIsUserGod)
  const asset = useAsset('/assets/error-page')

  return (
    <div className="container">
      <EmptyState
        image={<Image src={asset('404-rack.svg')} />}
        title={translate('client_error.title')}
        body={translate('client_error.body')}
        action={
          <Button styling={Button.Styling.Filled} onClick={handleRetry}>
            {translate('actions.retry')}
          </Button>
        }
      />
      {isGod && (
        <Container>
          <StackTrace error={error} errorInfo={errorInfo} />
        </Container>
      )}
    </div>
  )
}

export default LayoutError
