import { createSelector } from 'reselect'

import { SelectedSection, stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getSearchesState = createSelector(localState, ({ searches }) => searches)
export const getSearchesById = createSelector(getSearchesState, ({ byId }) => byId)
export const getSearchIds = createSelector(getSearchesState, ({ ids }) => ids)
export const getSearches = createSelector(getSearchIds, getSearchesById, (ids, byId) =>
  ids.map(id => byId[id]),
)
export const getSubscribedSearchIds = createSelector(getSearches, searches => {
  const subscribedSearches = searches.filter(({ subscribed }) => subscribed)

  if (!subscribedSearches) return null

  return subscribedSearches.map(({ id }) => id)
})
export const getRecentSearchIds = createSelector(getSearches, searches => {
  const recentSearches = searches.filter(({ subscribed }) => !subscribed)

  if (!recentSearches) return null

  return recentSearches.map(({ id }) => id)
})

export const getCurrentSearchId = (state: AllState) => localState(state).currentSearchId
export const getIsSubscribedModalOpen = (state: AllState) => localState(state).isSubscribeModalOpen

export const getSearchById = (state: AllState, id: number | null | undefined) => {
  if (!id) return null

  return localState(state).searches.byId[id]
}

export const getCurrentSearch = (state: AllState) => getSearchById(state, getCurrentSearchId(state))

export const getIsSearchLoadingMap = (state: AllState) => localState(state).isSearchLoadingMap

export const getIsCatalogSearchButtonLoading = (state: AllState) =>
  localState(state).isCatalogSearchButtonLoading

export const getSubscribedSearches = (state: AllState) => {
  const local = localState(state)

  return local.subscribedSearches.ids.map(id => local.searches.byId[id])
}

export const getSelectedSection = (state: AllState) => localState(state).selectedSection

export const getSavedRecentSearchListId = (state: AllState) => {
  const section = getSelectedSection(state)
  const local = localState(state)

  if (section === SelectedSection.Recent) {
    return local.searches.listId
  }

  return local.subscribedSearches.listId
}

export const getSelectedSearches = (state: AllState) => {
  const section = getSelectedSection(state)

  const allSearches = getSearches(state)
  const subscribedSearches = getSubscribedSearches(state)

  if (section === SelectedSection.Recent) {
    return allSearches
  }

  return subscribedSearches
}
