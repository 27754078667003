export enum Gender {
  NotSelected = '',
  Female = 'F',
  Male = 'M',
  Other = 'O',
}

export enum UserAccountStatus {
  Active = 0,
  Deleted = 3,
  Banned = 8,
  ActiveUnconfirmed = 9,
  TemporaryUnconfirmed = 14,
}
