import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import {
  getTransactionPackageSizes,
  selectTransactionPackageSize as selectTransactionPackageSizeApi,
  getPackageSizes,
} from 'data/api'

import { actions } from './slice'
import { transformPackageSizesDto } from './transformers'

export function* fetchTransactionPackageSizes({
  payload,
}: ReturnType<typeof actions.fetchTransactionPackageSizesRequest>) {
  const { transactionId } = payload

  const response = yield* call(getTransactionPackageSizes, { transactionId })

  if ('errors' in response) {
    yield put(actions.fetchTransactionPackageSizesFailure({ errors: response.errors }))

    return
  }

  yield put(
    actions.fetchTransactionPackageSizesSuccess({
      packageSizes: transformPackageSizesDto(response.package_sizes),
    }),
  )
}

export function* selectTransactionPackageSize({
  payload,
}: ReturnType<typeof actions.selectTransactionPackageSizeRequest>) {
  const { transactionId, packageSizeId, customShipmentPrice } = payload

  const response = yield* call(selectTransactionPackageSizeApi, {
    transactionId,
    packageSizeId,
    customShipmentPrice,
  })

  if ('errors' in response && response.errors) {
    yield put(actions.selectTransactionPackageSizeFailure({ errors: response.errors }))

    return
  }

  yield put(actions.selectTransactionPackageSizeSuccess())
}

export function* fetchCatalogPackageSizes({
  payload,
}: ReturnType<typeof actions.fetchCatalogPackageSizesRequest>) {
  const { brandId, catalogId, price } = payload

  const response = yield* call(getPackageSizes, { brandId, catalogId, price })

  if ('errors' in response) {
    yield put(actions.fetchCatalogPackageSizesFailure({ errors: response.errors }))

    return
  }

  yield put(
    actions.fetchCatalogPackageSizesSuccess({
      packageSizes: transformPackageSizesDto(response.package_sizes),
      isOfflineVerificationEligible: response.is_offline_verification_eligible,
    }),
  )
}

export default function* saga() {
  yield takeEvery(actions.fetchTransactionPackageSizesRequest, fetchTransactionPackageSizes)
  yield takeEvery(actions.selectTransactionPackageSizeRequest, selectTransactionPackageSize)
  yield takeEvery(actions.fetchCatalogPackageSizesRequest, fetchCatalogPackageSizes)
}
