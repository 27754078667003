import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'

import { actions } from './slice'
import * as statelessActions from './actions'
import { transformOrder } from './transformers'

export function* prepareOrder({ payload }: ReturnType<typeof statelessActions.prepareOrder>) {
  yield put(actions.prepareOrderRequest())

  const { amount, charityCode, currencyCode } = payload

  if (!amount || !charityCode || !currencyCode) return

  const response = yield* call(api.createDirectDonationOrder, {
    amount,
    charityCode,
    currencyCode,
  })

  if ('errors' in response) {
    const message = 'message' in response ? response.message : undefined

    yield put(actions.prepareOrderFailure({ message }))

    return
  }

  const order = response.direct_donation_order

  yield put(
    actions.prepareOrderSuccess({
      order: transformOrder(order),
    }),
  )
}

export function* getOrder({ payload: { orderId } }: ReturnType<typeof actions.getOrderRequest>) {
  const response = yield* call(api.getDirectDonationOrder, orderId)

  if ('errors' in response) {
    yield put(actions.getOrderFailure())

    return
  }

  const order = transformOrder(response.direct_donation_order)

  yield put(
    actions.getOrderSuccess({
      order,
    }),
  )
}

export default function* saga() {
  yield takeLatest(statelessActions.prepareOrder.type, prepareOrder)
  yield takeLatest(actions.getOrderRequest.type, getOrder)
}
