import { PageId } from 'types/page-id'

import { MS_PER_SECOND } from './date'

export const ExtraServiceCheckoutPage: Record<string, Array<PageId | null>> = {
  Item: ['item'],
  Donate: ['wallet-balance-donate'],
  Catalog: ['catalog', 'brand', 'catalog-by-id'],
  Balance: ['wallet-balance'],
  NewsFeed: ['root'],
  UserProfile: ['member'],
  MultiplePushUp: ['items-new-push-up'],
  FeaturedCollection: ['items-featured-collections', 'items-featured-collection'],
  MessageList: ['inbox', 'inbox-message'],
  ViewIssueDetails: ['complaint'],
  CollectionsManagement: ['items-collections-management'],
}

export enum ExtraServiceCheckoutEntityType {
  DirectDonationOrder = 'direct_donation_order',
  WebviewDirectDonationOrder = 'webview_direct_donation_order',
  MsgThreadReturnLabelOrder = 'msg_thread_return_label_order',
  Unknown = 'unknown',
}

export enum ExtraServicePaymentStatus {
  New = 0,
  Processing = 10,
  Pending = 20,
  Failed = 30,
  Processed = 40,
  Refunded = 50,
}

export enum ExtraServiceOrderType {
  PushUp = 'push_up_order',
  ClosetPromotion = 'closet_promotion_order',
  DirectDonation = 'direct_donation_order',
  FeaturedCollection = 'featured_collection_order',
  ReturnLabel = 'return_label_order',
}

export const extraServiceOrderTypeToTrackingTypeMap = {
  [ExtraServiceOrderType.PushUp]: 'push_up',
  [ExtraServiceOrderType.ClosetPromotion]: 'closet_promotion',
  [ExtraServiceOrderType.DirectDonation]: 'direct_donation',
  [ExtraServiceOrderType.FeaturedCollection]: 'featured_collection',
  [ExtraServiceOrderType.ReturnLabel]: 'return_label',
}

export const PAYMENT_POLL_DELAY = MS_PER_SECOND
/* eslint id-length: 0 */
export const VAS_PENDING_STATE_AB_TEST_EXTENDED_PAYMENT_POLL_DURATION = {
  a: 5 * MS_PER_SECOND,
  b: 15 * MS_PER_SECOND,
  c: 30 * MS_PER_SECOND,
}

export enum ExtraServiceCheckoutModal {
  PreCheckout = 'preCheckout',
  PreCheckoutHelp = 'preCheckoutHelp',
  DynamicPricingInfo = 'dynamicPricingInfo',
  OrderReview = 'orderReview',
  ValueProposition = 'valueProposition',
  PayInMethodSelection = 'payInMethodSelection',
  PaymentConfirmation = 'paymentConfirmation',
  OrderConfirmation = 'orderConfirmation',
  PaymentAuth = 'paymentAuth',
  CreditCardForm = 'creditCardForm',
  BlikCodeInput = 'blikCodeInput',
  SalesTaxInfo = 'salesTaxInfo',
  OrderStatus = 'orderStatus',
  Checkout = 'checkout',
  Error = 'error',
  GenericErrorModal = 'genericErrorModal',
  None = 'none',
}

export enum ExtraServiceOrderStatus {
  Success = 'success',
  Processing = 'processing',
  // LongProcessing = 'long_processing',
  Failure = 'failure',
  Idle = 'idle',
}
