import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect'
import { isEqual } from 'lodash'

import {
  findListerActivationBannerFromCatalogIds,
  getParentListerActivationBanner,
  getRootCatalogListerActivationBanners,
} from 'data/utils/banner'
import { getScreenUtmParams } from 'libs/common/event-tracker/helpers'
import { TrackingEventContext } from 'libs/common/event-tracker/types'
import { serverSide } from 'libs/utils/environment'

import {
  getAnonId,
  getIsAuthenticated,
  getIsBusinessAccount,
  getLanguageCode,
  getTrackerDebugPin,
  getTrackingPlatform,
  getUserId,
} from './session/selectors'
import { getPortal as getRailsContextPortal } from './rails-context/selectors'
import { getScreenName } from './screen/selectors'
import { getPortal as getSystemConfigPortal } from './system-configuration/selectors'
import {
  getCatalogMap,
  getDeepestSelectedCatalog,
  getSelectedCatalogs,
} from './catalog-filters/selectors'
import { getBanners } from './banners/selectors'

export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual)

export const getEventTrackerContext = createSelector(
  getAnonId,
  getUserId,
  getRailsContextPortal,
  getSystemConfigPortal,
  getLanguageCode,
  getTrackerDebugPin,
  getTrackingPlatform,
  getScreenName,
  (
    anonId,
    userId,
    railsContextPortal,
    systemConfigPortal,
    languageCode,
    debugPin,
    platform,
    screenName,
  ): TrackingEventContext => {
    const utmParams = getScreenUtmParams()
    const portal = railsContextPortal || systemConfigPortal || ''

    return {
      anonId,
      userId,
      portal,
      languageCode,
      debugPin,
      platform,
      screen: {
        width: serverSide ? 0 : window.screen.width,
        height: serverSide ? 0 : window.screen.height,
        name: screenName,
      },
      utm: {
        campaign: utmParams.utm_campaign,
        source: utmParams.utm_source,
        medium: utmParams.utm_medium,
        content: utmParams.utm_content,
        term: utmParams.utm_term,
      },
    }
  },
)

export const getAvailableListerBannersBySelectedCatalogs = createSelector(
  getSelectedCatalogs,
  getCatalogMap,
  getBanners,
  (selectedCatalogs, catalogMap, { listerActivation }) => {
    if (!selectedCatalogs.length) return listerActivation

    const selectedCatalogIds = selectedCatalogs.map(({ id }) => id)
    const directBanner = findListerActivationBannerFromCatalogIds(
      selectedCatalogIds,
      listerActivation,
    )
    const targetBanner = directBanner ? [directBanner] : undefined

    return (
      targetBanner ||
      getParentListerActivationBanner(selectedCatalogs, catalogMap, listerActivation) ||
      getRootCatalogListerActivationBanners(selectedCatalogs, listerActivation) ||
      listerActivation
    )
  },
)

export const getShowFundraiser = createSelector(
  getIsBusinessAccount,
  isBusinessAccount => !isBusinessAccount,
)

export const getGoogleAnalyticsData = createSelector(
  [
    getUserId,
    getIsAuthenticated,
    getAnonId,
    getLanguageCode,
    getDeepestSelectedCatalog,
    getScreenName,
  ],
  (userId, authStatus, anonymousId, language, currentCatalog, screenName) => {
    return {
      userId,
      authStatus,
      anonymousId,
      language,
      currentCatalogUrl: currentCatalog?.urlEn,
      screenName,
    }
  },
)
