import {
  PaymentsIdentityConfigurationModel,
  PaymentsIdentityDocumentConfigurationModel,
  PaymentsIdentityDocumentFileConfigModel,
  DocumentFieldConfigurationModel,
  PaymentsIdentityDocumentInfoModel,
  PaymentsIdentityDynamicConfigurationModel,
} from 'types/models'
import {
  PaymentsIdentityDocumentConfigurationDto,
  PaymentsIdentityDocumentFileConfigurationDto,
  DocumentFieldConfigurationDto,
  PaymentstIdentityDocumentInfoDto,
  PaymentsIdentityDynamicConfigurationDto,
} from 'types/dtos'
import { PaymentsIdentityFormConfigurationResp } from 'types/api'
import { transformAddress } from 'data/transformers/address'

export const transformPaymentsIdentityDocumentFile = (
  file: PaymentsIdentityDocumentFileConfigurationDto,
): PaymentsIdentityDocumentFileConfigModel => ({
  identifier: file.identifier,
  uploadNote: file.upload_note,
})

export const transformPaymentsIdentityDocumentConfiguration = (
  document: PaymentsIdentityDocumentConfigurationDto,
): PaymentsIdentityDocumentConfigurationModel => ({
  files: document.files.map(file => transformPaymentsIdentityDocumentFile(file)),
  title: document.title,
  type: document.type,
  maximumFileSize: document.maximum_file_size,
  mediaTypes: document.media_types,
})

export const transformPaymentstIdentityDocumentInfo = ({
  title,
  description,
}: PaymentstIdentityDocumentInfoDto): PaymentsIdentityDocumentInfoModel => ({
  title,
  description,
})

export const transformPaymentsIdentityDynamicConfiguration = (
  dynamicConfiguration: PaymentsIdentityDynamicConfigurationDto,
): PaymentsIdentityDynamicConfigurationModel => ({
  personalIdNumberRequiredNationalities:
    dynamicConfiguration.personal_id_number_required_nationalities,
  addressType: dynamicConfiguration.address_type,
})

export const transformDocumentFieldConfiguration = (
  documentFieldConfigurations: DocumentFieldConfigurationDto,
): DocumentFieldConfigurationModel => {
  return {
    field: documentFieldConfigurations.field,
    group: documentFieldConfigurations.group,
    hint: documentFieldConfigurations.hint,
    options: documentFieldConfigurations.options.map(
      transformPaymentsIdentityDocumentConfiguration,
    ),
    info: documentFieldConfigurations.info
      ? transformPaymentstIdentityDocumentInfo(documentFieldConfigurations.info)
      : null,
  }
}

export const transformDocumentFieldConfigurations = (
  documentFieldConfigurations: Array<DocumentFieldConfigurationDto>,
): Array<DocumentFieldConfigurationModel> =>
  documentFieldConfigurations.map(transformDocumentFieldConfiguration)

export const transformPaymentsIdentityConfiguration = ({
  payments_identity,
}: PaymentsIdentityFormConfigurationResp): PaymentsIdentityConfigurationModel => {
  return {
    firstName: payments_identity.first_name,
    lastName: payments_identity.last_name,
    birthdate: payments_identity.birthdate,
    ssnSerial: payments_identity.ssn_serial,
    personalIdNumber: payments_identity.personal_id_number,
    status: payments_identity.status,
    requiredFields: payments_identity.required_fields,
    nationality: payments_identity.nationality,
    address: (payments_identity.address && transformAddress(payments_identity.address)) || null,
    agreementHint: payments_identity.agreement_hint,
    businessAddress:
      payments_identity.business_address && transformAddress(payments_identity.business_address),
    registrationNumber: payments_identity.registration_number,
    accountType: payments_identity.account_type,
    documentFieldConfigurations: transformDocumentFieldConfigurations(
      payments_identity.document_field_configurations,
    ),
    verificationFailures: payments_identity.verification_failures && [
      ...payments_identity.verification_failures,
    ],
    statusMessage: payments_identity.status_message,
    statusNote: payments_identity.status_note,
    dynamicConfiguration:
      payments_identity.dynamic_configuration &&
      transformPaymentsIdentityDynamicConfiguration(payments_identity.dynamic_configuration),
    externalVerificationId: payments_identity.external_verification_id,
  }
}
