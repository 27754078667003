import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { ExtraServiceCheckoutModal } from 'constants/extra-service'
import { BumpableItemModel } from 'types/models'

import { State } from './types'

import { stateName } from './constants'

export const initialState: State = {
  selectedIds: [],
  itemThumbnailsById: {},
  pushUpCheckout: {
    activeModal: ExtraServiceCheckoutModal.None,
  },
}

const setSelectedItems: CaseReducer<State, PayloadAction<{ itemIds: Array<number> }>> = (
  draft,
  action,
) => {
  const { itemIds } = action.payload

  draft.selectedIds = itemIds
}

const setSelectedItemThumbnails: CaseReducer<
  State,
  PayloadAction<{ items: Array<BumpableItemModel> }>
> = (draft, action) => {
  const { items } = action.payload

  items.forEach(item => {
    draft.itemThumbnailsById[item.id] = item
  })
}

const setCheckoutModal: CaseReducer<
  State,
  PayloadAction<{
    modal: ExtraServiceCheckoutModal
  }>
> = (draft, action) => {
  const { modal } = action.payload

  draft.pushUpCheckout.activeModal = modal
}

const pushUpSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setCheckoutModal,
    setSelectedItems,
    setSelectedItemThumbnails,
  },
})

export const { actions } = pushUpSlice
export const plug = { [stateName]: pushUpSlice.reducer }
export default pushUpSlice.reducer
