import { omit, flattenDeep } from 'lodash'

import { filterEmptyValues } from 'libs/utils/object'
import { CatalogModel, SearchScopedSuggestionModel } from 'types/models'
import { DynamicFilterOptionModel, SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { SelectedDynamicFilterDto } from 'types/dtos/dynamic-filter'
import { OptionType } from 'constants/dynamic-filters'
import { CATALOG_URL } from 'constants/routes'
import { CATALOG_PER_PAGE } from 'state/items/constants'
import { logMessage } from 'libs/utils/window'

import { FilterGroupItemModel, FilterGroupModel } from 'types/models/filter-group'

import { BRAND_ROUTE_REGEX, CATALOG_ROUTE_REGEX } from './constants'
import { FilterState, Pagination, CatalogExtraUrlParams } from './types'

export const searchSuggestionToFilters = (
  suggestion: SearchScopedSuggestionModel,
): Partial<FilterState> => {
  const { filters } = suggestion

  return {
    query: filters.query,
    catalogIds: filters.catalogIds,
  }
}

export const searchSuggestionToUrlParams = (suggestion: SearchScopedSuggestionModel) => {
  const { filters } = suggestion

  const params = {
    search_text: filters.query,
    brand_id: [],
    color_id: [],
    size_id: [],
    status: [],
    catalog: filters.catalogIds,
    disposal: [],
    price_from: null,
    price_to: null,
  }

  return filterEmptyValues(params)
}

export const buildCatalogPaginationParams = (pagination: Pagination) => {
  const page = Number(pagination.page) > 1 ? pagination.page : null
  const perPage = pagination.perPage === CATALOG_PER_PAGE ? null : pagination.perPage

  return {
    per_page: perPage,
    time: pagination.time,
    page,
  }
}

export const buildFilterUrlParams = (filters: Partial<FilterState>) => ({
  search_text: filters.query || null,
  catalog: filters.catalogIds,
  price_from: filters.priceFrom,
  price_to: filters.priceTo,
  currency: filters.currency,
  order: filters.sortBy,
  disabled_personalization: filters.isPersonalizationDisabled ? true : null,
  popular: filters.isPopularCatalog ? true : null,
  catalog_from: filters.query ? null : filters.catalogFrom,
})

type buildCatalogUrlParamsArgs = {
  extraParams?: CatalogExtraUrlParams
  filters: FilterState
  selectedDynamicFilters?: Array<SelectedDynamicFilterModel>
  pagination: Pagination
}

export const getSelectedDynamicFiltersDtoParams = (
  selectedDynamicFilters: Array<SelectedDynamicFilterDto>,
): Record<string, Array<number | undefined>> => {
  const dynamicParams: Record<string, Array<number | undefined>> = {}

  selectedDynamicFilters.forEach(({ code, ids }) => {
    dynamicParams[`${code}_ids`] = ids
  })

  return dynamicParams
}

export const getSelectedDynamicFiltersParams = (
  selectedDynamicFilters?: Array<SelectedDynamicFilterModel>,
): Record<string, Array<number | undefined>> => {
  if (!selectedDynamicFilters) return {}

  const dynamicParams: Record<string, Array<number | undefined>> = {}

  selectedDynamicFilters.forEach(({ type, ids }) => {
    dynamicParams[`${type}_ids`] = ids
  })

  return dynamicParams
}

export const buildCatalogUrlParams = ({
  filters,
  selectedDynamicFilters,
  pagination,
  extraParams,
}: buildCatalogUrlParamsArgs) => {
  const params = {
    ...extraParams,
    ...buildFilterUrlParams(filters),
    ...getSelectedDynamicFiltersParams(selectedDynamicFilters),
    ...buildCatalogPaginationParams(pagination),
  }

  return filterEmptyValues(params)
}

export const getCatalogInitializersParamsFromUrl = (
  relativeUrl: string,
  params: Record<string, string | Array<string> | undefined>,
): Record<string, string | Array<string> | undefined> => {
  if (relativeUrl === CATALOG_URL) {
    // catalog?color_ids[]=1&catalog[]=2050
    return params
  }

  if (relativeUrl.match(CATALOG_ROUTE_REGEX)) {
    // catalog/5-men
    const catalogIdMatches = relativeUrl.match(CATALOG_ROUTE_REGEX)
    const catalogId = catalogIdMatches?.[1]

    return {
      ...params,
      catalog: catalogId && [catalogId],
    }
  }

  if (relativeUrl.match(BRAND_ROUTE_REGEX)) {
    // brand/175586-garancia
    const brandIdMatches = relativeUrl.match(BRAND_ROUTE_REGEX)
    const brandId = brandIdMatches?.[1]

    return {
      ...params,
      brand_ids: brandId && [brandId],
    }
  }

  logMessage('Unexpected catalog code branch in getCatalogInitializersParamsFromUrl')

  return params
}

export const buildSubcatalogUrlParams = (args: Omit<buildCatalogUrlParamsArgs, 'pagination'>) => {
  const { filters, extraParams } = args

  const fullParams = {
    ...extraParams,
    ...buildFilterUrlParams(filters),
  }

  // Omiting params that might not be available on target catalog, these come from catalog_helper.rb
  // TODO: improve these if continuing with this feature
  const subcatalogParams = omit(fullParams, ['catalog', 'size_id', 'material_ids', 'id', 'page'])

  return filterEmptyValues(subcatalogParams)
}

export type ClosetUrlSupportedFilters = Pick<FilterState, 'catalogIds' | 'query'>

export const isBrandFilterVisible = (catalogs: Array<CatalogModel>) =>
  !catalogs.length || catalogs.some(({ isBrandsAvailable }) => isBrandsAvailable)

const dynamicFilterOptions = (
  options: Array<DynamicFilterOptionModel>,
): RecursiveArray<DynamicFilterOptionModel> =>
  options.map(option => {
    return [option, ...dynamicFilterOptions(option.options)]
  })

export const flattenDynamicFilterOptions = (
  options: Array<DynamicFilterOptionModel>,
): Array<DynamicFilterOptionModel> =>
  flattenDeep(dynamicFilterOptions(options)).filter(filter => filter.type === OptionType.Default)

export const modifySelectedNavigationalFilters = (
  groups: Array<FilterGroupModel<DynamicFilterOptionModel>>,
  selectedIds: Array<number>,
): Array<FilterGroupModel<DynamicFilterOptionModel>> => {
  const selectedGroups: Array<FilterGroupModel<DynamicFilterOptionModel>> = []
  const remainingGroups: Array<FilterGroupModel<DynamicFilterOptionModel>> = []

  groups.forEach(group => {
    const { id, description, items } = group
    const selectedItems: Array<FilterGroupItemModel<DynamicFilterOptionModel>> = []
    const remainingItems: Array<FilterGroupItemModel<DynamicFilterOptionModel>> = []

    items.forEach(item => {
      if (selectedIds.includes(item.id)) {
        selectedItems.push(item)
      } else {
        remainingItems.push(item)
      }
    })

    if (selectedItems.length > 0) {
      const selectedGroup: FilterGroupModel<DynamicFilterOptionModel> = {
        // Due to duplications of groups, we need to create new unique group ids for react keys
        id: id * -1,
        description,
        items: selectedItems,
      }
      selectedGroups.push(selectedGroup)

      if (remainingItems.length > 0) {
        remainingGroups.push({ id, description, items: remainingItems })
      }
    } else {
      remainingGroups.push(group)
    }
  })

  return [...selectedGroups, ...remainingGroups]
}
