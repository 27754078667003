export const createPhotoFormData = (file: File, type: string, tempUuid?: string): FormData => {
  const formData = new FormData()

  formData.append('photo[type]', type)
  formData.append('photo[file]', file)

  if (tempUuid) {
    formData.append('photo[temp_uuid]', tempUuid)
  }

  return formData
}
