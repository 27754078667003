import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { BundleSummaryModel, InstantBundleSummaryModel } from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  selectedIds: [],
  bundleSummary: null,
  instantBundleSummary: null,
  bundleTrackingId: null,
  isBundlingInProgress: false,
}

const getBundleSummarySuccess: CaseReducer<
  State,
  PayloadAction<{ bundleSummary: BundleSummaryModel }>
> = (draft, action) => {
  const { bundleSummary } = action.payload

  draft.bundleSummary = bundleSummary
}

const getInstantBundleSummarySuccess: CaseReducer<
  State,
  PayloadAction<{ instantBundleSummary: InstantBundleSummaryModel }>
> = (draft, action) => {
  const { instantBundleSummary } = action.payload

  draft.instantBundleSummary = instantBundleSummary
}

const setSelectedItems: CaseReducer<
  State,
  PayloadAction<{ itemIds: Array<number>; userId: number }>
> = (draft, action) => {
  const { itemIds } = action.payload

  draft.selectedIds = itemIds
}

const startBundling: CaseReducer<State> = draft => {
  draft.isBundlingInProgress = true
}

const resumeBundling: CaseReducer<State, PayloadAction<{ bundleTrackingId: string }>> = (
  draft,
  action,
) => {
  draft.isBundlingInProgress = true
  draft.bundleTrackingId = action.payload.bundleTrackingId
}

const setBundleTrackingId: CaseReducer<State, PayloadAction<{ bundleTrackingId: string }>> = (
  draft,
  action,
) => {
  draft.bundleTrackingId = action.payload.bundleTrackingId
}

const bundleSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    startBundling,
    resumeBundling,
    setSelectedItems,
    setBundleTrackingId,
    getBundleSummarySuccess,
    getInstantBundleSummarySuccess,
  },
})

export const { actions } = bundleSlice
export const plug = { [stateName]: bundleSlice.reducer }
export default bundleSlice.reducer
