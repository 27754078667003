import { GlobalEmailNotificationsSetting } from 'constants/notification-settings'
import { NotificationSettingsDto } from 'types/dtos'
import { NotificationSettingsSectionModel } from 'types/models'

export const transformNotificationSettingDto = (
  setting: NotificationSettingsDto,
): NotificationSettingsSectionModel => {
  const isGlobalSection = setting.name === GlobalEmailNotificationsSetting.Section

  return {
    isGlobalSection,
    id: setting.name,
    title: setting.type === 'invisible_header' ? undefined : setting.title,
    settings: setting.value
      .filter(({ type }) => type === 'boolean')
      .map(booleanSetting => ({
        id: booleanSetting.name,
        title: booleanSetting.title,
        description: booleanSetting.description,
        isGlobalEmailSwitch:
          isGlobalSection && booleanSetting.name === GlobalEmailNotificationsSetting.Setting,
        enabled: Boolean(booleanSetting.value),
      })),
  }
}
export const transformNotificationSettingsDto = (
  settings: ReadonlyArray<NotificationSettingsDto>,
): ReadonlyArray<NotificationSettingsSectionModel> => settings.map(transformNotificationSettingDto)
