import { FeaturedCollectionPricingDto } from 'types/dtos'
import { FeaturedCollectionPricingModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformFeaturedCollectionPricing = (
  dto: FeaturedCollectionPricingDto,
): FeaturedCollectionPricingModel => ({
  effectiveDays: dto.effective_days,
  totalPrice: transformCurrencyAmountDto(dto.total_price),
  pricePerDay: transformCurrencyAmountDto(dto.price_per_day),
})
