import { takeEvery, takeLatest, put, delay } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import {
  getUserItems as getUserItemsRequest,
  getFavouriteItems as getFavouriteItemsRequest,
  getUserBundleItems as getUserBundleItemsRequest,
  getSimilarSoldItems as getSimilarItemsRequest,
  getCatalogItems as getCatalogItemsRequest,
} from 'data/api'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { transformItemDtosToProductItems } from 'data/transformers/product-item'
import { scrollToTop } from 'libs/utils/window'
import { actions as filterActions } from 'state/catalog-filters/slice'
import {
  getSearchSessionId,
  getIsSearchSessionStale,
  getSelectedDynamicFilters,
  getFilters,
} from 'state/catalog-filters/selectors'

import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'
import { PER_PAGE, CATALOG_FETCH_ITEM_DEBOUNCE_AMOUNT } from './constants'

export function* getUserItems({
  payload: { userId, filter },
}: ReturnType<typeof actions.getUserItemsRequest>) {
  const currentPage = yield select(selectors.getUserItemCurrentPage)

  const response = yield* call(getUserItemsRequest, {
    userId,
    currentPage,
    perPage: PER_PAGE,
    filter,
  })

  if ('errors' in response || (!response.items && !response.drafts)) {
    yield put(actions.getUserItemsFailure({ errors: [] }))

    return
  }

  yield put(
    actions.getUserItemsSuccess({
      items: transformItemDtosToProductItems(response.items),
      pagination: response.pagination,
    }),
  )
}

export function* getUserBundleItems({
  payload: { userId, selectedItemId },
}: ReturnType<typeof actions.getUserBundleItemsRequest>) {
  const currentPage = yield select(selectors.getUserBundleItemCurrentPage)
  const response = yield* call(getUserBundleItemsRequest, {
    userId,
    currentPage,
    selectedItemId,
    perPage: PER_PAGE,
  })

  if ('errors' in response) {
    yield put(actions.getUserBundleItemsFailure({ errors: [] }))

    return
  }

  yield put(
    actions.getUserBundleItemsSuccess({
      items: response.items,
      pagination: response.pagination,
      containsOfflineVerificationItem: response.contains_offline_verification_item,
      offlineVerificationFee:
        response.offline_verification_fee &&
        transformCurrencyAmountDto(response.offline_verification_fee),
    }),
  )
}

export function* getFavouriteItems({
  payload: { userId },
}: ReturnType<typeof actions.getFavouriteItemsRequest>) {
  const currentPage = yield select(selectors.getFavouriteItemCurrentPage)

  const response = yield* call(getFavouriteItemsRequest, {
    page: currentPage + 1,
    perPage: PER_PAGE,
    userId,
    includeSold: true,
  })

  if ('errors' in response) {
    yield put(actions.getFavouriteItemsFailure({ errors: [] }))

    return
  }

  yield put(actions.getFavouriteItemsSuccess(response))
}

export function* getSimilarItems({
  payload: { brandId, statusId, catalogId },
}: ReturnType<typeof actions.getSimilarItemsRequest>) {
  const response = yield* call(getSimilarItemsRequest, { brandId, statusId, catalogId })

  if ('errors' in response) {
    yield put(actions.getSimilarItemsFailure({ errors: [] }))

    return
  }

  yield put(
    actions.getSimilarItemsSuccess({
      items: response.items,
    }),
  )
}

export function* getCatalogItems({
  payload: { filters, selectedDynamicFilters, pagination, searchSessionId },
  meta: { isPaginationEvent },
}: ReturnType<typeof actions.getCatalogItemsRequest>) {
  const response = yield* call(getCatalogItemsRequest, {
    ...filters,
    ...pagination,
    searchSessionId,
    selectedDynamicFilters,
  })

  if ('errors' in response) {
    yield put(actions.getCatalogItemsFailure({ errors: response.errors }))

    return
  }

  yield put(
    actions.getCatalogItemsSuccess(
      {
        items: response.items,
        pagination: response.pagination,
        searchCorrelationId: response.search_tracking_params.search_correlation_id,
        searchSessionId: response.search_tracking_params.search_session_id,
        globalSearchSessionId: response.search_tracking_params.global_search_session_id,
        catalogTitle: response.title,
      },
      { isPaginationEvent },
    ),
  )
}

export function* paginationChange() {
  yield call(scrollToTop)
}

export function* catalogParamsChange({
  isPaginationEvent,
  skipDelay,
}: {
  isPaginationEvent: boolean
  skipDelay?: boolean
}) {
  if (!skipDelay) yield delay(CATALOG_FETCH_ITEM_DEBOUNCE_AMOUNT)

  const filters = yield* select(getFilters)
  const selectedDynamicFilters = yield* select(getSelectedDynamicFilters)
  const page = yield* select(selectors.getCatalogItemCurrentPage)
  const time = yield* select(selectors.getCatalogItemTime)
  const perPage = yield* select(selectors.getCatalogItemPerPage)
  const searchSessionId = yield* select(getSearchSessionId)
  const isSearchSessionStale = yield* select(getIsSearchSessionStale)

  yield put(
    actions.getCatalogItemsRequest(
      {
        filters,
        selectedDynamicFilters,
        pagination: {
          page,
          perPage,
          time,
        },
        searchSessionId: isSearchSessionStale || !searchSessionId ? undefined : searchSessionId,
      },
      { isPaginationEvent },
    ),
  )
}

export default function* saga() {
  yield takeEvery(actions.getUserItemsRequest, getUserItems)
  yield takeEvery(actions.getUserBundleItemsRequest, getUserBundleItems)
  yield takeEvery(actions.getFavouriteItemsRequest, getFavouriteItems)
  yield takeEvery(actions.getSimilarItemsRequest, getSimilarItems)
  yield takeLatest(actions.getCatalogItemsRequest, getCatalogItems)
  yield takeEvery([actions.setCatalogItemsPage, actions.setCatalogItemsPerPage], paginationChange)
  yield takeLatest(
    [filterActions.changeFilters, statelessActions.retryCatalogItemsRequest],
    catalogParamsChange,
    { isPaginationEvent: false },
  )
  yield takeLatest(
    [actions.setCatalogItemsPage, actions.setCatalogItemsPerPage],
    catalogParamsChange,
    { isPaginationEvent: true },
  )
  yield takeLatest(filterActions.setInitialFilters, catalogParamsChange, {
    isPaginationEvent: false,
    skipDelay: true,
  })
}
