import { State, AllState } from './types'
import { stateName } from './constants'

const localState = (state: AllState): State => state[stateName]

export const getCountryId = (state: AllState) => localState(state).settings.countryId
export const getCityId = (state: AllState) => localState(state).settings.cityId
export const getCityTitle = (state: AllState) => localState(state).settings.cityTitle
export const getIsCityVisible = (state: AllState) => localState(state).settings.isCityVisible

export const getUserAbout = (state: AllState) => localState(state).settings.about

export const getProfileSettings = (state: AllState) => localState(state).settings

export const getSettingsUiState = (state: AllState) => localState(state).uiState

export const getError = (state: AllState) => localState(state).error

export const getLanguageCode = (state: AllState) => localState(state).settings.languageCode

export const getUsername = (state: AllState) => localState(state).settings.username

export const getContactEmail = (state: AllState) => localState(state).settings.contactEmail

export const getContactNumber = (state: AllState) => localState(state).settings.contactNumber

export const getVat = (state: AllState) => localState(state).settings.vat
