export enum ContentSource {
  PromotedClosets = 'promoted_closets',
  UserItems = 'user_items',
  FavouriteItems = 'favorite_items',
  BundleItems = 'bundle',
  Catalog = 'catalog',
  Search = 'search',
  PopularItems = 'popular_items',
  PromotedCatalog = 'catalog_promoted_items',
  PromotedSearch = 'search_promoted_items',
  PromotedPopularItems = 'popular_promoted_items',
  ListerActivationBanner = 'lister_activation_banner',
  Transaction = 'transaction',
  Settings = 'settings',
  CatalogPromotedClosets = 'catalog_promoted_closets',
  SearchPromotedClosets = 'search_promoted_closets',
  SimilarItems = 'similar_items',
  OtherUserItems = 'other_user_items',
  AdCatalog = 'catalog_ad',
  AdSearch = 'search_ad',
  AdFeed = 'feed_ad',
  FeaturedCollection = 'featured_collection',
  RecommendedItems = 'recommended_items',
  GalleriedItems = 'galleried_items',
  FollowedUsers = 'followed_users',
  HomepageBlock = 'homepage_block',
  EditPrice = 'edit_price',
}
