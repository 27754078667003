import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  locale: 'en-us',
  messages: {},
}

const setLocale: CaseReducer<State, PayloadAction<{ locale: string }>> = (draft, action) => {
  draft.locale = action.payload.locale
}

const setMessages: CaseReducer<State, PayloadAction<{ translations: Record<string, string> }>> = (
  draft,
  action,
) => {
  draft.messages = action.payload.translations
}

const intlSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setLocale,
    setMessages,
  },
})

export const { actions } = intlSlice
export const plug = { [stateName]: intlSlice.reducer }
export default intlSlice.reducer
