import {
  BumpOrderDto,
  BumpOrderItemDto,
  PushUpMinimumPriceDto,
  PushUpMotivationDto,
  PushUpOptionDto,
  PushUpOptionsDto,
} from 'types/dtos'
import {
  PushUpMinimumPriceModel,
  PushUpMotivationModel,
  PushUpOptionModel,
  PushUpOptionsModel,
  PushUpOrderItemModel,
  PushUpOrderModel,
} from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformBumpMinimumPrice = (dto: PushUpMinimumPriceDto): PushUpMinimumPriceModel => ({
  total: transformCurrencyAmountDto(dto.total),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  hasFreeBump: dto.has_free_bump,
})

const transformPushUpOrderItem = (dto: BumpOrderItemDto): PushUpOrderItemModel => ({
  itemId: dto.item_id,
  total: transformCurrencyAmountDto(dto.total),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  discounted: transformCurrencyAmountDto(dto.discounted),
})

export const transformPushUpOrderItems = (
  dtos: Array<BumpOrderItemDto> = [],
): Array<PushUpOrderItemModel> => dtos.map(transformPushUpOrderItem)

export const transformPushUpOrder = (dto: BumpOrderDto): PushUpOrderModel => ({
  id: dto.id,
  status: dto.status,
  freeCount: dto.free_count,
  totalCount: dto.total_count,
  total: transformCurrencyAmountDto(dto.total),
  payIn: transformCurrencyAmountDto(dto.pay_in),
  payable: transformCurrencyAmountDto(dto.payable),
  discount: transformCurrencyAmountDto(dto.discount),
  discounted: transformCurrencyAmountDto(dto.discounted),
  freePushUpsValue: transformCurrencyAmountDto(dto.free_push_ups_value),
  discountPercentage: dto.discount_percentage,
  pushUpOrderItems: transformPushUpOrderItems(dto.push_up_order_items),
  salesTax: dto.sales_tax ? transformCurrencyAmountDto(dto.sales_tax) : null,
  isTaxCoverageAvailable: dto.tax_coverage_available ?? null,
  isTaxCovered: dto.tax_covered ?? null,
})

export const transformPushUpOption = ({
  days,
  payable,
  description,
  default: isDefault,
  best_value,
  international,
  countries,
  total,
  discount,
  discounted,
  push_up_order_items,
}: PushUpOptionDto): PushUpOptionModel => {
  return {
    id: `${days}${international ? '-int' : ''}`,
    days,
    isDefault,
    description,
    isBestValue: !!best_value,
    international,
    countries,
    payable: transformCurrencyAmountDto(payable),
    total: transformCurrencyAmountDto(total),
    discount: transformCurrencyAmountDto(discount),
    discounted: transformCurrencyAmountDto(discounted),
    pushUpOrderItems: push_up_order_items.map(transformPushUpOrderItem),
  }
}

export const transformPushUpOptions = (dtos: Array<PushUpOptionDto>): Array<PushUpOptionModel> =>
  dtos.map(transformPushUpOption)

export const transformPushUpMotivation = (dto: PushUpMotivationDto): PushUpMotivationModel => ({
  title: dto.title,
  description: dto.description,
  highlight: dto.highlight,
})

export const transformPushUpsOptions = (dto: PushUpOptionsDto): PushUpOptionsModel => ({
  motivation: dto.motivation ? transformPushUpMotivation(dto.motivation) : undefined,
  options: dto.options ? transformPushUpOptions(dto.options) : [],
  hasLocalOptions: dto.has_local_options,
})
