import { first } from 'lodash'

import { FilterState } from 'state/catalog-filters/types'
import { SavedSearchApiParams } from 'types/api'
import { SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { filterEmptyValues } from 'libs/utils/object'
import { getSelectedDynamicFiltersParams } from 'state/catalog-filters/utils'

export const filtersToApiParams = (
  filters: FilterState,
  selectedDynamicFilters: Array<SelectedDynamicFilterModel>,
): SavedSearchApiParams => {
  const params: SavedSearchApiParams = {
    search_text: filters.query || undefined,
    catalog_id: first(filters.catalogIds),
    price_from: filters.priceFrom || undefined,
    price_to: filters.priceTo || undefined,
    currency: filters.currency || undefined,
    filters: getSelectedDynamicFiltersParams(selectedDynamicFilters),
  }

  return filterEmptyValues(params)
}
