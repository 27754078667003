import { Sagas } from './types'

import catalogFilterSagas from './catalog-filters/sagas'
import savedSearchesSagas from './saved-searches/sagas'
import feedbackSagas from './user-feedback/sagas'
import mediaUpload from './media-upload/sagas'
import faqEntrySagas from './faq-entry/sagas'
import conversationSagas from './conversation/sagas'
import profileSagas from './profile/sagas'
import { sagas as checkoutSagas } from './checkout'
import itemsSagas from './items/sagas'
import locationSagas from './location/sagas'
import accountSettingsSagas from './account-settings/sagas'
import itemUploadSagas from './item-upload/sagas'
import bannersSagas from './banners/sagas'
import directDonationSagas from './direct-donation/sagas'
import fundraiserSagas from './fundraiser/sagas'
import bundleSagas from './bundle/sagas'
import extraServiceSagas from './extra-service/sagas'
import packageSizesSagas from './package-sizes/sagas'
import kycSagas from './kyc/sagas'
import walletSagas from './wallet/sagas'
import activateBalanceSagas from './activate-balance/sagas'
import payout from './payout/sagas'
import bankAccountSagas from './bank-account/sagas'
import profileSettingsSagas from './profile-settings/sagas'
import businessAddressesSagas from './business-addresses/sagas'
import helpCenterFeedbackSagas from './help-center-feedback/sagas'
import shippingConfigSagas from './shipping-config/sagas'
import shippingContactSagas from './shipping-contact/sagas'
import systemConfigurationSagas from './system-configuration/sagas'
import returnShipmentSagas from './return-shipment/sagas'
import adsSagas from './ads/sagas'
import intellectualPropertyInfringementSagas from './intellectual-property-infringement/sagas'
import complaintSagas from './complaint/sagas'
import dropOffPointMapSagas from './drop-off-point-map/sagas'
import userAddressSagas from './user-address/sagas'

export const getStaticSagas = (): Sagas => []

export const getCoreSagas = (): Sagas => [
  catalogFilterSagas(),
  savedSearchesSagas(),
  feedbackSagas(),
  mediaUpload(),
  faqEntrySagas(),
  conversationSagas(),
  checkoutSagas(),
  itemsSagas(),
  accountSettingsSagas(),
  locationSagas(),
  itemUploadSagas(),
  bannersSagas(),
  directDonationSagas(),
  fundraiserSagas(),
  bundleSagas(),
  extraServiceSagas(),
  packageSizesSagas(),
  kycSagas(),
  walletSagas(),
  activateBalanceSagas(),
  payout(),
  bankAccountSagas(),
  profileSettingsSagas(),
  businessAddressesSagas(),
  helpCenterFeedbackSagas(),
  shippingConfigSagas(),
  shippingContactSagas(),
  systemConfigurationSagas(),
  returnShipmentSagas(),
  adsSagas(),
  intellectualPropertyInfringementSagas(),
  profileSagas(),
  complaintSagas(),
  dropOffPointMapSagas(),
  userAddressSagas(),
]

export { default as businessAccountsSaga } from './business-accounts/sagas'
