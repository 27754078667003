import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'

import { State, Payout, PayoutConfiguration } from './types'
import { stateName } from './constants'

export const initialState: State = {
  payout: {
    amount: '',
    currency: '',
    progressDetails: [],
    uiState: UiState.Idle,
  },
  payoutConfiguration: {
    amount: '',
    currency: '',
    note: null,
    uiState: UiState.Idle,
  },
}

const getPayoutConfigurationRequest: CaseReducer<
  State,
  PayloadAction<{ userId: number }>
> = draft => {
  draft.payoutConfiguration.uiState = UiState.Pending
}

const getPayoutConfigurationSuccess: CaseReducer<
  State,
  PayloadAction<{ payoutConfiguration: PayoutConfiguration }>
> = (draft, action) => {
  const { payoutConfiguration } = action.payload

  draft.payoutConfiguration = {
    ...payoutConfiguration,
    uiState: UiState.Success,
  }
}

const getPayoutConfigurationFailure: CaseReducer<State> = draft => {
  draft.payoutConfiguration.uiState = UiState.Failure
}

const getPayoutRequest: CaseReducer<
  State,
  PayloadAction<{ userId: number; payoutId: number }>
> = draft => {
  draft.payout.uiState = UiState.Pending
}

const getPayoutFailure: CaseReducer<State> = draft => {
  draft.payout.uiState = UiState.Failure
}

const getPayoutSuccess: CaseReducer<State, PayloadAction<{ payout: Payout }>> = (draft, action) => {
  const { payout } = action.payload

  draft.payout = {
    ...payout,
    uiState: UiState.Success,
  }
}

const payoutSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getPayoutConfigurationRequest,
    getPayoutConfigurationSuccess,
    getPayoutConfigurationFailure,
    getPayoutRequest,
    getPayoutSuccess,
    getPayoutFailure,
  },
})

export const { actions } = payoutSlice
export const plug = { [stateName]: payoutSlice.reducer }
export default payoutSlice.reducer
