import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { DirectDonationOrderDto } from 'types/dtos'
import { DirectDonationOrderModel } from 'types/models'

export const transformOrder = (dto: DirectDonationOrderDto): DirectDonationOrderModel => ({
  id: dto.id,
  charityCode: dto.charity_code,
  totalAmount: transformCurrencyAmountDto(dto.total_amount),
  payableAmount: transformCurrencyAmountDto(dto.payable_amount),
  termsNote: dto.terms_note,
})
