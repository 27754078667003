import { createAction } from '@reduxjs/toolkit'

import { FaqEntryType } from 'constants/faq-entry'

import { stateName } from './constants'

export const fetchFaqEntryByIdRequest = createAction<{ id: number | string }>(
  `${stateName}/fetchFaqEntryByIdRequest`,
)

export const fetchFaqEntryByTypeRequest = createAction<{ type: FaqEntryType }>(
  `${stateName}/fetchFaqEntryByTypeRequest`,
)
