import { MediaUploadPhotoDto } from 'types/dtos'
import { MediaUploadPhotoModel } from 'types/models'

import { transformPhotoThumbnailDtos } from './photo'

export const transformMediaUploadPhotoDto = (
  photoDto: MediaUploadPhotoDto,
): MediaUploadPhotoModel => ({
  id: photoDto.id,
  width: photoDto.width,
  height: photoDto.height,
  url: photoDto.url,
  tempUuid: photoDto.temp_uuid,
  dominantColor: photoDto.dominant_color,
  dominantColorOpaque: photoDto.dominant_color_opaque,
  highResolution: photoDto.high_resolution,
  isSuspicious: photoDto.is_suspicious,
  fullSizeUrl: photoDto.full_size_url,
  isWeb: photoDto.extra.is_web,
  orientation: photoDto.orientation || 0,
  thumbnails: transformPhotoThumbnailDtos(photoDto.thumbnails),
})

export const transformMediaUploadPhotoDtos = (
  photoDtos: Array<MediaUploadPhotoDto>,
): Array<MediaUploadPhotoModel> => photoDtos.map(transformMediaUploadPhotoDto)
