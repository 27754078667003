import { takeLatest, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import * as api from 'data/api'
import { transformShippingContactConfigurationDto } from 'data/transformers/shipping-contact'

import { actions } from './slice'

export function* fetchShippingContactConfiguration() {
  const response = yield* call(api.getShippingContactConfiguration)

  if ('errors' in response) {
    yield put(actions.fetchShippingContactConfigurationFailure())

    return
  }

  yield put(
    actions.fetchShippingContactConfigurationSuccess({
      configuration: transformShippingContactConfigurationDto(
        response.shipping_contact_configuration,
      ),
    }),
  )
}

export function* saveTransactionShippingContact(
  action: ReturnType<typeof actions.saveTransactionShippingContactRequest>,
) {
  const { transactionId, buyerPhoneNumber, sellerPhoneNumber, saveForLater } = action.payload

  if (!transactionId) return
  if (!buyerPhoneNumber && !sellerPhoneNumber) return

  const response = yield* call(api.saveTransactionShippingContact, {
    transactionId,
    buyerPhoneNumber,
    sellerPhoneNumber,
    saveForLater,
  })

  if ('errors' in response) {
    yield put(actions.saveTransactionShippingContactFailure({ error: response.errors[0] }))

    return
  }

  yield put(
    actions.saveTransactionShippingContactSuccess({
      transactionId,
      buyerPhoneNumber,
      sellerPhoneNumber,
    }),
  )
}

export function* deleteTransactionShippingContact(
  action: ReturnType<typeof actions.deleteTransactionShippingContactRequest>,
) {
  const { transactionId } = action.payload

  if (!transactionId) return

  const response = yield* call(api.deleteTransactionShippingContact, transactionId)

  if ('errors' in response) {
    yield put(actions.deleteTransactionShippingContactFailure())

    return
  }

  yield put(actions.deleteTransactionShippingContactSuccess({ transactionId }))
}

export default function* saga() {
  yield takeLatest(actions.saveTransactionShippingContactRequest, saveTransactionShippingContact)
  yield takeLatest(
    actions.fetchShippingContactConfigurationRequest,
    fetchShippingContactConfiguration,
  )
  yield takeLatest(
    actions.deleteTransactionShippingContactRequest,
    deleteTransactionShippingContact,
  )
}
