import { CountryModel, CityModel } from 'types/models'

import { State, AllState } from './types'
import { stateName } from './constants'

const localState = (state: AllState): State => state[stateName]

export const getCountries = (state: AllState): Array<CountryModel> => {
  const { countries } = localState(state)

  return countries.ids.map((id: string | number) => countries.byId[id])
}

export const getCountryById = (state: AllState, id: number | null): CountryModel | null => {
  if (id === null) return null

  return localState(state).countries.byId[id] || null
}

export const getCities = (state: AllState): Array<CityModel> => {
  const { cities } = localState(state)

  return cities.ids.map((id: string | number) => cities.byId[id])
}

export const getCityById = (state: AllState, id: number | null): CityModel | null => {
  if (id === null) return null

  return localState(state).cities.byId[id] || null
}
