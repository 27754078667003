import { takeEvery, put } from 'redux-saga/effects'
import { all, call, select } from 'typed-redux-saga'

import * as api from 'data/api'
import { HttpStatus } from 'data/api/response-codes'
import { SavedFileModel } from 'types/models'

import { actions } from './slice'
import * as statelessActions from './actions'
import * as selectors from './selectors'

function* uploadSingleFile(file: File) {
  const response = yield* call(api.uploadReportAttachment, file)

  if (response.status === HttpStatus.Forbidden) {
    yield put(actions.addFileToRetry({ file }))

    return null
  }

  if ('errors' in response) {
    yield put(actions.addFailedFilename({ filename: file.name }))

    return null
  }

  return { id: response.attachment.temp_uuid, fileName: file.name }
}

export function* uploadFilesRequest({ payload }: ReturnType<typeof actions.uploadFilesRequest>) {
  const responses = yield* all(payload.files.map(uploadSingleFile))

  const savedFiles = responses.filter((file): file is SavedFileModel => !!file)

  yield put(actions.uploadFilesSuccess({ savedFiles }))
}

export function* submitFormRequest({ payload }: ReturnType<typeof actions.submitFormRequest>) {
  const response = yield* call(api.sendIntellectualPropertyInfringement, payload.formData)

  if (response.status === HttpStatus.Forbidden) {
    yield put(actions.setFormData({ formData: payload.formData }))

    return
  }

  if ('errors' in response) {
    yield put(actions.submitFormFailure({ errors: response.errors }))

    return
  }

  yield put(actions.submitFormSuccess())
}

export function* continueUploading() {
  const filesToRetry = yield* select(selectors.getFilesToRetry)

  if (filesToRetry.length) {
    yield put(actions.uploadFilesRequest({ files: filesToRetry }))

    return
  }

  const formData = yield* select(selectors.getFormData)

  if (formData) {
    yield put(actions.submitFormRequest({ formData }))
  }
}

export default function* saga() {
  yield takeEvery(actions.uploadFilesRequest, uploadFilesRequest)
  yield takeEvery(actions.submitFormRequest, submitFormRequest)
  yield takeEvery([statelessActions.continueFormSubmit], continueUploading)
}
