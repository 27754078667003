import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { AddressModel } from 'types/models'
import { UiState } from 'constants/ui'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  address: undefined,
  ui: {
    isShippingAddressSelected: true,
    error: null,
    uiState: UiState.Idle,
  },
}

const fetchDefaultShippingAddressRequest: CaseReducer<State> = draft => {
  draft.ui.uiState = UiState.Pending
}

const fetchDefaultShippingAddressFailure: CaseReducer<State> = draft => {
  draft.address = null
  draft.ui.uiState = UiState.Failure
}

const fetchDefaultShippingAddressSuccess: CaseReducer<
  State,
  PayloadAction<{
    address: AddressModel | null
  }>
> = (draft, action) => {
  draft.address = action.payload.address
  draft.ui.uiState = UiState.Success
}

const setDefaultShippingAddress: CaseReducer<
  State,
  PayloadAction<{
    address: AddressModel
  }>
> = (draft, action) => {
  draft.address = action.payload.address
}

const toggleIsShippingAddressChecked: CaseReducer<State> = draft => {
  draft.ui.isShippingAddressSelected = !draft.ui.isShippingAddressSelected
}

const setIsShippingAddressSelected: CaseReducer<State> = draft => {
  draft.ui.isShippingAddressSelected = true
}

const userAddressSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchDefaultShippingAddressRequest,
    fetchDefaultShippingAddressFailure,
    fetchDefaultShippingAddressSuccess,
    setDefaultShippingAddress,
    toggleIsShippingAddressChecked,
    setIsShippingAddressSelected,
  },
})

export const { actions } = userAddressSlice
export const plug = { [stateName]: userAddressSlice.reducer }
export default userAddressSlice.reducer
