import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  areProblemOptionsLoading: false,
  areRatingsOptionsLoading: false,
  isFeedbackModalOpen: false,
  isProblemsModalOpen: false,
  problemOptions: [],
}

const fetchFeedbackDenyOptionsRequest: CaseReducer<State> = draft => {
  draft.areProblemOptionsLoading = true
}

const fetchFeedbackDenyOptionsSuccess: CaseReducer<
  State,
  PayloadAction<
    Array<{
      id: number
      body: string
      hasMessageInput: boolean
    }>
  >
> = (draft, action) => {
  draft.areProblemOptionsLoading = false
  draft.isProblemsModalOpen = true
  draft.problemOptions = action.payload
}

const fetchFeedbackDenyOptionsFailure: CaseReducer<State> = draft => {
  draft.areProblemOptionsLoading = false
}

const fetchFeedbackRatingsRequest: CaseReducer<State> = draft => {
  draft.areRatingsOptionsLoading = true
}

const fetchFeedbackRatingsSuccess: CaseReducer<
  State,
  PayloadAction<{
    subtitleText: string
    ratingOptions: Record<
      string,
      { title: string; options: Array<{ id: number; body: string; hasMessageInput: boolean }> }
    >
    ratingIds: Array<number>
  }>
> = (draft, action) => {
  draft.areRatingsOptionsLoading = false
  draft.isFeedbackModalOpen = true
  draft.ratings = action.payload
}

const fetchFeedbackRatingsFailure: CaseReducer<State> = draft => {
  draft.areRatingsOptionsLoading = false
}

const closeProblemsModal: CaseReducer<State> = draft => {
  draft.isProblemsModalOpen = false
}

const closeFeedbackModal: CaseReducer<State> = draft => {
  draft.isFeedbackModalOpen = false
}

const helpCenterFeedbackSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchFeedbackDenyOptionsRequest,
    fetchFeedbackDenyOptionsSuccess,
    fetchFeedbackDenyOptionsFailure,
    fetchFeedbackRatingsRequest,
    fetchFeedbackRatingsSuccess,
    fetchFeedbackRatingsFailure,
    closeProblemsModal,
    closeFeedbackModal,
  },
})

export const { actions } = helpCenterFeedbackSlice
export const plug = { [stateName]: helpCenterFeedbackSlice.reducer }
export default helpCenterFeedbackSlice.reducer
