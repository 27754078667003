import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import {
  CountryBoundsModel,
  CoordinatesModel,
  DropOffPointModel,
  DropOffPointCarrierModel,
} from 'types/models'

import { UiState } from 'constants/ui'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  countryBounds: undefined,
  nearbyDropOffPoints: undefined,
  selectedDropOffPoint: undefined,
  suggestedDropOffPointCode: undefined,
  dropOffPointCarrier: undefined,
  dropOffPointSource: undefined,
  dropOffPointView: undefined,
  ui: {
    error: null,
    uiState: UiState.Idle,
  },
}

const fetchCountryBoundsSuccess: CaseReducer<
  State,
  PayloadAction<{ countryBounds: CountryBoundsModel }>
> = (draft, action) => {
  const { countryBounds } = action.payload

  draft.countryBounds = countryBounds
}

const fetchNearbyDropOffPointsRequest: CaseReducer<
  State,
  PayloadAction<{
    coordinates?: CoordinatesModel
    dropOffPointSource?: string | null
    limit?: number
  }>
> = draft => {
  draft.ui.uiState = UiState.Pending
  draft.selectedDropOffPoint = null
}

const fetchNearbyDropOffPointFailure: CaseReducer<State> = draft => {
  draft.nearbyDropOffPoints = []
  draft.ui.uiState = UiState.Failure
}

const fetchNearbyDropOffPointSuccess: CaseReducer<
  State,
  PayloadAction<{
    suggestedDropOffPointCode: string | null
    dropOffPoints: Array<DropOffPointModel>
    dropOffPointCarrier: DropOffPointCarrierModel | null
    dropOffPointSource?: string | null
  }>
> = (draft, action) => {
  draft.nearbyDropOffPoints = action.payload.dropOffPoints
  draft.suggestedDropOffPointCode = action.payload.suggestedDropOffPointCode
  draft.dropOffPointCarrier = action.payload.dropOffPointCarrier
  draft.dropOffPointSource = action.payload.dropOffPointSource
  draft.ui.uiState = UiState.Success
}

const setSelectedDropOffPoint = (
  draft: State,
  action: PayloadAction<{ selectedDropOffPoint: DropOffPointModel | null | undefined }>,
) => {
  draft.selectedDropOffPoint = action.payload.selectedDropOffPoint
}

const setDropOffPointView = (
  draft: State,
  action: PayloadAction<{ dropOffPointView: string | undefined }>,
) => {
  draft.dropOffPointView = action.payload.dropOffPointView
}

const clearNearbyDropOffPoints: CaseReducer<State> = draft => {
  draft.nearbyDropOffPoints = undefined
  draft.suggestedDropOffPointCode = undefined
  draft.dropOffPointCarrier = undefined
  draft.dropOffPointSource = undefined
  draft.selectedDropOffPoint = undefined
}

const dropOffPointMapSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchCountryBoundsSuccess,
    fetchNearbyDropOffPointsRequest,
    fetchNearbyDropOffPointFailure,
    fetchNearbyDropOffPointSuccess,
    setSelectedDropOffPoint,
    setDropOffPointView,
    clearNearbyDropOffPoints,
  },
})

export const { actions } = dropOffPointMapSlice
export const plug = { [stateName]: dropOffPointMapSlice.reducer }
export default dropOffPointMapSlice.reducer
