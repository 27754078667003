import Cookies from 'universal-cookie'

const cookies = new Cookies()

const saveCookie = (name: string, value: string, expirationDate?: Date) => {
  const date = new Date()
  const expires = expirationDate || new Date(date.setFullYear(date.getFullYear() + 1))

  cookies.set(name, value, { expires, path: '/' })
}

const deleteCookie = (name: string) => {
  cookies.remove(name, { path: '/' })
}

const getCookie = (name: string): string | undefined => cookies.get(name)

export { saveCookie, deleteCookie, getCookie }
