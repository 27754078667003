export const stateName = 'auth' as const

export enum AuthView {
  SelectTypeRegister = 'select_type_register',
  SelectTypeLogin = 'select_type_login',
  EmailLogin = 'email_login',
  EmailRegister = 'email_register',
  ResetPassword = 'reset_password',
  TwoFactorLogin = 'two_factor_login',
}

export enum AuthExternalRegisterView {
  FacebookRegister = 'facebook_register',
  GoogleRegister = 'google_register',
  AppleRegister = 'apple_register',
  PasswordVerification = 'password_verification',
}

export enum NewsletterSubscription {
  OptIn = 'opt_in',
  OptOut = 'opt_out',
  WithTerms = 'with_terms',
}
