import { FeatureSwitchDto } from 'types/dtos'

export const isFeatureEnabled = (
  featureSwitchName: string,
  featureSwitches: ReadonlyArray<FeatureSwitchDto>,
): boolean => {
  const featureSwitch = featureSwitches.find(feature => feature.name === featureSwitchName)

  return featureSwitch?.enabled ?? false
}
