import { VINTED_LOCALE_TO_ISO_MAP } from 'constants/language'

import { stateName } from './constants'
import { State, AllState } from './types'

const validLocale = (locale: string): string => VINTED_LOCALE_TO_ISO_MAP[locale] || locale

const localState = (state: AllState): State => state[stateName]

export const getMessages = (state: AllState) => localState(state).messages
export const getLocale = (state: AllState) => validLocale(localState(state).locale)
