import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { CharityModel, FundraiserModel } from 'types/models'

import { ManageModalScreenName, State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  fundraiser: undefined,
  charities: [],
  selectedCharity: null,
  currentModalScreen: ManageModalScreenName.Overview,
  uiState: UiState.Idle,
}

const fetchFundraiserConfiguration: CaseReducer<State> = draft => {
  draft.uiState = UiState.Pending
}

const setFailureState: CaseReducer<State> = draft => {
  draft.uiState = UiState.Failure
}

const setFundraiserConfiguration: CaseReducer<
  State,
  PayloadAction<{ fundraiser: FundraiserModel }>
> = (draft, action) => {
  const { fundraiser } = action.payload

  draft.fundraiser = fundraiser
  draft.selectedCharity = fundraiser.charity || null
  draft.currentModalScreen = fundraiser.active
    ? ManageModalScreenName.Overview
    : ManageModalScreenName.CharitySelect
  draft.uiState = UiState.Success
}

const manageFundraiserConfiguration: CaseReducer<
  State,
  PayloadAction<{ charityCode: string; percentage?: number }>
> = draft => {
  draft.uiState = UiState.Pending
}

const stopActiveFundraiser: CaseReducer<State> = draft => {
  draft.uiState = UiState.Pending
}

const fetchCharitiesSuccess: CaseReducer<
  State,
  PayloadAction<{ charities: Array<CharityModel> }>
> = (draft, action) => {
  const { charities } = action.payload

  draft.charities = charities
}

const setSelectedCharity: CaseReducer<State, PayloadAction<{ charity: CharityModel }>> = (
  draft,
  action,
) => {
  const { charity } = action.payload

  draft.selectedCharity = charity
  draft.currentModalScreen = ManageModalScreenName.Overview
}

const setCurrentModalScreen: CaseReducer<
  State,
  PayloadAction<{ screenName: ManageModalScreenName }>
> = (draft, action) => {
  const { screenName } = action.payload

  draft.currentModalScreen = screenName
}

const fundraiserSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchFundraiserConfiguration,
    fetchFundraiserConfigurationSuccess: setFundraiserConfiguration,
    fetchFundraiserConfigurationFailure: setFailureState,
    manageFundraiserConfiguration,
    manageFundraiserConfigurationSuccess: setFundraiserConfiguration,
    manageFundraiserConfigurationFailure: setFailureState,
    stopActiveFundraiser,
    stopActiveFundraiserFailure: setFailureState,
    fetchCharitiesSuccess,
    fetchCharitiesFailure: setFailureState,
    setSelectedCharity,
    setCurrentModalScreen,
  },
})

export const { actions } = fundraiserSlice
export const plug = { [stateName]: fundraiserSlice.reducer }
export default fundraiserSlice.reducer
