import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const translateConversationRequest = createAction<{
  conversationId: number | string
  translate: boolean
}>(`${stateName}/translateConversationNewRequest`)

export const getConversationRequest = createAction<{
  conversationId: number | string
  fromPostReply?: boolean
}>(`${stateName}/getConversationRequest`)

export const getDigitalLabelRequest = createAction<{
  transactionId: number
}>(`${stateName}/getDigitalLabelRequest`)
