import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'

import { DirectDonationOrderModel } from 'types/models'
import { ExtraServiceCheckoutModal } from 'constants/extra-service'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  directDonationOrder: {
    fetchUiState: UiState.Idle,
    prepareUiState: UiState.Idle,
  },
  directDonationCheckout: {
    activeModal: ExtraServiceCheckoutModal.None,
  },
}

const clearCurrentOrder: CaseReducer<State, PayloadAction> = draft => {
  draft.directDonationOrder = {
    order: undefined,
    prepareUiState: UiState.Idle,
    fetchUiState: UiState.Idle,
  }
}

const prepareOrderRequest: CaseReducer<State, PayloadAction> = draft => {
  draft.directDonationOrder = {
    order: undefined,
    prepareUiState: UiState.Pending,
    fetchUiState: UiState.Idle,
  }
}

const prepareOrderFailure: CaseReducer<State, PayloadAction<{ message?: string }>> = (
  draft,
  action,
) => {
  const { message } = action.payload

  draft.directDonationOrder = {
    order: undefined,
    errorMessage: message,
    prepareUiState: UiState.Failure,
    fetchUiState: UiState.Idle,
  }
}

const prepareOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    order: DirectDonationOrderModel
  }>
> = (draft, action) => {
  const { order } = action.payload

  draft.directDonationOrder = {
    order,
    prepareUiState: UiState.Success,
    fetchUiState: UiState.Idle,
  }
}

const setCheckoutModal: CaseReducer<
  State,
  PayloadAction<{
    modal: ExtraServiceCheckoutModal
  }>
> = (draft, action) => {
  const { modal } = action.payload

  draft.directDonationCheckout.activeModal = modal
}

const getOrderRequest: CaseReducer<State, PayloadAction<{ orderId: number }>> = draft => {
  draft.directDonationOrder = {
    order: undefined,
    fetchUiState: UiState.Pending,
    prepareUiState: UiState.Idle,
  }
}

const getOrderFailure: CaseReducer<State, PayloadAction> = draft => {
  draft.directDonationOrder = {
    order: undefined,
    fetchUiState: UiState.Failure,
    prepareUiState: UiState.Idle,
  }
}

const getOrderSuccess: CaseReducer<
  State,
  PayloadAction<{
    order: DirectDonationOrderModel
  }>
> = (draft, action) => {
  const { order } = action.payload

  draft.directDonationOrder = {
    order,
    fetchUiState: UiState.Success,
    prepareUiState: UiState.Idle,
  }
}

const directDonationSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setCheckoutModal,
    getOrderRequest,
    getOrderFailure,
    getOrderSuccess,
    clearCurrentOrder,
    prepareOrderRequest,
    prepareOrderFailure,
    prepareOrderSuccess,
  },
})

export const { actions } = directDonationSlice
export const plug = { [stateName]: directDonationSlice.reducer }
export default directDonationSlice.reducer
