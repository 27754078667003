import { ShipmentItemDto } from 'types/dtos'
import { ShipmentItemModel } from 'types/models'

export const transformShipmentItemDto = ({
  name,
  image_url,
}: ShipmentItemDto): ShipmentItemModel => {
  return {
    name,
    imageUrl: image_url,
  }
}

export const transformShipmentItemDtos = (
  shipmentItems: Array<ShipmentItemDto>,
): Array<ShipmentItemModel> => shipmentItems.map(transformShipmentItemDto)
