import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const updateFeedbackDenyOptionsRequest = createAction<{
  selectedOption?: number
  message: string
}>(`${stateName}/updateFeedbackDenyOptionsRequest`)

export const confirmComplaintRequest = createAction(`${stateName}/confirmComplaintRequest`)
export const confirmComplaintFailure = createAction(`${stateName}/confirmComplaintFailure`)

export const submitFeedbackRatingRequest = createAction<{
  rating: number
  feedbackStatementIds?: Array<number>
  message?: string
  finalized?: boolean
}>(`${stateName}/submitFeedbackRatingRequest`)

export const submitFeedbackRatingFailure = createAction(`${stateName}/submitFeedbackRatingFailure`)
