import { stateName } from './constants'
import { AllState } from './types'
import { getSystemConfiguration } from '../system-configuration/selectors'

const localState = (state: AllState) => state[stateName]

export const getPortal = (state: AllState) =>
  getSystemConfiguration(state)?.portal || localState(state).portal
/**
 * @deprecated - use `/api/v2/configurations/session_defaults` instead or pass it directly as prop
 */
export const DEPRECATED_getCurrency = (state: AllState) => localState(state).currency
export const getFlavour = (state: AllState) =>
  getSystemConfiguration(state)?.portalFlavour || localState(state).flavour
