export enum Field {
  RightsToOrganization = 'rightsToOrganization',
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  WorldCountry = 'worldCountry',
  City = 'city',
  PostalCode = 'postalCode',
  Company = 'company',
  Address = 'address',
  IntellectualPropertyCategory = 'intellectualPropertyCategory',
  IntellectualPropertyOwner = 'intellectualPropertyOwner',
  IntellectualPropertyName = 'intellectualPropertyName',
  RegistrationNumber = 'registrationNumber',
  RightsConsistOf = 'rightsConsistOf',
  ProofOfRights = 'proofOfRights',
  Attachments = 'attachments',
  Brand = 'brand',
  Description = 'description',
  InfringingUrlsTextArea = 'infringingUrlsTextArea',
  IsLegalFooterConfirmed = 'isLegalFooterConfirmed',
  IllegalContentType = 'illegalContentType',
  ReportReasonType = 'reportReasonType',
}
