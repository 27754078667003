import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { transformComplaint } from 'data/transformers/complaint'

import * as api from 'data/api'

import { actions } from './slice'

export function* getComplaint({
  payload: { complaintId },
}: ReturnType<typeof actions.getComplaintRequest>) {
  const response = yield* call(api.getComplaint, complaintId.toString())

  if ('errors' in response) {
    yield put(actions.getComplaintFailure({ error: response?.errors?.[0]?.value || null }))

    return
  }

  yield put(actions.getComplaintSuccess(transformComplaint(response.complaint)))
}

export function* getSnadComplaint({
  payload: { transactionId },
}: ReturnType<typeof actions.getSnadComplaintRequest>) {
  const response = yield* call(api.getSnadComplaint, transactionId)

  if ('errors' in response) {
    yield put(actions.getComplaintFailure({ error: response?.errors?.[0]?.value || null }))

    return
  }

  yield put(
    actions.getComplaintSuccess(response.complaint ? transformComplaint(response.complaint) : null),
  )
}

export default function* saga() {
  yield takeEvery(actions.getComplaintRequest, getComplaint)
  yield takeEvery(actions.getSnadComplaintRequest, getSnadComplaint)
}
