import { createContext, ReactNode } from 'react'

import { FeatureSwitchDto } from 'types/dtos'

export type FeatureSwitchesContextType = Array<FeatureSwitchDto>

type FeatureSwitchesProviderProps = {
  children: ReactNode
  featureSwitches: Array<FeatureSwitchDto> | undefined
}

export const FeatureSwitchesContext = createContext<FeatureSwitchesContextType | undefined>(
  undefined,
)

const FeatureSwitchesProvider = ({ featureSwitches, children }: FeatureSwitchesProviderProps) => {
  return (
    <FeatureSwitchesContext.Provider value={featureSwitches}>
      {children}
    </FeatureSwitchesContext.Provider>
  )
}

export default FeatureSwitchesProvider
