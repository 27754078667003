import { CityDto } from 'types/dtos'
import { CityModel } from 'types/models'

export const transformCityDto = (city: CityDto): CityModel => ({
  id: city.id,
  title: city.title,
  countryId: city.country_id,
  isDefault: !!city.is_default,
})

export const transformCityDtos = (cityDtos: Array<CityDto>) => cityDtos.map(transformCityDto)
