import { FeaturedCollectionOrderDto } from 'types/dtos'
import { FeaturedCollectionOrderModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformFeaturedCollectionOrder = (
  dto: FeaturedCollectionOrderDto,
): FeaturedCollectionOrderModel => ({
  id: dto.id,
  effectiveDays: dto.effective_days,
  maxActiveCollectionsCount: dto.max_active_count,
  planPricingId: dto.pricing_id,
  termsNote: dto.terms_note,
  // todo: remove fallback to payable_amount when backend will implement total_amount
  total: transformCurrencyAmountDto(dto.total_amount ?? dto.payable_amount),
  payable: transformCurrencyAmountDto(dto.payable_amount),
  salesTax: dto.sales_tax ? transformCurrencyAmountDto(dto.sales_tax) : null,
  isTaxCovered: dto.tax_covered,
  isTaxCoverageAvailable: dto.tax_coverage_available,
})
