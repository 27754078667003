import { PayoutConfigurationDto, PayoutDto } from 'types/dtos'
import { transformProgressDetailsDto } from 'data/transformers/payout'

import { Payout, PayoutConfiguration } from './types'

export const transformPayout = ({ amount, currency, progress_details }: PayoutDto): Payout => ({
  amount,
  currency,
  progressDetails: progress_details.map(transformProgressDetailsDto),
})

export const transformPayoutConfiguration = ({
  amount,
  note,
}: PayoutConfigurationDto): PayoutConfiguration => ({
  amount: amount.amount,
  currency: amount.currency_code,
  note,
})
