import { createSlice, CaseReducer, PayloadAction } from '@reduxjs/toolkit'

import {
  CreditCardModel,
  PayInMethodModel,
  WalletBalanceModel,
  PaymentAuthStateModel,
  PaymentAuthActionModel,
  CheckoutConfigurationModel,
} from 'types/models'
import { UiState } from 'constants/ui'
import { ExtraServiceOrderType, ExtraServiceOrderStatus } from 'constants/extra-service'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  payInMethods: {
    creditCards: [],
    payInMethods: [],
    walletBalance: null,
    selectedPayInMethod: null,
    uiState: UiState.Idle,
  },
  orderPayment: {
    configuration: null,
    status: ExtraServiceOrderStatus.Idle,
    auth: {
      embedded: null,
      payInRedirectUrl: null,
    },
    error: {
      isKycConfirmationNeeded: false,
    },
  },
}

const getPayInMethodsRequest: CaseReducer<State> = draft => {
  draft.payInMethods.uiState = UiState.Pending
}

const getPayInMethodsFailure: CaseReducer<State> = draft => {
  draft.payInMethods.uiState = UiState.Failure
}

const getPayInMethodsSuccess: CaseReducer<
  State,
  PayloadAction<{
    walletBalance: WalletBalanceModel
    creditCards: Array<CreditCardModel>
    payInMethods: Array<PayInMethodModel>
  }>
> = (draft, action) => {
  draft.payInMethods.uiState = UiState.Success
  draft.payInMethods.creditCards = action.payload.creditCards
  draft.payInMethods.payInMethods = action.payload.payInMethods
  draft.payInMethods.walletBalance = action.payload.walletBalance
}

const getCreditCardSuccess: CaseReducer<
  State,
  PayloadAction<{
    creditCard: CreditCardModel
  }>
> = (draft, action) => {
  draft.payInMethods.creditCards.push(action.payload.creditCard)
}

const addCreditCardSuccess: CaseReducer<
  State,
  PayloadAction<{
    creditCard: CreditCardModel
  }>
> = (draft, action) => {
  const addedCard = action.payload.creditCard

  const sameCardIndex = draft.payInMethods.creditCards.findIndex(({ id }) => id === addedCard.id)
  const isCardAlreadyInState = sameCardIndex !== -1

  if (isCardAlreadyInState) {
    draft.payInMethods.creditCards[sameCardIndex] = addedCard
  } else {
    draft.payInMethods.creditCards.push(addedCard)
  }
}

const setSelectedPayInMethod: CaseReducer<
  State,
  PayloadAction<{
    creditCardId?: string
    selectedPayInMethodId: number
  }>
> = (draft, action) => {
  const { creditCardId, selectedPayInMethodId } = action.payload

  draft.payInMethods.selectedPayInMethod = {
    creditCardId,
    id: selectedPayInMethodId,
  }
}

const setErrorMessage: CaseReducer<State, PayloadAction<{ errorMessage?: string }>> = (
  draft,
  action,
) => {
  draft.errorMessage = action.payload.errorMessage
}

const triggerPaymentAuthWithRedirect: CaseReducer<
  State,
  PayloadAction<{ payInRedirectUrl: string }>
> = (draft, action) => {
  draft.orderPayment.auth.payInRedirectUrl = action.payload.payInRedirectUrl
}

const triggerEmbeddedPaymentAuth: CaseReducer<
  State,
  PayloadAction<{ paymentId: number; authAction: PaymentAuthActionModel }>
> = (draft, action) => {
  draft.orderPayment.auth.embedded = action.payload
}

const cancelOrderPaymentAuth: CaseReducer<State> = draft => {
  draft.orderPayment.status = ExtraServiceOrderStatus.Idle
  draft.orderPayment.auth = {
    embedded: null,
    payInRedirectUrl: null,
  }
  draft.orderPayment.error = {
    isKycConfirmationNeeded: false,
  }
}

const confirmOrderPaymentRequest: CaseReducer<
  State,
  PayloadAction<{
    blikCode?: string
    entityType?: string
    selectedCreditCardId?: string
    selectedPaymentMethodId?: number
    orderId: number
    orderType: ExtraServiceOrderType
  }>
> = draft => {
  draft.orderPayment.status = ExtraServiceOrderStatus.Processing
  draft.orderPayment.error = {
    isKycConfirmationNeeded: false,
  }
}

const confirmOrderPaymentSuccess: CaseReducer<State> = draft => {
  draft.orderPayment.status = ExtraServiceOrderStatus.Success
  draft.orderPayment.auth = {
    embedded: null,
    payInRedirectUrl: null,
  }
  draft.orderPayment.error = {
    isKycConfirmationNeeded: false,
  }
}

const updateOrderPaymentDataRequest: CaseReducer<
  State,
  PayloadAction<{ paymentId: number; state: PaymentAuthStateModel }>
> = draft => {
  draft.orderPayment.auth = {
    embedded: null,
    payInRedirectUrl: null,
  }
  draft.orderPayment.error = {
    isKycConfirmationNeeded: false,
  }
}

const setOrderPaymentErrorMessage: CaseReducer<State, PayloadAction<{ errorMessage?: string }>> = (
  draft,
  action,
) => {
  draft.orderPayment.status = ExtraServiceOrderStatus.Failure
  draft.orderPayment.auth = {
    embedded: null,
    payInRedirectUrl: null,
  }
  draft.orderPayment.error.message = action.payload.errorMessage
}

const setIsKycConfirmationNeeded: CaseReducer<State, PayloadAction<{ isNeeded: boolean }>> = (
  draft,
  action,
) => {
  draft.orderPayment.error.isKycConfirmationNeeded = action.payload.isNeeded
}

const fetchConfigurationSuccess: CaseReducer<
  State,
  PayloadAction<{
    configuration: CheckoutConfigurationModel
  }>
> = (draft, action) => {
  draft.orderPayment.configuration = action.payload.configuration
}

const fetchConfigurationFailure: CaseReducer<State, PayloadAction<{ errorMessage?: string }>> = (
  draft,
  action,
) => {
  draft.orderPayment.configuration = null
  draft.orderPayment.status = ExtraServiceOrderStatus.Failure
  draft.orderPayment.error.message = action.payload.errorMessage
}

const extraServiceSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    triggerPaymentAuthWithRedirect,
    triggerEmbeddedPaymentAuth,
    setErrorMessage,
    getCreditCardSuccess,
    addCreditCardSuccess,
    cancelOrderPaymentAuth,
    setSelectedPayInMethod,
    getPayInMethodsRequest,
    getPayInMethodsFailure,
    getPayInMethodsSuccess,
    fetchConfigurationFailure,
    fetchConfigurationSuccess,
    setIsKycConfirmationNeeded,
    confirmOrderPaymentRequest,
    confirmOrderPaymentSuccess,
    setOrderPaymentErrorMessage,
    updateOrderPaymentDataRequest,
  },
})

export const { actions } = extraServiceSlice
export const plug = { [stateName]: extraServiceSlice.reducer }
export default extraServiceSlice.reducer
