import { useCallback } from 'react'
import { useIntl } from 'react-intl'

import { pluralization, defaultInterpolations } from 'libs/utils/i18n'
import { Pluralize, TranslateWithPluralize } from 'types/intl'

function useTranslate(prefix?: string) {
  const intl = useIntl()

  return useCallback<TranslateWithPluralize>(
    (suffix: string, values, pluralize?: Pluralize) => {
      const id = prefix ? `${prefix}.${suffix}` : suffix

      const pluralizeFunc = pluralization(intl.locale)
      const translationKey = pluralize ? `${id}.${pluralizeFunc(pluralize.count)}` : id

      if (intl.messages[translationKey] === '') return ''
      if (!values) return intl.formatMessage({ id: translationKey })

      return intl.formatMessage({ id: translationKey }, { ...defaultInterpolations, ...values })
    },
    [intl, prefix],
  )
}

export default useTranslate
