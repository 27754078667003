import { ItemUploadABTestDto, ItemUploadConfigurationDto } from 'types/dtos'
import { ItemUploadABTestModel, ItemUploadConfigurationModel } from 'types/models'

export const transformABTestDtoToModel = (dto: ItemUploadABTestDto): ItemUploadABTestModel => {
  return {
    testId: dto.test_id,
    testName: dto.test_name,
    countryCode: dto.country_code,
    testAnonId: dto.test_anon_id,
    testUserId: dto.test_user_id,
    variant: dto.variant,
  }
}

export const transformItemUploadConfigDtoToModel = (
  dto: ItemUploadConfigurationDto,
): ItemUploadConfigurationModel => {
  const transformedABTests: { [key: string]: ItemUploadABTestModel } = {}

  if (dto.ab_tests) {
    Object.keys(dto.ab_tests).forEach(key => {
      if (dto.ab_tests?.[key]) {
        transformedABTests[key] = transformABTestDtoToModel(dto.ab_tests[key])
      }
    })
  }

  return {
    catalogGroupFields: dto.catalog_group_fields,
    maxCustomShippingPrice: dto.max_custom_shipping_price,
    abTests: transformedABTests,
  }
}
