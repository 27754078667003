import { createAction } from '@reduxjs/toolkit'

import { ExtraServicePaymentResp, ResponseError } from 'types/api'

import { stateName } from './constants'

export const handleOrderConfirmationResult = createAction<{
  response: ExtraServicePaymentResp | ResponseError
}>(`${stateName}/handleOrderConfirmationResult`)

export const fetchConfigurationRequest = createAction(`${stateName}/fetchConfigurationRequest`)
