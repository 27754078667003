import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { BankAccountRegistrationModel, BankAccountModel } from 'types/models'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  registration: {
    bankAccountRegistration: null,
    uiState: UiState.Idle,
  },
  bankAccounts: null,
  uiState: UiState.Idle,
}

const fetchBankAccountRegistrationRequest: CaseReducer<State> = draft => {
  draft.registration.uiState = UiState.Pending
}

const fetchBankAccountRegistrationFailure: CaseReducer<State> = draft => {
  draft.registration.uiState = UiState.Failure
}

const fetchBankAccountRegistrationSuccess: CaseReducer<
  State,
  PayloadAction<{ bankAccountRegistration: BankAccountRegistrationModel }>
> = (draft, action) => {
  const { bankAccountRegistration } = action.payload

  draft.registration = {
    bankAccountRegistration,
    uiState: UiState.Success,
  }
}

const fetchBankAccountsRequest: CaseReducer<State> = draft => {
  draft.uiState = UiState.Pending
}

const fetchBankAccountsFailure: CaseReducer<State> = draft => {
  draft.uiState = UiState.Failure
}

const fetchBankAccountsSuccess: CaseReducer<
  State,
  PayloadAction<{ bankAccounts: Array<BankAccountModel> }>
> = (draft, action) => {
  const { bankAccounts } = action.payload

  draft.bankAccounts = bankAccounts
  draft.uiState = UiState.Success
}

const bankAccountSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    fetchBankAccountRegistrationRequest,
    fetchBankAccountRegistrationFailure,
    fetchBankAccountRegistrationSuccess,
    fetchBankAccountsRequest,
    fetchBankAccountsFailure,
    fetchBankAccountsSuccess,
  },
})

export const { actions } = bankAccountSlice
export const plug = { [stateName]: bankAccountSlice.reducer }
export default bankAccountSlice.reducer
