import { isNumber } from 'lodash'

import { nullifyEmptyValue } from 'libs/utils/object'

import { ClickableElement } from 'constants/tracking/clickable-elements'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { Screen } from 'constants/tracking/screens'
import { FailureReason } from 'constants/tracking/failure-reasons'
import { SavedSearchType } from 'constants/tracking/search'
import { ViewableElement } from 'constants/tracking/viewable-elements'
import { OndatoWebSdkState } from 'constants/tracking/ondato'
import { CatalogAttribute } from 'constants/catalog'
import { SearchSuggestionType } from 'constants/search'
import {
  FieldTrackingName as ItemUploadFieldTrackingName,
  ItemUploadFailReason,
} from 'state/item-upload/constants'
import { SortByOption } from 'constants/filter'
import { AdPage } from 'constants/ads'
import { ColorTheme } from 'constants/dark-mode'
import { Filter } from 'constants/tracking/filters'
import { ItemBlockCtaType } from 'constants/home'
import {
  ExtraServiceOrderType,
  extraServiceOrderTypeToTrackingTypeMap,
} from 'constants/extra-service'
import { PaymentsProvider } from 'constants/payments'

import { ErrorItem } from 'types/api'
import { FilterModel } from 'types/models'
import { SelectedDynamicFilterModel } from 'types/models/dynamic-filter'
import { TransactionSide } from 'constants/transaction'
import { CreditCardType, CreditCardFormLocation } from 'constants/credit-card'

import { TrackingEvent, AbTestExposeEventExtra } from './types'

export type clickEventArgs = {
  target: ClickableElement
  screen?: string | null
  targetDetails?: string | null
  path?: string | null
  env?: string | null
}

type ClickEventExtra = {
  target: ClickableElement
  screen?: string
  target_details?: string
  path?: string | null
  env?: string | null
}

const clickEvent = (args: clickEventArgs): TrackingEvent => {
  const { target, screen, targetDetails, env, path } = args
  const extra: ClickEventExtra = { target }

  if (screen) extra.screen = screen

  if (targetDetails) extra.target_details = targetDetails

  if (env) extra.env = env

  if (path) extra.path = path

  return {
    event: 'user.click',
    extra,
  }
}

export type kycClickEventArgs = {
  target: ClickableElement
  details?: string | number | null
}

type KycClickEventExtra = {
  target: ClickableElement
  details?: string | number | null
}

const kycClickEvent = (args: kycClickEventArgs): TrackingEvent => {
  const { target, details } = args

  const extra: KycClickEventExtra = { target }

  if (details) extra.details = details

  return {
    event: 'kyc.user.click',
    extra,
  }
}

type FavouriteItemEventArgs = {
  itemId: number
  isFollowEvent: boolean
  contentSource?: ContentSource | null
  searchCorrelationId?: string
  searchSessionId?: string
  globalSearchSessionId?: string | null
  homepageSessionId?: string
}

type FavouriteItemEventExtra = {
  item_id: number
  content_source?: ContentSource
  search_correlation_id?: string
  search_session_id?: string
  global_search_session_id?: string | null
  homepage_session_id?: string
}

const favouriteItemEvent = ({
  itemId,
  isFollowEvent,
  contentSource,
  searchCorrelationId,
  searchSessionId,
  globalSearchSessionId,
  homepageSessionId,
}: FavouriteItemEventArgs): TrackingEvent => {
  const eventName = isFollowEvent ? 'user.favorite_item' : 'user.unfavorite_item'
  const extra: FavouriteItemEventExtra = { item_id: itemId }

  if (contentSource) extra.content_source = contentSource
  if (searchCorrelationId) extra.search_correlation_id = searchCorrelationId
  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId
  if (homepageSessionId) extra.homepage_session_id = homepageSessionId

  return {
    event: eventName,
    extra,
  }
}

type followUserEventArgs = {
  userId: number
  isFollowEvent: boolean
  contentSource?: ContentSource
}

type FollowUserEventExtra = {
  user_id: number
  content_source?: ContentSource
}

const followUserEvent = ({
  userId,
  isFollowEvent,
  contentSource,
}: followUserEventArgs): TrackingEvent => {
  const eventName = isFollowEvent ? 'user.follow_user' : 'user.unfollow_user'

  const extra: FollowUserEventExtra = {
    user_id: userId,
  }

  if (isFollowEvent) extra.content_source = contentSource

  return {
    event: eventName,
    extra,
  }
}

type followBrandEventArgs = {
  brandId: number
  isFollowEvent: boolean
}

type FollowBrandEventExtra = {
  brand_id: number
}

const followBrandEvent = ({ brandId, isFollowEvent }: followBrandEventArgs): TrackingEvent => {
  const eventName = isFollowEvent ? 'user.follow_brand' : 'user.unfollow_brand'

  const extra: FollowBrandEventExtra = {
    brand_id: brandId,
  }

  return {
    event: eventName,
    extra,
  }
}

type ImpressionEventArgs = {
  id: number | string
  contentType: ListItemContentType
  contentSource: ContentSource
  position: number
  itemDistance?: number | null
  itemOwnerId?: string | number | null
  searchScore?: string
  searchSignals?: Array<string> | null
  searchCorrelationId?: string
  searchSessionId?: string | null
  globalSearchSessionId?: string | null
  homepageSessionId?: string
  bumpRecommended?: boolean
  uploadSessionId?: string
  screen?: Screen
}

type ImpressionEventExtra = {
  content_type: ListItemContentType
  content_source: ContentSource
  id: number | string
  position: number
  item_owner_id?: string | number
  search_score?: string
  search_signals?: Array<string>
  search_correlation_id?: string
  search_session_id?: string
  global_search_session_id?: string | null
  buyer_item_distance_km?: number
  homepage_session_id?: string
  bump_recommended?: boolean
  upload_session_id?: string
  screen?: Screen
}

const impressionEvent = (args: ImpressionEventArgs): TrackingEvent => {
  const {
    id,
    contentType,
    contentSource,
    itemDistance,
    itemOwnerId,
    position,
    searchScore,
    searchSignals,
    searchCorrelationId,
    searchSessionId,
    globalSearchSessionId,
    homepageSessionId,
    bumpRecommended,
    uploadSessionId,
    screen,
  } = args

  const extra: ImpressionEventExtra = {
    id,
    position,
    content_type: contentType,
    content_source: contentSource,
  }

  if (itemOwnerId) extra.item_owner_id = itemOwnerId
  if (searchScore) extra.search_score = JSON.stringify(searchScore)
  if (searchSignals) extra.search_signals = searchSignals
  if (searchCorrelationId) extra.search_correlation_id = searchCorrelationId
  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId
  if (isNumber(itemDistance)) extra.buyer_item_distance_km = itemDistance
  if (homepageSessionId) extra.homepage_session_id = homepageSessionId
  if (bumpRecommended) extra.bump_recommended = bumpRecommended
  if (uploadSessionId) extra.upload_session_id = uploadSessionId
  if (screen) extra.screen = screen

  return {
    event: 'list.show_item',
    extra,
  }
}

export type ClickListItemEventArgs = {
  id: number | string
  contentType: ListItemContentType
  contentSource: ContentSource
  position: number
  searchCorrelationId?: string
  searchSessionId?: string | null
  globalSearchSessionId?: string | null
  searchScore?: string
  searchSignals?: Array<string> | null
  itemDistance?: number | null
  homepageSessionId?: string
}

type ClickListEventExtra = {
  content_type: ListItemContentType
  content_source: ContentSource
  id: number | string
  position: number
  search_correlation_id?: string
  search_session_id?: string
  global_search_session_id?: string | null
  search_score?: string
  search_signals?: Array<string>
  buyer_item_distance_km?: number
  homepage_session_id?: string
}

const clickListItemEvent = (args: ClickListItemEventArgs): TrackingEvent => {
  const {
    id,
    contentType,
    contentSource,
    position,
    searchCorrelationId,
    searchSessionId,
    globalSearchSessionId,
    searchScore,
    searchSignals,
    itemDistance,
    homepageSessionId,
  } = args

  const extra: ClickListEventExtra = {
    id,
    position,
    content_type: contentType,
    content_source: contentSource,
  }

  if (searchCorrelationId) extra.search_correlation_id = searchCorrelationId
  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId
  if (searchScore) extra.search_score = searchScore
  if (searchSignals) extra.search_signals = searchSignals
  if (isNumber(itemDistance)) extra.buyer_item_distance_km = itemDistance
  if (homepageSessionId) extra.homepage_session_id = homepageSessionId

  return {
    event: 'user.click_list_item',
    extra,
  }
}

export type profileClickEventArgs = {
  target: ClickableElement
  ownerId: string
  screen?: Screen
}

type profileClickEventExtra = {
  target: ClickableElement
  owner_id: string
  screen?: Screen
}

const profileClickEvent = (args: profileClickEventArgs): TrackingEvent => {
  const { target, ownerId, screen } = args

  const extra: profileClickEventExtra = { target, owner_id: ownerId }

  if (screen) extra.screen = screen

  return {
    event: 'profile.click',
    extra,
  }
}

export type ClickClosetPromotionEventArgs = {
  ownerId: number
  contentSource: ContentSource
  target: string
}

type ClickClosetPromotionEventExtra = {
  owner_id: number
  content_source: ContentSource
  target: string
}

const clickClosetPromotionEvent = (args: ClickClosetPromotionEventArgs) => {
  const { contentSource, target, ownerId } = args

  const extra: ClickClosetPromotionEventExtra = {
    owner_id: ownerId,
    content_source: contentSource,
    target,
  }

  return {
    event: 'user.click_closet_promotion',
    extra,
  }
}

type endOfListEventArgs = {
  itemCount: number
  page?: number
  perPage?: number
}

type EndOfListEventExtra = {
  item_count: number
  page?: number
  per_page?: number
}

const endOfListEvent = (args: endOfListEventArgs): TrackingEvent => {
  const { itemCount, page, perPage } = args

  const extra: EndOfListEventExtra = {
    item_count: itemCount,
  }

  if (page) extra.page = page
  if (perPage) extra.per_page = perPage

  return {
    event: 'user.reach_end_of_list',
    extra,
  }
}

type viewEventArgs = {
  target: ViewableElement
  targetDetails?: string
  screen?: string
}

type ViewEventExtra = {
  target: ViewableElement
  target_details?: string
  screen?: string
}

const viewEvent = (args: viewEventArgs): TrackingEvent => {
  const { target, targetDetails, screen } = args

  const extra: ViewEventExtra = {
    target,
  }

  if (targetDetails) extra.target_details = targetDetails
  if (screen) extra.screen = screen

  return {
    event: 'user.view',
    extra,
  }
}

type viewScreenEventArgs = {
  screen: string
}

const viewScreenEvent = (args: viewScreenEventArgs): TrackingEvent => {
  const { screen } = args

  const extra = {
    screen,
  }

  return {
    event: 'user.view_screen',
    extra,
  }
}

type viewUserEventArgs = {
  path: string
  user_id: number
}

const viewUserEvent = (args: viewUserEventArgs): TrackingEvent => {
  const { path, user_id } = args

  const extra = {
    path,
    user_id,
  }

  return {
    event: 'user.view_user',
    extra,
  }
}

type kycViewScreenEventArgs = {
  screen: string
  details?: string | number | null
}

type KycViewScreenEventExtra = {
  screen: string
  details?: string | number | null
}

const kycViewScreenEvent = (args: kycViewScreenEventArgs): TrackingEvent => {
  const { screen, details } = args

  const extra: KycViewScreenEventExtra = { screen }

  if (details) extra.details = details

  return {
    event: 'kyc.user.view_screen',
    extra,
  }
}

type systemTraceClosetPromoEventArgs = {
  uuid: string
  closetCount: number
  itemCount: number
  type: string
}

const systemTraceClosetPromoEvent = (args: systemTraceClosetPromoEventArgs): TrackingEvent => {
  const { uuid, closetCount, itemCount, type } = args

  return {
    event: 'system.trace_closet_promo',
    extra: {
      uuid,
      type,
      closet_count: closetCount,
      item_count: itemCount,
    },
  }
}

type conversationReplyEventArgs = {
  userId: number
  itemId: number | null
}

const conversationReplyEvent = (args: conversationReplyEventArgs): TrackingEvent => {
  const { itemId, userId } = args

  const extra = {
    user_id: userId,
    item_id: itemId,
  }

  return {
    event: 'user.reply',
    extra,
  }
}

export type SelectSearchSuggestionEventArgs = {
  suggestions: Array<string>
  index: number
  suggestion: string
  subtitle?: string
  query: string
  scopedSuggestionCount: number
  countryCode: string | undefined
  searchSessionId: string
  type?: SearchSuggestionType
  typeId?: string
  globalSearchSessionId: string | null
  suggestionsSessionId: string
  params?: Array<Map<string, Array<string>>>
  isFrontendGeneratedSuggestion: boolean
  suggestionsListId?: string | null
  suggestionPosition?: number
}

type SelectSearchSuggestionEventExtra = {
  suggestion_index: number
  suggestions_list: Array<string>
  selected_suggestion_text: string
  selected_suggestion_subtitle?: string
  query: string
  filter_suggestions_count: number
  country_code?: string
  search_session_id?: string
  global_search_session_id: string | null
  type?: SearchSuggestionType
  type_id?: string
  suggestions_session_id: string
  params?: Array<Map<string, Array<string>>>
  frontend_generated_text_selected?: boolean
  suggestions_list_id?: string | null
  suggestion_position?: number
}

const selectSearchSuggestionEvent = (args: SelectSearchSuggestionEventArgs): TrackingEvent => {
  const {
    suggestions,
    index,
    suggestion,
    subtitle,
    scopedSuggestionCount,
    query,
    countryCode,
    searchSessionId,
    type,
    typeId,
    globalSearchSessionId,
    suggestionsSessionId,
    params,
    isFrontendGeneratedSuggestion,
    suggestionsListId,
    suggestionPosition,
  } = args

  const extra: SelectSearchSuggestionEventExtra = {
    suggestions_session_id: suggestionsSessionId,
    suggestion_index: index,
    suggestions_list: suggestions,
    selected_suggestion_text: suggestion,
    filter_suggestions_count: scopedSuggestionCount,
    query,
    global_search_session_id: globalSearchSessionId,
    frontend_generated_text_selected: isFrontendGeneratedSuggestion,
  }

  if (subtitle) {
    extra.selected_suggestion_subtitle = subtitle
  }

  if (countryCode) {
    extra.country_code = countryCode
  }

  if (searchSessionId) {
    extra.search_session_id = searchSessionId
  }

  if (params?.length) {
    extra.params = params
  }

  const allowedSuggestionTypes = [
    SearchSuggestionType.Catalog,
    SearchSuggestionType.Brand,
    SearchSuggestionType.Fallback,
  ]

  if (type && allowedSuggestionTypes.includes(type)) {
    extra.type = type
  }

  if (typeId) {
    extra.type_id = typeId
  }

  if (suggestionsListId) {
    extra.suggestions_list_id = suggestionsListId
  }

  if (suggestionPosition) {
    extra.suggestion_position = suggestionPosition
  }

  return {
    event: 'user.select_search_suggestion',
    extra,
  }
}

export type ViewSearchSuggestionsEventArgs = {
  suggestions: Array<string>
  query: string
  suggestionsSessionId: string
}

type ViewSearchSuggestionsEventExtra = {
  suggestions_list: Array<string>
  query: string
  suggestions_session_id: string
}

const viewSearchSuggestionsEvent = (args: ViewSearchSuggestionsEventArgs): TrackingEvent => {
  const { suggestions, query, suggestionsSessionId } = args

  const extra: ViewSearchSuggestionsEventExtra = {
    suggestions_session_id: suggestionsSessionId,
    suggestions_list: suggestions,
    query,
  }

  return {
    event: 'user.view_search_suggestions',
    extra,
  }
}

export type ViewSearchSuggestionEventArgs = {
  suggestionPosition: number
  selectedSuggestionText: string
  query: string
  suggestionsSessionId: string
  suggestionsListId: string | null
  params?: Array<Map<string, Array<string>>>
  frontendGeneratedTextSelected: boolean
  searchSessionId: string | null
  globalSearchSessionId: string | null
}

type ViewSearchSuggestionEventExtra = {
  suggestion_position: number
  selected_suggestion_text: string
  query: string
  suggestions_session_id: string
  suggestions_list_id: string | null
  params?: Array<Map<string, Array<string>>>
  frontend_generated_text_selected: boolean
  search_session_id: string | null
  global_search_session_id: string | null
}

const viewSearchSuggestionEvent = (args: ViewSearchSuggestionEventArgs): TrackingEvent => {
  const {
    suggestionPosition,
    query,
    suggestionsSessionId,
    selectedSuggestionText,
    suggestionsListId,
    params,
    frontendGeneratedTextSelected,
    searchSessionId,
    globalSearchSessionId,
  } = args

  const extra: ViewSearchSuggestionEventExtra = {
    suggestion_position: suggestionPosition,
    selected_suggestion_text: selectedSuggestionText,
    query,
    suggestions_session_id: suggestionsSessionId,
    suggestions_list_id: suggestionsListId,
    frontend_generated_text_selected: frontendGeneratedTextSelected,
    search_session_id: searchSessionId,
    global_search_session_id: globalSearchSessionId,
  }

  if (params?.length) {
    extra.params = params
  }

  return {
    event: 'user.view_search_suggestion',
    extra,
  }
}

type viewShippingPriceEventArgs = {
  prices: Array<string>
  screen: string
  itemId?: number
  transactionId?: number
}

type ViewShippingPriceEventExtra = {
  prices: Array<string> | null
  screen: string
  item_id?: number
  transaction_id?: number
}

const viewShippingPriceEvent = (args: viewShippingPriceEventArgs): TrackingEvent => {
  const { prices, screen, itemId, transactionId } = args

  const extra: ViewShippingPriceEventExtra = {
    prices: prices.length ? prices : null,
    screen,
  }

  if (itemId) extra.item_id = itemId
  if (transactionId) extra.transaction_id = transactionId

  return {
    event: 'user.view_shipping_price',
    extra,
  }
}

type ClickFilterEventArgs = {
  filter: Filter
  screen?: Screen
  searchSessionId: string
  globalSearchSessionId: string | null
  type?: string
  code?: string
}

type ClickFilterEventExtra = {
  filter: Filter
  screen?: Screen
  search_session_id?: string
  global_search_session_id?: string
  type?: string
  code?: string
}

const clickFilterEvent = (args: ClickFilterEventArgs): TrackingEvent => {
  const { filter, screen, searchSessionId, globalSearchSessionId, type, code } = args
  const extra: ClickFilterEventExtra = {
    filter,
    screen,
  }

  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId
  if (type) extra.type = type
  if (code) extra.code = code

  return {
    event: 'user.click_filter',
    extra,
  }
}

type ViewFilterEventArgs = {
  filter: Filter
  screen?: Screen
  searchSessionId: string
  globalSearchSessionId: string | null
  type?: string
  code?: string
}

type ViewFilterEventExtra = {
  filter: Filter
  screen?: Screen
  search_session_id?: string
  global_search_session_id?: string
  type?: string
  code?: string
}

const viewFilterEvent = (args: ViewFilterEventArgs): TrackingEvent => {
  const { filter, screen, searchSessionId, globalSearchSessionId, type, code } = args
  const extra: ViewFilterEventExtra = {
    filter,
    screen,
  }

  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId
  if (type) extra.type = type
  if (code) extra.code = code

  return {
    event: 'user.view_filter',
    extra,
  }
}

type startConversationEventArgs = {
  userId: number
  itemId: number | string | null | undefined
}

const startConversationEvent = (args: startConversationEventArgs): TrackingEvent => {
  const { itemId, userId } = args

  const extra = {
    user_id: userId,
    item_id: itemId,
  }

  return {
    event: 'user.start_conversation',
    extra,
  }
}

type checkoutBuyerInputEventArgs = {
  transactionId?: number | null
  targetDetails?: string
  screen: string
  target: string
  valid: boolean | null
}

type CheckoutBuyerInputEventExtra = {
  valid: boolean | null
  screen: string
  target: string
  target_details?: string
  transaction_id?: number
}

const checkoutBuyerInputEvent = (args: checkoutBuyerInputEventArgs): TrackingEvent => {
  const { transactionId, targetDetails, screen, target, valid } = args

  const extra: CheckoutBuyerInputEventExtra = {
    screen,
    target,
    valid,
  }

  if (transactionId) extra.transaction_id = transactionId
  if (targetDetails) extra.target_details = targetDetails

  return {
    event: 'checkout.buyer.input',
    extra,
  }
}

type SingleCheckoutBuyerInputEventArgs = {
  valid: boolean | null
  screen: string
  target: string
  checkoutId?: string
  location?: CreditCardFormLocation.Checkout | CreditCardFormLocation.Settings
}

type SingleCheckoutBuyerInputEventExtra = {
  valid: boolean | null
  screen: string
  target: string
  checkout_id?: string
  location: CreditCardFormLocation.Checkout | CreditCardFormLocation.Settings
}

const singleCheckoutBuyerInputEvent = (args: SingleCheckoutBuyerInputEventArgs): TrackingEvent => {
  const { checkoutId, screen, target, valid, location } = args

  const extra: SingleCheckoutBuyerInputEventExtra = {
    screen,
    target,
    valid,
    location: location || CreditCardFormLocation.Checkout,
  }

  if (checkoutId) extra.checkout_id = checkoutId

  return {
    event: 'credit_card_add.input',
    extra,
  }
}

type transactionSellerInputEventArgs = {
  screen: string
  target: string
  valid: boolean | null
  targetDetails?: string
  transactionId?: number | null
}

type transactionSellerInputEventExtra = {
  screen: string
  target: string
  valid: boolean | null
  target_details?: string
  transaction_id?: number
}

const transactionSellerInputEvent = (args: transactionSellerInputEventArgs): TrackingEvent => {
  const { transactionId, targetDetails, screen, target, valid } = args

  const extra: transactionSellerInputEventExtra = {
    screen,
    target,
    valid,
  }

  if (transactionId) extra.transaction_id = transactionId
  if (targetDetails) extra.target_details = targetDetails

  return {
    event: 'transaction.seller_input',
    extra,
  }
}

type UserInputEventArgs = {
  screen?: Screen
  target: string
  value?: string | null
  state: 'focus' | 'unfocus'
  tempUuid?: string
  isDynamic?: boolean
}

const userInputEvent = (args: UserInputEventArgs): TrackingEvent => {
  const { state, target, value, screen, tempUuid, isDynamic } = args
  const extra = {
    screen,
    target,
    value,
    state,
    upload_session_id: tempUuid,
    is_dynamic: isDynamic || false,
  }

  return {
    event: 'user.input',
    extra,
  }
}

type CheckoutEventExtra = {
  transaction_id: number
  payment_method: string
  screen: string
}

type checkoutPickPaymentMethodEventArgs = {
  transactionId: number
  eventTrackingCode: string | null
  screen: string
}

const checkoutPickPaymentMethodEvent = (
  args: checkoutPickPaymentMethodEventArgs,
): TrackingEvent => {
  const { transactionId, eventTrackingCode, screen } = args

  const extra: CheckoutEventExtra = {
    transaction_id: transactionId,
    payment_method: eventTrackingCode || '',
    screen,
  }

  return {
    event: 'buyer.pick_payment_method',
    extra,
  }
}

type BuyerViewEscrowCheckoutEventArgs = {
  transactionId: number | null
  isExternalOpen?: boolean
  screen: string
}

type BuyerViewExtraServiceCheckoutEventArgs = {
  orderId: number
  orderType: ExtraServiceOrderType
  screen: string
}

type BuyerViewEscrowCheckoutEventExtra = {
  transaction_id: number | string
  entry_from_link: boolean | null
  checkout_type: 'escrow'
  screen: string
}

type BuyerViewExtraServiceCheckoutEventExtra = {
  order_id: number
  checkout_type: string
  screen: string
}

type BuyerViewCheckoutEventArgs =
  | BuyerViewEscrowCheckoutEventArgs
  | BuyerViewExtraServiceCheckoutEventArgs

const buyerViewCheckoutEvent = (args: BuyerViewCheckoutEventArgs): TrackingEvent => {
  const event = 'buyer.view_checkout'

  if ('transactionId' in args) {
    const extra: BuyerViewEscrowCheckoutEventExtra = {
      screen: args.screen,
      checkout_type: 'escrow',
      transaction_id: args.transactionId ?? '',
      entry_from_link: args.isExternalOpen === undefined ? null : !!args.isExternalOpen,
    }

    return { event, extra }
  }

  const extra: BuyerViewExtraServiceCheckoutEventExtra = {
    screen: args.screen,
    order_id: args.orderId,
    checkout_type: extraServiceOrderTypeToTrackingTypeMap[args.orderType],
  }

  return { event, extra }
}

type ViewSingleCheckoutScreenEventArgs = {
  screen?: string
  checkoutId?: string
}

const viewSingleCheckoutEvent = (args: ViewSingleCheckoutScreenEventArgs): TrackingEvent => {
  return {
    event: 'checkout.screen',
    extra: {
      screen: args.screen || ViewableElement.Checkout,
      checkout_id: args.checkoutId,
    },
  }
}

type ClickSingleCheckoutScreenEventArgs = {
  screen?: string
  target?: string
  checkoutId?: string
}

const clickSingleCheckoutEvent = (args: ClickSingleCheckoutScreenEventArgs): TrackingEvent => {
  return {
    event: 'checkout.click',
    extra: {
      target: args.target,
      checkout_id: args.checkoutId,
      screen: args.screen || Screen.Checkout,
    },
  }
}

type ViewCreditCardAddEventArgs = {
  screen?: string
  location?: CreditCardFormLocation.Checkout | CreditCardFormLocation.Settings
  checkoutId: string
}

const viewCreditCardAddEvent = (args: ViewCreditCardAddEventArgs): TrackingEvent => {
  return {
    event: 'credit_card_add.screen',
    extra: {
      screen: args.screen || Screen.CreditCardAdd,
      checkout_id: args.checkoutId,
      location: args.location || CreditCardFormLocation.Checkout,
    },
  }
}

type CreditCardAddClickEventArgs = {
  cardBrandIdentificator?: string | null
  valid?: boolean
  cardDetailsStored?: boolean
  cardBrand?: CreditCardType
  checkoutId?: string
  target: ClickableElement
  screen?: Screen
  location?: CreditCardFormLocation.Checkout | CreditCardFormLocation.Settings
  clickId?: string
}

type CreditCardAddClickEventExtra = {
  card_brand_identificator?: string | null
  target: ClickableElement
  valid?: boolean
  card_details_stored?: boolean
  card_brand?: CreditCardType
  checkout_id?: string
  screen: Screen
  location: CreditCardFormLocation.Checkout | CreditCardFormLocation.Settings
  click_id?: string
}

const creditCardAddClickEvent = (args: CreditCardAddClickEventArgs) => {
  const {
    valid,
    cardDetailsStored,
    cardBrand,
    cardBrandIdentificator,
    checkoutId,
    target,
    clickId,
    location,
  } = args

  const extra: CreditCardAddClickEventExtra = {
    screen: args.screen || Screen.CreditCardAdd,
    location: location || CreditCardFormLocation.Checkout,
    target,
    checkout_id: checkoutId,
    valid,
  }

  if (clickId) extra.click_id = clickId
  if (cardDetailsStored) extra.card_details_stored = cardDetailsStored
  if (cardBrand) extra.card_brand = cardBrand
  if (cardBrandIdentificator) extra.card_brand_identificator = cardBrandIdentificator

  return {
    event: 'credit_card_add.click',
    extra,
  }
}

type ViewPaymentSettingsEventArgs = {
  screen: Screen
}

const buyerViewSettingsCheckoutEvent = (args: ViewPaymentSettingsEventArgs) => {
  return {
    event: 'buyer.view_checkout',
    extra: {
      screen: args.screen,
      checkout_type: 'settings',
    },
  }
}

type UserViewCheckoutEventArgs = {
  screen: string
}

type UserViewCheckoutEventExtra = {
  screen: string
}

const userViewCheckoutEvent = (args: UserViewCheckoutEventArgs): TrackingEvent => {
  const { screen } = args

  const extra: UserViewCheckoutEventExtra = {
    screen,
  }

  return {
    event: 'user.view_checkout',
    extra,
  }
}

type SelectCatalogEventArgs = {
  catalogIds: Array<number>
  attributeId?: CatalogAttribute
  screen?: Screen
}

type SelectCatalogEventExtra = {
  catalog_id: string
  attribute_id: number | null
  screen?: string
}

const selectCatalogEvent = (args: SelectCatalogEventArgs): TrackingEvent => {
  const { catalogIds, attributeId, screen } = args

  const extra: SelectCatalogEventExtra = {
    catalog_id: catalogIds.join(','),
    attribute_id: attributeId ?? null,
  }

  if (screen) extra.screen = screen

  return {
    event: 'user.select_catalog',
    extra,
  }
}

type selfServiceViewScreenEventArgs = {
  screen: string
  transactionId: number | string
  faqEntryId?: number | string | null
  details?: string | null
  variant?: string | null
}

type selfServiceViewScreenEventExtra = {
  screen: string
  transaction_id: number | string
  faq_entry_id?: number | string | null
  details?: string | null
  // "variant" is not present in the schema, however it is used in some places
  variant?: string | null
}

const selfServiceViewScreenEvent = (args: selfServiceViewScreenEventArgs): TrackingEvent => {
  const { screen, transactionId, faqEntryId, details, variant } = args

  const extra: selfServiceViewScreenEventExtra = {
    screen,
    transaction_id: transactionId,
  }

  if (faqEntryId) extra.faq_entry_id = faqEntryId
  if (details) extra.details = details
  if (variant) extra.variant = variant

  return {
    event: 'user.self_service.view_screen',
    extra,
  }
}

type viewPopularSearchEventArgs = {
  index: number
  query: string
}

type ViewPopularSearchEventExtra = {
  index: number
  search_query: string
}

const viewPopularSearchEvent = (args: viewPopularSearchEventArgs): TrackingEvent => {
  const { index, query } = args

  const extra: ViewPopularSearchEventExtra = {
    index,
    search_query: query,
  }

  return {
    event: 'user.view_popular_search',
    extra,
  }
}

type selectPopularSearchEventArgs = {
  index: number
  query: string
}

type SelectPopularSearchEventExtra = {
  index: number
  search_query: string
}

const selectPopularSearchEvent = (args: selectPopularSearchEventArgs): TrackingEvent => {
  const { index, query } = args

  const extra: SelectPopularSearchEventExtra = {
    index,
    search_query: query,
  }

  return {
    event: 'user.select_popular_search',
    extra,
  }
}

type SelectBrandEventArgs = {
  brandId: number
  brandClusterId: number
  position: number
}

type SelectBrandEventExtra = {
  brand_id: number
  brand_cluster_id: number
  position: number
}

const selectBrandEvent = (args: SelectBrandEventArgs): TrackingEvent => {
  const { brandId, brandClusterId, position } = args

  const extra: SelectBrandEventExtra = {
    brand_id: brandId,
    brand_cluster_id: brandClusterId,
    position,
  }

  return {
    event: 'user.select_brand',
    extra,
  }
}

type UserFilterItemsEventArgs = {
  itemCount: number
  filters: FilterModel
  searchCorrelationId: string
  searchSessionId: string
  globalSearchSessionId: string | null
  searchStartId: string | null
  searchStartType: string | null
  selectedDynamicFilters: Array<SelectedDynamicFilterModel>
}

type UserFilterItemsEventExtra = {
  item_count: number
  search_correlation_id: string
  search_session_id: string
  global_search_session_id: string | null
  conditions: Partial<{
    search_term: string | null
    brand_ids: Array<number> | null
    catalog_ids: Array<number> | null
    color_ids: Array<number> | null
    material_ids: Array<number> | null
    video_game_rating_ids: Array<number> | null
    size_ids: Array<number> | null
    status_ids: Array<number> | null
    price_from: number | null
    price_to: number | null
    order: string | null
    popular: boolean
  }>
  search_start_id?: string
  search_start_type?: string
  selected_filters?: Record<string, Array<number>>
}

const userFilterItemsEvent = (args: UserFilterItemsEventArgs): TrackingEvent => {
  const {
    itemCount,
    filters,
    searchCorrelationId,
    searchSessionId,
    globalSearchSessionId,
    searchStartId,
    searchStartType,
    selectedDynamicFilters,
  } = args

  const extra: UserFilterItemsEventExtra = {
    item_count: itemCount,
    search_correlation_id: searchCorrelationId,
    search_session_id: searchSessionId,
    global_search_session_id: globalSearchSessionId,
    conditions: {
      search_term: nullifyEmptyValue(filters.query),
      catalog_ids: nullifyEmptyValue(filters.catalogIds),
      price_from: filters.priceFrom ? parseFloat(filters.priceFrom) : null,
      price_to: filters.priceTo ? parseFloat(filters.priceTo) : null,
      order: nullifyEmptyValue(filters.sortBy),
      popular: filters.isPopularCatalog,
    },
  }

  if (searchStartType) {
    extra.search_start_type = searchStartType
  }

  if (searchStartId) {
    extra.search_start_id = searchStartId
  }

  selectedDynamicFilters
    .filter(({ ids }) => ids.length)
    .forEach(({ type, ids }) => {
      if (!extra.selected_filters) extra.selected_filters = {}

      extra.selected_filters[type] = ids
      // TO DO: remove this when dynamic catalog filters scaled
      extra.conditions[`${type}_ids`] = ids
    })

  return {
    event: 'user.filter_items',
    extra,
  }
}

type SettingsUserInputEventArgs = {
  screen: string
  target: string
  isValid: boolean
  targetDetails?: string
}

type SettingsUserInputEventExtra = {
  screen: string
  target: string
  valid: boolean
  target_details?: string
}

const settingsUserInputEvent = (args: SettingsUserInputEventArgs): TrackingEvent => {
  const { screen, target, isValid, targetDetails } = args

  const extra: SettingsUserInputEventExtra = {
    screen,
    target,
    valid: isValid,
    target_details: targetDetails,
  }

  return {
    event: 'settings.user.input',
    extra,
  }
}

type AuthType = 'internal' | 'facebook' | 'google' | 'apple'

type UserRegisterSuccessEventArgs = {
  type: AuthType
  userId?: number
}

type UserRegisterSuccessEventExtra = {
  type: AuthType
}

const userRegisterSuccessEvent = (args: UserRegisterSuccessEventArgs): TrackingEvent => {
  const { type, userId } = args

  const extra: UserRegisterSuccessEventExtra = { type }

  return { event: 'user.register_success', extra, userId }
}

type UserRegisterFailEventArgs = {
  type: AuthType
  errors?: Array<ErrorItem>
}

type UserRegisterFailEventExtra = {
  type: AuthType
  reason: string
  details?: string
}

const userRegisterFailEvent = (args: UserRegisterFailEventArgs): TrackingEvent => {
  const { type, errors } = args

  const extra: UserRegisterFailEventExtra = {
    type,
    reason: 'validation_error',
    details: errors?.map(error => error.value).join(', '),
  }

  return { event: 'user.register_fail', extra }
}

export type AuthenticateSuccessEventArgs = {
  type: AuthType
  userId?: number
}

type AuthenticateSuccessEventExtra = {
  type: AuthType
}

const authenticateSuccessEvent = (args: AuthenticateSuccessEventArgs): TrackingEvent => {
  const { type, userId } = args

  const extra: AuthenticateSuccessEventExtra = { type }

  return { event: 'user.authenticate_success', extra, userId }
}

type AuthenticateFailEventArgs = {
  type: AuthType
  errors?: Array<ErrorItem>
  error?: string
}

type AuthenticateFailEventExtra = {
  type: AuthType
  reason: string
  details?: string
}

const authenticateFailEvent = (args: AuthenticateFailEventArgs): TrackingEvent => {
  const { type, errors, error } = args

  const extra: AuthenticateFailEventExtra = {
    type,
    reason: 'validation_error',
    details: errors?.map(({ value }) => value).join(', ') || error,
  }

  return { event: 'user.authenticate_fail', extra }
}

type SelectSuggestedMessageEventArgs = {
  id: string
  position?: number
}

type SelectSuggestedMessageEventExtra = {
  id: string
  position?: number
}

const selectSuggestedMessageEvent = (args: SelectSuggestedMessageEventArgs): TrackingEvent => {
  const { id, position } = args

  const extra: SelectSuggestedMessageEventExtra = { id }
  if (position) extra.position = position

  return {
    event: 'user.click_suggested_message',
    extra,
  }
}

type ViewSuggestedMessageEventArgs = {
  id: string
  position?: number
}

type ViewSuggestedMessageEventExtra = {
  id: string
  position?: number
}

const viewSuggestedMessageEvent = (args: ViewSuggestedMessageEventArgs): TrackingEvent => {
  const { id, position } = args

  const extra: ViewSuggestedMessageEventExtra = { id }
  if (position) extra.position = position

  return {
    event: 'user.view_suggested_message',
    extra,
  }
}

type DonationsClickEventArgs = {
  target: ClickableElement
  screen?: Screen | null
  targetDetails?: string | number
}

type DonationsClickEventExtra = {
  target: string
  screen?: string
  target_details?: string | number
}

const donationsClickEvent = (args: DonationsClickEventArgs): TrackingEvent => {
  const { target, screen, targetDetails } = args

  const extra: DonationsClickEventExtra = {
    target,
  }

  if (screen) extra.screen = screen

  if (targetDetails) extra.target_details = targetDetails

  return {
    event: 'user.click_donations',
    extra,
  }
}

type FundraiserClickEventArgs = {
  target: ClickableElement
  screen?: Screen | null
  targetDetails?: string | number
}

type FundraiserClickEventExtra = {
  target: string
  screen?: string
  target_details?: string | number
}

const fundraiserClickEvent = (args: FundraiserClickEventArgs): TrackingEvent => {
  const { target, screen, targetDetails } = args

  const extra: FundraiserClickEventExtra = {
    target,
  }

  if (screen) extra.screen = screen

  if (targetDetails) extra.target_details = targetDetails

  return {
    event: 'user.click_donations',
    extra,
  }
}

type BuyerLoadShippingPointsEventArgs = {
  screen: string
  transactionId?: number | null
  shippingPointsCount: string | null
  latitude: number
  longitude: number
}

type BuyerLoadShippingPointsEventExtra = {
  screen: string
  transaction_id?: number | null
  shipping_points_count: string | null
  latitude: number
  longitude: number
}

const buyerLoadShippingPointsEvent = (args: BuyerLoadShippingPointsEventArgs): TrackingEvent => {
  const { transactionId, shippingPointsCount, screen, latitude, longitude } = args

  const extra: BuyerLoadShippingPointsEventExtra = {
    screen,
    latitude,
    longitude,
    shipping_points_count: shippingPointsCount,
  }

  if (transactionId) extra.transaction_id = transactionId

  return {
    event: 'buyer.load_shipping_points',
    extra,
  }
}

type BuyerPreselectedShippingOptionsEventArgs = {
  screen: Screen
  transactionId?: number | null
  distance?: number
  isNewEntry?: boolean
  carrier: string
  shippingPoint: string | null
}

type BuyerPreselectedShippingOptionsEventExtra = {
  screen: Screen
  transaction_id?: number | null
  distance?: number
  is_new_entry?: boolean
  carrier: string
  shipping_point: string | null
}

const buyerPreselectedShippingOptionsEvent = (
  args: BuyerPreselectedShippingOptionsEventArgs,
): TrackingEvent => {
  const { screen, transactionId, isNewEntry, carrier, shippingPoint, distance } = args

  const extra: BuyerPreselectedShippingOptionsEventExtra = {
    screen,
    carrier,
    shipping_point: shippingPoint,
  }

  if (transactionId) extra.transaction_id = transactionId
  if (distance !== undefined) extra.distance = distance
  if (isNewEntry) extra.is_new_entry = isNewEntry

  return {
    event: 'buyer.preselected_shipping_option',
    extra,
  }
}

type BuyerPickDeliveryTypeEventArgs = {
  transactionId?: number | null
  carrier: string | null
  deliveryType: string
  isNewEntry: boolean
  success: boolean
}

type BuyerPickDeliveryTypeEventExtra = {
  transaction_id?: number | null
  carrier: string | null
  delivery_type: string
  is_new_entry: boolean
  success: boolean
}

const buyerPickDeliveryTypeEvent = (args: BuyerPickDeliveryTypeEventArgs): TrackingEvent => {
  const { transactionId, carrier, isNewEntry, success, deliveryType } = args

  const extra: BuyerPickDeliveryTypeEventExtra = {
    success,
    carrier,
    delivery_type: deliveryType,
    is_new_entry: isNewEntry,
  }

  if (transactionId) extra.transaction_id = transactionId

  return {
    event: 'buyer.pick_delivery_type',
    extra,
  }
}

type WantItemButtonExtra =
  | 'message'
  | 'buy'
  | 'item_reservation_request'
  | 'make_offer'
  | 'create_bundle'

type BuyerWantItemEventExtra = {
  item_id: number | null
  transaction_id: number | null
  button: WantItemButtonExtra
  global_search_session_id: string | null
  search_session_id: string | null
  homepage_session_id?: string | null
}

type BuyerWantItemEventArgs = {
  transactionId: number | null
  itemId: number | null
  button: WantItemButtonExtra
  globalSearchSessionId: string | null
  searchSessionId: string | null
  homepageSessionId?: string | null
}

const buyerWantItemEvent = ({
  transactionId,
  itemId,
  button,
  globalSearchSessionId,
  searchSessionId,
  homepageSessionId,
}: BuyerWantItemEventArgs): TrackingEvent => {
  const extra: BuyerWantItemEventExtra = {
    button,
    item_id: itemId,
    transaction_id: transactionId,
    global_search_session_id: globalSearchSessionId,
    search_session_id: searchSessionId,
    homepage_session_id: homepageSessionId,
  }

  return {
    event: 'buyer.want_item',
    extra,
  }
}

type ConfirmWalletEventArgs = {
  source: ContentSource
  prefilled_billing_address?: boolean | null
}

type ConfirmWalletEventExtra = {
  source: string
  prefilled_billing_address?: boolean | null
}

const confirmWalletEvent = (args: ConfirmWalletEventArgs): TrackingEvent => {
  const extra: ConfirmWalletEventExtra = {
    source: args.source,
    prefilled_billing_address: args.prefilled_billing_address,
  }

  return {
    event: 'user.confirm_wallet',
    extra,
  }
}

type SaveBankAccountEventArgs = {
  valid: boolean
  details: string
  failure_reason?: FailureReason
}

type SaveBankAccountEventExtra = {
  valid: boolean
  failure_reason?: string
  details: string
}

const saveBankAccountEvent = (args: SaveBankAccountEventArgs) => {
  const extra: SaveBankAccountEventExtra = {
    valid: args.valid,
    failure_reason: args.failure_reason,
    details: args.details,
  }

  return {
    event: 'user.save_bank_account',
    extra,
  }
}

type ItemUploadStartEventArgs = {
  screenName: Screen
  tempUuid?: string
}

type ItemUploadStartEventExtra = {
  upload_session_id?: string
  screen: Screen
}

const itemUploadStartEventExtra = (args: ItemUploadStartEventArgs) => {
  const extra: ItemUploadStartEventExtra = {
    screen: args.screenName,
    upload_session_id: args.tempUuid,
  }

  return {
    event: 'user.upload_item.start',
    extra,
  }
}

type ItemUploadFieldSetEventArgs = {
  fieldName?: ItemUploadFieldTrackingName
  tempUuid?: string
  suggestionSeen?: boolean
  suggestions: Array<string>
  userSelection: Array<string>
  query?: string | null
  position?: number
  querySuggestions?: Array<string>
}

type ItemUploadFieldSetEventExtra = {
  field_name?: string
  upload_session_id?: string
  suggestion_seen?: boolean
  suggestions: Array<string> | null
  user_selection: Array<string> | null
  query?: string
  position?: number
  query_suggestions?: Array<string> | null
}

const itemUploadFieldSetEvent = (args: ItemUploadFieldSetEventArgs) => {
  const {
    fieldName,
    tempUuid,
    suggestionSeen,
    suggestions,
    userSelection,
    query,
    position,
    querySuggestions,
  } = args

  const extra: ItemUploadFieldSetEventExtra = {
    suggestions: suggestions.length ? suggestions : null,
    field_name: fieldName,
    upload_session_id: tempUuid,
    suggestion_seen: suggestionSeen,
    user_selection: userSelection?.length ? userSelection : null,
  }

  if (query) extra.query = query
  if (position) extra.position = position
  if (querySuggestions) {
    extra.query_suggestions = querySuggestions.length ? querySuggestions : null
  }

  return {
    event: 'user.upload_item.field_set',
    extra,
  }
}

type ItemUploadSubmitFailArgs = {
  reason: ItemUploadFailReason
  tempUuid?: string
  validationErrors?: Array<string>
  screen?: string
}

type ItemUploadSubmitFailExtra = {
  reason: ItemUploadFailReason
  upload_session_id?: string
  validation_errors?: Array<string>
  screen?: string
}

const itemUploadSubmitFail = (args: ItemUploadSubmitFailArgs) => {
  const extra: ItemUploadSubmitFailExtra = {
    reason: args.reason,
    upload_session_id: args.tempUuid,
    validation_errors: args.validationErrors,
    screen: args.screen,
  }

  return {
    event: 'user.upload_item.submit_fail',
    extra,
  }
}

type ItemUploadSubmitSuccessArgs = {
  edited?: boolean
  photoCount?: number
  videoCount?: number
  itemId?: number | string
  screen?: Screen
  tempUuid?: string
}

type ItemUploadSubmitSuccessExtra = {
  edited?: boolean
  photo_count?: number
  video_count?: number
  item_id?: number | string
  screen?: Screen
  upload_session_id?: string
}

const itemUploadSubmitSuccess = (args: ItemUploadSubmitSuccessArgs) => {
  const extra: ItemUploadSubmitSuccessExtra = {
    edited: args.edited,
    photo_count: args.photoCount,
    video_count: args.videoCount,
    item_id: args.itemId,
    screen: args.screen,
    upload_session_id: args.tempUuid,
  }

  return {
    event: 'user.upload_item.submit_success',
    extra,
  }
}

const itemUploadFillZipCodeFail = () => ({
  event: 'user.upload_item.fill_zip_code_fail',
})

type SearchSectionListName = 'recent' | 'subscribed'

type ToggleSearchSubscriptionEventArgs = {
  position?: number
  isSubscribing: boolean
  searchTitle?: string
  searchQuery?: string | null
  searchSessionId: string
  globalSearchSessionId: string | null
  screen: Screen
  savedRecentSearchListId: string | null
  savedRecentSearchSessionId: string | null
  listName: SearchSectionListName | null
}

type ToggleSearchSubscriptionEventExtra = {
  position?: number
  is_subscribing: boolean
  search_title?: string
  search_query?: string
  search_session_id?: string
  global_search_session_id: string | null
  screen: Screen
  saved_recent_search_list_id: string | null
  saved_recent_search_session_id: string | null
  list_name: SearchSectionListName | null
}

const toggleSearchSubscriptionEvent = (args: ToggleSearchSubscriptionEventArgs) => {
  const {
    isSubscribing,
    searchTitle,
    searchQuery,
    searchSessionId,
    globalSearchSessionId,
    position,
    screen,
    savedRecentSearchListId,
    listName,
    savedRecentSearchSessionId,
  } = args

  const extra: ToggleSearchSubscriptionEventExtra = {
    screen,
    position,
    saved_recent_search_session_id: savedRecentSearchSessionId,
    list_name: listName,
    saved_recent_search_list_id: savedRecentSearchListId,
    is_subscribing: isSubscribing,
    global_search_session_id: globalSearchSessionId,
  }

  if (searchTitle) extra.search_title = searchTitle
  if (searchQuery) extra.search_query = searchQuery
  if (searchSessionId) extra.search_session_id = searchSessionId

  return {
    event: 'user.toggle_search_subscription',
    extra,
  }
}

type SelectSavedSearchEventArgs = {
  type: SavedSearchType
  searchTitle: string
  searchSessionId: string
  globalSearchSessionId: string | null
  newItemsCount: number | null
  unrestrictedNewItemsCount: number | null
  position: number
  screen: Screen
  savedRecentSearchListId: string
  savedRecentSearchSessionId: string
  listName: SearchSectionListName
}

type SelectSavedSearchEventExtra = {
  type: SavedSearchType
  search_title: string
  search_session_id?: string
  global_search_session_id: string | null
  new_items_count?: number | null
  unrestricted_new_items_count?: number | null
  position: number
  screen: Screen
  saved_recent_search_list_id: string
  saved_recent_search_session_id: string
  list_name: SearchSectionListName
}

const selectSavedSearchEvent = (args: SelectSavedSearchEventArgs) => {
  const {
    type,
    searchTitle,
    searchSessionId,
    globalSearchSessionId,
    newItemsCount,
    unrestrictedNewItemsCount,
    position,
    screen,
    savedRecentSearchListId,
    savedRecentSearchSessionId,
    listName,
  } = args

  const extra: SelectSavedSearchEventExtra = {
    screen,
    type,
    position,
    list_name: listName,
    saved_recent_search_list_id: savedRecentSearchListId,
    new_items_count: newItemsCount,
    unrestricted_new_items_count: unrestrictedNewItemsCount,
    search_title: searchTitle,
    global_search_session_id: globalSearchSessionId,
    search_session_id: searchSessionId || undefined,
    saved_recent_search_session_id: savedRecentSearchSessionId,
  }

  return {
    event: 'user.select_saved_search',
    extra,
  }
}

type AbTestExposeEventArgs = {
  id: number | string
  name: string
  variant?: string
  anonId: string
  userId?: number | null | string
  countryCode: string
}

const abTestExposeEvent = (args: AbTestExposeEventArgs) => {
  const { id, name, anonId, userId, countryCode, variant } = args

  const extra: AbTestExposeEventExtra = {
    test_id: String(id),
    test_name: name,
    test_anon_id: anonId,
    country_code: countryCode,
  }

  if (variant) extra.variant = variant
  if (userId) extra.test_user_id = String(userId)

  return {
    event: 'ab_test.expose',
    extra,
  }
}

const sharingReferCopyLinkEvent = (): TrackingEvent => ({
  event: 'sharing.refer.copy_link',
})

type BuyerViewAddContactDetailsEventArgs = {
  transactionId: number
  screen: string
}

type BuyerViewAddContactDetailsEventExtra = {
  transaction_id: number
  screen: string
}

const buyerViewAddContactDetailsEvent = (args: BuyerViewAddContactDetailsEventArgs) => {
  const { transactionId, screen } = args

  const extra: BuyerViewAddContactDetailsEventExtra = {
    transaction_id: transactionId,
    screen,
  }

  return {
    event: 'buyer.view_add_contact_details',
    extra,
  }
}

type SellerViewAddContactDetailsEventArgs = {
  transactionId: number
  screen: string
}

type SellerViewAddContactDetailsEventExtra = {
  transaction_id: number
  screen: string
}

const sellerViewAddContactDetailsEvent = (args: SellerViewAddContactDetailsEventArgs) => {
  const { transactionId, screen } = args

  const extra: SellerViewAddContactDetailsEventExtra = {
    transaction_id: transactionId,
    screen,
  }

  return {
    event: 'seller.view_add_contact_details',
    extra,
  }
}

type MakeOfferEventArgs = {
  isBuyer: boolean
  transactionId: number
  offerRequestId?: number
  currentPrice: number
  offeredPrice: number
  offerRequestSessionId?: string
  offerSuggestionOptionNumber?: number
}

type MakeOfferEventExtra = {
  transaction_id: number
  offer_id?: number
  current_price: number
  offered_price: number
  button_number?: number
  offer_request_session_id?: string
}

const makeOfferEvent = (args: MakeOfferEventArgs) => {
  const {
    isBuyer,
    currentPrice,
    offeredPrice,
    transactionId,
    offerRequestId,
    offerRequestSessionId,
    offerSuggestionOptionNumber,
  } = args

  const extra: MakeOfferEventExtra = {
    transaction_id: transactionId,
    offer_id: offerRequestId,
    offer_request_session_id: offerRequestSessionId,
    current_price: currentPrice,
    offered_price: offeredPrice,
    button_number: offerSuggestionOptionNumber,
  }

  return {
    event: isBuyer ? 'buyer.make_offer_request' : 'seller.make_offer',
    extra,
  }
}

type SharingReferralsOpenInfoBannerLinkArgs = {
  tabName: string
}

type SharingReferralsOpenInfoBannerLinkExtra = {
  tab_name: string
}

const sharingReferralsOpenInfoBannerLink = (args: SharingReferralsOpenInfoBannerLinkArgs) => {
  const { tabName } = args

  const extra: SharingReferralsOpenInfoBannerLinkExtra = {
    tab_name: tabName,
  }

  return {
    event: 'sharing.referrals.open_info_banner_link',
    extra,
  }
}

type BuyerPrecheckoutEventArgs = {
  transactionId: number
}

type BuyerPrecheckoutEventExtra = {
  transaction_id: number
}

const buyerPrecheckoutEvent = (args: BuyerPrecheckoutEventArgs) => {
  const { transactionId } = args

  const extra: BuyerPrecheckoutEventExtra = {
    transaction_id: transactionId,
  }

  return {
    event: 'buyer.precheckout',
    extra,
  }
}

type UserPaginateCatalogEventArgs = {
  currentPage: number
  searchCorrelationId: string
  searchSessionId: string
  globalSearchSessionId: string | null
}

type UserPaginateCatalogEventExtra = {
  page: number
  search_correlation_id?: string
  search_session_id?: string
  global_search_session_id?: string
}

const userPaginateCatalogEvent = (args: UserPaginateCatalogEventArgs): TrackingEvent => {
  const { currentPage, searchCorrelationId, searchSessionId, globalSearchSessionId } = args

  const extra: UserPaginateCatalogEventExtra = {
    page: currentPage,
  }

  if (searchCorrelationId) extra.search_correlation_id = searchCorrelationId
  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId

  return {
    event: 'user.paginate_catalog',
    extra,
  }
}

type UserAppliedOrderEventArgs = {
  order: SortByOption
  searchSessionId: string
  globalSearchSessionId: string | null
}

type UserAppliedOrderEventExtra = {
  order: string
  search_session_id?: string
  global_search_session_id?: string
}

const userAppliedOrderEvent = (args: UserAppliedOrderEventArgs) => {
  const { order, searchSessionId, globalSearchSessionId } = args

  const extra: UserAppliedOrderEventExtra = {
    order,
  }

  if (searchSessionId) extra.search_session_id = searchSessionId

  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId

  return { event: 'user.applied_order', extra }
}

type UserSelectThemeEventArgs = {
  theme: ColorTheme
}

const userSelectTheme = ({ theme }: UserSelectThemeEventArgs) => {
  const extra = { theme }

  return { event: 'user.select_theme', extra }
}

type ConversationHarassmentEventArgs = {
  conversationId: string | number | null
  wasEdited: boolean
  correlationId: string | null
}

const conversationHarassmentEvent = (args: ConversationHarassmentEventArgs): TrackingEvent => {
  const { conversationId, wasEdited, correlationId } = args

  const extra = {
    conversation_id: conversationId,
    is_message_edited: wasEdited,
    correlation_id: correlationId,
  }

  return {
    event: 'user.react_to_conversation_harassment_warning',
    extra,
  }
}

type BuyerViewDeliveryDetailsEventArgs = {
  transactionId: number
  screen: string
}

type BuyerViewDeliveryDetailsEventExtra = {
  transaction_id: number
  screen: string
}

const buyerViewDeliveryDetailsEvent = (args: BuyerViewDeliveryDetailsEventArgs) => {
  const { transactionId, screen } = args

  const extra: BuyerViewDeliveryDetailsEventExtra = {
    transaction_id: transactionId,
    screen,
  }

  return {
    event: 'buyer.view_delivery_details',
    extra,
  }
}

type BuyerBuyEventArgs = {
  transactionId: number
  screen: string
  globalSearchSessionId?: string | null
  homepageSessionId?: string | null
}

type BuyerBuyEventExtra = {
  transaction_id: number
  screen: string
  global_search_session_id?: string | null
  homepage_session_id?: string | null
}

const buyerBuyEvent = (args: BuyerBuyEventArgs) => {
  const { transactionId, screen, globalSearchSessionId, homepageSessionId } = args

  const extra: BuyerBuyEventExtra = {
    transaction_id: transactionId,
    screen,
    global_search_session_id: globalSearchSessionId,
    homepage_session_id: homepageSessionId,
  }

  return {
    event: 'buyer.buy',
    extra,
  }
}

type RequestedAdEventArgs = {
  screen: string
  placementId: string
  countryCode: string
}

type RequestedAdEventExtra = {
  screen: string
  placement_id: string
  country_code: string
}

const requestedAdEvent = (args: RequestedAdEventArgs) => {
  const { screen, placementId, countryCode } = args

  const extra: RequestedAdEventExtra = {
    placement_id: placementId,
    country_code: countryCode.toUpperCase(),
    screen,
  }

  return {
    event: 'system.requested_ad',
    extra,
  }
}

type ReceivedAdEventArgs = {
  placementId: string
  isMobileWeb: boolean
  countryCode: string
  creativeId?: string
  campaignId?: string
}

type ReceivedAdEventExtra = {
  placement_id: string
  is_mobile_web: boolean
  country_code: string
  creative_id?: string
  campaign_id?: string
}

const receivedAdEvent = (args: ReceivedAdEventArgs) => {
  const { placementId, isMobileWeb, countryCode, creativeId, campaignId } = args

  const extra: ReceivedAdEventExtra = {
    placement_id: placementId,
    is_mobile_web: isMobileWeb,
    creative_id: creativeId,
    campaign_id: campaignId,
    country_code: countryCode.toUpperCase(),
  }

  return {
    event: 'user.received_ad',
    extra,
  }
}

type ViewAdEventArgs = {
  placementId: string
  isMobileWeb: boolean
  countryCode: string
  creativeId?: string
  campaignId?: string
}

type ViewAdEventExtra = {
  placement_id: string
  is_mobile_web: boolean
  country_code: string
  creative_id?: string
  campaign_id?: string
}

const viewAdEvent = (args: ViewAdEventArgs) => {
  const { placementId, isMobileWeb, creativeId, campaignId, countryCode } = args

  const extra: ViewAdEventExtra = {
    placement_id: placementId,
    is_mobile_web: isMobileWeb,
    creative_id: creativeId,
    campaign_id: campaignId,
    country_code: countryCode.toUpperCase(),
  }

  return {
    event: 'user.view_ad',
    extra,
  }
}

type CrashAdComponentEventArgs = {
  error: string
  pageName: AdPage
  placementId: string
}

const crashAdComponentEvent = (args: CrashAdComponentEventArgs): TrackingEvent => {
  const { error, pageName, placementId } = args

  return {
    event: 'system.ad_component_crash',
    extra: { error, screen_name: pageName, placement_id: placementId },
  }
}

export enum CheckoutInteractionType {
  Scrolled = 'scrolled',
  ReturnedBack = 'returned_back',
  CoBrandedCard = 'co_branded_card',
  ScreenOnDuration = 'screen_on_duration',
  ScreenLoadDuration = 'screen_load_duration',
}

export enum CheckoutScrollInteractionDetailsType {
  NoScrolling = 'no_scrolling',
  PartialScrolling = 'partial_scrolling',
  ScrolledTillTheEnd = 'scrolled_till_the_end',
  NoScrollingSawCompleteScreen = 'no_scrolling_saw_complete_screen',
}

type CheckoutInteractionEventArgs = {
  screen: string
  orderId?: number
  checkoutId?: number
  transactionId?: number
  action: CheckoutInteractionType
  actionDetails?:
    | { co_branded_card: boolean }
    | { screen_load_duration: number }
    | { duration_in_milliseconds: number }
    | { type: CheckoutScrollInteractionDetailsType }
}

const checkoutInteractionEvent = (args: CheckoutInteractionEventArgs): TrackingEvent => {
  const { screen, action, actionDetails, transactionId, orderId, checkoutId } = args

  return {
    event: 'buyer.interacts_with_checkout',
    extra: {
      screen,
      action,
      order_id: orderId || null,
      checkout_id: checkoutId || null,
      transaction_id: transactionId || null,
      action_details: actionDetails ? JSON.stringify(actionDetails) : null,
    },
  }
}

type SingleCheckoutInteractionEventArgs = {
  checkoutId: string
  actionDetails: string // time in milliseconds
}

const singleCheckoutInteractionEvent = (
  args: SingleCheckoutInteractionEventArgs,
): TrackingEvent => {
  const { actionDetails, checkoutId } = args

  return {
    event: 'checkout.interaction',
    extra: {
      screen: Screen.Checkout,
      action: 'screen_load_duration_in_ms',
      checkout_id: checkoutId,
      action_details: actionDetails,
    },
  }
}

type ViewSuggestedPlacesEventArgs = {
  query: string
  count: number | null
  sessionToken: string
  screen: string
}

const viewSuggestedPlacesEvent = (args: ViewSuggestedPlacesEventArgs): TrackingEvent => {
  const { query, count, sessionToken, screen } = args

  return {
    event: 'user.autocomplete_results',
    extra: {
      query,
      count,
      session_token: sessionToken,
      screen,
    },
  }
}

type SelectSuggestedPlaceEventArgs = {
  selectedAutocomplete: string
  sessionToken: string
  screen: string
}

const selectSuggestedPlaceEvent = (args: SelectSuggestedPlaceEventArgs): TrackingEvent => {
  const { selectedAutocomplete, sessionToken, screen } = args

  return {
    event: 'user.autocomplete_details',
    extra: {
      selected_autocomplete: selectedAutocomplete,
      session_token: sessionToken,
      screen,
    },
  }
}

type FilterFeaturedCollectionItemsEventArgs = {
  sortBy: string
  catalogId?: string
  screen?: string
}

type FilterFeaturedCollectionItemsEventExtra = {
  screen?: string
  sort_by: string
  catalog_id: string | null
}

const filterFeaturedCollectionItemsEvent = (
  args: FilterFeaturedCollectionItemsEventArgs,
): TrackingEvent => {
  const { sortBy, catalogId, screen } = args

  const extra: FilterFeaturedCollectionItemsEventExtra = {
    sort_by: sortBy,
    catalog_id: catalogId || null,
  }

  if (screen) extra.screen = screen

  return {
    event: 'user.filter_featured_collections',
    extra,
  }
}

type SizeFilterEventArgs = {
  sizeIds: Array<number>
}

type SizeFilterExtra = {
  size_ids?: Array<number>
}

const sizeFilterEvent = (args: SizeFilterEventArgs): TrackingEvent => {
  const { sizeIds } = args
  let eventName = 'user.disable_size_filter'

  const extra: SizeFilterExtra = {}

  if (sizeIds.length > 0) {
    eventName = 'user.enable_size_filter'
    extra.size_ids = sizeIds
  }

  return {
    event: eventName,
    extra,
  }
}

type UserViewItemGalleryEventArgs = {
  imagePosition: number
  imageCount: number
  itemId: number
  isLoop: boolean
  contentType: string
  galleryContainsAd: boolean
}

type UserViewItemGalleryEventExtra = {
  image_position: number
  number_item_images: number
  item_id: number
  image_view: 'fullscreen'
  is_loop: boolean
  content_type: string
  gallery_contains_ad: boolean
}

const userViewItemGallery = (args: UserViewItemGalleryEventArgs): TrackingEvent => {
  const { imagePosition, imageCount, itemId, isLoop, contentType, galleryContainsAd } = args

  const extra: UserViewItemGalleryEventExtra = {
    // This value is hardcoded, because we have gallery only in a modal form
    // hence the `fullscreen` name. In apps, they have two modes to show gallery,
    // in overlay form or in the page itself, hence they have different values here.
    image_view: 'fullscreen',
    image_position: imagePosition,
    number_item_images: imageCount,
    item_id: itemId,
    is_loop: isLoop,
    content_type: contentType,
    gallery_contains_ad: galleryContainsAd,
  }

  return {
    event: 'user.view_item_gallery',
    extra,
  }
}

type ViewDynamicFilterEventArgs = {
  id: string | number
  type: string
  code: string
  position: number
  itemsCount?: number | null
  searchText?: string
  filteringSessionId: string | null
  globalSearchSessionId?: string | null
  searchSessionId?: string | null
  fromScreen: Screen
}

type ViewDynamicFilterEventExtra = {
  id: string | number
  type: string
  code: string
  position: number
  items_count?: number | null
  search_text?: string
  filtering_session_id: string | null
  global_search_session_id?: string | null
  search_session_id?: string | null
  from_screen: string
}

const userViewDynamicFilterOptionEvent = (args: ViewDynamicFilterEventArgs): TrackingEvent => {
  const {
    id,
    type,
    position,
    itemsCount,
    searchText,
    filteringSessionId,
    searchSessionId,
    globalSearchSessionId,
    fromScreen,
  } = args

  const extra: ViewDynamicFilterEventExtra = {
    id,
    position,
    type,
    code: type,
    items_count: itemsCount,
    search_text: searchText,
    filtering_session_id: filteringSessionId,
    from_screen: fromScreen,
  }

  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId

  return {
    event: 'user.view_filter_option',
    extra,
  }
}

type ClickDynamicFilterEventArgs = {
  id: string | number
  type: string
  code: string
  position: number
  isSelected: boolean
  itemsCount?: number | null
  searchText?: string
  filteringSessionId: string | null
  globalSearchSessionId?: string | null
  searchSessionId?: string | null
  fromScreen: Screen
}

type ClickDynamicFilterEventExtra = {
  id: string | number
  type: string
  code: string
  position: number
  is_selected: boolean
  items_count?: number | null
  search_text?: string
  filtering_session_id: string | null
  global_search_session_id?: string | null
  search_session_id?: string | null
  from_screen: string
}

const userClickDynamicFilterOptionEvent = (args: ClickDynamicFilterEventArgs): TrackingEvent => {
  const {
    id,
    type,
    position,
    isSelected,
    itemsCount,
    searchText,
    filteringSessionId,
    searchSessionId,
    globalSearchSessionId,
    fromScreen,
  } = args

  const extra: ClickDynamicFilterEventExtra = {
    id,
    position,
    type,
    code: type,
    is_selected: isSelected,
    items_count: itemsCount,
    search_text: searchText,
    filtering_session_id: filteringSessionId,
    from_screen: fromScreen,
  }

  if (searchSessionId) extra.search_session_id = searchSessionId
  if (globalSearchSessionId) extra.global_search_session_id = globalSearchSessionId

  return {
    event: 'user.click_filter_option',
    extra,
  }
}

type SearchFaqEntriesEventArgs = {
  searchSessionId: string | null
  searchQuery: string
  faqEntryIds: Array<number>
  helpCenterSessionId: string
}

type SearchFaqEntriesEventExtra = {
  search_session_id: string | null
  search_query: string
  faq_entry_ids: Array<number>
  help_center_session_id: string
}

const searchFaqEntriesEvent = (args: SearchFaqEntriesEventArgs) => {
  const extra: SearchFaqEntriesEventExtra = {
    search_session_id: args.searchSessionId,
    search_query: args.searchQuery,
    faq_entry_ids: args.faqEntryIds,
    help_center_session_id: args.helpCenterSessionId,
  }

  return {
    event: 'user.search_faq_entries',
    extra,
  }
}

type LeaveFaqEntryEventArgs = {
  faqEntryId: number
  faqAccessChannel: string | null
  initialPageScrollDepth: number
  maxPageScrollDepth: number
  totalViewTime: number
  helpCenterSessionId: string
}

type LeaveFaqEntryEventExtra = {
  faq_entry_id: number
  faq_access_channel: string | null
  initial_page_scroll_depth: number
  max_page_scroll_depth: number
  total_view_time: number
  help_center_session_id: string
}

const leaveFaqEntryEvent = (args: LeaveFaqEntryEventArgs) => {
  const extra: LeaveFaqEntryEventExtra = {
    faq_entry_id: args.faqEntryId,
    faq_access_channel: args.faqAccessChannel,
    initial_page_scroll_depth: args.initialPageScrollDepth,
    max_page_scroll_depth: args.maxPageScrollDepth,
    total_view_time: args.totalViewTime,
    help_center_session_id: args.helpCenterSessionId,
  }

  return {
    event: 'user.leave_faq_entry',
    extra,
  }
}

type ViewFaqEntryArgs = {
  faqEntryId: number
  faqEntryChannel: string
  faqAccessChannel: string | null
  screen: string
  helpCenterSessionId: string
}

type ViewFaqEntryExtra = {
  faq_entry_id: number
  faq_access_channel: string | null
  faq_entry_channel: string
  screen: string
  help_center_session_id: string
}

const viewFaqEntry = (args: ViewFaqEntryArgs) => {
  const extra: ViewFaqEntryExtra = {
    faq_entry_id: args.faqEntryId,
    faq_entry_channel: args.faqEntryChannel,
    faq_access_channel: args.faqAccessChannel,
    screen: args.screen,
    help_center_session_id: args.helpCenterSessionId,
  }

  return {
    event: 'user.view_faq_entry',
    extra,
  }
}

type userClickFaqRelatedQuestionEventArgs = {
  helpCenterSessionId: string | null
  initialFaqId?: number
  targetFaqId: number
  faqAccessChannel?: string
  faqEntryChannel?: string
}

const userClickFaqRelatedQuestionEvent = (
  args: userClickFaqRelatedQuestionEventArgs,
): TrackingEvent => {
  const { helpCenterSessionId, initialFaqId, targetFaqId, faqEntryChannel, faqAccessChannel } = args

  const extra = {
    help_center_session_id: helpCenterSessionId,
    initial_faq_id: initialFaqId,
    target_faq_id: targetFaqId,
    faq_entry_channel: faqEntryChannel,
    faq_access_channel: faqAccessChannel,
  }

  return {
    event: 'user.click_faq_related_question',
    extra,
  }
}

type userClickRecommendedTopicEventArgs = {
  helpCenterSessionId: string | null
}

const userClickRecommendedTopicEvent = (
  args: userClickRecommendedTopicEventArgs,
): TrackingEvent => {
  const { helpCenterSessionId } = args

  const extra = {
    help_center_session_id: helpCenterSessionId,
  }

  return {
    event: 'user.click_recommended_topic',
    extra,
  }
}

type userClickHelpCenterSearchResultArgs = {
  helpCenterSessionId: string | null
}

const userClickHelpCenterSearchResult = (
  args: userClickHelpCenterSearchResultArgs,
): TrackingEvent => {
  const { helpCenterSessionId } = args

  const extra = {
    help_center_session_id: helpCenterSessionId,
  }

  return {
    event: 'user.click_help_center_search_result',
    extra,
  }
}

export enum FaqFeedback {
  Positive = 'positive',
  Negative = 'negative',
}

type submitFaqFeedbackEventArgs = {
  faqId: number
  helpCenterSessionId: string | null
  faqAccessChannel?: string
  faqEntryChannel?: string
  faqFeedback: FaqFeedback
}

const userSubmitFaqFeedbackEvent = (args: submitFaqFeedbackEventArgs): TrackingEvent => {
  const { faqId, helpCenterSessionId, faqEntryChannel, faqAccessChannel, faqFeedback } = args
  const extra = {
    faq_id: faqId,
    help_center_session_id: helpCenterSessionId,
    faq_entry_channel: faqEntryChannel,
    faq_access_channel: faqAccessChannel,
    faq_feedback: faqFeedback,
  }

  return {
    event: 'user.submit_faq_feedback',
    extra,
  }
}

type userContactSupportEventArgs = {
  helpCenterSessionId: string | null
}

const userContactSupportEvent = (args: userContactSupportEventArgs): TrackingEvent => {
  const { helpCenterSessionId } = args

  const extra = {
    help_center_session_id: helpCenterSessionId,
  }

  return {
    event: 'user.contact_support',
    extra,
  }
}

type proofGatheringFormEventArgs = {
  userSide: TransactionSide
}

const viewProofGatheringForm = (args: proofGatheringFormEventArgs) => {
  const extra = {
    user_side: args.userSide,
  }

  return {
    event: 'user.view_proof_gathering_form',
    extra,
  }
}

const viewProofGatheringFormSubmitModal = () => {
  return {
    event: 'user.view_proof_gathering_form_submit_modal',
  }
}

const proofGatheringFormSubmission = (
  args: proofGatheringFormEventArgs & { submittedViaModal: boolean },
) => {
  const extra = {
    user_side: args.userSide,
    submitted_via_modal: args.submittedViaModal,
  }

  return {
    event: 'user.proof_gathering_form_submission',
    extra,
  }
}
const proofGatheringFormModalCancelSubmission = () => {
  return {
    event: 'user.proof_gathering_form_modal_cancel_submission',
  }
}

export enum LiveChatBannerViewStatus {
  Available = 'available',
  Unavailable = 'unavailable',
}

type liveChatBannerViewedArgs = {
  status: LiveChatBannerViewStatus
}

const liveChatBannerViewed = (args: liveChatBannerViewedArgs) => {
  const extra = {
    live_chat_status: args.status,
  }

  return {
    event: 'user.live_chat_banner_viewed',
    extra,
  }
}

const liveChatBannerClicked = () => ({
  event: 'user.live_chat_banner_clicked',
})

const liveChatFormSubmitted = () => ({
  event: 'user.live_chat_form_submitted',
})

const liveChatFormViewed = () => ({
  event: 'user.live_chat_form_viewed',
})

type AdBlockCheckEventArgs = {
  isMobileWeb: boolean
  isAdBlockerUsed: boolean
}

type AdBlockCheckEventExtra = {
  is_mobile_web: boolean
  is_adblock_used: boolean
}

const adBlockCheckEvent = (args: AdBlockCheckEventArgs): TrackingEvent => {
  const { isMobileWeb, isAdBlockerUsed } = args

  const extra: AdBlockCheckEventExtra = {
    is_mobile_web: isMobileWeb,
    is_adblock_used: isAdBlockerUsed,
  }

  return {
    event: 'user.adblock_check',
    extra,
  }
}

type BuyerViewCarrierDiscountedShippingPriceEventArgs = {
  screen?: Screen
  transactionId?: number | null
}

type BuyerViewCarrierDiscountedShippingPriceEventExtra = {
  screen?: Screen
  transaction_id?: number | null
}

const buyerViewCarrierDiscountedShippingPriceEvent = (
  args: BuyerViewCarrierDiscountedShippingPriceEventArgs,
): TrackingEvent => {
  const { screen, transactionId } = args

  const extra: BuyerViewCarrierDiscountedShippingPriceEventExtra = {
    screen,
    transaction_id: transactionId,
  }

  return {
    event: 'buyer.view_carrier_discounted_shipping_price',
    extra,
  }
}
const conversationPasteEvent = (conversationId: number | string) => ({
  event: 'user.conversation_paste',
  extra: {
    conversation_id: conversationId,
  },
})

type UserModifyCarrierPreferencesEventArgs = {
  screen: Screen
  target: ClickableElement
  targetDetails: string | null
}

type UserModifyCarrierPreferencesEventExtra = {
  screen: Screen
  target: ClickableElement
  target_details: string | null
}

const userModifyCarrierPreferencesEvent = (
  args: UserModifyCarrierPreferencesEventArgs,
): TrackingEvent => {
  const { screen, target, targetDetails } = args

  const extra: UserModifyCarrierPreferencesEventExtra = {
    screen,
    target,
    target_details: targetDetails,
  }

  return {
    event: 'user.modify_carrier_preferences',
    extra,
  }
}

type UserViewHomepageBlockArgs = {
  blockName: string
  blockPosition: number
  homepageSessionId?: string
  id?: string | number | null
}

type UserViewHomepageBlockExtra = {
  block_name: string
  block_position: number
  homepage_session_id?: string
  id?: string | number | null
}

const userViewHomepageBlock = (args: UserViewHomepageBlockArgs): TrackingEvent => {
  const { blockName, blockPosition, homepageSessionId, id } = args

  const extra: UserViewHomepageBlockExtra = {
    block_name: blockName,
    block_position: blockPosition,
  }

  if (homepageSessionId) extra.homepage_session_id = homepageSessionId
  if (id) extra.id = id

  return {
    event: 'user.view_homepage_block',
    extra,
  }
}

type UserViewHomepageBlockCtaArgs = {
  type: ItemBlockCtaType
  blockName: string
  blockPosition: number
  homepageSessionId?: string
  id?: string | number | null
}

type UserViewHomepageBlockCtaExtra = {
  type: ItemBlockCtaType
  block_name: string
  block_position: number
  homepage_session_id?: string
  id?: string | number | null
}

const userViewHomepageBlockCta = (args: UserViewHomepageBlockCtaArgs): TrackingEvent => {
  const { type, blockName, blockPosition, homepageSessionId, id } = args

  const extra: UserViewHomepageBlockCtaExtra = {
    type,
    block_name: blockName,
    block_position: blockPosition,
  }

  if (homepageSessionId) extra.homepage_session_id = homepageSessionId
  if (id) extra.id = id

  return {
    event: 'user.view_homepage_block_cta',
    extra,
  }
}

type UserClickHomepageBlockCtaArgs = {
  type: ItemBlockCtaType
  blockName: string
  blockPosition: number
  homepageSessionId?: string
  id?: string | number | null
}

type UserClickHomepageBlockCtaExtra = {
  type: ItemBlockCtaType
  block_name: string
  block_position: number
  homepage_session_id?: string
  id?: string | number | null
}

const userClickHomepageBlockCta = (args: UserClickHomepageBlockCtaArgs): TrackingEvent => {
  const { type, blockName, blockPosition, homepageSessionId, id } = args

  const extra: UserClickHomepageBlockCtaExtra = {
    type,
    block_name: blockName,
    block_position: blockPosition,
  }

  if (homepageSessionId) extra.homepage_session_id = homepageSessionId
  if (id) extra.id = id

  return {
    event: 'user.click_homepage_block_cta',
    extra,
  }
}

type UserClickHomepageVerticalArgs = {
  homepageSessionId?: string
  target: string
}

type UserClickHomepageVerticalExtra = { target: string; homepage_session_id?: string }

const userClickHomepageVertical = (args: UserClickHomepageVerticalArgs): TrackingEvent => {
  const { target, homepageSessionId } = args
  const extra: UserClickHomepageVerticalExtra = { target }

  if (homepageSessionId) extra.homepage_session_id = homepageSessionId

  return {
    event: 'user.click_homepage_vertical',
    extra,
  }
}

type SellerActionOfferRequestArgs = {
  action: 'reject' | 'accept'
  transactionId: number
  currentPrice: string
  offeredPrice: string
  offerRequestId: string
}

type SellerActionOfferRequestExtra = {
  offer_id: number
  transaction_id: number
  current_price: number
  offered_price: number
}

const sellerActionOfferRequest = (args: SellerActionOfferRequestArgs): TrackingEvent => {
  const { action, transactionId, currentPrice, offeredPrice, offerRequestId } = args

  const extra: SellerActionOfferRequestExtra = {
    transaction_id: transactionId,
    current_price: Number(currentPrice),
    offered_price: Number(offeredPrice),
    offer_id: Number(offerRequestId),
  }

  return {
    event: `seller.${action}_offer_request`,
    extra,
  }
}

type CheckoutBuyerTimeOnTaskEventArgs = {
  transactionId: number
  screen: string
  duration: number
  timestamp: {
    utcTimeFrom: string
    utcTimeTo: string
  }
}

type CheckoutBuyerTimeOnTaskEventExtra = {
  transaction_id: number
  screen: string
  duration: number
  timestamp: string
}

const checkoutBuyerTimeOnTaskEvent = (args: CheckoutBuyerTimeOnTaskEventArgs) => {
  const extra: CheckoutBuyerTimeOnTaskEventExtra = {
    transaction_id: args.transactionId,
    screen: args.screen,
    duration: args.duration,
    timestamp: JSON.stringify({
      utc_time_from: args.timestamp.utcTimeFrom,
      utc_time_to: args.timestamp.utcTimeTo,
    }),
  }

  return {
    event: 'buyer.time_in_checkout',
    extra,
  }
}

type SingleCheckoutTimeOnTaskEventArgs = {
  checkoutId: string
  screen: string
  timeInCheckoutMS: string
  utcTimeFrom: string
  utcTimeTo: string
}

type SingleCheckoutTimeOnTaskEventExtra = {
  checkout_id: string
  screen: string
  action: string
  action_details: {
    time_in_milliseconds: string
    utc_time_from: string
    utc_time_to: string
  }
}

const singleCheckoutTimeOnTaskEvent = (args: SingleCheckoutTimeOnTaskEventArgs) => {
  const extra: SingleCheckoutTimeOnTaskEventExtra = {
    checkout_id: args.checkoutId,
    screen: args.screen,
    action: 'time_on_task_in_ms',
    action_details: {
      time_in_milliseconds: args.timeInCheckoutMS,
      utc_time_from: args.utcTimeFrom,
      utc_time_to: args.utcTimeTo,
    },
  }

  return {
    event: 'checkout.interaction',
    extra,
  }
}

type UserHomepageElementArgs = {
  blockName: string
  position: number
  contentSource: ContentSource
  contentSourceId: string | null
  contentSourceLabel: string
  extraAttributes?: string
  homepageSessionId: string
  screen: Screen
}

type UserHomepageElementExtra = {
  block_name: string
  position: number
  content_source: string
  content_source_id?: string
  content_source_label: string
  extra_attributes?: string
  homepage_session_id: string
  screen: string
}

const userClickHomepageElement = (args: UserHomepageElementArgs) => {
  const extra: UserHomepageElementExtra = {
    block_name: args.blockName,
    position: args.position,
    content_source: args.contentSource,
    content_source_label: args.contentSourceLabel,
    homepage_session_id: args.homepageSessionId,
    screen: args.screen,
  }

  if (args.contentSourceId) extra.content_source_id = args.contentSourceId
  if (args.extraAttributes) extra.extra_attributes = args.extraAttributes

  return {
    event: 'user.click_homepage_element',
    extra,
  }
}

const userViewHomepageElement = (args: UserHomepageElementArgs) => {
  const extra: UserHomepageElementExtra = {
    block_name: args.blockName,
    position: args.position,
    content_source: args.contentSource,
    content_source_label: args.contentSourceLabel,
    homepage_session_id: args.homepageSessionId,
    screen: args.screen,
  }

  if (args.contentSourceId) extra.content_source_id = args.contentSourceId
  if (args.extraAttributes) extra.extra_attributes = args.extraAttributes

  return {
    event: 'user.view_homepage_element',
    extra,
  }
}

type NoticesUserEventArgs = {
  threadId: string
}

type NoticesUserEventExtra = {
  thread_id: string
}

const noticesUserOpenFaq = (args: NoticesUserEventArgs) => {
  const extra: NoticesUserEventExtra = {
    thread_id: args.threadId,
  }

  return {
    event: 'notices.user.open_faq',
    extra,
  }
}

const noticesUserOpenContactForm = (args: NoticesUserEventArgs) => {
  const extra: NoticesUserEventExtra = {
    thread_id: args.threadId,
  }

  return {
    event: 'notices.user.open_contact_form',
    extra,
  }
}

const noticesUserSubmitTicket = (args: NoticesUserEventArgs) => {
  const extra: NoticesUserEventExtra = {
    thread_id: args.threadId,
  }

  return {
    event: 'notices.user.submit_ticket',
    extra,
  }
}

type UserClickMemberArgs = {
  userId: number
  contentSource: ContentSource
}

type UserClickMemberExtra = {
  user_id: number
  content_source: ContentSource
}

const userClickMemberEvent = (args: UserClickMemberArgs): TrackingEvent => {
  const extra: UserClickMemberExtra = {
    user_id: args.userId,
    content_source: args.contentSource,
  }

  return {
    event: 'user.click_member',
    extra,
  }
}

type TokenizationOutcomeEventArgs = {
  orderId?: number
  errorMessage?: string
  transactionId?: number
  outcome: 'success' | 'failure'
  providerName: PaymentsProvider
  checkoutType: 'escrow' | 'settings' | 'single_checkout' | ExtraServiceOrderType
}

const tokenizationOutcomeEvent = (args: TokenizationOutcomeEventArgs): TrackingEvent => {
  const { outcome, orderId, checkoutType, errorMessage, providerName, transactionId } = args

  const extra = {
    outcome,
    order_id: orderId,
    error_message: errorMessage,
    provider_name: providerName,
    transaction_id: transactionId,
    checkout_type: extraServiceOrderTypeToTrackingTypeMap[checkoutType] || checkoutType,
  }

  return {
    event: 'system.tokenization_outcome',
    extra,
  }
}

type SingleCheckoutTokenizationOutcomeEventArgs = {
  errorMessage?: string
  checkoutId?: string
  outcome: 'success' | 'failure'
  providerName: PaymentsProvider
  clickId?: string
}

const singleCheckoutTokenizationOutcomeEvent = (
  args: SingleCheckoutTokenizationOutcomeEventArgs,
): TrackingEvent => {
  const { outcome, errorMessage, providerName, checkoutId, clickId } = args

  const extra = {
    outcome,
    error_message: errorMessage,
    provider_name: providerName,
    checkout_id: checkoutId,
    click_id: clickId,
  }

  return {
    event: 'credit_card_add.tokenization_outcome',
    extra,
  }
}

type DsaUserEventArgs = {
  threadId: number
}

type DsaUserEventExtra = {
  thread_id: string
}

const dsaUserOpenAppealForm = (args: DsaUserEventArgs) => {
  const extra: DsaUserEventExtra = {
    thread_id: args.threadId.toString(),
  }

  return {
    event: 'dsa.user.open_appeal_form',
    extra,
  }
}

const dsaUserOpenAppealEducation = (args: DsaUserEventArgs) => {
  const extra: DsaUserEventExtra = {
    thread_id: args.threadId.toString(),
  }

  return {
    event: 'dsa.user.open_appeal_education',
    extra,
  }
}

const dsaUserOpenAppealAgreement = (args: DsaUserEventArgs) => {
  const extra: DsaUserEventExtra = {
    thread_id: args.threadId.toString(),
  }

  return {
    event: 'dsa.user.open_appeal_agreement',
    extra,
  }
}

const dsaUserAddPhotosAppealForm = (args: DsaUserEventArgs) => {
  const extra: DsaUserEventExtra = {
    thread_id: args.threadId.toString(),
  }

  return {
    event: 'dsa.user.add_photos_appeal_form',
    extra,
  }
}

const dsaUserSubmitAppealForm = (args: DsaUserEventArgs) => {
  const extra: DsaUserEventExtra = {
    thread_id: args.threadId.toString(),
  }

  return {
    event: 'dsa.user.submit_appeal_form',
    extra,
  }
}

type DsaNtdUserEventArgs = {
  referenceId: string
}

type DsaNtdUserEventExtra = {
  reference_id: string
}

const dsaNtdUserOpenAppealForm = (args: DsaNtdUserEventArgs) => {
  const extra: DsaNtdUserEventExtra = {
    reference_id: args.referenceId,
  }

  return {
    event: 'dsa_ntd.user.open_appeal_form',
    extra,
  }
}

const dsaNtdUserSubmitAppealForm = (args: DsaNtdUserEventArgs) => {
  const extra: DsaNtdUserEventExtra = {
    reference_id: args.referenceId,
  }

  return {
    event: 'dsa_ntd.user.submit_appeal_form',
    extra,
  }
}

export type taxRulesPlacementClickEventArgs = {
  targetDetails?: string
  screen: string
}

type TaxRulesPlacementClickEventExtra = {
  target_details?: string
  screen: string
}

const taxRulesPlacementClickEvent = (args: taxRulesPlacementClickEventArgs): TrackingEvent => {
  const { targetDetails, screen } = args

  const extra: TaxRulesPlacementClickEventExtra = { target_details: targetDetails, screen }

  return {
    event: 'user.click',
    extra,
  }
}

type UserKybSuccessEventArgs = {
  businessAccountId: number
}

const userKybSuccessEvent = (args: UserKybSuccessEventArgs): TrackingEvent => {
  return {
    event: 'user.kyb_success',
    extra: {
      business_account_id: args.businessAccountId,
    },
  }
}

type LandingPageViewScreenArgs = {
  screen: string
  path: string
  referrer: string
}

type LandingPageViewScreenExtra = {
  screen: string
  path: string
  referrer: string
}

const ViewLandingPageEvent = (args: LandingPageViewScreenArgs): TrackingEvent => {
  const { screen, path, referrer } = args

  const extra: LandingPageViewScreenExtra = {
    screen,
    path,
    referrer,
  }

  return {
    event: 'user.view_screen',
    extra,
  }
}

type ReferralsOpenLinkEventArgs = {
  referrerId: number
}

type ReferralsOpenLinkEventExtra = {
  referrer_id: number
}

const referralsOpenLinkEvent = (args: ReferralsOpenLinkEventArgs): TrackingEvent => {
  const { referrerId } = args

  const extra: ReferralsOpenLinkEventExtra = {
    referrer_id: referrerId,
  }

  return {
    event: 'sharing.referrals.open_link',
    extra,
  }
}

const firstTimeListerEducationOpenEvent = () => {
  return {
    event: 'user.first_time_lister_education_open',
  }
}

type FirstTimeListerEducationCloseArgs = {
  slideNumber: number
}

const firstTimeListerEducationCloseEvent = (args: FirstTimeListerEducationCloseArgs) => {
  const { slideNumber } = args

  return {
    event: 'user.first_time_lister_education_close',
    extra: {
      slide_number: slideNumber,
    },
  }
}

type ViewItemArgs = {
  itemId: number
  ownerId: number
  referrerPath?: string
  searchSessionId: string | null
}

const viewItemEvent = (args: ViewItemArgs) => {
  const { itemId, ownerId, referrerPath, searchSessionId } = args

  return {
    event: 'user.view_item',
    extra: {
      item_id: itemId,
      item_owner_id: ownerId,
      path: referrerPath,
      search_session: searchSessionId,
    },
  }
}

type ViewOwnItemArgs = {
  itemId: number
  ownerId: number
  referrerPath?: string
  searchSessionId: string | null
}

const viewOwnItemEvent = (args: ViewOwnItemArgs) => {
  const { itemId, ownerId, referrerPath, searchSessionId } = args

  return {
    event: 'user.view_current_user_item',
    extra: {
      item_id: itemId,
      item_owner_id: ownerId,
      path: referrerPath,
      search_session: searchSessionId,
    },
  }
}

type OndatoWebSdkStateChage = {
  state: OndatoWebSdkState
  details?: string | null
}

const ondatoWebSdkStateChange = (args: OndatoWebSdkStateChage) => {
  const { state, details } = args

  return {
    event: 'system.ondato.web_sdk_state_change',
    extra: {
      state,
      details,
    },
  }
}

type DataDomeScriptAction = {
  actionType: 'blocked' | 'response_displayed' | 'response_passed' | 'response_error'
  fingerprint: string
  endpointName: string
  responseType?: string
}

const dataDomeScriptActionEvent = (args: DataDomeScriptAction) => {
  const { actionType, fingerprint, endpointName, responseType } = args

  return {
    event: 'system.datadome_script_action',
    extra: {
      action_type: actionType,
      fingerprint,
      endpoint_name: endpointName,
      response_type: responseType,
    },
  }
}

type ViewSavedSearchArgs = {
  type: SavedSearchType
  searchTitle: string
  position: number
  searchSessionId?: string | null
  globalSearchSessionId?: string | null
  newItemsCount?: number | null
  unrestrictedNewItemsCount?: number | null
  screen: Screen
  savedRecentSearchListId: string
  savedRecentSearchSessionId: string
  listName: SearchSectionListName
}

type ViewSavedSearchExtra = {
  type: SavedSearchType
  search_title: string
  position: number
  search_session_id?: string | null
  global_search_session_id?: string | null
  new_items_count?: number | null
  unrestricted_new_items_count?: number | null
  screen: Screen
  saved_recent_search_list_id: string
  saved_recent_search_session_id: string
  list_name: SearchSectionListName
}

const viewSavedSearchEvent = (args: ViewSavedSearchArgs) => {
  const {
    position,
    searchSessionId,
    searchTitle,
    type,
    newItemsCount,
    globalSearchSessionId,
    unrestrictedNewItemsCount,
    screen,
    savedRecentSearchListId,
    savedRecentSearchSessionId,
    listName,
  } = args

  const extra: ViewSavedSearchExtra = {
    type,
    position,
    screen,
    list_name: listName,
    saved_recent_search_list_id: savedRecentSearchListId,
    search_title: searchTitle,
    new_items_count: newItemsCount,
    search_session_id: searchSessionId,
    global_search_session_id: globalSearchSessionId,
    unrestricted_new_items_count: unrestrictedNewItemsCount,
    saved_recent_search_session_id: savedRecentSearchSessionId,
  }

  return {
    event: 'user.view_saved_search',
    extra,
  }
}

type SystemTimingArgs = {
  section: 'largest_contentful_paint' | 'ad_load'
  duration: number
  completionState: 'succeeded'
  data?: string
}

type SystemTimingExtra = {
  section: SystemTimingArgs['section']
  /** Duration in millisecond */
  duration: number
  completion_state: 'succeeded'
  data?: string
}

const systemTimingEvent = (args: SystemTimingArgs) => {
  const { section, duration, completionState, data } = args

  const extra: SystemTimingExtra = { section, duration, completion_state: completionState, data }

  return {
    event: 'system.timing',
    extra,
  }
}

type ChangeLanguageEventArgs = {
  fromLanguage: string | null
  toLanguage: string
  screen: Screen
}

type ChangeLanguageEventExtra = {
  from_language: string | null
  to_language: string
  screen: Screen
}

const changeLanguageEvent = (args: ChangeLanguageEventArgs) => {
  const extra: ChangeLanguageEventExtra = {
    from_language: args.fromLanguage,
    to_language: args.toLanguage,
    screen: args.screen,
  }

  return {
    event: 'user.change_language',
    extra,
  }
}

type StartMakeOfferRequestEventArgs = {
  offerRequestSessionId: string
  hasSuggestedPrices: boolean
  currentPrice: number
}

type StartMakeOfferRequestEventExtra = {
  offer_request_session_id: string
  has_suggested_prices: boolean
  current_price: number
}

const startMakeOfferRequestEvent = (args: StartMakeOfferRequestEventArgs) => {
  const { offerRequestSessionId, hasSuggestedPrices, currentPrice } = args

  const extra: StartMakeOfferRequestEventExtra = {
    offer_request_session_id: offerRequestSessionId,
    has_suggested_prices: hasSuggestedPrices,
    current_price: currentPrice,
  }

  return {
    event: 'buyer.make_offer_request.start',
    extra,
  }
}

export {
  viewSavedSearchEvent,
  clickEvent,
  kycClickEvent,
  favouriteItemEvent,
  followUserEvent,
  followBrandEvent,
  impressionEvent,
  clickListItemEvent,
  clickClosetPromotionEvent,
  endOfListEvent,
  viewEvent,
  viewScreenEvent,
  viewUserEvent,
  kycViewScreenEvent,
  systemTraceClosetPromoEvent,
  conversationReplyEvent,
  selectSearchSuggestionEvent,
  viewSearchSuggestionEvent,
  viewSearchSuggestionsEvent,
  viewShippingPriceEvent,
  clickFilterEvent,
  startConversationEvent,
  checkoutBuyerInputEvent,
  transactionSellerInputEvent,
  userInputEvent,
  checkoutPickPaymentMethodEvent,
  buyerViewCheckoutEvent,
  buyerViewSettingsCheckoutEvent,
  userViewCheckoutEvent,
  userKybSuccessEvent,
  selectCatalogEvent,
  selfServiceViewScreenEvent,
  viewPopularSearchEvent,
  selectPopularSearchEvent,
  selectBrandEvent,
  userFilterItemsEvent,
  settingsUserInputEvent,
  userRegisterSuccessEvent,
  userRegisterFailEvent,
  authenticateSuccessEvent,
  authenticateFailEvent,
  selectSuggestedMessageEvent,
  viewSuggestedMessageEvent,
  donationsClickEvent,
  fundraiserClickEvent,
  buyerLoadShippingPointsEvent,
  buyerPreselectedShippingOptionsEvent,
  buyerPickDeliveryTypeEvent,
  confirmWalletEvent,
  itemUploadStartEventExtra,
  itemUploadFieldSetEvent,
  itemUploadSubmitFail,
  itemUploadSubmitSuccess,
  itemUploadFillZipCodeFail,
  saveBankAccountEvent,
  toggleSearchSubscriptionEvent,
  selectSavedSearchEvent,
  abTestExposeEvent,
  sharingReferCopyLinkEvent,
  buyerViewAddContactDetailsEvent,
  sellerViewAddContactDetailsEvent,
  makeOfferEvent,
  sharingReferralsOpenInfoBannerLink,
  userPaginateCatalogEvent,
  userAppliedOrderEvent,
  userSelectTheme,
  conversationHarassmentEvent,
  buyerViewDeliveryDetailsEvent,
  buyerBuyEvent,
  requestedAdEvent,
  receivedAdEvent,
  viewAdEvent,
  crashAdComponentEvent,
  checkoutInteractionEvent,
  viewSuggestedPlacesEvent,
  selectSuggestedPlaceEvent,
  viewFilterEvent,
  buyerPrecheckoutEvent,
  filterFeaturedCollectionItemsEvent,
  sizeFilterEvent,
  userViewItemGallery,
  userViewDynamicFilterOptionEvent,
  userClickDynamicFilterOptionEvent,
  searchFaqEntriesEvent,
  leaveFaqEntryEvent,
  viewFaqEntry,
  userContactSupportEvent,
  userClickFaqRelatedQuestionEvent,
  userClickRecommendedTopicEvent,
  userClickHelpCenterSearchResult,
  userSubmitFaqFeedbackEvent,
  viewProofGatheringForm,
  proofGatheringFormSubmission,
  viewProofGatheringFormSubmitModal,
  proofGatheringFormModalCancelSubmission,
  liveChatBannerViewed,
  liveChatBannerClicked,
  liveChatFormSubmitted,
  liveChatFormViewed,
  adBlockCheckEvent,
  buyerViewCarrierDiscountedShippingPriceEvent,
  conversationPasteEvent,
  userModifyCarrierPreferencesEvent,
  userViewHomepageBlock,
  userViewHomepageBlockCta,
  userClickHomepageBlockCta,
  sellerActionOfferRequest,
  buyerWantItemEvent,
  checkoutBuyerTimeOnTaskEvent,
  userClickHomepageElement,
  userViewHomepageElement,
  userClickHomepageVertical,
  noticesUserOpenFaq,
  noticesUserOpenContactForm,
  noticesUserSubmitTicket,
  userClickMemberEvent,
  tokenizationOutcomeEvent,
  dsaUserOpenAppealForm,
  dsaUserOpenAppealEducation,
  dsaUserOpenAppealAgreement,
  dsaUserAddPhotosAppealForm,
  dsaUserSubmitAppealForm,
  dsaNtdUserOpenAppealForm,
  dsaNtdUserSubmitAppealForm,
  taxRulesPlacementClickEvent,
  ViewLandingPageEvent,
  referralsOpenLinkEvent,
  firstTimeListerEducationOpenEvent,
  firstTimeListerEducationCloseEvent,
  viewItemEvent,
  viewOwnItemEvent,
  ondatoWebSdkStateChange,
  viewSingleCheckoutEvent,
  clickSingleCheckoutEvent,
  singleCheckoutInteractionEvent,
  singleCheckoutTimeOnTaskEvent,
  dataDomeScriptActionEvent,
  systemTimingEvent,
  changeLanguageEvent,
  startMakeOfferRequestEvent,
  profileClickEvent,
  singleCheckoutBuyerInputEvent,
  viewCreditCardAddEvent,
  singleCheckoutTokenizationOutcomeEvent,
  creditCardAddClickEvent,
}
