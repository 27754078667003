export enum BankAccountType {
  US = 'US',
  IBAN = 'IBAN',
  GB = 'GB',
}

export enum PaymentsProvider {
  Stripe = 'stripe',
  Mangopay = 'mangopay',
  Adyen = 'adyen',
  AdyenBank = 'adyen_bank',
  CheckoutCom = 'checkout',
  Payrails = 'payrails',
  None = 'none',
}

export enum BankAccountTokenizer {
  Stripe = 'stripe',
}

export enum BankAccountFieldType {
  Name = 'name',
  AccountNumber = 'account_number',
  RoutingNumber = 'routing_number',
  SpendingType = 'spending_type',
}

export enum BankAccountSpendingType {
  Checking = 'checking',
  Savings = 'savings',
}
