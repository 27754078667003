import { takeEvery, put } from 'redux-saga/effects'
import { call, select } from 'typed-redux-saga'

import {
  getUserFeedback,
  editFeedbackComment,
  deleteFeedbackComment,
  deleteFeedback as deleteFeedbackRequest,
  getFeedback as getFeedbackRequest,
} from 'data/api'

import { transformFeedbackResponse, transformFeedback } from './transformers'

import { actions } from './slice'
import * as statelessActions from './actions'
import * as selectors from './selectors'

export function* fetchFeedback({
  payload: { userId, feedbackFilterType },
}: ReturnType<typeof actions.feedbackGetRequest>) {
  const currentPage = yield* select(selectors.getCurrentPage)

  const response = yield* call(getUserFeedback, {
    userId,
    perPage: 20,
    page: currentPage + 1,
    feedbackFilterType,
  })

  if ('errors' in response) {
    yield put(actions.feedbackGetFailure())

    return
  }

  yield put(actions.feedbackGetSuccess({ feedbackData: transformFeedbackResponse(response) }))
}

export function* deleteFeedback({
  payload: { feedbackId },
}: ReturnType<typeof actions.feedbackDeleteRequest>) {
  const response = yield* call(deleteFeedbackRequest, { feedbackId })

  if ('errors' in response) {
    yield put(actions.feedbackDeleteFailure({ feedbackId, error: response }))

    return
  }

  yield put(actions.feedbackDeleteSuccess({ feedbackId }))
}

export function* createComment({
  payload: { feedbackId, comment },
}: ReturnType<typeof actions.feedbackCommentCreateRequest>) {
  const response = yield* call(editFeedbackComment, { feedbackId, comment })

  if ('errors' in response) {
    yield put(actions.feedbackCommentCreateFailure({ feedbackId, error: response }))

    return
  }

  yield put(
    actions.feedbackCommentCreateSuccess({
      feedbackId,
      feedback: transformFeedback(response.feedback),
    }),
  )
}

export function* editComment({
  payload: { feedbackId, comment },
}: ReturnType<typeof actions.feedbackCommentEditRequest>) {
  const response = yield* call(editFeedbackComment, { feedbackId, comment })

  if ('errors' in response) {
    yield put(
      actions.feedbackCommentEditFailure({
        feedbackId,
        error: response,
      }),
    )

    return
  }

  yield put(
    actions.feedbackCommentEditSuccess({
      feedbackId,
      feedback: transformFeedback(response.feedback),
    }),
  )
}

export function* deleteComment({
  payload: { feedbackId },
}: ReturnType<typeof actions.feedbackCommentDeleteRequest>) {
  const response = yield* call(deleteFeedbackComment, { feedbackId })

  if ('errors' in response) {
    yield put(actions.feedbackCommentDeleteFailure({ feedbackId, error: response }))

    return
  }

  yield put(
    actions.feedbackCommentDeleteSuccess({
      feedbackId,
      feedback: transformFeedback(response.feedback),
    }),
  )
}

export function* initiateTranslation({
  payload: { feedbackId, localize },
}: ReturnType<typeof statelessActions.feedbackInitiateTranslation>) {
  if (!localize) {
    yield put(actions.feedbackToggleTranslated({ feedbackId, localize }))

    return
  }

  const translatedFeedback = yield* select(selectors.getFeedbackByTranslatedId, feedbackId)

  if (translatedFeedback) {
    yield put(actions.feedbackToggleTranslated({ feedbackId, localize }))

    return
  }

  yield put(actions.feedbackTranslateRequest({ feedbackId, localize }))
}

export function* translateFeedback({
  payload: { feedbackId, localize },
}: ReturnType<typeof actions.feedbackTranslateRequest>) {
  const response = yield* call(getFeedbackRequest, {
    feedbackId,
    localize,
  })

  if ('errors' in response) {
    yield put(actions.feedbackTranslateFailure({ feedbackId, error: response }))

    return
  }

  yield put(
    actions.feedbackTranslateSuccess({
      feedbackId,
      feedback: transformFeedback(response.user_feedback),
      localize,
    }),
  )
}

export default function* saga() {
  yield takeEvery(actions.feedbackGetRequest, fetchFeedback)
  yield takeEvery(actions.feedbackDeleteRequest, deleteFeedback)
  yield takeEvery(actions.feedbackCommentCreateRequest, createComment)
  yield takeEvery(actions.feedbackCommentEditRequest, editComment)
  yield takeEvery(actions.feedbackCommentDeleteRequest, deleteComment)
  yield takeEvery(actions.feedbackTranslateRequest, translateFeedback)
  yield takeEvery(statelessActions.feedbackInitiateTranslation, initiateTranslation)
}
