import { stateName, FieldName } from './constants'
import { AllState, State } from './types'

const localState = (state: AllState): State => state[stateName]

const uiState = (state: AllState) => localState(state).ui

export const getShippingContactUiState = (state: AllState) => uiState(state).shippingContact

export const getShippingContactSaveUiState = (state: AllState) => uiState(state).saveShippingContact

export const getShippingContactDeleteUiState = (state: AllState) =>
  uiState(state).deleteShippingContact

export const getShippingContactConfigurationUiState = (state: AllState) =>
  uiState(state).configuration

export const getShippingContactConfiguration = (state: AllState) => localState(state).configuration

export const getBuyerPhoneNumber = (state: AllState) => localState(state).buyerPhoneNumber

export const getSellerPhoneNumber = (state: AllState) => localState(state).sellerPhoneNumber

export const getShippingContactErrors = (state: AllState) => localState(state).errors

export const getShippingContactFieldError = (state: AllState, field: FieldName) =>
  localState(state).errors.find(error => error.field === field)
