import { FeedbackProblemOptionsDto } from 'types/dtos'
import { FeedbackOptionsModel } from 'types/models'

export const transformFeedbackProblemOptions = (
  options: Array<FeedbackProblemOptionsDto>,
): Array<FeedbackOptionsModel> =>
  options.map(option => {
    const { id, body, allow_user_input } = option

    return {
      id,
      body,
      hasMessageInput: allow_user_input,
    }
  })
