import {
  ReturnShippingOptionDto,
  PricingSummaryDto,
  PricingSummaryPriceDto,
  PricingSummaryDiscountDto,
} from 'types/dtos/return-shipping-option'
import {
  ReturnShippingOptionModel,
  PricingSummaryModel,
  PricingSummaryPriceModel,
  PricingSummaryDiscountModel,
} from 'types/models/return-shipping-option'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformPricingSummaryDiscountDto = (
  discount: PricingSummaryDiscountDto,
): PricingSummaryDiscountModel => {
  return {
    percent: discount.percent,
    note: discount.note,
  }
}

export const transformPricingSummaryPriceDto = (
  price: PricingSummaryPriceDto,
): PricingSummaryPriceModel => {
  return {
    amount: price.amount,
    currencyCode: price.currency_code,
  }
}

export const transformPricingSummaryDto = (
  pricingSummary: PricingSummaryDto,
): PricingSummaryModel => {
  return {
    pricing: pricingSummary.pricing,
    title: pricingSummary.title,
    price: pricingSummary.price && transformPricingSummaryPriceDto(pricingSummary.price),
    priceNote: pricingSummary.price_note,
    discount:
      pricingSummary.discount && transformPricingSummaryDiscountDto(pricingSummary.discount),
    priceBeforeDiscount:
      pricingSummary.price_before_discount &&
      transformCurrencyAmountDto(pricingSummary.price_before_discount),
    lowestPriceIn30days:
      pricingSummary.lowest_price_in_30_days &&
      transformCurrencyAmountDto(pricingSummary.lowest_price_in_30_days),
  }
}

export const transformPricingSummaryDtos = (
  pricingSummaries: Array<PricingSummaryDto>,
): Array<PricingSummaryModel> => pricingSummaries.map(transformPricingSummaryDto)

export const transformReturnShippingOptionDto = (
  shippingOption: ReturnShippingOptionDto,
): ReturnShippingOptionModel => {
  return {
    code: shippingOption.code,
    iconUrl: shippingOption.icon_url,
    title: shippingOption.title,
    subtitle: shippingOption.subtitle,
    pricingSummary:
      shippingOption.pricing_summary && transformPricingSummaryDtos(shippingOption.pricing_summary),
    actionTitle: shippingOption.action_title,
    note: shippingOption.note,
    sellerPaidForReturn: !!shippingOption.seller_paid_for_return,
  }
}

export const transformReturnShippingOptionDtos = (
  shippingOptions: Array<ReturnShippingOptionDto>,
): Array<ReturnShippingOptionModel> => shippingOptions.map(transformReturnShippingOptionDto)
