import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'

import { WalletBalanceModel } from 'types/models'

import { State, Invoices } from './types'
import { stateName } from './constants'

export const initialState: State = {
  balance: {
    availableAmount: { amount: '0', currencyCode: 'EUR' },
    pendingAmount: { amount: '0', currencyCode: 'EUR' },
    uiState: UiState.Idle,
  },
  invoices: {
    invoiceLines: [],
    startBalance: {
      amount: '',
      currencyCode: '',
    },
    endBalance: {
      amount: '',
      currencyCode: '',
    },
    startingDate: '',
    uiState: UiState.Idle,
    history: undefined,
    taxpayerRestricted: false,
  },
}

const getBalanceRequest: CaseReducer<State, PayloadAction<{ userId: number }>> = draft => {
  draft.balance.uiState = UiState.Pending
}

const getBalanceFailure: CaseReducer<State> = draft => {
  draft.balance.uiState = UiState.Failure
}

const getBalanceSuccess: CaseReducer<State, PayloadAction<{ balance: WalletBalanceModel }>> = (
  draft,
  action,
) => {
  const { balance } = action.payload

  draft.balance = {
    ...balance,
    uiState: UiState.Success,
  }
}

const getCurrentInvoicesRequest: CaseReducer<State> = draft => {
  draft.invoices.uiState = UiState.Pending
}

const getCurrentInvoicesFailure: CaseReducer<State> = draft => {
  draft.invoices.uiState = UiState.Failure
}

const getCurrentInvoicesSuccess: CaseReducer<State, PayloadAction<{ invoices: Invoices }>> = (
  draft,
  action,
) => {
  const { invoices } = action.payload

  draft.invoices = {
    ...invoices,
    uiState: UiState.Success,
  }
}

const getHistoricalInvoicesRequest: CaseReducer<
  State,
  PayloadAction<{ year: number; month: number }>
> = draft => {
  draft.invoices.uiState = UiState.Pending
}

const getHistoricalInvoicesFailure: CaseReducer<State> = draft => {
  draft.invoices.uiState = UiState.Failure
}

const getHistoricalInvoicesSuccess: CaseReducer<State, PayloadAction<{ invoices: Invoices }>> = (
  draft,
  action,
) => {
  const { invoices } = action.payload

  draft.invoices = {
    ...invoices,
    uiState: UiState.Success,
  }
}

const walletSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getBalanceRequest,
    getBalanceSuccess,
    getBalanceFailure,
    getCurrentInvoicesRequest,
    getCurrentInvoicesSuccess,
    getCurrentInvoicesFailure,
    getHistoricalInvoicesRequest,
    getHistoricalInvoicesFailure,
    getHistoricalInvoicesSuccess,
  },
})

export const { actions } = walletSlice
export const plug = { [stateName]: walletSlice.reducer }
export default walletSlice.reducer
