import { WalletBalanceDto, InvoiceDto } from 'types/dtos'
import { InvoiceModel, WalletBalanceModel } from 'types/models'

import { transformCurrencyAmountDto } from './currency-amount'

export const transformWalletBalanceDto = ({
  available_amount,
  escrow_amount,
}: WalletBalanceDto): WalletBalanceModel => ({
  pendingAmount: transformCurrencyAmountDto(escrow_amount),
  availableAmount: transformCurrencyAmountDto(available_amount),
})

export const transformInvoiceDto = (invoice: InvoiceDto): InvoiceModel => ({
  invoiceSeries: invoice.invoice_series,
  invoiceNo: invoice.invoice_no,
  invoiceDate: invoice.invoice_date,
  id: invoice.id,
})
