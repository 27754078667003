import { takeLatest, takeEvery, put, delay } from 'redux-saga/effects'
import { call, select, all, apply } from 'typed-redux-saga'
import { unionBy } from 'lodash'

import { tracker } from 'libs/common/tracker'
import {
  userFilterItemsEvent,
  endOfListEvent,
  userPaginateCatalogEvent,
  userAppliedOrderEvent,
} from 'libs/common/event-tracker/events'
import { TrackingEvent } from 'libs/common/event-tracker/types'
import { toUrlQuery, toParams } from 'libs/utils/url'
import { scrollToTop } from 'libs/utils/window'
import {
  getSearchStartData,
  getSearchStartDataFromUrl,
  removeSearchStartData,
  setSearchSessionData,
} from 'libs/utils/search'
import { transformCatalogDtos } from 'data/transformers/catalog'
import {
  transformDynamicFilterOptions,
  transformDynamicFilters,
  transformSelectedDefaultDynamicFilters,
  transformSelectedDynamicFilters,
} from 'data/transformers/dynamic-filter'
import * as api from 'data/api'

import * as itemSelectors from 'state/items/selectors'
import { actions as itemActions } from 'state/items/slice'
import * as savedSearchesSelectors from 'state/saved-searches/selectors'
import { actions as savedSearchesActions } from 'state/saved-searches/slice'
import * as savedSearchesStatelessActions from 'state/saved-searches/actions'
import { getUserId } from 'state/session/selectors'

import { CATALOG_URL } from 'constants/routes'
import { SortByOption } from 'constants/filter'
import { CATALOG_PER_PAGE } from 'state/items/constants'
import { SUPPORTED_DISPLAY_TYPES } from 'constants/dynamic-filters'
import { transformBrandDto } from 'data/transformers'

import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'

import { CATALOG_FILTERS_SEARCH_DEBOUNCE_AMOUNT, SCROLL_TO_TOP_DELAY } from './constants'
import { buildCatalogUrlParams, getCatalogInitializersParamsFromUrl } from './utils'
import { TransformedApiData } from './types'

const DEFAULT_PAGE_NUMBER = 1

export function* fetchConfigurationData() {
  const response = yield* call(api.getSessionDefaults)

  if ('errors' in response) {
    yield put(actions.fetchConfigDataFailure())

    return
  }

  yield put(actions.fetchConfigDataSuccess(response.session_defaults_configuration))
}

export function* getCatalogBrandFlow() {
  const selectedFilters = yield* select(selectors.getSelectedDynamicFilters)
  const selectedBrandFilters = selectedFilters.find(filter => filter.type === 'brand')

  if (selectedBrandFilters?.ids.length === 1) {
    const brandId = selectedBrandFilters.ids[0]
    const currentBrand = yield* select(selectors.getCatalogBrand)

    if (currentBrand?.id !== brandId) {
      const response = yield* call(api.getBrandById, brandId)

      if ('errors' in response) {
        yield put(actions.removeCatalogBrand())

        return
      }

      const { brand } = response

      if (brand) yield put(actions.setCatalogBrand({ brand: transformBrandDto(brand) }))
    }
  } else {
    yield put(actions.removeCatalogBrand())
  }
}

export function* fetchData({ payload }: ReturnType<typeof actions.fetchDataRequest>) {
  const { relativeUrl, searchParams } = payload

  const userId = yield* select(getUserId)

  yield put(actions.changeIsInCatalog(true))
  yield put(
    itemActions.setCatalogItemsPagination({
      time: Number(searchParams.time) || undefined,
      page: Number(searchParams.page) || DEFAULT_PAGE_NUMBER,
      perPage: CATALOG_PER_PAGE,
    }),
  )

  yield put(actions.fetchConfigDataRequest())

  const catalogInitializersFromUrl = getCatalogInitializersParamsFromUrl(relativeUrl, searchParams)

  const initializersParams = {
    ...catalogInitializersFromUrl,
    supported_display_types: SUPPORTED_DISPLAY_TYPES.join(','),
  }

  let catalogIds: Array<number> | undefined

  if (catalogInitializersFromUrl.catalog) {
    const { catalog } = catalogInitializersFromUrl

    if (Array.isArray(catalog)) {
      catalogIds = catalog.map(value => parseInt(value, 10))
    } else {
      catalogIds = [parseInt(catalog, 10)]
    }

    yield put(
      actions.updateTemporaryCatalogIds({
        temporaryCatalogIds: catalogIds,
      }),
    )
  }

  const response = yield* call(api.getCatalogInitializers, initializersParams)

  if ('errors' in response) {
    yield put(actions.fetchDataFailure())

    return
  }

  const { dtos, filters } = response

  yield put(
    actions.changeDtos({
      dtos: {
        ...dtos,
      },
    }),
  )

  const data: TransformedApiData = {
    catalogs: transformCatalogDtos(dtos.catalogs),
  }
  const currentSearchId = Number(searchParams.search_id) || null

  if (dtos.dynamicFilters && dtos.selectedDynamicFilters) {
    data.dynamicFilters = transformDynamicFilters(dtos.dynamicFilters)
    data.selectedDynamicFilters = transformSelectedDynamicFilters(dtos.selectedDynamicFilters)
  }

  yield put(actions.fetchDataSuccess(data))
  yield put(
    actions.fetchDefaultDynamicFiltersSuccess({
      selectedDefaultFilters: transformSelectedDefaultDynamicFilters(
        dtos.selectedDefaultFilters || [],
      ),
    }),
  )

  yield put(actions.setInitialFilters({ filters }))
  if (currentSearchId) yield put(savedSearchesActions.setSearchId({ currentSearchId }))

  const currentBrand = yield* select(selectors.getCatalogBrand)
  if (!currentBrand) yield call(getCatalogBrandFlow)

  if (userId && !searchParams.search_id) {
    if (catalogIds?.[0] || searchParams.search_text) {
      yield put(
        savedSearchesStatelessActions.createSearchRequest({
          userId,
          search: {
            search_text: searchParams.search_text as string | undefined,
            catalog_id: catalogIds?.[0],
            subscribed: false,
          },
        }),
      )
    }
  }
}

export function* fetchDynamicFilterSearch({
  payload,
}: ReturnType<typeof statelessActions.fetchDynamicFilterSearchRequest>) {
  yield delay(CATALOG_FILTERS_SEARCH_DEBOUNCE_AMOUNT)

  const filters = yield* select(selectors.getFilters)
  const selectedDynamicFilters = yield* select(selectors.getSelectedDynamicFilters)
  const response = yield* call(api.getCatalogSearchedFilters, {
    ...filters,
    selectedDynamicFilters,
    filterSearchText: payload.text,
    filterSearchCode: payload.code,
  })

  if ('errors' in response) {
    yield put(statelessActions.fetchDynamicFilterSearchFailure())

    return
  }

  yield put(
    actions.fetchDynamicFilterSearchSuccess({
      code: payload.code,
      options: transformDynamicFilterOptions(response.options),
      isSelectionHighlighted: response.is_selection_highlighted,
    }),
  )
}

export function* updateUrl({ shouldReplaceHistory }: { shouldReplaceHistory: boolean }) {
  const filters = yield* select(selectors.getFilters)
  const selectedDynamicFilters = yield* select(selectors.getSelectedDynamicFilters)
  const page = yield* select(itemSelectors.getCatalogItemCurrentPage)
  const perPage = yield* select(itemSelectors.getCatalogItemPerPage)
  const time = yield* select(itemSelectors.getCatalogItemTime)
  const currentSearchId = yield* select(savedSearchesSelectors.getCurrentSearchId)
  const currentUrlParams = toParams(window.location.search)

  const extraParams = {
    ...currentUrlParams,
    search_id: currentSearchId,
  }

  const urlParams = buildCatalogUrlParams({
    pagination: { page, perPage, time },
    extraParams,
    filters,
    selectedDynamicFilters,
  })

  const urlQuery = `${CATALOG_URL}?${toUrlQuery(urlParams)}`

  const historyStateAction = shouldReplaceHistory
    ? window.history.replaceState
    : window.history.pushState

  yield call([window.history, historyStateAction], undefined, '', urlQuery)
}

export function* trackFilterChange({
  meta: { isPaginationEvent },
}: ReturnType<typeof itemActions.getCatalogItemsSuccess>) {
  const filters = yield* select(selectors.getFilters)
  const selectedDynamicFilters = yield* select(selectors.getSelectedDynamicFilters)
  const searchCorrelationId = yield* select(selectors.getSearchCorrelationId)
  const searchSessionId = yield* select(selectors.getSearchSessionId)
  const globalSearchSessionId = yield* select(selectors.getGlobalSearchSessionId)
  const itemCount = (yield* select(itemSelectors.getCatalogItemTotalEntries)) ?? 0
  const isEndReached = yield* select(itemSelectors.getCatalogItemEndReached)
  const currentPage = (yield* select(itemSelectors.getCatalogItemCurrentPage)) ?? 0
  const perPage = yield* select(itemSelectors.getCatalogItemPerPage)
  const { searchStartId: startId, searchStartType: startType } = yield* call(getSearchStartData)
  const { searchStartId: startIdFromUrl, searchStartType: startTypeFromUrl } =
    yield* call(getSearchStartDataFromUrl)

  const searchStartId = startId || startIdFromUrl
  const searchStartType = startType || startTypeFromUrl

  const events: Array<TrackingEvent> = []

  if (isPaginationEvent) {
    events.push(
      userPaginateCatalogEvent({
        currentPage,
        searchCorrelationId,
        searchSessionId,
        globalSearchSessionId,
      }),
    )
  } else {
    events.push(
      userFilterItemsEvent({
        itemCount,
        searchCorrelationId,
        searchSessionId,
        filters,
        globalSearchSessionId,
        searchStartId,
        searchStartType,
        selectedDynamicFilters,
      }),
    )

    if (searchStartId || searchStartType) yield* call(removeSearchStartData)

    if (filters.sortBy && filters.sortBy !== SortByOption.Relevance) {
      events.push(
        userAppliedOrderEvent({ order: filters.sortBy, searchSessionId, globalSearchSessionId }),
      )
    }
  }

  if (isEndReached) {
    events.push(
      endOfListEvent({
        itemCount,
        page: currentPage,
        perPage,
      }),
    )
  }

  yield all(events.map(event => apply(tracker, tracker.track, [event])))
}

export function* catalogFiltersChangeFlow() {
  const isInCatalog = yield* select(selectors.getIsInCatalog)

  if (!isInCatalog) return

  const filters = yield* select(selectors.getFilters)

  yield put(actions.changeFilters({ filters }))
}

export function* dynamicFiltersChangeFlow() {
  yield put(actions.fetchDynamicFiltersRequest())
}

export function* temporaryDynamicFiltersChangeFlow() {
  yield put(actions.fetchTemporaryDynamicFiltersRequest())
}

export function* filterRemoveFlow({ payload }: ReturnType<typeof actions.removeFilter>) {
  const { filter } = payload

  if (!('value' in filter)) return

  if (filter.name === 'catalogIds') {
    yield call(dynamicFiltersChangeFlow)
  }
}

export function* setFiltersFlow({ payload }: ReturnType<typeof actions.setFilters>) {
  const { filters } = payload

  if (!filters?.catalogIds?.length) return

  yield call(dynamicFiltersChangeFlow)
  yield call(catalogFiltersChangeFlow)
}

export function* queryChange() {
  yield delay(SCROLL_TO_TOP_DELAY)
  yield call(scrollToTop)
}

export function* getCatalogItemsSuccessWorker(
  action: ReturnType<typeof itemActions.getCatalogItemsSuccess>,
) {
  const { searchSessionId, globalSearchSessionId } = action.payload

  yield* call(trackFilterChange, action)

  if (searchSessionId) {
    yield* call(setSearchSessionData, { searchSessionId })
  }

  if (globalSearchSessionId) {
    yield* call(setSearchSessionData, { globalSearchSessionId })
  }
}

export function* fetchDynamicFilters() {
  const filters = yield* select(selectors.getFilters)
  const selectedDynamicFilters = yield* select(selectors.getSelectedDynamicFilters)
  const filterSearchQuery = yield* select(selectors.getFilterSearchQuery)
  const time = yield* select(itemSelectors.getCatalogItemTime)
  const response = yield* call(api.getCatalogFilters, {
    ...filters,
    selectedDynamicFilters,
    time,
    filterSearchText: filterSearchQuery.text,
    filterSearchCode: filterSearchQuery.code,
    supportedDisplayTypes: SUPPORTED_DISPLAY_TYPES,
  })

  if ('errors' in response) {
    yield put(actions.fetchDynamicFiltersFailure({ errors: response.errors }))
  } else {
    yield put(
      actions.fetchDynamicFiltersSuccess({
        dynamicFilters: transformDynamicFilters(response.filters),
        selectedDynamicFilters: transformSelectedDynamicFilters(response.selected_filters),
      }),
    )
  }
}

export function* fetchTemporaryDynamicFilters() {
  const filters = yield* select(selectors.getFilters)
  const temporarySelectedDynamicFilter = yield* select(selectors.getTemporarySelectedDynamicFilters)
  const selectedCatalogIds = yield* select(selectors.getTemporaryCatalogIds)
  const selectedPriceRange = yield* select(selectors.getTemporaryPriceRange)
  const filterSearchQuery = yield* select(selectors.getFilterSearchQuery)
  const time = yield* select(itemSelectors.getCatalogItemTime)
  const response = yield* call(api.getCatalogFilters, {
    ...filters,
    catalogIds: selectedCatalogIds,
    priceFrom: selectedPriceRange.priceFrom,
    priceTo: selectedPriceRange.priceTo,
    selectedDynamicFilters: temporarySelectedDynamicFilter,
    filterSearchText: filterSearchQuery.text,
    filterSearchCode: filterSearchQuery.code,
    supportedDisplayTypes: SUPPORTED_DISPLAY_TYPES,
    time,
  })

  if ('errors' in response) {
    yield put(actions.fetchTemporaryDynamicFiltersFailure({ errors: response.errors }))
  } else {
    yield put(
      actions.fetchTemporaryDynamicFiltersSuccess({
        dynamicFilters: transformDynamicFilters(response.filters),
        temporarySelectedDynamicFilters: transformSelectedDynamicFilters(response.selected_filters),
      }),
    )
  }
}

export function* fetchDefaultDynamicFilters() {
  const selectedCatalogIds = yield* select(selectors.getCatalogIds)
  const selectedDynamicFilters = yield* select(selectors.getSelectedDynamicFilters)
  const response = yield* call(api.getCatalogDefaultFilters, {
    catalogIds: selectedCatalogIds,
  })

  if ('errors' in response) {
    yield put(actions.fetchDefaultDynamicFiltersFailure({ errors: response.errors }))
  } else {
    const defaultFilters = transformSelectedDefaultDynamicFilters(response.defaults || [])

    yield put(
      actions.fetchDefaultDynamicFiltersSuccess({
        selectedDefaultFilters: defaultFilters,
      }),
    )
    yield put(
      actions.setSelectedDynamicFilters({
        selectedDynamicFilters: unionBy(defaultFilters, selectedDynamicFilters, 'type'),
      }),
    )
  }
}

export function* fetchTemporaryDefaultDynamicFilters() {
  const temporarySelectedCatalogIds = yield* select(selectors.getTemporaryCatalogIds)
  const temporarySelectedDynamicFilters = yield* select(
    selectors.getTemporarySelectedDynamicFilters,
  )
  const response = yield* call(api.getCatalogDefaultFilters, {
    catalogIds: temporarySelectedCatalogIds,
  })

  if ('errors' in response) {
    yield put(actions.fetchDefaultDynamicFiltersFailure({ errors: response.errors }))
  } else {
    const defaultFilters = transformSelectedDefaultDynamicFilters(response.defaults || [])

    yield put(
      actions.fetchDefaultDynamicFiltersSuccess({
        selectedDefaultFilters: defaultFilters,
      }),
    )
    yield put(
      actions.setTemporarySelectedDynamicFilters({
        temporarySelectedDynamicFilters: unionBy(
          defaultFilters,
          temporarySelectedDynamicFilters,
          'type',
        ),
      }),
    )
  }
}

export default function* saga() {
  yield takeLatest(actions.fetchConfigDataRequest, fetchConfigurationData)
  yield takeLatest(actions.fetchDataRequest, fetchData)
  yield takeLatest(actions.fetchDynamicFiltersRequest, fetchDynamicFilters)
  yield takeLatest(actions.fetchTemporaryDynamicFiltersRequest, fetchTemporaryDynamicFilters)
  yield takeEvery(itemActions.getCatalogItemsSuccess, getCatalogItemsSuccessWorker)
  yield takeEvery(actions.removeFilter, filterRemoveFlow)
  yield takeLatest(actions.catalogFilterSet, fetchDefaultDynamicFilters)
  yield takeLatest(actions.setTemporaryCatalogIds, fetchTemporaryDefaultDynamicFilters)
  yield takeLatest(statelessActions.fetchDynamicFilterSearchRequest, fetchDynamicFilterSearch)
  yield takeLatest(actions.setQuery, queryChange)
  yield takeLatest(actions.setFilters, setFiltersFlow)
  yield takeLatest(actions.changeFilters, getCatalogBrandFlow)

  yield takeLatest(
    [
      actions.removeFilter,
      actions.removeFilters,
      actions.priceRangeFilterSet,
      actions.toggleSortFilterOption,
      actions.setQuery,
      actions.fetchDynamicFiltersSuccess,
    ],
    catalogFiltersChangeFlow,
  )

  yield takeLatest(
    [
      actions.submitSearchQuery,
      // relevant static filters actions
      actions.removeFilter,
      actions.priceRangeFilterSet,
      // dynamic filters actions
      actions.setSelectedDynamicFilter,
      actions.setSelectedDynamicFilters,
      actions.removeSelectedDynamicFilterId,
      actions.removeSelectedDynamicFilter,
      actions.removeSelectedDynamicFilters,
    ],
    dynamicFiltersChangeFlow,
  )

  // adding or removing temporary dynamic filters will trigger new fetch
  yield takeLatest(
    [
      actions.setTemporarySelectedDynamicFilter,
      actions.setTemporarySelectedDynamicFilters,
      actions.removeTemporarySelectedDynamicFilter,
      actions.removeTemporarySelectedDynamicFilterId,
      actions.removeTemporarySelectedDynamicFilters,
      // relevant temporary static filters actions
      actions.removeTemporaryCatalogIds,
      actions.setTemporaryPriceRange,
      actions.removeTemporaryPriceRange,
    ],
    temporaryDynamicFiltersChangeFlow,
  )

  yield takeLatest(
    [actions.changeFilters, itemActions.setCatalogItemsPage, itemActions.setCatalogItemsPerPage],
    updateUrl,
    { shouldReplaceHistory: false },
  )
}
