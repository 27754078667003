import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { ComplaintModel } from 'types/models'

import { UiState } from 'constants/ui'

import { State } from './types'
import { stateName } from './constants'

export const initialState: State = {
  complaint: undefined,
  ui: {
    uiState: UiState.Idle,
    error: null,
  },
}

const getComplaintRequest: CaseReducer<State, PayloadAction<{ complaintId: number }>> = draft => {
  draft.ui.uiState = UiState.Pending
}

const getSnadComplaintRequest: CaseReducer<
  State,
  PayloadAction<{ transactionId: number }>
> = draft => {
  draft.ui.uiState = UiState.Pending
}

const getComplaintSuccess: CaseReducer<State, PayloadAction<ComplaintModel | null>> = (
  draft,
  action,
) => {
  draft.ui.uiState = UiState.Success
  draft.complaint = action.payload
}

const getComplaintFailure: CaseReducer<State, PayloadAction<{ error: string | null }>> = (
  draft,
  action,
) => {
  const { error } = action.payload

  draft.ui.uiState = UiState.Failure
  draft.ui.error = error
}

const complaintSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getSnadComplaintRequest,
    getComplaintRequest,
    getComplaintSuccess,
    getComplaintFailure,
  },
})

export const { actions } = complaintSlice
export const plug = { [stateName]: complaintSlice.reducer }
export default complaintSlice.reducer
