import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = []

const showNotification: CaseReducer<State, PayloadAction<{ id: string }>> = (draft, action) => {
  const { id } = action.payload

  draft.push(id)
}

const hideNotification: CaseReducer<State, PayloadAction<{ id: string }>> = (draft, action) => {
  const { id } = action.payload

  if (!draft.includes(id)) return

  draft.splice(draft.indexOf(id), 1)
}

const checkoutSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    showNotification,
    hideNotification,
  },
})

export const { actions } = checkoutSlice
export const plug = { [stateName]: checkoutSlice.reducer }
export default checkoutSlice.reducer
