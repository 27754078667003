import { getCookie } from 'libs/utils/cookies'
import { ConsentGroup } from 'constants/consent'

export function getConsentGroups(serverCookieOptanonConsent = '') {
  const cookie = getCookie('OptanonConsent') || serverCookieOptanonConsent

  const optanonConsentCookie = new URLSearchParams(cookie)

  return optanonConsentCookie.get('groups')?.split(',') || []
}

// This only captures the current cookie value. useIsConsentGroupEnabled hook should be used in most cases
export function checkConsentGroup(group: ConsentGroup, serverCookieOptanonConsent = '') {
  return getConsentGroups(serverCookieOptanonConsent).includes(group)
}
