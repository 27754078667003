import { createSelector } from 'reselect'
import { compact } from 'lodash'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getUserAddress = (state: AllState) => localState(state).address

export const getFormattedAddress = createSelector(getUserAddress, address =>
  compact([
    address?.line1,
    address?.line2,
    address?.city,
    address?.state,
    address?.postalCode,
  ]).join(' '),
)

export const getUiState = createSelector(localState, ({ ui }) => ui)

export const getUserAddressUiState = createSelector(getUiState, ui => ui.uiState)

export const getUserAddressUiError = createSelector(getUiState, ui => ui.error)

export const getIsShippingAddressSelected = createSelector(
  getUiState,
  ui => ui.isShippingAddressSelected,
)
