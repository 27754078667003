import { takeEvery, call, put, select } from 'typed-redux-saga'
import { includes } from 'lodash'

import { UiState } from 'constants/ui'
import { getBanners } from 'data/api'

import * as statelessActions from './actions'
import { actions } from './slice'
import * as selectors from './selectors'
import { transformBanners } from './transformers'

export function* fetchBanners() {
  const state = yield* select(selectors.getBannersUiState)

  if (includes([UiState.Success, UiState.Pending], state)) return

  yield* put(actions.changeUiState({ uiState: UiState.Pending }))
  const response = yield* call(getBanners)

  if ('errors' in response) {
    yield* put(actions.changeUiState({ uiState: UiState.Failure }))

    return
  }

  const transformedBanners = yield* call(transformBanners, response.banners)

  yield* put(actions.fetchBannersSuccess({ banners: transformedBanners }))
}

export default function* saga() {
  yield takeEvery(statelessActions.fetchBannersRequest, fetchBanners)
}
