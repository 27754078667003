import { ExtraServicePaymentDto } from 'types/dtos'
import { ExtraServicePaymentModel } from 'types/models'

export const transformExtraServicePaymentDto = ({
  id,
  status,
  pay_in_redirect_url,
  failure_reason,
}: ExtraServicePaymentDto): ExtraServicePaymentModel => ({
  id,
  status,
  payInRedirectUrl: pay_in_redirect_url,
  failureReason: failure_reason,
})
