import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { UiState, UiState as UiStateEnum } from 'constants/ui'
import { transformUserStatsDto } from 'data/transformers/user-stats'
import { UserStatsDto } from 'types/dtos'

import { stateName } from './constants'
import { State } from './types'

export const initialState: State = {
  requestState: UiState.Idle,
}

const getUserStatsRequest: CaseReducer<State> = draft => {
  draft.requestState = UiStateEnum.Pending
}

const getUserStatsFailure: CaseReducer<State> = draft => {
  draft.requestState = UiStateEnum.Failure
}

const getUserStatsSuccess: CaseReducer<State, PayloadAction<{ stats: UserStatsDto }>> = (
  draft,
  action,
) => {
  const { stats } = action.payload

  draft.stats = transformUserStatsDto(stats)
  draft.requestState = UiState.Success
}

const setUnreadMsgCount: CaseReducer<State, PayloadAction<{ count: number }>> = (draft, action) => {
  if (!draft.stats) return

  const { count } = action.payload

  draft.stats.unreadMsgCount = count
}

const userStatsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getUserStatsRequest,
    getUserStatsFailure,
    getUserStatsSuccess,
    setUnreadMsgCount,
  },
})

export const { actions } = userStatsSlice
export const plug = { [stateName]: userStatsSlice.reducer }
export default userStatsSlice.reducer
