'use client'

import { ReactNode, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'

import { serverSide } from 'libs/utils/environment'
import { PageId } from 'types/page-id'

import RequestContext from './RequestContext'

type Props = {
  children: ReactNode
  pageId: PageId | null
  locationUrl: string
  userAgent: string
}

const NextRequestProvider = ({ pageId, children, locationUrl, userAgent }: Props) => {
  // Value of this state isn't needed a goal is only to trigger rerender
  // eslint-disable-next-line react/hook-use-state
  const [, setRefresh] = useState(false)
  const { back, push, replace, query } = useRouter()
  const pushRef = useRef(push)
  const replaceRef = useRef(replace)

  pushRef.current = push
  replaceRef.current = replace

  const historyFunctions = useMemo(
    () => ({
      push: (url: string) => pushRef.current(url, undefined, { shallow: true }),
      replace: (url: string) => replaceRef.current(url, undefined, { shallow: true }),
      refreshUrl: () => setRefresh(prevState => !prevState),
    }),
    [],
  )

  const url = serverSide ? locationUrl : window.location.href

  const value = useMemo(
    () => ({
      pageId,
      userAgent,
      location: {
        ...historyFunctions,
        url,
        urlParams: query,
        back,
      },
    }),
    [pageId, historyFunctions, url, query, back, userAgent],
  )

  return <RequestContext.Provider value={value}>{children}</RequestContext.Provider>
}

export default NextRequestProvider
