import { createSelector } from 'reselect'

import { AddressType } from 'constants/address'
import { AccountType, DocumentGroup } from 'constants/kyc'

import { FieldName } from './constants'
import { AllState } from './types'

const select = (state: AllState) => state.kyc

const selectFormState = (state: AllState) => select(state).formState

const selectFormData = (state: AllState) => select(state).formData

const selectPaymentsIdentityConfiguration = (state: AllState) =>
  select(state).paymentsIdentityConfiguration

export const getFormUiState = (state: AllState) => select(state).formState.uiState

export const getFirstName = (state: AllState) => selectFormData(state).firstName || ''

export const getLastName = (state: AllState) => selectFormData(state).lastName || ''

export const getNationality = (state: AllState) => selectFormData(state).nationality || undefined

export const getBirthdate = (state: AllState) => selectFormData(state).birthdate || ''

export const getGeneralError = (state: AllState, fieldName: FieldName) => {
  return select(state).generalErrors.byName[fieldName] || undefined
}

export const getApiErrors = (state: AllState) => select(state).apiErrors

export const getAddress = (state: AllState) => selectFormData(state).address

export const getPaymentsIdentityConfiguration = createSelector(
  selectPaymentsIdentityConfiguration,
  paymentsIdentityConfiguration => paymentsIdentityConfiguration,
)

export const getAccountType = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.accountType

export const getIsFormDisabled = (state: AllState) => select(state).formState.isFormDisabled

export const getRequiredFields = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.requiredFields

export const getIsOndatoIdvInitiated = (state: AllState) => select(state).isOndatoIdvInitiated

export const getStatus = (state: AllState) => selectPaymentsIdentityConfiguration(state)?.status

export const getIsFormDirty = (state: AllState) => select(state).formState.isFormDirty

export const getAgreementHint = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.agreementHint || ''

export const getIsLegalRepresentativeUbo = (state: AllState) =>
  selectFormData(state).isLegalRepresentativeUbo

export const getUbos = (state: AllState) => selectFormData(state).ubos

export const getDocumentFieldConfigurations = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.documentFieldConfigurations

export const getPersonalIdNumberRequiredNationalities = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.dynamicConfiguration
    ?.personalIdNumberRequiredNationalities || []

export const getAddressType = (state: AllState) =>
  selectPaymentsIdentityConfiguration(state)?.dynamicConfiguration?.addressType ||
  AddressType.Billing

export const getFormData = createSelector(
  selectFormData,
  getAccountType,
  getFirstName,
  getLastName,
  getBirthdate,
  getAddress,
  getNationality,
  getIsLegalRepresentativeUbo,
  getUbos,
  getRequiredFields,
  (
    formData,
    accountType,
    firstName,
    lastName,
    birthday,
    address,
    nationality,
    isLegalRepresentativeUbo,
    ubos,
    requiredFields,
  ) => {
    const isUboRequired = requiredFields?.includes(FieldName.Ubo)
    const isBusinessAccount = accountType === AccountType.Business

    if (isUboRequired && isBusinessAccount && isLegalRepresentativeUbo && address) {
      const firstUbo = ubos?.[0] || {
        nationality: '',
        birthplaceCountry: '',
        birthplaceCity: '',
      }

      return {
        ...formData,
        ubos: [
          {
            ...firstUbo,
            firstName,
            lastName,
            birthday,
            address,
          },
        ],
      }
    }

    return formData
  },
)

export const getKycStatus = createSelector(getPaymentsIdentityConfiguration, configuration => {
  return configuration?.status
})

export const getIdentityDocumentConfiguration = createSelector(
  getDocumentFieldConfigurations,
  documentFieldConfigurations =>
    documentFieldConfigurations?.filter(
      ({ group }) => group === DocumentGroup.IdentityDocuments,
    )?.[0],
)

export const getSupportingDocumentsConfiguration = createSelector(
  getDocumentFieldConfigurations,
  documentFieldConfigurations =>
    documentFieldConfigurations?.filter(
      ({ group, options }) => group === DocumentGroup.SupportingDocuments && options.length === 1,
    ),
)

export const getIsSupportingDocumentRequired = createSelector(
  getSupportingDocumentsConfiguration,
  getRequiredFields,
  (documentFieldConfigurations, requiredFields) => {
    const supportingDocumentTypes = documentFieldConfigurations?.map(
      ({ options }) => options?.[0].type,
    )

    return !!requiredFields?.filter(field => supportingDocumentTypes?.includes(field))?.length
  },
)

export const getHasUnknownApiErrorOccured = (state: AllState) =>
  selectFormState(state).hasUnknownApiErrorOccured
