import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { UiState } from 'constants/ui'
import { AddressEntryType } from 'constants/address'
import { AddressModel, DynamicConfigurationModel, RequirementsModel } from 'types/models'

import { State, ActivateBalanceFormValues } from './types'
import { stateName } from './constants'

export const initialState: State = {
  form: {
    firstName: '',
    lastName: '',
    birthdate: '',
    ssn: '',
    personalIdNumber: '',
    nationality: null,
    pepType: undefined,
    pepPosition: '',
    pepOrganisation: '',
    pepPositionDate: '',
  },
  address: null,
  canChange: true,
  status: UiState.Idle,
  isPersonalIdNumberRequired: false,
  isBalanceActivated: false,
  agreementHint: '',
  dynamicConfiguration: null,
  requirements: null,
}

const getDefaultFormValuesRequest: CaseReducer<State> = draft => {
  draft.status = UiState.Pending
}

const getDefaultFormValuesSuccess: CaseReducer<
  State,
  PayloadAction<{ form: ActivateBalanceFormValues }>
> = (draft, action) => {
  const { form } = action.payload

  draft.form = form
}

const setAddress: CaseReducer<State, PayloadAction<{ address: AddressModel | null }>> = (
  draft,
  action,
) => {
  const { address } = action.payload

  draft.address = address
}

const setCanChange: CaseReducer<State, PayloadAction<{ canChange: boolean }>> = (draft, action) => {
  const { canChange } = action.payload

  draft.canChange = canChange
}

const setIsPersonalIdNumberRequired: CaseReducer<State, PayloadAction<{ status: boolean }>> = (
  draft,
  action,
) => {
  const { status } = action.payload

  draft.isPersonalIdNumberRequired = status
}

const setIsBalanceActivated: CaseReducer<State, PayloadAction<{ status: boolean }>> = (
  draft,
  action,
) => {
  const { status } = action.payload

  draft.isBalanceActivated = status
}

const setAgreementHint: CaseReducer<State, PayloadAction<{ status: string }>> = (draft, action) => {
  const { status } = action.payload

  draft.agreementHint = status
}

const setStatus: CaseReducer<State, PayloadAction<{ status: UiState }>> = (draft, action) => {
  const { status } = action.payload

  draft.status = status
}

const prefillAddress: CaseReducer<State, PayloadAction<{ fullName: string }>> = (draft, action) => {
  const { fullName } = action.payload

  draft.address = {
    name: fullName,
    entryType: AddressEntryType.Billing,
    countryId: null,
    city: null,
    line1: null,
    line2: null,
    postalCode: null,
    state: null,
  }
}

const setDynamicConfiguration: CaseReducer<
  State,
  PayloadAction<{ dynamicConfiguration: DynamicConfigurationModel | null }>
> = (draft, action) => {
  const { dynamicConfiguration } = action.payload

  draft.dynamicConfiguration = dynamicConfiguration
}

const setRequirements: CaseReducer<
  State,
  PayloadAction<{ requirements: RequirementsModel | null }>
> = (draft, action) => {
  const { requirements: dynamicConfiguration } = action.payload

  draft.requirements = dynamicConfiguration
}

const activateBalanceSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    getDefaultFormValuesRequest,
    getDefaultFormValuesSuccess,
    setAddress,
    setCanChange,
    setIsPersonalIdNumberRequired,
    setIsBalanceActivated,
    setAgreementHint,
    setStatus,
    setDynamicConfiguration,
    setRequirements,
    prefillAddress,
  },
})

export const { actions } = activateBalanceSlice
export const plug = { [stateName]: activateBalanceSlice.reducer }
export default activateBalanceSlice.reducer
