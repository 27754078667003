import { createSelector } from 'reselect'

import {
  getSessionAppleClientId,
  getSessionFacebookAppId,
  getSessionGoogleClientId,
  getSessionGoogleMapsApiKey,
} from 'state/session/selectors'

import { stateName } from './constants'
import { AllState, UniversalEnvs, State } from './types'

const localState = (state: AllState): State => state[stateName]

export const getEnv = (name: keyof UniversalEnvs) => (state: AllState) => localState(state)[name]

// TODO: These break isolation and access session state in order to remain compatible with rails; the session access should be removed after migration
export const getFacebookAppId = createSelector(
  [getSessionFacebookAppId, getEnv('FACEBOOK_CLIENT_ID')],
  (sessionFacebookAppId, facebookAppId) => sessionFacebookAppId || facebookAppId,
)
export const getAppleClientId = createSelector(
  [getSessionAppleClientId, getEnv('APPLE_CLIENT_ID')],
  (sessionAppleClientId, appleClientId) => sessionAppleClientId || appleClientId,
)
export const getGoogleClientId = createSelector(
  [getSessionGoogleClientId, getEnv('GOOGLE_CLIENT_ID')],
  (sessionGoogleClientId, googleClientId) => sessionGoogleClientId || googleClientId,
)
export const getGoogleMapsApiKey = createSelector(
  [getSessionGoogleMapsApiKey, getEnv('GOOGLE_MAPS_API_KEY')],
  (sessionGoogleMapsApiKey, googleMapsApiKey) => sessionGoogleMapsApiKey || googleMapsApiKey || '',
)
