import { UiState } from 'constants/ui'
import { CharityModel, FundraiserModel } from 'types/models'

import { stateName } from './constants'

export enum ManageModalScreenName {
  CharitySelect,
  Overview,
}

export type State = {
  fundraiser?: FundraiserModel
  charities: Array<CharityModel>
  selectedCharity: CharityModel | null
  currentModalScreen: ManageModalScreenName
  uiState: UiState
}

export type AllState = { [key in typeof stateName]: State }
