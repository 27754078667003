import { ItemEditDto, ItemAttributeDto } from 'types/dtos'
import { ItemEditModel, ItemAttributeModel, BookDetailsModel } from 'types/models'

import { transformBrandDto } from './brand'
import { transformItemPhotoDtos } from './item'
import { transformVideoGameRatingDto } from './video-game-rating'
import { transformItemAlertDto } from './item-alert'

export const transformItemAttributesDto = ({
  ids,
  code,
}: ItemAttributeDto): ItemAttributeModel => ({ ids, code })

export const transformItemAttributesDtos = (
  itemAttributes: Array<ItemAttributeDto>,
): Array<ItemAttributeModel> => itemAttributes.map(transformItemAttributesDto)

export const transformColorIds = ({ color1_id, color2_id }: ItemEditDto): Array<number> => {
  const colorIds: Array<number> = []

  if (color1_id) colorIds.push(color1_id)
  if (color2_id) colorIds.push(color2_id)

  return colorIds
}

export const transformItemEditBookDetailsDto = ({
  author,
  book_title,
}: ItemEditDto): BookDetailsModel | null => {
  if (!author || !book_title) return null

  return {
    author,
    title: book_title,
  }
}

export const transformShipmentPrices = (item: ItemEditDto) => {
  const { shipment_prices } = item

  if (!shipment_prices) {
    return { domesticShipmentPrice: null, internationalShipmentPrice: null }
  }

  return {
    domesticShipmentPrice: shipment_prices.domestic?.amount || null,
    internationalShipmentPrice: shipment_prices.international?.amount || null,
  }
}

export const transformItemEditDto = (item: ItemEditDto): ItemEditModel => ({
  id: item.id,
  title: item.title,
  description: item.description,
  colorIds: transformColorIds(item),
  sizeId: item.size_id,
  catalogId: item.catalog_id,
  packageSizeId: item.package_size_id,
  brand: transformBrandDto(item.brand_dto),
  brandId: item.brand_id,
  statusId: item.status_id,
  status: item.status,
  currency: item.currency,
  photos: transformItemPhotoDtos(item.photos),
  isbn: item.isbn,
  bookDetails: transformItemEditBookDetailsDto(item),
  price: Number(item.price?.amount) || null,
  measurementWidth: item.measurement_width,
  measurementLength: item.measurement_length,
  measurementUnit: item.measurement_unit,
  videoGameRating:
    (item.video_game_rating && transformVideoGameRatingDto(item.video_game_rating)) || null,
  videoGameRatingId: item.video_game_rating_id,
  isUnisex: !!item.is_unisex,
  canPushUp: item.can_push_up,
  itemAlert: item.item_alert && transformItemAlertDto(item.item_alert),
  itemAttributes: (item.item_attributes && transformItemAttributesDtos(item.item_attributes)) || [],
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  manufacturer: item.manufacturer,
  manufacturerLabel: item.manufacturer_labelling,
  ...transformShipmentPrices(item),
})
