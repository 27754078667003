import { takeEvery, put } from 'redux-saga/effects'
import { call } from 'typed-redux-saga'

import { getCustomShipmentConfig } from 'data/api'
import { transformCustomShipmentDto } from 'data/transformers/shipment-custom-config'

import { actions } from './slice'

export function* fetchCustomShipmentConfig() {
  const response = yield* call(getCustomShipmentConfig)

  if ('errors' in response) {
    yield put(actions.fetchCustomShipmentConfigFailure())

    return
  }

  yield put(
    actions.fetchCustomShipmentConfigSuccess({
      config: transformCustomShipmentDto(response.custom_shipment_configuration),
    }),
  )
}

export default function* saga() {
  yield takeEvery(actions.fetchCustomShipmentConfigRequest, fetchCustomShipmentConfig)
}
