import { delay, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { all, call, select, take } from 'typed-redux-saga'

import { MEMBER_PROFILE_URL, ROOT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { EntryType } from 'constants/photo-tip'
import { ItemAlertStatus } from 'constants/item'
import { ItemAfterUploadActions, PromotionStorageKeys } from 'constants/item-upload'
import { GoogleTagManagerEvent } from 'constants/google'
import { googleTagManagerTrack } from 'data/utils/google'
import * as api from 'data/api'
import { ResponseCode } from 'data/api/response-codes'
import {
  transformItemEditDto,
  transformBrandDtos,
  transformItemAuthenticityModalDto,
  transformVideoGameRatingDtos,
  transformPhotoTipDtos,
  transformSizeGroupDtos,
  transformPackageSizeShippingOptionDtos,
  transformDynamicAttributes,
  transformSupportedAttributesToSelectedValues,
  transformItemUploadConfigDtoToModel,
} from 'data/transformers'
import { navigateToPage, scrollToElementById } from 'libs/utils/window'
import { tracker } from 'libs/common/tracker'
import {
  itemUploadFieldSetEvent,
  itemUploadStartEventExtra,
  itemUploadSubmitFail,
  itemUploadSubmitSuccess,
} from 'libs/common/event-tracker/events'
import { getAbTestByName } from 'state/ab-tests/selectors'
import * as mediaUploadSelectors from 'state/media-upload/selectors'
import { actions as mediaUploadActions } from 'state/media-upload/slice'
import { actions as packageSizesActions } from 'state/package-sizes/slice'
import { actions as itemActions } from 'state/items/slice'
import {
  CreateItemResp,
  DraftItemResp,
  ItemCompletionResp,
  ItemResp,
  ResponseError,
} from 'types/api'
import { CurrencyAmountDto, ItemDto } from 'types/dtos'
import { UiState as UiStateEnum } from 'constants/ui'
import { ImageOrientation } from 'constants/images'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { brazeLogCustomEvent } from 'libs/common/braze/utils/custom-event'
import { BrazeCustomEvent } from 'libs/common/braze/constants'

import * as statelessActions from './actions'
import * as transformers from './transformers'
import * as selectors from './selectors'
import {
  REQUEST_DELAY,
  WITHOUT_BRAND_ID,
  FieldName,
  IsbnValidity,
  ItemUploadFailReason,
  SupportedDynamicAttributes,
  ItemStatus,
  FieldTrackingName,
} from './constants'
import { actions } from './slice'
import {
  getSupportedAttributesArray,
  setItemIdToStorage,
  setPromotionsAfterItemUploadToStorage,
} from './helpers'

function* getIsSuggestedPackageSizeAbTestEnabled() {
  const suggestedPackageSizeAbTest = yield* select(getAbTestByName('parcel_size_suggestion_web'))

  return suggestedPackageSizeAbTest?.variant === 'on'
}

export function* dynamicAttributesChangeFlow() {
  const supportedAttributes = getSupportedAttributesArray({
    [SupportedDynamicAttributes.Material]: true,
    [SupportedDynamicAttributes.Gender]: true,
  })

  const selectedValues = transformSupportedAttributesToSelectedValues(supportedAttributes)

  yield put(
    actions.setSelectedDynamicAttributeValues({
      selectedValues,
    }),
  )
}

export function* fetchCategoryAttributes(catalogId: number | null, itemStatus: ItemStatus) {
  if (!catalogId) return

  yield put(actions.setDynamicAttributesUiState({ uiState: UiStateEnum.Pending }))

  const response = yield* call(api.getCategoryAttributes, catalogId)

  if ('errors' in response) {
    yield put(actions.setCatalogId({ id: null, itemStatus }))
    yield put(actions.fetchCategoryAttributesFailure())

    return
  }

  yield put(
    actions.fetchCategoryAttributesSuccess({
      attributes: transformDynamicAttributes(response.attributes),
      additionalAttributes: response.additional_attributes,
    }),
  )
}

export function* fetchItem({
  payload: { id, tempUuid, userId, setItemStatus },
}: ReturnType<typeof statelessActions.fetchItemRequest>) {
  yield put(statelessActions.fetchCatalogs())
  yield call(dynamicAttributesChangeFlow)
  yield put(statelessActions.getDropOffLocationPrompt({ userId }))

  const configurationResponse = yield* call(api.getItemUploadConfiguration)

  if ('errors' in configurationResponse) return

  const configurationModel = yield* call(transformItemUploadConfigDtoToModel, configurationResponse)
  yield put(actions.setConfiguration({ configuration: configurationModel }))

  if (!id) {
    const sessionResponse = yield* call(api.getSessionDefaults)
    if ('errors' in sessionResponse) return

    yield put(
      actions.setCurrency({ currency: sessionResponse.session_defaults_configuration.currency }),
    )

    yield put(actions.setUiState({ uiState: UiStateEnum.Success }))
    yield call(
      tracker.track,
      itemUploadStartEventExtra({ tempUuid, screenName: Screen.ItemUpload }),
    )

    return
  }

  const response = yield* call(api.getItemEdit, { id })

  if ('errors' in response) {
    yield put(actions.setUiState({ uiState: UiStateEnum.Failure }))

    return
  }

  const item = transformItemEditDto(response.item)

  yield put(actions.setInitialItemData({ item }))

  const itemStatus = item.isDraft ? ItemStatus.DraftEdit : ItemStatus.Edit
  yield call(setItemStatus, itemStatus)

  yield put(actions.setCurrency({ currency: item.currency }))

  yield call(fetchCategoryAttributes, item.catalogId, itemStatus)

  const selectedValues = transformers.transformDynamicAttributes(item.itemAttributes)
  yield put(actions.setSelectedDynamicAttributeValues({ selectedValues }))

  yield put(statelessActions.fetchPriceSuggestions())
  yield put(statelessActions.fetchItemOfflineVerificationEligibility())
  yield put(actions.setUiState({ uiState: UiStateEnum.Success }))
  yield call(
    tracker.track,
    itemUploadStartEventExtra({
      tempUuid,
      screenName: item.isDraft ? Screen.ItemDraft : Screen.ItemEdit,
    }),
  )
}

export function* convertAssignedPhoto(id: number) {
  const photo = yield* select(mediaUploadSelectors.getPhoto, id)

  return { id, orientation: photo.orientation || ImageOrientation.Degree0 }
}

export function* requestItemAuthenticityModal({
  payload,
}: ReturnType<typeof statelessActions.requestItemAuthenticityModal>) {
  const { catalogId, brandId, id } = yield* select(selectors.selectAttributes)
  const { force, modalDataOnly } = payload

  if (!brandId) return

  const response = yield* call(api.getItemAuthenticityModal, {
    catalogId,
    itemId: id,
    force,
    modalDataOnly,
  })

  if ('errors' in response) return
  if (!response.authenticity_modal) return

  const model = yield* call(transformItemAuthenticityModalDto, response.authenticity_modal)

  yield put(actions.setAuthenticityModalContent({ content: model }))

  if (modalDataOnly) return

  yield put(actions.setIsAuthenticityModalOpen({ isOpen: true }))
}

export function* fetchBrands({ payload }: ReturnType<typeof statelessActions.fetchBrands>) {
  // Prevent request load so takeLatest effect would cancel saga between actual requests
  yield delay(200)

  const { keyword, includeAllBrands } = payload

  const response = yield* call(api.getBrands, { keyword, includeAllBrands })

  if ('errors' in response) return

  const brands = yield* call(transformBrandDtos, response.brands)

  yield put(actions.setBrands({ brands }))
}

export function* selectCatalog({ payload }: ReturnType<typeof statelessActions.selectCatalog>) {
  const currentCatalog = yield* select(selectors.getCurrentCatalog)
  const catalog = yield* select(selectors.getCatalog, payload.catalogId)

  if (!catalog) return

  if (catalog.restrictedToStatusId) {
    yield put(actions.setStatusId({ id: null, itemStatus: payload.itemStatus }))
  }

  if (!catalog.isIsbnInputShown) {
    yield put(actions.setIsbn({ isbn: null, validity: IsbnValidity.Unvalidated }))
  }

  if (!catalog.isBrandSelectShown) {
    yield put(
      actions.setBrand({
        id: WITHOUT_BRAND_ID,
        title: null,
        isLuxury: false,
        itemStatus: payload.itemStatus,
      }),
    )
  }

  if (currentCatalog) {
    if (currentCatalog.sizeGroupId !== catalog.sizeGroupId) {
      yield put(actions.setSizeId({ id: null, itemStatus: payload.itemStatus }))
    }
  }

  yield put(actions.setCatalogId({ id: payload.catalogId, itemStatus: payload.itemStatus }))

  yield call(fetchCategoryAttributes, payload.catalogId, payload.itemStatus)

  const brandIsLuxury = yield* select(selectors.getIsBrandLuxury)

  yield put(actions.setAuthenticityModalContent({ content: null }))

  if (brandIsLuxury) {
    yield put(statelessActions.requestItemAuthenticityModal({ modalDataOnly: true }))
  }
}

export function* fetchSizeGroupsByCatalog() {
  const { catalogId } = yield* select(selectors.selectAttributes)

  if (!catalogId) return

  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)
  const response = yield* call(api.getSizeGroupsByCatalog, catalogId, isMultipleSizeGroupsEnabled)

  if ('errors' in response) return

  const transformedDto = yield* call(transformSizeGroupDtos, response.size_groups)

  yield put(actions.setSizeGroups({ sizeGroups: transformedDto.sizeGroups }))
  yield put(actions.setSizes({ sizes: transformedDto.sizes }))
}

export function* fetchVideoGameRatings() {
  const response = yield* call(api.getVideoGameRatings)

  if ('errors' in response) return

  const transformedDto = yield* call(transformVideoGameRatingDtos, response.video_game_ratings)

  yield put(actions.setVideoGameRatings(transformedDto))
}

export function* fetchCatalogs() {
  const response = yield* call(api.getCatalogs)

  if ('errors' in response) return

  const catalogs = yield* call(transformers.transformCatalogs, response.catalogs)

  yield put(actions.setCatalogs({ catalogs }))
}

export function* fetchColors() {
  const response = yield* call(api.requestColors)

  if ('errors' in response) {
    yield delay(REQUEST_DELAY)
    yield put(actions.fetchColorsRequest())

    return
  }

  const colors = yield* call(transformers.transformColors, response.colors)

  yield put(actions.fetchColorsSuccess({ colors }))
}

export function* fetchStatuses() {
  const response = yield* call(api.getStatuses)

  if ('errors' in response) return

  const statuses = yield* call(transformers.transformStatuses, response.statuses)

  yield put(actions.setStatuses({ statuses }))
}

export function* fetchPriceSuggestions() {
  const { catalogId, statusId, brandId } = yield* select(selectors.selectAttributes)

  if (!brandId || !statusId || !catalogId) {
    yield put(actions.clearPriceSuggestions())

    return
  }

  const response = yield* call(api.getItemPriceSuggestions, { brandId, statusId, catalogId })

  if ('errors' in response) {
    yield put(actions.clearPriceSuggestions())

    return
  }

  const suggestions = transformers.transformPriceSuggestionsDto(response)

  yield put(actions.setPriceSuggestions({ suggestions }))
}

export function* fetchItemTitleSuggestions({ payload }: { payload: { itemStatus: ItemStatus } }) {
  yield put(actions.setTitleIdleState())

  const holdoutAbTest = yield* select(getAbTestByName('buyer_domain_holdout_2024q2'))
  const abTest = yield* select(getAbTestByName('pregenerated_title_and_description_moved_down'))

  if (holdoutAbTest?.variant === 'off' || abTest?.variant !== 'b') return

  const isTitleEditedByUser = yield* select(selectors.getIsTitleEditedByUser)
  const { itemStatus } = payload

  if (isTitleEditedByUser || itemStatus !== ItemStatus.New) return

  const currentCatalog = yield* select(selectors.getCurrentCatalog)
  const { statusId, brandId, sizeId, colorIds, brandTitle } = yield* select(
    selectors.selectAttributes,
  )

  if (
    !currentCatalog ||
    !statusId ||
    !(brandId || brandTitle) ||
    (currentCatalog.isColorSelectShown && !colorIds[0]) ||
    (currentCatalog.isSizeSelectShown && !sizeId)
  ) {
    yield put(actions.setTitle({ title: '', isEditedByUser: false }))

    return
  }

  yield put(actions.fetchItemTitleSuggestionsRequest())

  const response = yield* call(api.getItemTitleSuggestions, {
    brandId,
    statusId,
    catalogId: currentCatalog.id,
    sizeId: currentCatalog.isSizeSelectShown ? sizeId : null,
    colorId: currentCatalog.isColorSelectShown ? colorIds[0] : null,
  })

  if ('errors' in response) {
    yield put(actions.fetchItemTitleSuggestionsFailure())

    return
  }

  yield put(
    actions.fetchItemTitleSuggestionsSuccess({
      title: response.title || '',
      isEditedByUser: false,
    }),
  )
}

export function* toggleSimilarItemsModal() {
  const isSimilarItemsModalOpen = yield* select(selectors.getIsSimilarItemsModalOpen)

  if (isSimilarItemsModalOpen) {
    const { catalogId, statusId, brandId } = yield* select(selectors.selectAttributes)

    if (!brandId || !statusId || !catalogId) {
      yield put(itemActions.resetSimilarItems())

      return
    }

    yield put(itemActions.getSimilarItemsRequest({ brandId, statusId, catalogId }))
  } else {
    yield put(itemActions.resetSimilarItems())
  }
}

export function* fetchBookDetails({ payload }: ReturnType<typeof actions.setIsbn>) {
  if (payload.validity === IsbnValidity.Unvalidated) return

  if (payload.validity === IsbnValidity.Invalid || !payload.isbn) {
    yield put(actions.removeFieldError({ fieldName: FieldName.Isbn }))

    return
  }

  yield put(actions.setIsbnPendingState())
  yield delay(REQUEST_DELAY)

  const response = yield* call(api.getBookDetails, { isbn: payload.isbn })

  if ('errors' in response) {
    yield put(
      actions.setFieldError({
        fieldError: { field: FieldName.Isbn, value: response.message },
      }),
    )

    return
  }

  const bookDetails = transformers.transformBookDetails(response)

  yield put(actions.setBookDetails({ bookDetails }))
}

export function* fetchPackageSizes() {
  const { catalogId, price: priceValue, brandId } = yield* select(selectors.selectAttributes)
  const currency = yield* select(selectors.getCurrency)
  const price: CurrencyAmountDto | undefined = priceValue
    ? { amount: priceValue.toString(), currency_code: currency }
    : undefined

  if (!catalogId) return

  yield put(
    packageSizesActions.fetchCatalogPackageSizesRequest({
      catalogId,
      brandId: brandId || undefined,
      price,
    }),
  )
}

export function* fetchSuggestedPackageSizeCode() {
  yield delay(REQUEST_DELAY)

  const isSuggestedPackageSizeCodeEnabled = yield* call(getIsSuggestedPackageSizeAbTestEnabled)

  if (!isSuggestedPackageSizeCodeEnabled) return

  const itemAttributes = yield* select(selectors.selectAttributes)

  if (!itemAttributes.catalogId) return

  const assignedPhotos = yield* all(
    itemAttributes.assignedPhotos.map(photoId => call(convertAssignedPhoto, photoId)),
  )
  const dynamicAttributes = yield* select(selectors.getSelectedDynamicAttributes)
  const currency = yield* select(selectors.getCurrency)

  const itemDto = yield* call(
    transformers.transformItemAttributesToItemUploadDto,
    itemAttributes,
    assignedPhotos,
    dynamicAttributes,
    currency || '',
    '',
  )

  const response = yield* call(api.getSuggestedPackageSizeCode, { item: itemDto })

  if ('errors' in response) {
    yield put(packageSizesActions.fetchSuggestedPackageSizeCodeFailure({ errors: response.errors }))

    return
  }

  yield put(
    packageSizesActions.fetchSuggestedPackageSizeCodeSuccess({
      suggestedPackageSizeCode: response.package_size_code,
    }),
  )
}

export function* toggleShippmentOptionsModal() {
  const isShipmentOptionsModalOpen = yield* select(selectors.getIsShippingOptionsModalOpen)

  if (!isShipmentOptionsModalOpen) return

  yield put(statelessActions.fetchShippingOptionsRequest())
}

export function* deleteDraft({ payload }: ReturnType<typeof statelessActions.deleteDraft>) {
  const { id } = yield* select(selectors.selectAttributes)

  if (!id) return

  yield put(actions.setIsFormDisabled({ isFormDisabled: true }))
  yield* call(api.deleteItemDraft, id)

  const { userId } = payload
  const redirectUrl = MEMBER_PROFILE_URL(userId)

  yield* call(navigateToPage, redirectUrl)
}

export function* handleNewItemIncompleteTaxAddressResponse({
  meta,
  payload: { tempUuid, itemStatus, screenName },
}: Pick<ReturnType<typeof statelessActions.submitItem>, 'meta' | 'payload'>) {
  yield put(actions.showMissingPostalCode())
  const action = yield* take(actions.hideMissingPostalCode)

  if (!action.payload?.resubmitItem) return

  yield put(
    statelessActions.submitItem(
      tempUuid,
      itemStatus,
      screenName,
      meta.saveAsDraft,
      meta.isItemPushedUp,
    ),
  )
}

export function* handleItemSubmitResponseCode({ responseCode, meta, payload }) {
  switch (responseCode) {
    case ResponseCode.IncompleteTaxAddress:
      yield* call(handleNewItemIncompleteTaxAddressResponse, { meta, payload })
      break
    case ResponseCode.PhotoMinimumCountRequired:
      yield put(actions.setIsLuxuryItemModalOpen({ isOpen: true }))
      break
    default:
      break
  }
}

export function* handleReplicaProof(response: ItemResp) {
  yield put(actions.setIsAuthenticityProofModalOpen({ isOpen: true }))

  yield take(actions.setIsAuthenticityProofModalOpen)

  if (
    response.after_upload_actions?.includes(ItemAfterUploadActions.ShowOfflineVerificationModal)
  ) {
    yield put(actions.setIsOfflineVerificationModalOpen({ isOpen: true }))

    return
  }

  yield* call(navigateToPage, MEMBER_PROFILE_URL(response.item.user.id))
}

export function* trackListingToGoogleTagManager(item: ItemDto) {
  const isGtmEcFieldEnabled = yield* select(getIsFeatureSwitchEnabled('web_gtm_ec_field'))
  const event = {
    item_name: item.title,
    item_id: item.id,
    value: parseFloat(item.price.amount),
    currency: item.currency,
    user_email: item.user.email,
  }

  yield call(
    googleTagManagerTrack,
    GoogleTagManagerEvent.ItemList,
    {
      ...event,
      item_category_id: item.catalog_id,
      item_brand: item.brand_dto?.title,
      item_brand_id: item.brand_dto?.id,
      item_condition: item.disposal_conditions,
    },
    isGtmEcFieldEnabled,
  )

  const isPreviousLister = yield* select(selectors.getIsPreviousLister)

  if (!isPreviousLister) {
    yield call(googleTagManagerTrack, GoogleTagManagerEvent.FirstList, event, isGtmEcFieldEnabled)
  }

  const isSecondDayLister = yield* select(selectors.getIsSecondDayLister)

  if (isSecondDayLister) {
    yield call(
      googleTagManagerTrack,
      GoogleTagManagerEvent.SecondDayList,
      event,
      isGtmEcFieldEnabled,
    )
  }
}

export function* trackListingToBraze(item: ItemDto, screenName: Screen) {
  yield call(
    brazeLogCustomEvent,
    {
      event: BrazeCustomEvent.ListingCreated,
      modelId: item.id,
      userExternalId: item.user.external_id || null,
      screen: screenName,
    },
    {
      defer: true,
    },
  )
}

export function* submitItem({ meta, payload }: ReturnType<typeof statelessActions.submitItem>) {
  const { tempUuid, itemStatus, screenName } = payload

  yield put(actions.setIsFormDisabled({ isFormDisabled: true }))

  let response: DraftItemResp | CreateItemResp | ItemResp | ItemCompletionResp | ResponseError

  const itemAttributes = yield* select(selectors.selectAttributes)
  const assignedPhotos = yield* all(
    itemAttributes.assignedPhotos.map(photoId => call(convertAssignedPhoto, photoId)),
  )
  const dynamicAttributes = yield* select(selectors.getSelectedDynamicAttributes)
  const currency = yield* select(selectors.getCurrency)
  const itemDto = yield* call(
    transformers.transformItemAttributesToItemUploadDto,
    itemAttributes,
    assignedPhotos,
    dynamicAttributes,
    currency,
    tempUuid,
  )
  const generatedTitleSuggestions = yield* select(selectors.getGeneratedTitleSuggestions)

  const holdoutAbTest = yield* select(getAbTestByName('buyer_domain_holdout_2024q2'))
  const pregeneratedTitleAbTest = yield* select(
    getAbTestByName('pregenerated_title_and_description_moved_down'),
  )

  const { isItemPushedUp, saveAsDraft } = meta

  if (saveAsDraft) {
    if (itemDto.id) {
      response = yield* call(api.updateItemDraft, itemDto.id, itemDto, itemAttributes.feedbackId)
    } else {
      response = yield* call(api.createItemDraft, itemDto, itemAttributes.feedbackId)
    }
  } else if (itemStatus === ItemStatus.DraftEdit && itemDto.id) {
    response = yield* call(api.completeItem, itemDto.id, itemDto, itemAttributes.feedbackId)
    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  } else if (itemDto.id) {
    response = yield* call(api.updateItem, itemDto.id, itemDto, itemAttributes.feedbackId)

    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    }

    if ('item' in response) {
      const { item } = response
      const previousAlert = yield* select(selectors.getPreviousAlertType)

      if (
        previousAlert === ItemAlertStatus.ReplicaProof &&
        item.item_alert?.item_alert_type === ItemAlertStatus.UnderReview
      ) {
        yield call(handleReplicaProof, response)

        return
      }
    }
  } else {
    response = yield* call(api.createItem, itemDto, itemAttributes.feedbackId)
    if (isItemPushedUp) {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowPushedUp)
    } else {
      setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowUploadAnotherItemTip)
    }
  }

  if (!itemAttributes.feedbackId) {
    setPromotionsAfterItemUploadToStorage(response, PromotionStorageKeys.ShowFeedback)
  }

  if (!response) {
    yield put(actions.setIsFormDisabled({ isFormDisabled: false }))

    return
  }

  if ('errors' in response) {
    yield put(actions.setFieldErrors({ fieldErrors: response.errors }))
    yield* call(scrollToElementById, response.errors[0]?.field)
    yield put(actions.setIsFormDisabled({ isFormDisabled: false }))

    tracker.track(
      itemUploadSubmitFail({
        reason: ItemUploadFailReason.ValidationError,
        validationErrors: response.errors.map(error => error.value),
        screen: screenName,
        tempUuid,
      }),
    )

    yield call(handleItemSubmitResponseCode, {
      responseCode: response.code,
      meta,
      payload,
    })

    return
  }

  let redirectUrl = ROOT_URL
  let itemId = itemDto.id

  if ('item' in response) {
    const { item } = response
    const isItemEdit = itemStatus === ItemStatus.Edit
    const isNewListing = !saveAsDraft && !isItemEdit

    if (isNewListing) {
      yield call(trackListingToGoogleTagManager, item)
      yield call(trackListingToBraze, item, screenName)
    }

    redirectUrl = MEMBER_PROFILE_URL(item.user.id)
    itemId = item.id
  } else if ('draft' in response) {
    redirectUrl = MEMBER_PROFILE_URL(response.draft.user.id)
    itemId = response.draft.id
  }

  tracker.track(
    itemUploadSubmitSuccess({
      itemId: itemId || undefined,
      tempUuid,
      screen: screenName,
    }),
  )

  if (
    holdoutAbTest?.variant !== 'off' &&
    pregeneratedTitleAbTest?.variant === 'b' &&
    itemStatus === ItemStatus.New
  ) {
    const nonEmptySuggestions = generatedTitleSuggestions.filter(suggestion => suggestion !== '')
    const lastSuggestion = generatedTitleSuggestions.at(-1)

    yield call(
      tracker.track,
      itemUploadFieldSetEvent({
        userSelection: [itemAttributes.title],
        suggestionSeen: !!nonEmptySuggestions.length,
        suggestions: lastSuggestion ? [lastSuggestion] : [],
        tempUuid,
        fieldName: FieldTrackingName.Title,
      }),
    )
  }

  if (
    response.after_upload_actions?.includes(ItemAfterUploadActions.ShowOfflineVerificationModal)
  ) {
    yield put(actions.setIsOfflineVerificationModalOpen({ isOpen: true }))

    return
  }

  if (itemId) setItemIdToStorage(itemId)

  yield* call(navigateToPage, redirectUrl)
}

export function* fetchPhotoTips() {
  const response = yield* call(api.getPhotoTips, EntryType.NewItem)

  if ('errors' in response) return

  const photoTipsModel = yield* call(transformPhotoTipDtos, response.photo_tips)

  yield put(actions.setPhotoTips({ photoTips: photoTipsModel }))
}

export function* fetchItemSuggestions() {
  yield delay(REQUEST_DELAY)

  const { title, description, catalogId } = yield* select(selectors.selectAttributes)
  const assignedPhotos = yield* select(selectors.getAssignedPhotos)
  const isMultipleSizeGroupsEnabled = yield* select(selectors.getIsMultipleSizeGroupsABTestEnabled)

  const args = {
    title,
    description,
    catalogId,
    photoIds: assignedPhotos,
    isMultipleSizeGroupsEnabled,
  }

  const response = yield* call(api.getItemSuggestions, args)

  if ('errors' in response) return

  const { colors, sizes, brands, catalogs } = yield* call(
    transformers.transformItemSuggestions,
    response,
  )

  yield put(actions.setSuggestions({ colors, sizes, brands, catalogs }))
}

export function* fetchShippingOptions() {
  const {
    catalogId,
    price: priceValue,
    brandId,
    packageSize,
  } = yield* select(selectors.selectAttributes)
  const currency = yield* select(selectors.getCurrency)
  const price: CurrencyAmountDto | undefined = priceValue
    ? { amount: priceValue.toString(), currency_code: currency }
    : undefined

  if (!packageSize?.id || !catalogId) return

  const response = yield* call(api.getPackageShippingOptions, {
    packageSizeId: packageSize.id,
    catalogId,
    brandId: brandId || undefined,
    price,
  })

  if ('errors' in response) {
    yield put(actions.fetchShippingOptionsFailure())

    return
  }

  const shippingOptions = transformPackageSizeShippingOptionDtos(response.shipping_options)

  yield put(actions.fetchShippingOptionsSuccess({ shippingOptions }))
}

export function* fetchItemOfflineVerificationEligibility() {
  yield delay(REQUEST_DELAY)

  const { catalogId, price, brandId } = yield* select(selectors.selectAttributes)

  if (!brandId || !price || !catalogId) {
    yield put(actions.setIsOfflineVerificationEligible({ eligible: false }))

    return
  }

  const response = yield* call(api.getItemOfflineVerificationEligibility, {
    brandId,
    price,
    catalogId,
  })

  if ('errors' in response) {
    yield put(actions.setIsOfflineVerificationEligible({ eligible: false }))

    return
  }

  yield put(actions.setIsOfflineVerificationEligible({ eligible: response.eligible }))
}

export function* fetchIsSecondDayLister() {
  const response = yield* call(api.getIsSecondDayLister)

  if ('errors' in response) {
    yield put(actions.setIsSecondDayLister({ isSecondDayLister: false }))

    return
  }

  yield put(actions.setIsSecondDayLister({ isSecondDayLister: response.value }))
}

export function* fetchIsPreviousLister() {
  const response = yield* call(api.getIsPreviousLister)

  if ('errors' in response) {
    yield put(actions.setIsPreviousLister({ isPreviousLister: false }))

    return
  }

  yield put(actions.setIsPreviousLister({ isPreviousLister: response.value }))
}

export default function* saga() {
  yield takeLatest(
    [
      actions.setTitle,
      actions.setDescription,
      actions.setCatalogId,
      mediaUploadActions.uploadPhotoSuccess,
      mediaUploadActions.fetchPhotosSuccess,
    ],
    fetchItemSuggestions,
  )

  yield takeLatest(statelessActions.fetchItemRequest, fetchItem)
  yield takeLatest(statelessActions.fetchIsSecondDayListerRequest, fetchIsSecondDayLister)
  yield takeLatest(statelessActions.fetchIsPreviousListerRequest, fetchIsPreviousLister)

  yield takeEvery(statelessActions.requestItemAuthenticityModal, requestItemAuthenticityModal)

  yield takeEvery(statelessActions.selectCatalog, selectCatalog)

  yield takeEvery(actions.fetchColorsRequest, fetchColors)
  yield takeEvery(statelessActions.fetchStatuses, fetchStatuses)
  yield takeEvery(statelessActions.fetchSizeGroupsByCatalog, fetchSizeGroupsByCatalog)
  yield takeEvery(statelessActions.fetchVideoGameRatings, fetchVideoGameRatings)
  yield takeLatest(statelessActions.fetchBrands, fetchBrands)
  yield takeLatest(statelessActions.fetchPhotoTips, fetchPhotoTips)

  yield takeEvery(statelessActions.deleteDraft, deleteDraft)
  yield takeEvery(statelessActions.submitItem, submitItem)

  yield takeLatest(
    [
      statelessActions.fetchPriceSuggestions,
      actions.setBrand,
      actions.setStatusId,
      actions.setCatalogId,
    ],
    fetchPriceSuggestions,
  )

  yield takeLatest(
    [
      actions.setBrand,
      actions.setStatusId,
      actions.setCatalogId,
      actions.setColorIds,
      actions.setSizeId,
    ],
    fetchItemTitleSuggestions,
  )

  yield takeLatest(actions.toggleIsSimilarItemsModalOpen, toggleSimilarItemsModal)

  yield takeLatest(actions.setIsbn, fetchBookDetails)

  yield takeLatest(
    [actions.setCatalogId, statelessActions.fetchPackageSizesRequest],
    fetchPackageSizes,
  )
  yield takeLatest(
    [
      actions.setTitle,
      actions.setDescription,
      actions.setCatalogId,
      actions.setPrice,
      mediaUploadActions.uploadPhotoSuccess,
      mediaUploadActions.fetchPhotosSuccess,
      actions.setBrand,
      actions.setStatusId,
      actions.setColorIds,
      actions.setSizeId,
    ],
    fetchSuggestedPackageSizeCode,
  )

  yield takeLatest(actions.toggleIsShippingOptionsModalOpen, toggleShippmentOptionsModal)
  yield takeLatest(statelessActions.fetchShippingOptionsRequest, fetchShippingOptions)

  yield takeLatest(statelessActions.fetchCatalogs, fetchCatalogs)

  yield takeLatest(
    [
      statelessActions.fetchItemOfflineVerificationEligibility,
      actions.setBrand,
      actions.setPrice,
      actions.setCatalogId,
    ],
    fetchItemOfflineVerificationEligibility,
  )
}
