import { CaseReducer, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { FaqEntryType } from 'constants/faq-entry'
import { UiState } from 'constants/ui'
import { FaqEntryModel } from 'types/models'

import { stateName } from './constants'
import { FaqEntryRecord, State } from './types'

export const initialState: State = {
  byId: {},
  byType: {},
}

const setStateById: CaseReducer<State, PayloadAction<{ id: string | number; state: UiState }>> = (
  draft,
  action,
) => {
  const { id, state } = action.payload

  draft.byId[id] = { state }
}

const setStateByType: CaseReducer<State, PayloadAction<{ type: FaqEntryType; state: UiState }>> = (
  draft,
  action,
) => {
  const { type, state } = action.payload

  draft.byType[type] = { state }
}

const fetchFaqEntrySuccess: CaseReducer<
  State,
  PayloadAction<{ faqEntry: FaqEntryModel; type?: FaqEntryType }>
> = (draft, action) => {
  const { faqEntry, type } = action.payload

  const faqEntryRecord: FaqEntryRecord = {
    entry: faqEntry,
    state: UiState.Success,
  }

  if (type) draft.byType[type] = faqEntryRecord
  draft.byId[faqEntry.id] = faqEntryRecord
}

const faqEntrySlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setStateById,
    setStateByType,
    fetchFaqEntrySuccess,
  },
})

export const { actions } = faqEntrySlice
export const plug = { [stateName]: faqEntrySlice.reducer }
export default faqEntrySlice.reducer
