import AppHealth from '@vinted/apphealth-web'

import { withServerSideProxy } from './proxy'

const windowLogHelpers: {
  logError: (typeof AppHealth)['captureError']
  logWarning: (typeof AppHealth)['captureWarning']
  logMessage: (typeof AppHealth)['captureMessage']
} = {
  logError: (...args) => window.apphealth?.captureError(...args),
  logWarning: (...args) => window.apphealth?.captureWarning(...args),
  logMessage: (...args) => window.apphealth?.captureMessage(...args),
}

const windowHelpers = {
  ...windowLogHelpers,
  getLocale: (): string => window.MD?.config.get('lang') || '',
  getFiredEvents: (event: string) =>
    window.MD?.firedEvents ? window.MD.firedEvents[event] || [] : [],
  clearFiredEvents: (event: string) => {
    if (window.MD?.firedEvents) {
      window.MD.firedEvents[event] = []
    }
  },
  scrollToTop: (behavior?: 'smooth'): void => window.scroll({ top: 0, left: 0, behavior }),
  scrollToElementById: (elementId: string, containerClass = 'site-content'): void => {
    const { document } = window

    const container = document.getElementsByClassName(containerClass)[0]
    const elementScrollTo = document.getElementById(elementId)

    if (!container || !elementScrollTo) return

    if (container instanceof HTMLElement) {
      window.scroll({
        top:
          elementScrollTo.getBoundingClientRect().top -
          container.getBoundingClientRect().top -
          container.offsetTop,
        behavior: 'smooth',
      })
    }
  },
  navigateToPage: (path: string) => {
    window.location.href = path
  },
  redirectToPage: (page: string) => window.location.replace(page),
  reloadPage: (): void => window.location.reload(),
  preventLeave: (unloadEvent: BeforeUnloadEvent) => {
    unloadEvent.preventDefault()

    // Workaround for chrome not supporting the above recommended way, for more details see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
    unloadEvent.returnValue = true // eslint-disable-line no-param-reassign

    return true
  },
  toggleOneTrustInfoDisplay: () => {
    window.OneTrust?.ToggleInfoDisplay()
  },
  openBrowsingContext: (...args: Parameters<(typeof window)['open']>) => window.open(...args),
  getBrowserWidth: () => window.innerWidth,
  getBrowserHeight: () => window.innerHeight,
  redirectToPageInNewTab: (page: string) => {
    window.open(page, '_blank')
  },
}

const safeWindowHelpers = withServerSideProxy(windowHelpers, {
  getLocale: () => '',
  getFiredEvents: () => [],
  preventLeave: () => true,
  openBrowsingContext: () => null,
  getBrowserWidth: () => 0,
  getBrowserHeight: () => 0,
  // Server console logs are outputed and forwarded to kibana, next-logger provides automated structure
  /* eslint-disable no-console  */
  logError: (error: Error, context?: Parameters<typeof AppHealth.captureError>[1]) =>
    console.error(error, context),
  logWarning: (warning: unknown, context?: Parameters<typeof AppHealth.captureWarning>[1]) =>
    console.warn(warning, context),
  logMessage: (message: unknown, context?: Parameters<typeof AppHealth.captureMessage>[1]) =>
    console.log(message, context),
  /* eslint-enable no-console */
})

export const {
  getLocale,
  getFiredEvents,
  clearFiredEvents,
  scrollToTop,
  scrollToElementById,
  navigateToPage,
  redirectToPage,
  reloadPage,
  preventLeave,
  toggleOneTrustInfoDisplay,
  openBrowsingContext,
  logWarning,
  logError,
  logMessage,
  getBrowserWidth,
  getBrowserHeight,
  redirectToPageInNewTab,
} = safeWindowHelpers
