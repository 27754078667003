import { createAction } from '@reduxjs/toolkit'
import { Dispatch, SetStateAction } from 'react'

import { Screen } from 'constants/tracking/screens'

import { ItemStatus, stateName } from './constants'

export const fetchItemOfflineVerificationEligibility = createAction(
  `${stateName}/fetchItemOfflineVerificationEligibility`,
)

export const requestItemAuthenticityModal = createAction(
  `${stateName}/requestItemAuthenticityModal`,
  ({
    force = false,
    modalDataOnly = false,
  }: { force?: boolean; modalDataOnly?: boolean } = {}) => ({
    payload: {
      force,
      modalDataOnly,
    },
  }),
)

export const fetchBrands = createAction(
  `${stateName}/fetchBrands`,
  ({
    keyword = '',
    includeAllBrands = false,
  }: {
    keyword?: string
    includeAllBrands?: boolean
  }) => ({
    payload: {
      keyword,
      includeAllBrands,
    },
  }),
)

export const fetchShippingOptionsRequest = createAction(`${stateName}/fetchShippingOptionsRequest`)

export const fetchPriceSuggestions = createAction(`${stateName}/fetchPriceSuggestions`)

export const fetchItemRequest = createAction<{
  id: number | null
  tempUuid: string
  userId: number
  setItemStatus: Dispatch<SetStateAction<ItemStatus>>
}>(`${stateName}/fetchItemRequest`)

export const fetchIsSecondDayListerRequest = createAction(
  `${stateName}/fetchIsSecondDayListerRequest`,
)

export const fetchIsPreviousListerRequest = createAction(
  `${stateName}/fetchIsPreviousListerRequest `,
)

export const fetchPhotoTips = createAction(`${stateName}/fetchPhotoTips`)

export const submitItem = createAction(
  `${stateName}/submitItem`,
  (
    tempUuid: string,
    itemStatus: ItemStatus,
    screenName: Screen,
    saveAsDraft: boolean,
    isItemPushedUp = false,
  ) => ({
    payload: {
      tempUuid,
      itemStatus,
      screenName,
    },
    meta: {
      saveAsDraft,
      isItemPushedUp,
    },
  }),
)
export const deleteDraft = createAction<{ userId: number }>(`${stateName}/deleteDraft`)

export const selectCatalog = createAction<{ catalogId: number; itemStatus: ItemStatus }>(
  `${stateName}/selectCatalog`,
)

export const fetchCatalogs = createAction(`${stateName}/fetchCatalogs`)
export const fetchStatuses = createAction(`${stateName}/fetchStatuses`)
export const fetchSizeGroupsByCatalog = createAction(`${stateName}/fetchSizeGroupsByCatalog`)
export const fetchVideoGameRatings = createAction(`${stateName}/fetchVideoGameRatings`)

export const fetchPackageSizesRequest = createAction(`${stateName}/fetchPackageSizesRequest`)

export const getDropOffLocationPrompt = createAction<{ userId: number }>(
  `${stateName}/getDropOffLocationPrompt`,
)
