import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { Gender } from 'constants/user'
import { UiState } from 'constants/ui'
import { ResponseError } from 'types/api'

import { stateName } from './constants'
import { State, AccountSettings } from './types'

export const initialState: State = {
  settings: {
    realName: undefined,
    gender: Gender.NotSelected,
    birthday: undefined,
    isOnHoliday: false,
    username: '',
    isInternationalTradingEnabled: null,
    isDarkMode: false,
  },
  uiState: UiState.Idle,
}

const setInitialSettings: CaseReducer<State, PayloadAction<{ settings: AccountSettings }>> = (
  draft,
  action,
) => {
  draft.settings = action.payload.settings
}

const setRealName: CaseReducer<State, PayloadAction<{ realName: string | undefined }>> = (
  draft,
  action,
) => {
  draft.settings.realName = action.payload.realName
}

const setGender: CaseReducer<State, PayloadAction<{ gender: Gender }>> = (draft, action) => {
  draft.settings.gender = action.payload.gender
}

const setBirthday: CaseReducer<State, PayloadAction<{ birthday: string | undefined }>> = (
  draft,
  action,
) => {
  draft.settings.birthday = action.payload.birthday
}

const setIsOnHoliday: CaseReducer<State, PayloadAction<{ isOnHoliday: boolean }>> = (
  draft,
  action,
) => {
  draft.settings.isOnHoliday = action.payload.isOnHoliday
}

const setIsDarkMode: CaseReducer<State, PayloadAction<{ isDarkMode: boolean }>> = (
  draft,
  action,
) => {
  draft.settings.isDarkMode = action.payload.isDarkMode
}

const setUsername: CaseReducer<State, PayloadAction<{ username: string }>> = (draft, action) => {
  draft.settings.username = action.payload.username
}

const setIsInternationalTradingEnabled: CaseReducer<
  State,
  PayloadAction<{ isInternationalTradingEnabled: boolean | null }>
> = (draft, action) => {
  const { isInternationalTradingEnabled } = action.payload

  draft.settings.isInternationalTradingEnabled = isInternationalTradingEnabled
}

const setError: CaseReducer<State, PayloadAction<{ error: ResponseError }>> = (draft, action) => {
  draft.error = action.payload.error
}

const changeUiState: CaseReducer<State, PayloadAction<{ uiState: UiState }>> = (draft, action) => {
  const { uiState } = action.payload

  draft.uiState = uiState
}

const accountSettingsSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setInitialSettings,
    setRealName,
    setGender,
    setBirthday,
    setIsOnHoliday,
    setIsDarkMode,
    setUsername,
    setIsInternationalTradingEnabled,
    setError,
    changeUiState,
  },
})

export const { actions } = accountSettingsSlice
export const plug = { [stateName]: accountSettingsSlice.reducer }
export default accountSettingsSlice.reducer
