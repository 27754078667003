import { createSelector } from '@reduxjs/toolkit'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getPhotos = (state: AllState) => localState(state).photos.byId

export const getPhoto = (state: AllState, id: number) => getPhotos(state)[id]

export const getPhotoIds = (state: AllState) => localState(state).photos.ids

export const getUiState = (state: AllState) => localState(state).uiState

export const getPhotosCount = (state: AllState) => {
  return state.mediaUpload.photos.ids.length
}

export const getPhotoTempIds = createSelector(getPhotos, getPhotoIds, (photos, ids) => {
  const tempUuids = ids.map(id => photos[id]?.tempUuid)

  const filteredTempUuids = tempUuids.filter(
    value => typeof value === 'string' && value,
  ) as Array<string> // TODO: use TS fix import "@total-typescript/ts-reset/filter-boolean";

  return filteredTempUuids.length ? filteredTempUuids : undefined
})
