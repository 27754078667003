import { ShipmentDto, CheckoutShipmentDto } from 'types/dtos/shipment'
import { ShipmentModel, CheckoutShipmentModel } from 'types/models/shipment'

import { transformAddress } from './address'
import { transformCarrierDto } from './carrier'
import { transformPackageTypeDto } from './package-type'
import { transformShippingPointDto } from './shipping-point'
import { transformDigitalLabelTypes } from './digital-label-types'

export const transformShipmentDto = ({
  id,
  price,
  status,
  carrier,
  currency,
  label_url,
  rate_uuid,
  to_address,
  from_address,
  package_type,
  pickup_point,
  tracking_code,
  digital_label_types,
  label_type,
}: ShipmentDto): ShipmentModel => ({
  id,
  price,
  status,
  currency,
  rateUuid: rate_uuid,
  labelUrl: label_url,
  trackingCode: tracking_code,
  carrier: transformCarrierDto(carrier),
  packageType: transformPackageTypeDto(package_type),
  toAddress: to_address && transformAddress(to_address),
  fromAddress: from_address && transformAddress(from_address),
  pickupPoint: pickup_point && transformShippingPointDto(pickup_point),
  digitalLabelTypes: digital_label_types && transformDigitalLabelTypes(digital_label_types),
  shipmentLabelType: label_type && label_type,
})

export const transformCheckoutShipmentDto = ({
  id,
  price,
  status,
  carrier,
  currency,
  label_url,
  rate_uuid,
  carrier_id,
  to_address,
  entry_type,
  price_label,
  status_label,
  status_title,
  from_address,
  package_type,
  pickup_point,
  tracking_code,
  transaction_id,
  drop_off_type_id,
  status_updated_at,
  last_mile_carrier,
  first_mile_carrier,
  digital_label_types,
}: CheckoutShipmentDto): CheckoutShipmentModel => ({
  ...transformShipmentDto({
    id,
    price,
    status,
    carrier,
    currency,
    label_url,
    rate_uuid,
    to_address,
    from_address,
    package_type,
    pickup_point,
    tracking_code,
    digital_label_types,
  }),
  carrierId: carrier_id,
  entryType: entry_type,
  priceLabel: price_label,
  statusLabel: status_label,
  statusTitle: status_title,
  transactionId: transaction_id,
  dropOffTypeId: drop_off_type_id,
  statusUpdatedAt: status_updated_at,
  lastMileCarrier: transformCarrierDto(last_mile_carrier),
  firstMileCarrier: transformCarrierDto(first_mile_carrier),
})
