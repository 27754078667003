import { createAction } from '@reduxjs/toolkit'

import { stateName } from './constants'

export const loadFormAttributes = createAction(`${stateName}/loadFormAttributes`)

export const saveSettings = createAction<{
  redirect: string
}>(`${stateName}/saveSettings`)

export const selectCountry = createAction<{
  id: number
}>(`${stateName}/selectCountry`)

export const selectCity = createAction<{
  id: number
}>(`${stateName}/selectCity`)
