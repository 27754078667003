import { CookieConsentVersion } from 'constants/consent'
import { getSystemConfiguration } from 'state/system-configuration/selectors'

import { stateName } from './constants'
import { AllState } from './types'

const localState = (state: AllState) => state[stateName]

export const getCookieConsentVersion = (state: AllState) =>
  getSystemConfiguration(state)?.cookieConsentVersion || localState(state).cookieConsentVersion

export const getIsCookieConsentVersion = (state: AllState) =>
  getCookieConsentVersion(state) !== CookieConsentVersion.None
