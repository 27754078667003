import { BankAccountDto } from 'types/dtos'
import { BankAccountModel } from 'types/models'

export const transformBankAccount = ({
  id,
  name,
  account_number,
  routing_number,
  is_default,
  user_id,
  user_address_id,
  spending_type,
}: BankAccountDto): BankAccountModel => ({
  id,
  name,
  accountNumber: account_number,
  routingNumber: routing_number,
  isDefault: !!is_default,
  userId: user_id,
  userAddressId: user_address_id,
  spendingType: spending_type,
})

export const transformBankAccounts = (
  bankAccounts: Array<BankAccountDto>,
): Array<BankAccountModel> => bankAccounts.map(transformBankAccount)
