import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import useTracking from 'hooks/useTracking'
import { viewScreenEvent } from 'libs/common/event-tracker/events'
import { getScreenName } from 'state/screen/selectors'

type Props = {
  children: JSX.Element
}

const TrackScreen = ({ children }: Props) => {
  const { track } = useTracking()
  const screen = useSelector(getScreenName)

  useEffect(() => {
    track(viewScreenEvent({ screen }))
  }, [screen, track])

  return children
}

export default TrackScreen
