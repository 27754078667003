import {
  ShippingOrderPackageSizesDto,
  ShippingOrderPackageSizeDto,
  PackageSizeSelectionDto,
} from 'types/dtos'
import {
  ShippingOrderPackageSizesModel,
  ShippingOrderPackageSizeModel,
  PackageSizeSelectionModel,
} from 'types/models'

export const transformShippingOrderPackageSizeDto = ({
  id,
  code,
  title,
  education_text,
}: ShippingOrderPackageSizeDto): ShippingOrderPackageSizeModel => ({
  id,
  code,
  title,
  educationText: education_text,
})

export const transformPackageSizeSelectionDto = ({
  selected_package_size_id,
  recommended_package_size_id,
}: PackageSizeSelectionDto): PackageSizeSelectionModel => ({
  selectedPackageSizeId: selected_package_size_id,
  recommendedPackageSizeId: recommended_package_size_id,
})

export const transformShippingOrderPackageSizesDto = ({
  package_sizes,
  package_size_selections,
}: ShippingOrderPackageSizesDto): ShippingOrderPackageSizesModel => ({
  packageSizes: package_sizes.map(transformShippingOrderPackageSizeDto),
  packageSizeSelections: transformPackageSizeSelectionDto(package_size_selections),
})
