'use client'

import { datadogRum } from '@datadog/browser-rum'
import { memo, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import {
  getInitialBreakpoints,
  getServerSideBreakpoints,
  SsrBreakpointsConfigs,
} from 'components/Breakpoint/utils'
import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'

import { getBody, logBodyDiffs } from './utils'

type Props = SsrBreakpointsConfigs

const DISABLED_BREAKPOINTS = {
  phones: false,
  portables: false,
  tablets: false,
  tabletsUp: false,
  desktops: false,
  wide: false,
}

const TrackRerender = memo((props: Props) => {
  const isDomCompareEnabled = useSelector(
    getIsFeatureSwitchEnabled('next_hydration_error_dom_compare'),
  )

  const ssrBreakpoints = useRef({
    ...DISABLED_BREAKPOINTS,
    ...getServerSideBreakpoints(props),
  })
  const clientBreakpoints = useRef({
    ...DISABLED_BREAKPOINTS,
    ...getInitialBreakpoints(props),
  })
  const initialDomRef = useRef(getBody())

  useEffect(() => {
    const initialBody = initialDomRef.current
    initialDomRef.current = ''

    if (!initialBody) return
    if (!isDomCompareEnabled) return

    const afterBody = getBody()

    if (initialBody === afterBody) return

    logBodyDiffs({
      before: initialBody,
      after: afterBody,
      logContext: {
        ...props,
        ssrBreakpoints: ssrBreakpoints.current,
        clientBreakpoints: clientBreakpoints.current,
        dd_view_id: datadogRum.getInternalContext()?.view?.id,
      },
    })
  }, [isDomCompareEnabled, props])

  return null
})

export default TrackRerender
