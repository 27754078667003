import { createSelector } from 'reselect'

import { State, AllState } from './types'
import { stateName } from './constants'

const localState = (state: AllState): State => state[stateName]

export const getUserInfo = (state: AllState) => ({
  realName: localState(state).settings.realName,
  gender: localState(state).settings.gender,
  birthday: localState(state).settings.birthday,
})

export const getIsOnHoliday = (state: AllState) => localState(state).settings.isOnHoliday

export const getIsDarkMode = (state: AllState) => localState(state).settings.isDarkMode

export const getUsername = (state: AllState) => localState(state).settings.username

export const getAccountSettings = (state: AllState) => localState(state).settings

export const getSettingsUiState = (state: AllState) => localState(state).uiState

export const getError = (state: AllState) => localState(state).error

export const getIsInternationalTradingEnabled = createSelector(
  getAccountSettings,
  ({ isInternationalTradingEnabled }) => isInternationalTradingEnabled,
)
