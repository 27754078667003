import { FilterModel } from 'types/models'

export const stateName = 'catalogFilters' as const

export const FILTER_PRICE_FROM: keyof FilterModel = 'priceFrom'
export const FILTER_PRICE_TO: keyof FilterModel = 'priceTo'

export const CATALOG_FETCH_DEBOUNCE_AMOUNT = 500
export const CATALOG_FILTERS_SEARCH_DEBOUNCE_AMOUNT = 300
export const SCROLL_TO_TOP_DELAY = 1000

export const CATALOG_ROUTE_REGEX = /^\/catalog\/(\d+).*$/
export const BRAND_ROUTE_REGEX = /^\/brand\/(\d+).*$/
