// HTTP statuses
export enum HttpStatus {
  Ok = 200,
  Created = 201,
  TemporarilyMoved = 302,
  MovedPermanently = 301,
  TemporaryRedirect = 307,
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  RequestTimeout = 408,
  PayloadTooLarge = 413,
  UnprocessableEntity = 422,
  TooEarly = 425,
  TooManyRequests = 429,
  InternalError = 500,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
}

// Vinted specific API codes
export enum ResponseCode {
  HttpError = -200,
  JsError = -100,
  SessionFromTokenError = -99,
  Ok = 0,
  InvalidUser = 10,
  TransactionInProgress = 20,
  LoginRequired = 21,
  ValidationError = 99,
  PhotoUploadError = 50,
  PhotoDimensionsTooBig = 51,
  PhotoMinimumCountRequired = 52,
  InvalidToken = 100,
  IpBlocked = 102,
  InvalidProtocol = 103,
  NotFound = 104,
  ServerError = 105, // Generic unhandled error
  AccessDenied = 106,
  LockConflict = 107,
  KYCError = 108,
  UnavailableCarrierError = 110,
  ConfirmEmail = 112,
  UnsupportedFeature = 113,
  CheckoutError = 114,
  UserVerificationRequired = 115,
  Deprecated = 116,
  FollowingTooManyMembers = 117,
  User2FAConfirmation = 118,
  PaymentProcessingError = 119,
  LoginWithoutEmail = 121,
  Required2FA = 122,
  CantDeliver2FACode = 123,
  CreditCardExpired = 124,
  CountryChangeNotAllowed = 127,
  BusinessAccountConversionInProgress = 128,
  BusinessAccountConversionFailed = 129,
  CheckoutChecksumMismatchError = 130,
  IncompleteTaxAddress = 131,
  HarassmentInMessageDetected = 134,
  EmailSharingDetected = 136,
  EmailVerificationCodeExpired = 140,
  ShippingDiscountAlreadyUsed = 142,
  PaymentAlreadyProcessed = 143,
  TwoFARequired = 146,
  FidoVerificationRequired = 149,
  TaxpayerSellingBlocked = 152,
  ActionRestricted = 156,
}
