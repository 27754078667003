import { useSelector } from 'react-redux'
import { useCallback } from 'react'

import {
  getIsAuthenticated,
  getIsWebView,
  getSessionBrazeConfig,
  getUserExternalId,
} from 'state/session/selectors'

import { getIsFeatureSwitchEnabled } from 'state/feature-switches/selectors'
import { getBrazeConfig } from 'state/system-configuration/selectors'
import {
  logBrazeError,
  logBrazeMessage,
  logConfigurationFailure,
} from 'libs/common/braze/utils/error-loggers'
import { removeAllDeferredBrazeCustomEvents } from 'libs/common/braze/utils/custom-event'
import { disableBraze } from 'libs/common/braze/utils/async-utils'
import { FALLBACK_INITIALIZE_RETURN_VALUE, initializeBraze } from 'libs/common/braze/initialize'
import { getCookieConsentVersion } from 'state/consent/selectors'
import useIsConsentGroupEnabled from 'hooks/useIsConsentGroupEnabled'
import { ConsentGroup } from 'constants/consent'

const useInitializeBraze = () => {
  const userExternalId = useSelector(getUserExternalId)
  const sessionBrazeConfigs = useSelector(getSessionBrazeConfig)
  const brazeConfigs = useSelector(getBrazeConfig)
  const isLoggedIn = useSelector(getIsAuthenticated)
  const isBrazeEnabledFeatureSwitch = useSelector(getIsFeatureSwitchEnabled('braze_sdk_enabled'))
  const isLoggingToConsoleEnabled = useSelector(getIsFeatureSwitchEnabled('web_braze_logging'))
  const isLoggingToAppHealthEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_braze_log_app_health'),
  )
  const isBrazePushNotificationsEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_braze_push_notifications'),
  )
  const isNonErrorLoggingEnabled = useSelector(
    getIsFeatureSwitchEnabled('web_braze_non_error_logging'),
  )
  const isBrazeInWebViewEnabled = useSelector(
    getIsFeatureSwitchEnabled('braze_sdk_in_webview_enabled'),
  )
  const cookieConsentVersion = useSelector(getCookieConsentVersion)
  const isConsentGroupEnabled = useIsConsentGroupEnabled(ConsentGroup.Targeting)

  const isWebView = useSelector(getIsWebView)

  const isBrazeEnabled =
    isBrazeEnabledFeatureSwitch && ((isWebView && isBrazeInWebViewEnabled) || !isWebView)

  // TODO: Remove sessionBrazeConfigs 👇 after we fully migrate to the next.js because the same data is already existing in the systemConfigs
  const { sdkEndpoint, sdkKey, safariWebsitePushId } = brazeConfigs || sessionBrazeConfigs

  return useCallback(async (): ReturnType<typeof initializeBraze> => {
    if (!isBrazeEnabled) {
      disableBraze()
      removeAllDeferredBrazeCustomEvents()

      return FALLBACK_INITIALIZE_RETURN_VALUE
    }

    if (!userExternalId || !isLoggedIn || !sdkKey) {
      disableBraze()
      logConfigurationFailure({
        brazeSdkKey: sdkKey,
        userExternalId,
        isLoggedIn,
      })

      return FALLBACK_INITIALIZE_RETURN_VALUE
    }

    const isLoggingEnabled = isLoggingToAppHealthEnabled || isLoggingToConsoleEnabled

    const logger = (message: string) => {
      const errorKeywords = ['error', 'exception', 'fail', 'fatal']
      const isError = errorKeywords.some(keyword => message.toLowerCase().includes(keyword))

      if (isLoggingToAppHealthEnabled && (isNonErrorLoggingEnabled || isError)) {
        logBrazeMessage(`Braze_SDK_log: ${message}`)
      }

      // Default Braze behaviour that we want to keep
      // eslint-disable-next-line no-console
      if (isLoggingToConsoleEnabled) console.log(message)
    }

    return initializeBraze(
      {
        cookieConsentVersion,
        isConsentGroupEnabled,
        userExternalId,
        apiKey: sdkKey,
        safariWebsitePushId: isBrazePushNotificationsEnabled ? safariWebsitePushId : undefined,
        baseUrl: sdkEndpoint,
        logger: isLoggingEnabled ? logger : undefined,
      },
      (error: unknown) => {
        disableBraze()

        if (error instanceof Error) logBrazeError(error)
      },
    )
  }, [
    isBrazeEnabled,
    cookieConsentVersion,
    isConsentGroupEnabled,
    userExternalId,
    isLoggedIn,
    sdkKey,
    isLoggingToAppHealthEnabled,
    isLoggingToConsoleEnabled,
    isBrazePushNotificationsEnabled,
    safariWebsitePushId,
    sdkEndpoint,
    isNonErrorLoggingEnabled,
  ])
}

export default useInitializeBraze
