import { isNil, omitBy, isEmpty } from 'lodash'

import {
  DeliveryTypePickupDto,
  DeliveryTypeCustomDto,
  DeliveryTypeMeetupDto,
  DeliveryTypeHomeDto,
  DeliveryTypesDto,
} from 'types/dtos'
import {
  DeliveryTypePickupModel,
  DeliveryTypeCustomModel,
  DeliveryTypeMeetupModel,
  DeliveryTypeHomeModel,
  DeliveryTypesModel,
} from 'types/models'
import { DeliveryTypesResp } from 'types/api'

import { transformShippingOptionDto, transformShippingOptionDtos } from './shipping-option'
import { transformCurrencyAmountDto } from './currency-amount'

export const transformDeliveryTypeHomeDto = ({
  price,
  delivery_type,
  shipping_options,
}: DeliveryTypeHomeDto): DeliveryTypeHomeModel => ({
  price: transformCurrencyAmountDto(price),
  deliveryType: delivery_type,
  shippingOptions: shipping_options && transformShippingOptionDtos(shipping_options),
})

export const transformDeliveryTypePickupDto = ({
  price,
  delivery_type,
  package_types_ids,
  contains_different_prices,
  shipping_option,
  shipping_options,
  selected_rate_uuid,
}: DeliveryTypePickupDto): DeliveryTypePickupModel => ({
  price: transformCurrencyAmountDto(price),
  deliveryType: delivery_type,
  packageTypeIds: package_types_ids,
  containsDifferentPrices: contains_different_prices,
  shippingOption: shipping_option && transformShippingOptionDto(shipping_option),
  shippingOptions: shipping_options && transformShippingOptionDtos(shipping_options),
  selectedRateUuid: selected_rate_uuid,
})

export const transformDeliveryTypeCustomDto = ({
  price,
  delivery_type,
}: DeliveryTypeCustomDto): DeliveryTypeCustomModel => ({
  price: transformCurrencyAmountDto(price),
  deliveryType: delivery_type,
})

export const transformDeliveryTypeMeetupDto = ({
  delivery_type,
}: DeliveryTypeMeetupDto): DeliveryTypeMeetupModel => ({
  deliveryType: delivery_type,
})

export const transformDeliveryTypesDto = ({
  home,
  pickup,
  meetup,
  custom,
}: DeliveryTypesDto): DeliveryTypesModel | null => {
  const deliveryTypes = omitBy(
    {
      pickup: pickup && transformDeliveryTypePickupDto(pickup),
      home: home && transformDeliveryTypeHomeDto(home),
      custom: custom && transformDeliveryTypeCustomDto(custom),
      meetup: meetup && transformDeliveryTypeMeetupDto(meetup),
    },
    isNil,
  )

  return isEmpty(deliveryTypes) ? null : deliveryTypes
}

export const transformDeliveryTypesResponse = ({
  selected_delivery_type,
  delivery_types,
}: DeliveryTypesResp) => ({
  selectedDeliveryType: selected_delivery_type,
  deliveryTypes: transformDeliveryTypesDto(delivery_types),
})
