export enum Hostname {
  At = 'vinted.at',
  AtSandbox = 'sandbox-at.vinted.net',
  Be = 'vinted.be',
  BeSandbox = 'sandbox-be.vinted.net',
  Cz = 'vinted.cz',
  OldCz = 'old.vinted.cz',
  CzSandbox = 'sandbox-cz.vinted.net',
  De = 'vinted.de',
  OldDe = 'kleiderkreisel.de',
  DeSandbox = 'sandbox-de.vinted.net',
  OldDeBabies = 'mamikreisel.de',
  Es = 'vinted.es',
  OldEs = 'local.vinted.es',
  EsSandbox = 'sandbox-es.vinted.net',
  Fr = 'vinted.fr',
  FrSandbox = 'sandbox-fr.vinted.net',
  Hu = 'vinted.hu',
  HuSandbox = 'sandbox-hu.vinted.net',
  Lt = 'vinted.lt',
  LtSandbox = 'sandbox-new-lt.vinted.net',
  Lu = 'vinted.lu',
  LuSandbox = 'sandbox-lu.vinted.net',
  Nl = 'vinted.nl',
  OldNl = 'local.vinted.nl',
  NlSandbox = 'sandbox-nl.vinted.net',
  Pl = 'vinted.pl',
  OldPl = 'old.vinted.pl',
  PlSandbox = 'sandbox-pl.vinted.net',
  OldPlSandbox = 'sandbox-pl-old.vinted.net',
  Se = 'vinted.se',
  SeSandbox = 'sandbox-se.vinted.net',
  Sk = 'vinted.sk',
  SkSandbox = 'sandbox-sk.vinted.net',
  Pt = 'vinted.pt',
  PtSandbox = 'sandbox-pt.vinted.net',
  Uk = 'vinted.co.uk',
  OldUk = 'old.vinted.co.uk',
  UkSandbox = 'sandbox-uk.vinted.net',
  Us = 'vinted.com',
  OldUs = 'old.vinted.com',
  UsSandbox = 'sandbox-us.vinted.net',
  It = 'vinted.it',
  ItSandbox = 'sandbox-it.vinted.net',
  Ro = 'vinted.ro',
  RoSandbox = 'sandbox-ro.vinted.net',
  Dk = 'vinted.dk',
  DkSandbox = 'sandbox-dk.vinted.net',
  Fi = 'vinted.fi',
  FiSandbox = 'sandbox-fi.vinted.net',
  Hr = 'vinted.hr',
  HrSandbox = 'sandbox-hr.vinted.net',
  Gr = 'vinted.gr',
  GrSandbox = 'sandbox-gr.vinted.net',
}

export const SANDBOX_HOSTNAME = Object.keys(Hostname).reduce<Record<string, string>>(
  (accumulator, key) => {
    if (key.endsWith('Sandbox')) {
      accumulator[key] = Hostname[key as keyof typeof Hostname]
    }

    return accumulator
  },
  {},
) as {
  [P in keyof typeof Hostname & `${string}Sandbox`]: (typeof Hostname)[P]
}

export const OLD_HOST_REDIRECTS: Record<
  (typeof Hostname)[keyof typeof Hostname & `Old${string}`],
  Hostname
> = {
  [Hostname.OldCz]: Hostname.Cz,
  [Hostname.OldDe]: Hostname.De,
  [Hostname.OldDeBabies]: Hostname.De,
  [Hostname.OldEs]: Hostname.Es,
  [Hostname.OldNl]: Hostname.Nl,
  [Hostname.OldPl]: Hostname.Pl,
  [Hostname.OldPlSandbox]: Hostname.PlSandbox,
  [Hostname.OldUk]: Hostname.Uk,
  [Hostname.OldUs]: Hostname.Us,
}

// Please keep in sync with config/constants/hostnames.rb
export const DOMAIN_ALIASES: Record<
  'sandbox' | 'production',
  Record<string, Hostname | `www.${Hostname}`>
> = {
  sandbox: {
    'fr-FR': Hostname.FrSandbox,
    'es-ES': Hostname.EsSandbox,
    'fr-BE': Hostname.BeSandbox,
    'fr-LU': Hostname.LuSandbox,
    'nl-NL': Hostname.NlSandbox,
    'lt-LT': Hostname.LtSandbox,
    'de-DE': Hostname.DeSandbox,
    'de-AT': Hostname.AtSandbox,
    'it-IT': Hostname.ItSandbox,
    'en-GB': Hostname.UkSandbox,
    'pt-PT': Hostname.PtSandbox,
    'en-US': Hostname.UsSandbox,
    'cs-CZ': Hostname.CzSandbox,
    'sk-SK': Hostname.SkSandbox,
    'pl-PL': Hostname.PlSandbox,
    'sv-SE': Hostname.SeSandbox,
    'hu-HU': Hostname.HuSandbox,
    'ro-RO': Hostname.RoSandbox,
    'da-DK': Hostname.DkSandbox,
    'fi-FI': Hostname.FiSandbox,
    'hr-HR': Hostname.HrSandbox,
    'gr-GR': Hostname.GrSandbox,
  },
  production: {
    'fr-FR': `www.${Hostname.Fr}`,
    'es-ES': `www.${Hostname.Es}`,
    'fr-BE': `www.${Hostname.Be}`,
    'fr-LU': `www.${Hostname.Lu}`,
    'nl-NL': `www.${Hostname.Nl}`,
    'lt-LT': `www.${Hostname.Lt}`,
    'de-DE': `www.${Hostname.De}`,
    'de-AT': `www.${Hostname.At}`,
    'it-IT': `www.${Hostname.It}`,
    'en-GB': `www.${Hostname.Uk}`,
    'pt-PT': `www.${Hostname.Pt}`,
    'en-US': `www.${Hostname.Us}`,
    'cs-CZ': `www.${Hostname.Cz}`,
    'sk-SK': `www.${Hostname.Sk}`,
    'pl-PL': `www.${Hostname.Pl}`,
    'sv-SE': `www.${Hostname.Se}`,
    'hu-HU': `www.${Hostname.Hu}`,
    'ro-RO': `www.${Hostname.Ro}`,
    'da-DK': `www.${Hostname.Dk}`,
    'fi-FI': `www.${Hostname.Fi}`,
    'hr-HR': `www.${Hostname.Hr}`,
    'gr-GR': `www.${Hostname.Gr}`,
  },
}
