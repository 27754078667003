import { useEffect, useRef } from 'react'

/**
 * Attaches an event listener to the window object for the specified event.
 */
export default function useWindowEvent<T extends keyof WindowEventMap>(
  eventName: T,
  handler: (event: WindowEventMap[T]) => void,
) {
  const handlerRef = useRef(handler)

  useEffect(() => {
    handlerRef.current = handler
  }, [handler])

  useEffect(() => {
    const wrappedHandler = (event: WindowEventMap[T]) => handlerRef.current(event)

    window.addEventListener(eventName, wrappedHandler)

    return () => window.removeEventListener(eventName, wrappedHandler)
  }, [eventName])
}
