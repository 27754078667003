import { createSlice, PayloadAction, CaseReducer } from '@reduxjs/toolkit'

import { State, UiState } from './types'
import { stateName, PromoAfterItemUpload } from './constants'

const initialUiState: UiState = {
  currentPromotion: null,
  promotions: {
    pushedUp: false,
    feedback: false,
    uploadAnotherItemTip: false,
  },
}

export const initialState: State = {
  uploadedItemId: null,
  ui: initialUiState,
}

const setUploadedItemId: CaseReducer<State, PayloadAction<{ id: number }>> = (draft, action) => {
  const { id } = action.payload

  draft.uploadedItemId = id
}

const setShowPushedUp: CaseReducer<State, PayloadAction<boolean>> = (draft, action) => {
  draft.ui.promotions.pushedUp = action.payload
}

const setShowFeedback: CaseReducer<State, PayloadAction<boolean>> = (draft, action) => {
  draft.ui.promotions.feedback = action.payload
}

const setShowUploadAnotherItemTip = (draft: State, action: PayloadAction<boolean>) => {
  draft.ui.promotions.uploadAnotherItemTip = action.payload
}

const setCurrentPromotionAfterItemUpload: CaseReducer<
  State,
  PayloadAction<{ promo: PromoAfterItemUpload }>
> = (draft, action) => {
  const { promo } = action.payload

  draft.ui.currentPromotion = promo
}

const profileSlice = createSlice({
  name: stateName,
  initialState,
  reducers: {
    setShowFeedback,
    setUploadedItemId,
    setShowPushedUp,
    setCurrentPromotionAfterItemUpload,
    setShowUploadAnotherItemTip,
  },
})

export const { actions } = profileSlice
export const plug = { [stateName]: profileSlice.reducer }
export default profileSlice.reducer
